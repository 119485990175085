import React from 'react';
import styles from './styles.module.scss';

const CheckYourEmailMessage = ({ onLinkClick }) => {

    return <div className={styles.messageParentContainer}>
        <div className={styles.messageContainer}>
            <div className={styles.heading}>
                Check your email
            </div>
            <div className={styles.content1}>
                An email with a sign-in link has been sent to your email address.
                Please check your inbox to proceed with signing in.
            </div>
            <div className={styles.content2}>
                Remember, you can only use this link in the browser you're using right now.
                You can also close this window if you want to.
            </div>
            <div className={styles.content1}>
                <div onClick={onLinkClick} className={styles.clickHereLink}>Click here</div>to login with different email
            </div>

        </div>
    </div>

}

export default CheckYourEmailMessage;