import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
// import API from '../../network';
import Config from '../../config';

const httpClient = axios.create();

httpClient.defaults.timeout = 180000;

const Unsubscribe = () => {
  const [status, setStatus] = useState('loading');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = () => {
      const params = new URLSearchParams(location.search);
      const email = params.get('email');

      const config = {
        headers: {
          'content-type': 'application/json',
          Accept: '/'
        }
      };

      if (!email) {
        navigate('/');
        return;
      }

      httpClient
        .post(
          `${Config.unsubscribe}?email=${email}`,
          config
        )
        .then(() => {
          setStatus('success');
        })
        .catch(() => {
          setStatus('error');
        });
    };

    unsubscribe();
  }, [location, navigate]);

  return (
    <div style={{ 
      height: '100vh', 
      display: 'flex', 
      flexDirection: 'column',
      alignItems: 'center', 
      justifyContent: 'center',
      textAlign: 'center',
      padding: '20px',
      backgroundColor: '#f0f2f5'
    }}>
      {status === 'loading' && (
        <div className="loader" style={{
          border: '4px solid #f3f3f3',
          borderTop: '4px solid #3498db',
          borderRadius: '50%',
          width: '50px',
          height: '50px',
          animation: 'spin 1s linear infinite',
        }}></div>
      )}
      {status === 'success' && (
        <div>
          <h2 style={{ color: '#4CAF50', marginBottom: '20px' }}>Successfully Unsubscribed</h2>
          <p>You have been successfully unsubscribed from our newsletter.</p>
          <p>We're sorry to see you go!</p>
        </div>
      )}
      {status === 'error' && (
        <div>
          <h2 style={{ color: '#f44336', marginBottom: '20px' }}>Something Went Wrong</h2>
          <p>We couldn't process your unsubscribe request at this time.</p>
          <p>Please try again later or contact our support team.</p>
        </div>
      )}
    </div>
  );
};

export default Unsubscribe;
