import React from 'react'
import {
    CircularProgressbar,
    CircularProgressbarWithChildren,
    buildStyles
} from 'react-circular-progressbar';
// import 'react-circular-progressbar/dist/styles.module.css';
import styles from './styles.module.css'
// import ChangingProgressProvider from '../ChangingProgressProvider';

const MultiCircularProgressBar = props => {
    const { percentScore, barStyle } = props
    let pathColor;
    let outSidePathColor;
    let gradientCircleColor;
    if (percentScore <= 35) {
        pathColor = '#fd5656';
        outSidePathColor = '#4CAEE6'
        gradientCircleColor = 'radial-gradient(rgb(255, 255, 255) 5%, rgb(254, 221, 226) 70%, transparent)'
    } else if (percentScore < 65) {
        pathColor = '#fe895d';
        outSidePathColor = '#4CAEE6'
        gradientCircleColor = 'radial-gradient(rgb(255, 255, 255) 5%, rgb(254, 223, 205) 70%, transparent)'
    } else {
        pathColor = '#4baee7'
        outSidePathColor = '#fd5656'
        gradientCircleColor = 'radial-gradient(rgb(255, 255, 255) 5%, rgb(222, 241, 254) 70%, transparent)'
    }
    return (
        <div id="RelevanceScore" style={barStyle} className={styles.progressBarWrap}>
            <CircularProgressbarWithChildren
                value={100}
                circleRatio={0.75}
                className={styles.dashboard}
                strokeWidth={3}
                styles={buildStyles({
                    rotation: 1 / 2 + 1 / 8,
                    strokeLinecap: "round",
                    pathColor: outSidePathColor,
                })}
            >
                {/*
                Width here needs to be (100 - 2 * strokeWidth)% 
                in order to fit exactly inside the outer progressbar.
                */}
                <div style={{ width: "75%", background: gradientCircleColor, borderRadius: '50%' }}>
                    <CircularProgressbar
                        value={percentScore || 0}
                        className={styles.innerCircle}
                        text={`${percentScore}%`}
                        background
                        backgroundPadding={-2}
                        strokeWidth={3}
                        counterClockwise
                        styles={buildStyles({
                            trailColor: "transparent",
                            pathColor: pathColor,
                            backgroundColor: 'transparent',
                            strokeLinecap: "round",
                            textColor: '#000000'
                        })
                        }
                    />
                </div>
            </CircularProgressbarWithChildren>
        </div>
    );
}

export default MultiCircularProgressBar;