import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        When most people join LinkedIn, they think it's just another social media platform - but they 
        couldn't be more wrong. Fast forward to 2024, and LinkedIn has become a powerhouse for 
        professional networking, job hunting, and career advancement. This article will take you on a 
        journey through the LinkedIn labyrinth, sharing secrets uncovered by seasoned professionals 
        who've mastered the platform.
      </div>

      <div className={styles.title}>
        LinkedIn: Your Career's Command Center
      </div>

      <div className={styles.text}>
        Forget everything you think you know about job hunting. LinkedIn isn't just a place to post your 
        resume and hope for the best. It's a living, breathing ecosystem of opportunities - if you know 
        where to look.
      </div>

      <div className={styles.text}>
        As of 2024, LinkedIn boasts over 1 billion members across 200+ countries. But here's the kicker: 
        77 percent of recruiters rely on LinkedIn and 54 percent of employers have rejected candidates 
        based on their social media profiles. [1]
      </div>

      <div className={styles.title}>
        LinkedIn's Unseen Job Market
      </div>

      <div className={styles.text}>
        Consider the story of Mark, a Senior Marketing Manager at a top tech firm. He found his current 
        role without ever applying to a job posting. How? By tapping into LinkedIn's hidden job market.
      </div>

      <div className={styles.text}>
        Here's how it went down:
      </div>
      <br />
      
      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Mark consistently shared insights about marketing trends and campaign results (without revealing 
            confidential info, of course).
          </li>
          <li>
            He engaged thoughtfully on posts from industry leaders he admired.
          </li>
          <li>
            One day, the CMO of his dream company commented on one of his posts.
          </li>
          <li>
            Mark seized the opportunity and sent a personalized connection request.
          </li>
          <li>
            They had a great conversation about the industry, and Mark casually mentioned he was open to 
            new opportunities.
          </li>
          <li>
            Two weeks later, he was interviewing for a position that hadn't even been created yet.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        This isn't just Mark's story. Take Sarah, a software engineer struggling to break into the AI 
        field. She started engaging with content from AI researchers on LinkedIn. One insightful comment 
        led to a conversation, which led to an introduction, which led to a job offer at a cutting-edge 
        AI startup.
      </div>

      <div className={styles.text}>
        The lesson? Your next opportunity is probably hiding in plain sight, nestled in your LinkedIn 
        network.
      </div>

      <div className={styles.title}>
        Networking on LinkedIn: Building Genuine Connections
      </div>

      <div className={styles.text}>
        Think of LinkedIn as the world's largest professional cocktail party. You wouldn't walk into a 
        real-life networking event and start handing out resumes, would you? (If you would, we need to 
        talk.)
      </div>

      <div className={styles.text}>
        Instead, focus on building genuine connections. Here's a tried-and-true approach:
      </div>
      <br />
      
      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Identify 5-10 companies you'd love to work for.
          </li>
          <li>
            Find second-degree connections for those who work there.
          </li>
          <li>
            Craft a personalized connection request that shows you've done your homework.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>For example:</strong>
      </div>

      <div className={styles.highlight}>
        "Hi Alex, I noticed you're leading the AI ethics initiative at TechGiant Inc. I recently 
        published an article on the intersection of AI and privacy rights that I think might interest 
        you. I'd love to connect and perhaps chat about the evolving landscape of AI ethics in tech. 
        Coffee's on me (virtually, of course)!"
      </div>

      <div className={styles.text}>
        This approach has a 70% success rate for many professionals. Why? Because it's genuine, shows 
        interest, and offers value upfront.
      </div>

      <div className={styles.title}>
        Content Strategy that Works
      </div>

      <div className={styles.text}>
        Consider this striking statistic.: according to LinkedIn's own data, only 1% of users create 
        content regularly.[2] That means if you're consistently sharing valuable insights, you're 
        already in the top 1% of LinkedIn users in terms of visibility.
      </div>

      <div className={styles.text}>
        But don't just post for the sake of posting. Think quality over quantity. Here's a content 
        strategy that's helped many professionals build a following of over 20,000 industry connections:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Monday: Share an insight from a recent project (anonymized, of course).
          </li>
          <li>
            Wednesday: Comment on a major industry news story.
          </li>
          <li>
            Friday: Post a "Friday Reflection" - a lesson learned or a question to ponder over the weekend.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Remember, your content doesn't have to be earth-shattering. It just has to be authentic and 
        valuable to your network.
      </div>

      <div className={styles.title}>
        Decoding the LinkedIn Algorithm
      </div>

      <div className={styles.text}>
        LinkedIn's algorithm isn't some mystical force - it's more like a discerning party host, always 
        on the lookout for engaging conversations to promote.
      </div>

      <div className={styles.text}>
        Here's what successful LinkedIn users have learned about staying on the algorithm's good side:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Post when your audience is most active. For many, that's between 8-9 AM on weekdays.
          </li>
          <li>
            Use native LinkedIn features like polls and documents - the algorithm loves keeping users 
            on the platform.
          </li>
          <li>
            Engage with others' content genuinely. The algorithm rewards those who contribute to 
            conversations, not just start them.
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Personal Branding on LinkedIn
      </div>

      <div className={styles.text}>
        Your LinkedIn profile isn't just an online resume - it's your personal brand's homepage. And 
        personal branding isn't just for influencers and CEOs.
      </div>

      <div className={styles.text}>
        Here's how savvy professionals craft their personal brand on LinkedIn:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            The headline isn't just a job title. It's a value proposition: "Turning Marketing Chaos into 
            Digital Symphony | Helping Brands Find Their Voice in the Noise"
          </li>
          <li>
            The "About" section tells a professional story, including challenges overcome and lessons 
            learned.
          </li>
          <li>
            The "Featured" section showcases the best work, including articles written and projects to 
            be proud of.
          </li>
          <li>
            Regularly ask for recommendations from colleagues and clients after successful projects.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Remember, your personal brand should be authentic. Don't try to be Gary Vaynerchuk if you're 
        more of a Warren Buffett. Embrace your unique professional personality and let it shine through 
        your profile and content.
      </div>

      <div className={styles.title}>
        The Resume Connection
      </div>

      <div className={styles.text}>
        While we're diving deep into LinkedIn strategies, let's not forget about the trusty resume. 
        Think of your LinkedIn profile as the trailer, and your resume as the full-length feature film. 
        They should complement each other perfectly.
      </div>

      <div className={styles.text}>
        Want a resume that perfectly complements your LinkedIn game? Check out Panna Resume Builder. 
        It's like having a professional resume writer in your pocket, helping you craft a resume that 
        complements and strengthens your LinkedIn presence.
      </div>
      <br />

      <ResumeBuildButton />

      <div className={styles.title}>
        Conclusion: Your LinkedIn Journey Starts Now
      </div>

      <div className={styles.text}>
        LinkedIn isn't just a platform - it's a career ecosystem waiting for you to make your mark. By 
        tapping into the hidden job pool, crafting a standout personal brand, and leveraging the power 
        of your network, you're not just job searching - you're building a career.
      </div>

      <div className={styles.text}>
        So, are you ready to dive in and make waves in your professional journey? Your LinkedIn adventure 
        awaits!
      </div>

      <div className={styles.title}>
        Frequently Asked Questions:
      </div>

      <div className={styles.text}>
        <strong>1.How often should I update my LinkedIn profile?</strong><br />
        A: Aim to review and update your profile at least monthly, or whenever you have new accomplishments 
        or skills to add.
      </div>

      <div className={styles.text}>
        <strong>2.Can recruiters find me on LinkedIn if my profile is not complete?</strong><br />
        A: While possible, a complete profile significantly increases your visibility to recruiters.
      </div>

      <div className={styles.text}>
        <strong>3.How many connections do I need on LinkedIn to be effective in job searching?</strong><br />
        A: Quality trumps quantity. Focus on meaningful connections in your industry rather than a specific number.
      </div>

      <div className={styles.text}>
        <strong>4.Is it appropriate to ask for a job directly on LinkedIn?</strong><br />
        A: It's better to build relationships first. Express interest in opportunities after 
        establishing a connection.
      </div>

      <div className={styles.text}>
        <strong>5.How can I make my LinkedIn profile stand out?</strong><br />
        A: Use a professional photo, craft a compelling headline, and regularly share industry-relevant 
        content.
      </div>

      <div className={styles.title}>
        References
      </div>

      <div className={styles.text}>
        [1]
        &nbsp;
        <a
          href="https://topresume.com/career-advice/7-top-job-search-statistics"
          target='_blank'
        >
          https://topresume.com/career-advice/7-top-job-search-statistics
        </a>
        <br />
        [2]
        &nbsp;
        <a
          href="https://kinsta.com/blog/linkedin-statistics"
          target='_blank'
        >
          https://kinsta.com/blog/linkedin-statistics
        </a>
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
