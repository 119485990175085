

/* eslint-disable import/imports-first */
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import AuthProvider from './context/AuthContext';

import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { store, persistor } from './store';

if (window.top !== window.self) {
  window.top.location = window.self.location;
}
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  ReactGA.initialize('GTM-MR2Z4FL');
}

ReactDOM.render(
  <AuthProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </AuthProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
