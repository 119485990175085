import hero from '../../../../assets/insights/resumeGap/hero.png';

export const meta = {
  author: "",
  date: "2024-07-03",
  type: "Insights",
  title: "The Resume Gap",
  subTitle: " Transforming Career Breaks into Opportunities",
  description: "In today's fast-paced, competitive job market, it is not uncommon for professionals to have gaps in their resumes. Whether it's due to personal reasons, further education, or job search challenges, a career break can often raise questions and concerns among potential employers.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/the-resume-gap",
};