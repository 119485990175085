import React from 'react'
import styles from './styles.module.css'
import Button from '../Button';

const JobDescriptionViewer = props => {
    const { jdTitle, value, onchangeJD, onBack, onMeasureClick } = props;
    return (
        <div className={styles.jdViewerContainer}>
            <div className={styles.titleStyle}>{`${jdTitle} Job Description`}</div>
            <textarea value={value} onChange={onchangeJD} className={styles.JdViewerStyle} />
            <div className={styles.buttonWrap}>
                <Button text="Back" buttonStyle={styles.cancelButtonStyle} onClick={onBack} />
                <Button id="ViewerMeasureButton" text="Measure" buttonStyle={styles.saveButtonStyle} onClick={onMeasureClick} disable={!value} />
            </div>
        </div>
    );
}

export default JobDescriptionViewer;