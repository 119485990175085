/* eslint-disable import/prefer-default-export */
// eslint-disable-next-line import/no-extraneous-dependencies
/* eslint-disable */

import keyMirror from 'fbjs/lib/keyMirror';

export const ActionTypes = keyMirror({
  CACHE_RECENT_JDS: undefined,

  CACHE_REPORT_DATA: undefined,

  UPDATE_USER_ID: undefined,

  UPDATE_FULL_NAME: undefined,

  UPDATE_USER_FILE: undefined,

  UPDATE_FEEDBACK: undefined,

  GET_RESUME_IN_HTML: undefined,

  UPDATE_USER_HTML_RESUME: undefined,

  UPDATE_RESUME_URL: undefined,

  PARSED_RESUME_DATA: undefined,

  CLEAR_STORE: undefined,

  UPDATE_LOCATION: undefined,

  RESET_WITH_NEW_DATA: undefined,

  UPDATE_USER_HISTORY_ID: undefined,

});
