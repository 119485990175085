/* eslint-disable */
import config from './config';

const axios = require('axios');

// TODO change name of fucnt
const logger = {
  postLogs: async (logMessage) => {
    try {
      const urlNew = config.postLogs;
      const config = {
        headers: {
          'Content-Type': 'application/json',
          sessionId: '1234'
        }
      };
      const data = {
        level: 'INFO',
        message: logMessage,
        application: 'panna_score_api',
      };
      return await axios.post(urlNew, data, config);
    } catch (error) {
      return error;
    }
  }
};
export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...logger
};
