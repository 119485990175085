import React from 'react';
import styles from './style.module.css';
import { Navbar } from '../Navbar';
const SignedInMessage = () => {
    return (<>
        <Navbar />
        <div className={styles.msg}>
            <p>{'Hey'}! You are already signed in.</p>
        </div>
    </>
    );
};


export default SignedInMessage;
