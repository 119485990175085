import React from 'react';
import rightArrow from '../../assets/jdToResume/right-arrowNew.png';

const Custombutton = props => {
    let buttonWrapperStyle = { 
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#ff5d5d',
        borderRadius: '5px',
        outline: 'none',
        width: '200px',
        height: '40px',
        fontWeight: '700',
        fontSize: '16px',
        color: '#ffffff',
        letterSpacing: '0',
        border: 'none',
        textAlign: 'center'
    };
    const textStyle = {display: 'flex', flex: '1', justifyContent: 'center', alignItems: 'center'};
    const imgStyle = { position: 'absolute', width: '22px', height: '16px', right: '10px' };
    if(props.disabled) {
        buttonWrapperStyle = { ...buttonWrapperStyle, opacity: '0.3'};
    }
    return (
        <div role="button" style={buttonWrapperStyle} className={props.containerStyles} tabIndex="0" onClick={props.onClick} onKeyPress={props.onKeyPress} disabled={props.disabled}>
            <div style={textStyle} className={props.textStyle}>{props.text}</div>
            <img src={rightArrow} style={imgStyle} className={props.arrowStyle} alt=""/>
        </div>
    );
}

export default Custombutton;