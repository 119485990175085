/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import axios from 'axios';
import API from '../../network';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

export const PromptToSignupModal = ({ onClick }) => {
  const [open, setOpen] = useState(true);

  useEffect(() => {
    // setTimeout(() => {
    //   console.log('Modal will be shown after a delay');
    //   setOpen(true);
    // }, 10000);
  }, []);
  const onClose = () => {
    // setOpen(false);
    onClick(false);
  };
  console.log('reaching here');
  const navigate = useNavigate();

  const { authState } = useAuth();

  return (
    <>
      {open && !authState && (
        <div id="signUpToContainer" className={styles.container}>
          <div id="signUpTo" className={styles.content}>
            <div className={styles.header}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  gap: '10px'
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="icon icon-tabler icon-tabler-checklist"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  stroke-width="2"
                  stroke="currentColor"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  style={{
                    height: '50px',
                    width: '50px',
                    color: '#aa6dce'
                  }}
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M9.615 20h-2.615a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h8a2 2 0 0 1 2 2v8" />
                  <path d="M14 19l2 2l4 -4" />
                  <path d="M9 8h4" />
                  <path d="M9 12h2" />
                </svg>
              </div>
              <p
                className={styles.title}
                style={{
                  textAlign: 'center',
                  fontSize: '1.8rem',
                  width: '100%',
                  marginTop: '2rem',
                  marginBottom: '2rem'
                }}
              >
                Do you want to build a new resume using AI?
              </p>
              <button className={styles.btn} onClick={onClose}>
                <CloseIcon />
              </button>
            </div>

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '10px',
                justifyContent: 'center',
                width: '50%',
                margin: 'auto'
              }}
            >
              <button
                onClick={() => {
                  navigate('/signup');
                }}
                className={styles.footerLink}
              >
                Proceed
              </button>
              <button
                className={styles.footerLinkCancel}
                onClick={onClose}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={styles.icon}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};
