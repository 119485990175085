import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import KickResumeImage from '../../../../../assets/insights/atsResumeBuilder/kickResume.jpg';
import Zety from '../../../../../assets/insights/atsResumeBuilder/zety.jpg';
import VisualCV from '../../../../../assets/insights/atsResumeBuilder/visualCV.jpg';
import ResumeGenius from '../../../../../assets/insights/atsResumeBuilder/resumeGenius.jpg';
import Rezi from '../../../../../assets/insights/atsResumeBuilder/rezy.png';
import Panna from '../../../../../assets/insights/atsResumeBuilder/panna.jpg';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's fiercely competitive job market, standing out with a resume that makes a
        lasting impression is more critical than ever. The advent of Applicant Tracking Systems
        (ATS) has transformed how resumes are screened, making it essential for job seekers to
        optimize theirs for digital scrutiny. An ATS resume checker scans a resume for specific
        keywords, skills, and formats to assess if it matches job descriptions, significantly
        influencing your resume score and, ultimately, your chances for an interview call.
        Recognizing the importance of beating the ATS, numerous ATS resume builders have emerged,
        offering tools to craft resumes not just with visual appeal but with the right elements to
        pass through these digital gatekeepers.
      </div>

      <div className={styles.text}>
        This article will delve into some of the best ATS resume builders available in 2024.
        Each platform brings unique features to the table, such as customizable resume templates,
        AI-driven resume score enhancers, and comprehensive resume writing guides that cater to
        various industries and career levels. Whether you're seeking an editable resume template
        that matches the latest resume format trends or a sophisticated ATS resume checker to
        gauge your resume's Paññã score, these builders cover a wide spectrum of resources. By
        leveraging these tools, job seekers can significantly increase their chances of making
        their resume ATS-friendly while encapsulating their professional experiences and skills
        effectively.
      </div>

      <div className={styles.title}>
        Kickresume
      </div>

      <div>
        <img
          src={KickResumeImage}
          alt='Kick Resume Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        Kickresume offers a comprehensive suite of tools designed to help job seekers create
        polished and professional resumes and cover letters. With a focus on privacy and security,
        Kickresume ensures user data is protected through partnerships with leading IT security
        companies for regular penetration testing. The platform provides a variety of professionally
        designed templates and features such as Kickresume Autopilot, which assembles resumes using
        over 20,000 pre-written phrases suitable for more than 3,200 job positions. Additionally,
        Kickresume includes AI-driven tools for writing resumes and cover letters, an AI resume
        checker, and a LinkedIn data import feature to streamline the resume creation process.
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        Kickresume has garnered positive feedback for its user-friendly interface and effective
        tools. Majority of users have praised the platform for its exceptional resume templates
        and intuitive design, noting significant improvements in their job application outcomes.
        However, some users have expressed concerns over the platform's limited design options
        and lack of flexibility in exporting documents in formats other than PDF.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            A wide range of templates
          </li>

          <li className={styles.listItem}>
            AI-enhanced writing tools
          </li>

          <li className={styles.listItem}>
            Ability to quickly import data from LinkedIn.
          </li>

          <li className={styles.listItem}>
            Mobile app for on-the-go resume building.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Limitations with free resume options
          </li>

          <li className={styles.listItem}>
            Overly vibrant template designs that may not be suitable for all job applications.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Zety
      </div>

      <div>
        <img
          src={Zety}
          alt='Zety Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        Zety is renowned for its user-friendly interface and professional-looking templates,
        designed to streamline the resume creation process. The platform offers a variety of
        features, including 20+ powerful resume templates created by Certified Career Experts,
        and a built-in cover letter creator that ensures your cover letter matches your resume.
        Zety also provides an intuitive text editor with an integrated spellchecker, making it
        easy to craft error-free documents quickly. Additionally, the platform uses AI to generate
        content for each resume section based on your job title, optimizing your resume for
        Applicant Tracking Systems (ATS).
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        Zety has received mixed reviews from users. While some praise the platform for its ease of
        use and effective templates, others criticize it for its pricing structure and lack of
        transparency. Users have expressed frustration over being led to believe the service was
        free until the point of download, where they are then required to pay. Despite this, Zety
        has been rated excellent on Trustpilot, with many users appreciating the quick and easy
        resume-building process.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            A wide array of ATS-optimized templates.
          </li>

          <li className={styles.listItem}>
            AI-enhanced writing suggestions that help users tailor their resumes effectively.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Many users feel misled by the initial free claim and are unhappy with the subscription
            model that only reveals costs at the final download step.
          </li>

          <li className={styles.listItem}>
            Lack of free downloadable templates and the necessity to pay for downloads.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        VisualCV
      </div>

      <div>
        <img
          src={VisualCV}
          alt='VisualCV Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        VisualCV stands out as a robust online resume builder that offers a plethora of features
        designed to assist job seekers in creating professional and visually appealing resumes.
        With over 30+ resume template options and the ability to easily edit and share resumes,
        VisualCV caters to a diverse range of professional needs. The platform supports
        customization, allowing users to tailor their resumes with various design elements to
        reflect their personal brand effectively. Additionally, VisualCV integrates advanced tools
        like AI-driven feedback and analytics to help users optimize their resumes for applicant
        tracking systems, ensuring higher visibility in job searches.
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        Users have expressed high satisfaction with VisualCV, particularly praising its
        user-friendly interface and the quality of its templates. Many have noted the platform's
        effectiveness in enhancing their job application process, with features that support easy
        updates and modifications to their resumes. However, some users have pointed out
        limitations in terms of design flexibility and customization, suggesting areas for
        potential improvement. The overall user response remains overwhelmingly positive, with
        many recommending VisualCV for its comprehensive features and user-centric approach.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            A wide array of design templates.
          </li>

          <li className={styles.listItem}>
            The convenience of online accessibility, which allows for easy updates and sharing.
          </li>

          <li className={styles.listItem}>
            The platform's capability to integrate with LinkedIn and provide detailed feedback on
            resume effectiveness adds significant value for users.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Some users feel the need for more creative control over the design elements.
          </li>

          <li className={styles.listItem}>
            Concerns about the compatibility of VisualCV-generated resumes with some ATS systems.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        ResumeGenius
      </div>

      <div>
        <img
          src={ResumeGenius}
          alt='ResumeGenius Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        ResumeGenius positions itself as a leading online resource for job seekers, providing a free resume
        builder that allows users to generate specific content like custom career objectives and optimized
        work experience sections. The platform is celebrated for its user-friendly interface and a variety of
        customizable resume templates that cater to different industries, roles, and experience levels. It
        also offers AI content suggestions and formatting assistance, ensuring that resumes are both
        professional-looking and optimized for Applicant Tracking Systems (ATS).
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        ResumeGenius has received high praise from users for its ease of use and effectiveness. The platform
        has an average rating of 4.6 stars from over 38,000 users on Trustpilot and a 4.5 rating from more
        than 110,000 reviews on Sitejabber, ranking it first among resume builders. Users appreciate the
        quick and easy resume creation process, as well as the professional language used by the AI, which
        many find enhances their resumes significantly.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            A wide range of templates.
          </li>

          <li className={styles.listItem}>
            AI-driven tools that help create resumes quickly and effectively, tailored to specific career paths.
          </li>

          <li className={styles.listItem}>
            The platform also handles all formatting automatically, which simplifies the resume creation process.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            While the initial use of the builder is free, downloading a completed resume requires payment,
            which has been a point of contention for those expecting a completely free service.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Rezi
      </div>

      <div>
        <img
          src={Rezi}
          alt='Rezi Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        Rezi is recognized for its robust AI-driven capabilities that streamline resume creation. It offers a
        user-friendly interface that simplifies the process of resume customization and optimization for ATS
        compatibility. Key features include unlimited industry-specific templates, AI-generated content, and
        comprehensive cover letter generation. Rezi also provides a unique feature that scores your resume,
        giving insight into how well it matches job description.
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        Users have praised Rezi for its intuitive design and effective tools. The platform is celebrated for
        its ability to structure users' thoughts with clear guidelines and recommendations, making it
        particularly user friendly. However some users have reported issues with the customer support
        responsiveness and occasional formatting glitches, which can hinder the user experience.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            AI-driven tools for resume building and job description matching.
          </li>

          <li className={styles.listItem}>
            Access to a wide range of templates and customization options.
          </li>

          <li className={styles.listItem}>
            Resume scoring system to gauge effectiveness.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Limited free plan features.
          </li>

          <li className={styles.listItem}>
            Some reports on subpar customer support.
          </li>

          <li className={styles.listItem}>
            Occasional formatting issues reported by users.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Paññã
      </div>

      <div>
        <img
          src={Panna}
          alt='Paññã Application UI'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.subTitle}>
        Overview and Features
      </div>

      <div className={styles.text}>
        Paññã's AI Resume Builder is designed to enhance resumes through advanced AI algorithms, making them
        optimized for Applicant Tracking Systems (ATS) and increasing their visibility to hiring managers. The
        platform offers a suite of features aimed at tailoring resumes to specific job descriptions by
        integrating industry-specific keywords, relevant skills, and proper formatting to ensure they pass ATS
        checks. Key features include instant Job Match Analysis, Keyword Optimization, AI-Powered Rewriting,
        ATS-Friendly Formatting, Customized Resume Building, and Professional Templates.
      </div>

      <div className={styles.subTitle}>
        User Reviews
      </div>

      <div className={styles.text}>
        Users of Paññã have reported a high level of satisfaction with the platform's ability to instantly
        geneate tailored resumes that meet specific job requirements. The tool is parsed for its easy editing
        capabilities, allowing users to continuously improve their resumes as their career progresses.
        Additionally, the platform's design is user-friendly, making it accessible for job seekers at any
        career stage.
      </div>

      <div className={styles.subTitle}>
        Pros
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Comprehensive AI-driven features for resume building and optimization.
          </li>

          <li className={styles.listItem}>
            Tailored resume generation based on job descriptions.
          </li>

          <li className={styles.listItem}>
            Professional and ATS-friendly templates.
          </li>

          <li className={styles.listItem}>
            Easy updating and customization of resumes.
          </li>
        </ul>
      </div>

      <div className={styles.subTitle}>
        Cons
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Limited features in the free plan.
          </li>

          <li className={styles.listItem}>
            Paid plans required for Resume Builder and full access to AI features as well as premium templates.
          </li>
        </ul>
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        Conclusion
      </div>

      <div className={styles.text}>
        Navigating through the myriads of options, the significance of leveraging advanced ATS AI-resume
        builders in today's competitive job market becomes evident. These platforms, through their unique
        features ranging from Al- driven optimizations to customization capabilities, empower job seekers to
        create resumes that not only pass the ATS checks but also resonate with hiring managers. By summarizing
        the key benefits and drawbacks of each service, this article has provided a comprehensive guide aimed at
        helping individuals enhance their resumes. The exploration of user experiences further enriches the
        understanding of what makes each platform stand out, ensuring readers are well-equipped to make informed
        decisions.
      </div>

      <div className={styles.text}>
        Reflecting on the broader implications, it's clear that the evolution of technology in resume building
        has significant potential to transform a candidate's job search journey. Platforms like these not only
        streamline the resume creation process but also elevate a candidate's chances of securing their desired
        role by tailoring content to specific job descriptions and optimizing keyword recognition. As job seekers
        continue to navigate the ever-changing landscapes of job markets, adopting such advanced tools will
        undoubtedly play a pivotal role in their success. Encouraging further exploration and adaptation of
        such technologies suggests a promising horizon for both job seekers and the recruitment industry at
        large.
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
