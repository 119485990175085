import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';
import pareto from '../../../../../assets/insights/jobSearchTimeAudit/pareto.jpg';
import shallowWork from '../../../../../assets/insights/jobSearchTimeAudit/shallowWork.jpg';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's hyper-competitive job market, time isn't just money - it's the difference 
        between landing your dream job and missing out on life-changing opportunities. Welcome 
        to the art and science of the Job Search Time Audit, a revolutionary approach that's 
        transforming how top candidates optimize their hunt for the perfect position.
      </div>

      <div className={styles.title}>
        The Shocking Reality of Job Search Inefficiency
      </div>

      <div className={styles.text}>
        According to a recent study by TalentWorks, the average job seeker spends about 11 
        hours per week on their search, but here's the kicker - only 2 of those hours 
        translate into meaningful progress. That's an 82% inefficiency rate!
      </div>

      <div className={styles.text}>
        Don't worry as this is highly common in the initial phases of job search. The solution 
        is to devise some strategies to flip that statistic on its head, making every minute of 
        your job search count.
      </div>

      <div className={styles.title}>
        The Job Search Time Audit: Your Step-by-Step Guide
      </div>

      <div className={styles.text}>
        <strong>Step 1: Track Your Activities</strong>
        <br />
        For the next week, become a detective of your own behavior. You can try using 
        &nbsp;
        <a href="https://www.smartsheet.com/" target="_blank">Smartsheet</a> 
        &nbsp;
        or 
        &nbsp;
        <a href="https://clockify.me/" target="_blank">Clockify</a> 
        &nbsp;
        and explore several tools that are available for FREE in the market. 
        So, what you need to do is
      </div>

      <div className={styles.text}>
        Record:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Activity type (e.g., application submission, networking, skill development)
          </li>
          <li>
            Start and end time
          </li>
          <li>
            Energy level (1-10)
          </li>
          <li>
            Perceived productivity (1-10)
          </li>
          <li>
            Outcome (if any)
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>Step 2: Analyze Your Data</strong>
        <br />
        After a week of diligent tracking, it's time to put on your data analyst hat. Look 
        for patterns and insights:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Which activities consumed the most time?
          </li>
          <li>
            When were your energy and productivity levels highest?
          </li>
          <li>
            Which activities led to tangible outcomes (e.g., interviews, meaningful connections)?
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>Pro Tip: </strong>Use a color-coding system to visualize your data. Green for 
        high-energy, high-productivity times; yellow for moderate; red for low.
      </div>

      <div className={styles.text}>
        <strong>Step 3: Identify Time-Wasters</strong>
        <br />
        Now for the moment of truth. It's time to confront those sneaky time-wasters that have 
        been sabotaging your job search efforts. Common culprits include:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Aimless job board scrolling (Average time wasted: 5 hours/week)
          </li>
          <li>
            Over-customizing applications for long-shot positions (Average time wasted: 3 hours/week)
          </li>
          <li>
            Unstructured social media "networking" (Average time wasted: 4 hours/week)
          </li>
          <li>
            Perfecting your resume without submitting applications (Average time wasted: 2 hours/week)
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Step 4: Optimize Your Schedule</strong>
        <br />
        Armed with your personalized data and insights, it's time to create a job search 
        schedule that would make even the most efficient CEOs jealous. Your optimized schedule should:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Align high-impact activities with your peak energy times
          </li>
          <li>
            Batch similar tasks for efficiency (e.g., dedicate one power hour to application submissions)
          </li>
          <li>
            Incorporate regular breaks to maintain focus (remember those ultradian rhythms!)
          </li>
          <li>
            Limit low-value activities to designated time slots
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>The 80/20 Rule: Your Secret Weapon</strong>
        <br />
        Ever heard of the Pareto Principle? It states that roughly 80% of effects come from 
        20% of causes. In job searching, this means 80% of your results likely come from 20% 
        of your efforts. The key is identifying that golden 20% and doubling down on it.
      </div>

      <img
        className={styles.applicationImage}
        src={pareto}
        alt="Pareto Analysis"
      />

      <div className={styles.text}>
        <em style={{color: "#717171", fontSize: "15px"}}>
          Image Credit: Proacton International
        </em>
      </div>

      <div className={styles.text}>
        Let's say you've been job searching for a month, applying to 100 positions with the 
        following breakdown:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            70 applications through job boards
          </li>
          <li>
            20 applications via company websites
          </li>
          <li>
            10 referrals from your network
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        After analyzing your results, you find:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            1 interview from job board applications (1.4% success rate)
          </li>
          <li>
            2 interviews from company website applications (10% success rate)
          </li>
          <li>
            3 interviews from network referrals (30% success rate)
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Applying the 80/20 rule, you realize that network referrals, while only 10% of your 
        applications, resulted in 50% of your interviews. This suggests that &nbsp;
        <strong>focusing more on networking and referrals</strong>&nbsp; could significantly boost 
        your job search effectiveness.
      </div>

      <div className={styles.text}>
        <strong>Action Plan:</strong>
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Increase time spent on networking activities (e.g., LinkedIn engagement, informational interviews)
          </li>
          <li>
            Aim for more referrals (target: 20-30% of applications)
          </li>
          <li>
            Reduce time spent on low-yield job board applications
          </li>
          <li>
            Maintain a moderate focus on company website applications
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        By reallocating your efforts based on this analysis, you're likely to see a substantial 
        improvement in your overall job search results, demonstrating the power of the 80/20 rule 
        in action.
      </div>

      <div className={styles.title}>
        From Job Seeker to Career Revolutionary: Kathryn Minshew's Story
      </div>

      <div className={styles.text}>
        When the 2008 financial crisis hit, Kathryn Minshew found herself in a familiar struggle: 
        countless hours on job boards, hundreds of applications sent, and a disheartening silence 
        in return. Sound familiar?
      </div>
      
      <div className={styles.text}>
        But Minshew wasn't content with the status quo. She flipped the script, transforming her 
        approach from quantity to quality. Instead of drowning in applications, she dove into 
        networking, conducted informational interviews, and started blogging to showcase her 
        insights.
      </div>

      <div className={styles.text}>
        The results were staggering:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Before: 200+ applications, 12 responses, 0 offers
          </li>
          <li>
            After: 50+ meaningful connections, 2 job offers in just 6 weeks
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        But Minshew's revolution was just beginning. Her job search epiphany sparked an idea: 
        what if everyone had access to this strategic approach? This led to the birth of The Muse, 
        now a career development powerhouse:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            $100 million+ company valuation
          </li>
          <li>
            75 million annual users
          </li>
          <li>
            $30 million in venture capital raised
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Minshew's secret? It's not about how many applications you send, but the value you bring to 
        each interaction. "Spend time on high-value activities that move the needle in your search,
        " she advises.
      </div>

      <div className={styles.title}>
        Leveraging Technology: Work Smarter, Not Harder
      </div>

      <div className={styles.text}>
        In today's digital age, there's no excuse for manual drudgery. Harness the power of 
        technology to supercharge your job search. Consider the usage of these tools to expediate 
        the process to land an interview.
      </div>

      <div className={styles.listWithNestedContent}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            AI-powered resume tailoring:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>
                <a href="https://www.panna.ai/score/in/resume-build-home" target="_blank">
                  Paññã
                </a>
              </li>
              <li className={styles.nestedListItem}>
                <a href="https://www.rezi.ai/" target="_blank">
                  Rezi
                </a>
              </li>
              <li className={styles.nestedListItem}>
                <a href="https://www.jobscan.co/?utm_term=jobscan&utm_campaign=Brand&utm_source=google&utm_medium=cpc&hsa_acc=6653739431&hsa_cam=21034189374&hsa_grp=160565151793&hsa_ad=691484624614&hsa_src=g&hsa_tgt=kwd-329216966316&hsa_kw=jobscan&hsa_mt=e&hsa_net=adwords&hsa_ver=3&gad_source=1&gclid=CjwKCAjw59q2BhBOEiwAKc0ijQgj6nWYV7sfYpOV5hfN0HFQ-naWVEVJLZjjt0EbSfLYdqVmdR-c-xoCIHUQAvD_BwE" target="_blank">
                  Jobscan
                </a>
              </li>
              <li className={styles.nestedListItem}>
                <a href="https://www.kickresume.com/en/" target="_blank">
                  Kickresume
                </a>
              </li>
              <li className={styles.nestedListItem}>
                <a href="https://enhancv.com/?utm_source=google&utm_medium=cpc&utm_campaign=search_brand_terms_india_all&utm_term=enhancv&gad_source=1&gclid=CjwKCAjw59q2BhBOEiwAKc0ijdfMrlWwUcV1OjNqNAKBh7wUeLPUbBWQ3NlkLHanZ_BusMIKYVopGBoCLeAQAvD_BwE" target="_blank">
                  Enhancv
                </a>
              </li>
            </ul>
          </li>

          <li className={styles.listItem}>
            Job application trackers:
            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Huntr</li>
              <li className={styles.nestedListItem}>Teal</li>
              <li className={styles.nestedListItem}>LoopCV</li>
              <li className={styles.nestedListItem}>Trello (with job search board setup)</li>
              <li className={styles.nestedListItem}>JobHero</li>
            </ul>
          </li>

          <li className={styles.listItem}>
            Email templates for follow-ups and networking:
            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>MixMax</li>
              <li className={styles.nestedListItem}>Yet Another Mail Merge</li>
              <li className={styles.nestedListItem}>Gmelius</li>
              <li className={styles.nestedListItem}>Streak</li>
              <li className={styles.nestedListItem}>Boomerang</li>
            </ul>
          </li>

          <li className={styles.listItem}>
            Calendar blocking apps for strict time management:
            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>RescueTime</li>
              <li className={styles.nestedListItem}>Focus@Will</li>
              <li className={styles.nestedListItem}>Clockify</li>
              <li className={styles.nestedListItem}>Toggl Track</li>
              <li className={styles.nestedListItem}>Forest</li>
            </ul>
          </li>

          <li className={styles.listItem}>
            Interview preparation tools:
            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Pramp</li>
              <li className={styles.nestedListItem}>InterviewBuddy</li>
              <li className={styles.nestedListItem}>Gainlo</li>
              <li className={styles.nestedListItem}>Interviewing.io</li>
              <li className={styles.nestedListItem}>LeetCode</li>
            </ul>
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Remember, the effectiveness of these tools can vary based on individual needs and preferences. 
        It's often helpful to try out a few options to see which ones work best for your specific job 
        search strategy and workflow.
      </div>

      <div className={styles.title}>
        The Power of Deep Work in Job Searching
      </div>

      <div className={styles.text}>
        Cal Newport, author of "Deep Work," argues that the ability to focus without distraction on 
        a cognitively demanding task is becoming increasingly rare and valuable in our 
        hyper-connected world. Apply this concept to your job search by allocating at least 2 hours 
        per day for "deep work" - intensely focused, distraction-free time dedicated to high-value 
        activities like tailoring applications or preparing for interviews.
      </div>

      <img
        className={styles.applicationImage}
        src={shallowWork}
        alt="Shallow Work vs Deep Work"
      />

      <div className={styles.text}>
        <em style={{color: "#717171", fontSize: "15px"}}>
          Image Credit: Lifehack 
        </em>
      </div>

      <div className={styles.title}>
        Your Job Search Revolution Starts Now
      </div>

      <div className={styles.text}>
        By implementing the Job Search Time Audit and the strategies outlined in this guide, you're 
        not just optimizing your time - you're revolutionizing your entire approach to career 
        advancement. Remember, it's not about working harder -- it's about working smarter.
      </div>

      <div className={styles.text}>
        In this hyper-competitive job market, every advantage counts. Did you know that only 2% of 
        applicants typically land an interview? That's a sobering statistic. But here's the 
        game-changer: tailoring your resume can boost your chances by up to 10x. This is where the 
        power of AI comes into play, perfectly aligning with our theme of maximizing efficiency.
      </div>

      <div className={styles.text}>
        AI-powered resume tools like Paññã are your secret weapon in this wild job market jungle. 
        They embody the very essence of working smarter, not harder. By using Paññã to optimize your 
        resume, you're applying the 80/20 rule we explored earlier - focusing your efforts on the 20% 
        of actions that yield 80% of the results.
      </div>

      <div className={styles.text}>
        Ready to take your job search to the next level? Let's put these strategies into action:
      </div>

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>
          Frequently Asked Questions
        </strong>
      </div>

      <div className={styles.text}>
        <strong>1. &nbsp;&nbsp;&nbsp;How often should I conduct a Job Search Time Audit?</strong><br />
        A: &nbsp;&nbsp;&nbsp;Initially, conduct a full audit weekly. Once you've optimized your process, monthly check-ins should suffice.
      </div>

      <div className={styles.text}>
        <strong>2. &nbsp;&nbsp;&nbsp;What's the ideal amount of time to spend on job searching daily?</strong><br />
        A: &nbsp;&nbsp;&nbsp;Aim for 3-4 hours of focused, high-quality job search activities per day.
      </div>

      <div className={styles.text}>
        <strong>3. &nbsp;&nbsp;&nbsp;How can I stay motivated during long periods of job searching?</strong><br />
        A: &nbsp;&nbsp;&nbsp;Set small, achievable daily goals, celebrate wins, and maintain a consistent routine.
      </div>

      <div className={styles.text}>
        <strong>4. &nbsp;&nbsp;&nbsp;Is it worth paying for premium job board subscriptions?</strong><br />
        A: &nbsp;&nbsp;&nbsp;It depends on your field. Evaluate the return on investment after a month of use.
      </div>

      <div className={styles.text}>
        <strong>5. &nbsp;&nbsp;&nbsp;How do I balance job searching with my current job?</strong><br />
        A: &nbsp;&nbsp;&nbsp;Use the techniques in this guide to maximize efficiency, focusing on early mornings, lunch breaks, and evenings for your search.
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
