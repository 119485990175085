import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import styles from './styles.module.scss';
import InstructionsResumeBuilder from './Instructions';
import FileUpload from '../component/FileUpload';
import error2Img from '../assets/jdToResume/error2.png';
import error3Img from '../assets/LandingPage/error3.png';
import unDrawImg from '../assets/jdToResume/undraw_collecting.png';
import Config from '../config';
import Errorpopup from '../component/ErrorPopup';
import fileUploadstyles from '../component/FileUpload/styles.module.css';
import Utility from '../utils';
import JobDescriptionField from '../component/JobDescriptionField';
import ConfirmPopup from '../component/ConfirmPopup';
import JobDescriptionViewer from '../component/JobDescriptionViewer';
import SaveJdPopup from '../component/SaveJdPopup';
import FullPageView from '../component/FullPageView';
import JdAnimation from '../animation/LoadingAnimationBuilder';
import API from '../network';
import data from './data';

import { useAuth } from '../context/AuthContext';


import { BASEPATH } from '../lib/constants';


import {
  cacheRecentJDs,
  cacheReportData,
  updateUserId,
  updateUserName,
  updateUserFile,
  getResumeInHTML,
  updateResumeHtmlLink,
  updateParsedResumeData,
  updateUserHistoryId
} from '../store/actions/cache';

const httpClient = axios.create();

httpClient.defaults.timeout = 180000;

const maxFileSize = 5242880;
const animationTime = 14300;

const saveJd = 'SAVE_JD_POPUP';
const uploadResumeStepID = 'Upload Resume';
const PasteJdStepId = 'Paste or Select Job Description';
const GetScoreStepId = 'Build Resume';
const uploadResumeStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';
const PasteJDStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';
const GetScoreStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';

class ResumeBuilderForm extends Component {
  constructor(props) {
    super(props);
    this.getSession();
    this.state = {
      isUploading: false,
      isUploaded: false,
      popupType: undefined,
      fullName: '',
      jobDescription: '',
      selectedJd: '',
      selectedSavedJd: '',
      isChecked: false,
      errorMessage: '',
      errorImage: error2Img,
      replaceJdName: '',
      isSample: false,
      isSampleSelected: false,
      isDone: false,
      isDriveUploadInProgress: false,
      paymentSessionId: null,
      currentStep: 1,
    };
  }

  componentDidMount() {
    document.body.style.overflow = 'auto';
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }
  onSavedJdSelected = (jd, title) => {
    this.setState({
      jobDescription: jd,
      selectedSavedJd: title,
      isSampleSelected: false
    });
  };

  onChange = e => {
    const file = e.target.files[0];
    this.setState({
      currentStep: 2
    });
    if (file) {
      const resume = file.name;
      const resumeType = resume.split('.');
      const UploadWrapDiv = document.getElementById('uploadResumeWithJdWrap');
      const uploadFileInput = document.getElementById('uploadResumeWithJd');
      if (this.validateResume(file.size, resumeType)) {
        this.setState({
          isUploading: true,
          fileName: resume,
          resumeUploadError: ''
        });
        Utility.uploadFile(
          file,
          resumeType[resumeType.length - 1],
          response => {
            const { dispatchUpdateHTMLLink, dispatchUpdateParsedResume } = this.props;
            dispatchUpdateHTMLLink(response.data.s3HtmlLink);

            setTimeout(() => {
              this.getResumeInHTML(response.data.s3HtmlLink);
            }, 15000);

            dispatchUpdateParsedResume(response.data);
            const { reduxProps } = this.props;
            console.log('reduxProps:', reduxProps);
            const { firstName, lastName, email, phoneNumber } = response.data;
            this.highlightInstruction(2);
            UploadWrapDiv.classList.add(styles.onUploadInputField);
            // fileImg2Div.classList.add(fileUploadstyles.fileImg2);
            // fileImg3Div.classList.add(fileUploadstyles.fileImg3);
            const fullName =
              firstName && lastName
                ? `${firstName} ${lastName}`
                : firstName || lastName || '';
            this.setState({
              isUploaded: true,
              fullName,
              phoneNumber,
              email,
              resumeURL: response.data.s3DownloadLink,
              resumeHtmlUrl: response.data.s3HtmlLink
            });
            if (email) {
              const requestBody = {
                resumeUrl: response.data.s3DownloadLink,
                OrgName: Config.organisationName,
                emailId: email
              };
              Utility.uploadResumeToSolar(requestBody);
            }
          },
          error => {
            this.highlightInstruction(1);
            uploadFileInput.value = null;
            // fileImg2Div.classList.replace(
            //   fileUploadstyles.fileImg2Uploading,
            //   fileUploadstyles.fileImg2
            // );
            // fileImg3Div.classList.replace(
            //   fileUploadstyles.fileImg3Uploading,
            //   fileUploadstyles.fileImg3
            // );
            this.setState({
              popupType: 'error',
              isUploading: false,
              isUploaded: false,
              fileName: '',
              errorMessage:
                'Error while uploading resume, please try again in a little while.',
              errorImage: error2Img,
              isDriveUploadInProgress: false
            });
            console.info('error', error);
          }
        );
      }
    } else {
      this.setState({
        isUploading: false,
        isUploaded: false,
        isDriveUploadInProgress: false
      });
    }
  };

  getResumeInHTML = async S3HTMLLink => {
    const { dispatchGetResumeInHTML } = this.props;
    await Utility.getResumeInHtml(
      S3HTMLLink,
      res => {
        this.resumeInHtml = res.data;
        dispatchGetResumeInHTML(this.resumeInHtml);
      },
      error => {
        this.resumeInHtml = 'Error';
        console.info('error in resume viewer', error, error.response);
      }
    );
  };

  validateResume = (fileSize, resumeType) => {
    let isResumeValid;
    const supportedVersions = ['doc', 'docx', 'txt', 'rtf', 'pdf'];
    if (
      resumeType &&
      resumeType.length !== 0 &&
      supportedVersions.includes(resumeType[resumeType.length - 1])
    ) {
      if (fileSize <= maxFileSize) {
        isResumeValid = true;
      } else {
        isResumeValid = false;
        this.setState({
          resumeUploadError: data.resumeSizeError,
          isDriveUploadInProgress: false
        });
      }
    } else {
      isResumeValid = false;
      this.setState({
        resumeUploadError: data.invalidResumeFormat,
        isDriveUploadInProgress: false
      });
    }
    return isResumeValid;
  };

  resetUploadedFile = () => {
    document.getElementById('uploadResumeWithJd').value = null;
    document
      .getElementById('uploadResumeWithJdWrap')
      .classList.replace(
        styles.onUploadInputField,
        fileUploadstyles.uploadArea
      );
    this.highlightInstruction(1);
    this.setState({
      isUploading: false,
      isUploaded: false,
      fullName: '',
      fileName: '',
      isDriveUploadInProgress: false
    });
    localStorage.removeItem('resumeDetails');
  };

  onChangeText = text => {
    const { value } = text.currentTarget;
    this.setState({ jobDescription: value });
    if (value) {
      this.highlightInstruction(3);
    } else {
      this.setState({ isSampleSelected: false, selectedJd: '' });
      this.highlightInstruction(2);
    }
  };

  onJdSelected = (jd, title) => {
    this.setState({
      jobDescription: jd,
      selectedJd: title,
      selectedSavedJd: '',
      isSampleSelected: true
    });
    this.highlightInstruction(3);
  };

  resetJobDescription = () => {
    this.setState({
      jobDescription: '',
      selectedJd: '',
      selectedSavedJd: '',
      isSampleSelected: false
    });
    this.highlightInstruction(2);
  };
  onCheckboxClicked = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }));
  };

  highlightInstruction = step => {
    this.setState({
      currentStep: step
    })
  };

  onErrorRetry = () => {
    this.setState({ popupType: undefined });
  };

  renderPopup = () => {
    const {
      popupType,
      isChecked,
      replaceJdName,
      jobDescription,
      isSample,
      selectedSavedJd,
      selectedJd
    } = this.state;
    const {
      reduxProps: { cache },
      dispatchRecentJds,
      history,
      router
    } = this.props;
    if (popupType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    switch (popupType) {
      case saveJd: {
        return isChecked ? (
          <div className={styles.popupStyle}>
            <SaveJdPopup
              title={data.saveJdPopupTitle}
              label={data.saveJdInputLabel}
              onCancel={() => this.setState({ popupType: undefined })}
              onSave={jdName => this.hydrateJobDescription(jdName)}
            />
          </div>
        ) : null;
      }
      case 'error': {
        const { errorMessage, errorImage } = this.state;
        return (
          <Errorpopup
            history={history}
            onErrorRetry={this.onErrorRetry}
            errorImage={errorImage}
            errorMessage={errorMessage}
          />
        );
      }
      case 'animation': {
        const { isDone } = this.state;
        return (
          <div className={styles.animationFullPage}>
            <JdAnimation
              title="Building."
              isDone={isSample || isDone}
              onAnimationEnd={() => {
                if (isSample) {
                  router.navigate('/report', {
                    state: {
                      data: data.trySampleResponse,
                      isSample
                    }
                  });
                }
              }}
            />
          </div>
        );
      }
      case 'confirmReplace': {
        return (
          <div className={styles.popupStyle}>
            <ConfirmPopup
              title="Job description already exist with this name, do you want to replace ?"
              onOk={() => {
                const { recentJds } = cache;
                let tempJds = [];

                tempJds = recentJds.map(jd => {
                  if (jd.name === replaceJdName) {
                    // eslint-disable-next-line no-param-reassign
                    jd.description = jobDescription;
                  }
                  return jd;
                });
                dispatchRecentJds(tempJds);
                this.uploadJobDescription();
              }}
              onCancel={() => this.setState({ popupType: saveJd })}
            />
          </div>
        );
      }
      case 'jdViewer': {
        return (
          <div className={styles.popupStyle}>
            <JobDescriptionViewer
              jdTitle={selectedSavedJd || selectedJd}
              value={jobDescription}
              onchangeJD={this.onChangeText}
              onBack={() => this.setState({ popupType: undefined })}
              onMeasureClick={this.onMeasureClick}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  onMeasureClick = () => {
    const { isChecked, selectedSavedJd } = this.state;
    if (isChecked && !selectedSavedJd) {
      this.setState({ popupType: saveJd });
    } else {
      this.uploadJobDescription();
    }
  };

  uploadJobDescription = () => {
    const { jobDescription } = this.state;
    this.setState({ popupType: 'animation' });
    // eslint-disable-next-line no-undef
    const file = new File([jobDescription], 'jobDescription.txt', {
      type: 'text/plain'
    });
    Utility.uploadFile(
      file,
      'txt',
      response => {
        this.setState({ jobDescriptionURL: response.data.s3DownloadLink });
        // Logger.postLogs(
        //   `"resume-jd-relevance - job description upload successfull", ${JSON.stringify(
        //     response.data
        //   )}`
        // );
        this.checkRelevance();
      },
      error => {
        this.setState({
          popupType: 'error',
          errorMessage:
            'Error while uploading job description, please try again in a little while.',
          gotoSection: 'secondSection',
          errorImage: error3Img
        });
        // Logger.postLogs(
        //   `"resume-jd-relevance - job description upload Unsuccessfull", ${JSON.stringify(
        //     error
        //   )}`
        // );
      }
    );
  };

  checkRelevance = async () => {
    const {
      resumeURL,
      jobDescriptionURL,
      fullName,
      phoneNumber,
      email,
      selectedJd,
      isSampleSelected,
      resumeHtmlUrl
    } = this.state;
    const {
      dispatchReportData,
      dispatchUpdateUserId,
      dispatchUpdateUserName,
      dispatchUpdateUserFile,
      dispatchGetResumeInHTML,
      history,
      router
    } = this.props;
    const url = Config.releavanceCheck;
    const config = {
      headers: {
        'content-type': 'application/json',
        Accept: '/'
      }
    };
    const requestBody = {
      Resume_URL: resumeURL,
      Jd_URL: jobDescriptionURL
    };
    const start = new Date().getTime();
    httpClient
      .post(
        Config.improvedRes,
        requestBody,
        config
      )
      .then(async response => {
        if (response.data.status_code === 0) {
          // Logger.postLogs(
          //   `"AI resume unsuccessful - relevance check unsuccessfull", ${response.data.status_msg}`
          // );
          this.setState({
            popupType: 'error',
            errorMessage: response.data.status_msg,
            errorImage: unDrawImg
          });
        } else {
          const { Uid } = response.data;
          const aiResumeData = response.data;

          // const url = Config.releavanceCheck;
          // const config = {
          //   headers: {
          //     'content-type': 'application/json',
          //     Accept: '/'
          //   }
          // };
          // const requestBody = {
          //   Resume_URL: resumeURL,
          //   Jd_URL: jobDescriptionURL,
          //   Full_name: fullName,
          //   Email: email,
          //   Phone_number: phoneNumber,
          //   is_sample_jd: isSampleSelected,
          //   jd_title: selectedJd || 'None',
          //   application: 'panna_score_api',
          //   skills_deleted_status: 0
          // };

          // const prevScoreResponse = await httpClient.post(
          //   url,
          //   requestBody,
          //   config
          // );
          // const prevScoreReport = prevScoreResponse.data;

          let navigationTime = 0;
          let date = new Date().getTime();
          date = new Date().getTime() - start;
          navigationTime = animationTime > date ? animationTime - date : 4000;
          console.info('response time: ', `${date} ms`);
          this.setState({ isDone: true });

          const data = {
            name: fullName,
            emailAddress: email,
            contact: phoneNumber,
            jobDescription: jobDescriptionURL,
            jobTitle: selectedJd || 'None',
            resumeUrl: resumeURL,
            resumeHtmlUrl: resumeHtmlUrl,
            builtResumeAI_Response: JSON.stringify({
              data: this.reportData,
              aiResumeData: aiResumeData,
              prevScoreReport: {}
            })
          }

          this.saveAIResumeResponse(data);
          const { paymentSessionId } = this.state;
          // Updating the payment status after resume build
          if (paymentSessionId) {
            this.updateResumeBuiltStatus(paymentSessionId);
          }
          setTimeout(
            () =>
              router.navigate('/builder-report', {
                state: {
                  data: this.reportData,
                  aiResumeData: aiResumeData,
                  prevScoreReport: {},
                  name: fullName,
                  email: email,
                  phoneNumber: phoneNumber,
                  s3DownloadLink: resumeURL,
                  jdURL: jobDescriptionURL,
                  jobTitle: selectedJd || null,
                  coverLetterJson: null,
                }
              }),
            navigationTime
          );
          // Logger.postLogs(
          //   `"resume-jd-relevance - relevance check successfull", ${JSON.stringify(
          //     response.data
          //   )}`
          // );
          dispatchUpdateUserFile({
            resume: resumeURL,
            jd: jobDescriptionURL
          });
          dispatchUpdateUserId(Uid);
          dispatchUpdateUserName(fullName);
          dispatchReportData(this.reportData);
          dispatchGetResumeInHTML(this.resumeInHtml);
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.info('error in DS service', error);
        // Logger.postLogs(
        //   `"resume-jd-relevance - relevance check unsuccessfull", ${JSON.stringify(
        //     error
        //   )}`
        // );
        this.setState({
          popupType: 'error',
          errorMessage:
            'There was an error while collecting your data. Please try again',
          errorImage: unDrawImg
        });
      });
  };


  getSession = () => {
    API.getInstance().getSession()
      .then(res => {
        const { paymentSessionId, userInfo } = res;
        this.setState({ paymentSessionId })
        console.log(res);

        //FIXME for temporarily Only
        const emailList = ['tejaswini.vemulapally@mroads.com', 'santhosh.bolla@yopmail.com', 'madhav.vs@mroads.com', 'venkatakrishna.avula@mroads.com', 'lalitha.jampana@mroads.com', 'pradeep.dantuluri@mroads.com']
        const skipPayment = emailList.includes(userInfo?.emailAddress);
        console.log('skipPayment:', skipPayment);
        if (skipPayment) {
          // allowing the build resume
          return;
        }
        if (!paymentSessionId) {
          window.location.href = BASEPATH + '/resume-build-home';
        }
      })
      .catch(error => {
        console.log(error);
        // const err = util.getErrorMessage(error);
        // this.showErrorPopup(err);
      });
  }


  updateResumeBuiltStatus = (paymentSessionId) => {
    console.log(paymentSessionId);
    // this.setState({ popup: { type: POPUP_CONSTANTS.SPINNER_POPUP } });
    API.getInstance().updateResumeBuiltStatus(paymentSessionId)
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(error);

        // const err = util.getErrorMessage(error);
        // this.showErrorPopup(err);
      });
  };


  saveAIResumeResponse = (data) => {
    const {
      dispatchUpdateUserHistoryId,
    } = this.props;
    console.log(data);
    // this.setState({ popup: { type: POPUP_CONSTANTS.SPINNER_POPUP } });
    API.getInstance().saveAIResumeResponse(data)
      .then(res => {
        console.log(res);
        this.setState({
          userHistoryId: res?.userHistoryId
        })
        dispatchUpdateUserHistoryId(res?.userHistoryId);
      })
      .catch(error => {
        console.log(error);

        // const err = util.getErrorMessage(error);
        // this.showErrorPopup(err);
      });
  };


  checkSampleRelevance = () => {
    this.setState({ popupType: 'animation', isSample: true });
  };


  render() {
    const {
      isUploading,
      isUploaded,
      fileName,
      fullName,
      jobDescription,
      selectedJd,
      selectedSavedJd,
      resumeUploadError,
      isChecked,
      isDriveUploadInProgress,
      currentStep
    } = this.state;

    const {
      reduxProps: { cache },
      history,
      router
    } = this.props;

    return (<div className={styles.resumeBuilderContainer}>
      {this.renderPopup()}
      <div className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.h1}>Craft your success</h1>
          Tailor your resume with precision and <span className={styles.textHigh}>AI-Powered insight</span>
        </div>
        <InstructionsResumeBuilder currentStep={currentStep} />
        <div className={styles.uploadBox}>
          <FileUpload
            fileName={fileName}
            fullName={fullName}
            onChange={this.onChange}
            resetUploadedFile={this.resetUploadedFile}
            isUploading={isUploading}
            isUploaded={isUploaded}
            errorMessage={resumeUploadError}
            onChangeNameText={this.onChangeNameText}
            testSpeed={this.testUserBandwidth}
            isUploadingInProgress={isDriveUploadInProgress}
          />
          {isUploaded ? (
            <JobDescriptionField
              jobDescription={jobDescription}
              onChange={this.onChangeText}
              onJdSelected={this.onJdSelected}
              onSavedJdSelected={this.onSavedJdSelected}
              selectedJd={selectedJd}
              selectedSavedJd={selectedSavedJd}
              resetJd={this.resetJobDescription}
              isChecked={isChecked}
              onMeasureClick={this.onMeasureClick}
              recentJds={cache.recentJds}
              onRemoveSavedJd={this.removeSavedJd}
              openJDViewer={() => this.setState({ popupType: 'jdViewer' })}
              text="Build Your Resume"
            />
          ) : null}
        </div>
      </div>
    </div>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps
});

const mapDispatchToProps = dispatch => ({
  dispatchRecentJds: recentJd => {
    dispatch(cacheRecentJDs(recentJd));
  },
  dispatchReportData: reportData => {
    dispatch(cacheReportData(reportData));
  },
  dispatchUpdateUserId: userId => {
    dispatch(updateUserId(userId));
  },
  dispatchUpdateUserName: userName => {
    dispatch(updateUserName(userName));
  },
  dispatchUpdateUserFile: userFiles => {
    dispatch(updateUserFile(userFiles));
  },
  dispatchGetResumeInHTML: userFiles => {
    dispatch(getResumeInHTML(userFiles));
  },
  dispatchUpdateHTMLLink: link => {
    dispatch(updateResumeHtmlLink(link));
  },
  dispatchUpdateParsedResume: link => {
    dispatch(updateParsedResumeData(link));
  },
  dispatchUpdateUserHistoryId: userHistoryId => {
    dispatch(updateUserHistoryId(userHistoryId));
  },
});


export default connect(mapStateToProps, mapDispatchToProps)(ResumeBuilderForm);
