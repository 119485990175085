import React from 'react';
import { LoginModal } from '../../component/LoginModal';
import { Navbar } from '../../component/Navbar';
import { Account } from '../../component/Account';
import BuildAIResumeView from '../../component/BuildAIResumeView';
import Helmet from 'react-helmet';
import data from '../MetaData/data';
import { BASEPATH } from '../../lib/constants';
import Utility from '../../utils'


class AccountPage extends React.Component {

  componentDidMount() {
    Utility.trackEvent('resume-build-home', 'PAGE_VISIT');
  }

  render() {
    return (
      <>
        <Helmet>
          <title>{data.resumeBuilderHome.title}</title>
          <meta name="title" content={data.resumeBuilderHome.metaTitle} />
          <meta name="description" content={data.resumeBuilderHome.metaDescription} />
          <link rel="alternate" href="https://www.panna.ai/score/us/resume-build-home" hreflang="en-us" />
          <link rel="alternate" href="https://www.panna.ai/score/in/resume-build-home" hreflang="en-in" />
          <link rel="alternate" href="https://www.panna.ai/score/us/resume-build-home" hreflang="x-default" />
          <link rel="canonical" href={'https://www.panna.ai' + BASEPATH + '/resume-build-home'} />
        </Helmet>
        <Navbar />
        <BuildAIResumeView open={true} />
      </>
    );
  }
}

export default AccountPage;
