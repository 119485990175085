/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

import API from '../../network';
import { BASEPATH } from '../../lib/constants';
import { connect } from 'react-redux';
import { resetStoreWithNewData } from '../../store/actions/cache';


class ValidateUser extends React.Component {
  // static contextType = AuthContext;
  // context;

  constructor(props) {
    super(props);

    this.state = {
      sample: ''
    };
  }

  async componentDidMount() {
    await this.verifyToken();
  }

  verifyToken = async () => {
    const { search } = this.props.router.location;

    const params = new URLSearchParams(search);

    // console.log('search', search);
    // console.log('params', params);
    const tempToken = params.get('token');

    if (tempToken) {
      localStorage.clear();
      try {
        const response = await API.getInstance().validate(tempToken);
        const token = response.token;
        localStorage.setItem('token', token);
        if (response && response?.userInfo?.isFromScoreReport) {
          this.fetchReportScore(token);
        } else {
          window.location.replace(BASEPATH + '/resume-build-home');
        }
      } catch (e) {
        console.log('catch block', e);
        alert('Session Expired, Return to login page');
        localStorage.setItem('token', '');
        window.location.replace(BASEPATH + '/login');
      }
    } else {
      alert('Session Expired, Return to login page');
      console.log('no temp token');
      window.location.replace(BASEPATH + '/login');
    }
  };

  validateAPI = token => {
    try {
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message;
      console.log('CATCH BLOCK', error);
      setError(error);
    }
  };

  fetchReportScore = async (token) => {
    const {
      history,
      router,
      dispatchResetStoreData
    } = this.props;
    try {
      const response = await API.getInstance().fetchScore(token);
      console.log('fetchReportScore:', JSON.parse(response?.scoreHistory?.scoreJson));
      dispatchResetStoreData(JSON.parse(response?.scoreHistory?.scoreJson));
      window.location.replace(BASEPATH + '/report');
    } catch (e) {
      console.log('catch block fetchReportScore', e);
      window.location.replace(BASEPATH + '/resume-build-home');
    }
  }


  render() {
    return <div />;
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps
});

const mapDispatchToProps = dispatch => ({
  dispatchResetStoreData: (data) => {
    dispatch(resetStoreWithNewData(data));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ValidateUser);
// export default ValidateUser;
