/* eslint-disable */


let basePath;

switch (true) {

    case window.location.pathname.includes(process.env.REACT_APP_REGION_BASE_PATH_INDIA1):
        basePath = process.env.REACT_APP_REGION_BASE_PATH_INDIA1;
        break;

    case window.location.pathname.includes(process.env.REACT_APP_REGION_BASE_PATH_US1):
        basePath = process.env.REACT_APP_REGION_BASE_PATH_US1;
        break;

    case window.location.pathname.includes(process.env.REACT_APP_REGION_BASE_PATH_INDIA):
        basePath = process.env.REACT_APP_REGION_BASE_PATH_INDIA;
        break;
    case window.location.pathname.includes(process.env.REACT_APP_REGION_BASE_PATH_US):
        basePath = process.env.REACT_APP_REGION_BASE_PATH_US;
        break;

    default:
        // Default case
        basePath = process.env.REACT_APP_DEFAULT_BASE_PATH;
        break;
}



// const basePath = window.location.pathname.includes(process.env.REACT_APP_REGION_BASE_PATH_INDIA) ? process.env.REACT_APP_REGION_BASE_PATH_INDIA : process.env.REACT_APP_REGION_BASE_PATH_US;

export const BASEPATH = basePath;


export const RESUME_TEMPLATES_CONSTANTS = {
    TEMPLATE1: 1,
    TEMPLATE2: 2,
    TEMPLATE3: 3,
    TEMPLATE4: 4
}


export const IS_PATH2 = BASEPATH.includes('/in1') || BASEPATH.includes('/us1');

export const IS_PATH1 = !IS_PATH2;