import React from 'react';
import { SignupModal } from '../../component/SignupModal';
import { Navbar } from '../../component/Navbar';
import CheckYourEmail from '../../component/CheckYourMail';
import { Helmet } from 'react-helmet';
import Utility from '../../utils'

class SignupPage extends React.Component {
  state = {
    isSignUpOpen: true
  };
  onLinkClick = () => {
    const { isSignUpOpen } = this.state;
    this.setState({ isSignUpOpen: !isSignUpOpen });
  };
  componentDidMount() {
    sessionStorage.removeItem('emailAddress');
    Utility.trackEvent('sign-up', 'PAGE_VISIT');
  }
  render() {
    const { isSignUpOpen } = this.state;
    return (
      <>
        <Helmet>
          <title>paññã Login</title>
          {/* <meta name={data.metaName} content={data.metaContent} />
          <meta name="theme-color" content="#008f68" /> */}
        </Helmet>
        <Navbar />
        <SignupModal open={isSignUpOpen} onSuccess={this.onLinkClick} />
        {!isSignUpOpen ? (
          <CheckYourEmail onLinkClick={this.onLinkClick} />
        ) : null}
      </>
    );
  }
}

export default SignupPage;
