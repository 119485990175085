/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import styles from './styles.module.scss';
import downloadIconBlue from '../../../assets/jdToResume/downloadImg.png';
import {
  cacheReportData,
  updateUserName,
  updateUserFile,
  getResumeInHTML,
  updateResumeHtmlLink
} from '../../../store/actions/cache';
import ReactToPrint from 'react-to-print';
import { Navbar } from '../../Navbar';
import Privacy from '../../../Privacy';
import { termsData } from '../../../component/Footer/Data';
import { saveAs } from 'file-saver';
import { BASEPATH } from '../../../lib/constants';
import fullScreenIcon from '../../../assets/icons/fullScreen.png'
const httpClient = axios.create();
import { useAuth } from '../../../context/AuthContext';
import html2pdf from 'html2pdf.js';
import Utility from '../../../utils';

import GoogleDocsViewer from '../../GoogleDocsViewer';
import ThumbsUpDownComponent from '../../ReportFeedBack';
import pdfIcon from '../../../assets/icons/pdf.png'
import wordIcon from '../../../assets/icons/doc.png'

import create from 'html-to-docx';
import API from '../../../network';

import Spinner from '../../Spinner';
import tinymce from 'tinymce/tinymce';
//import 'tinymce/plugins/paste';
import 'tinymce/plugins/link'; // Import the link plugin
import 'tinymce/plugins/image'
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
import 'tinymce/plugins/save';
import { Helmet } from 'react-helmet';
import metaData from '../../../pages/MetaData/data'


const tabsConstants = {
  oldResume: 'OldResume',
  aiGeneratedResume: 'AiGeneratedResume',
  coverLetter: 'coverLetter'
};

httpClient.defaults.timeout = 180000;

class ReportResumeBuilder extends React.Component {
  percent;
  placeHolderData = [];
  cardData = [];
  actionVerbCount = 0;
  detailReportData = [];
  skillDeleteCount = 0;
  newReportData;
  cardKeys;
  myHilitor;
  tempResumeInHTML;
  SwitchTabButton = [];
  actionVerbcategory = [];
  state = {
    status: 'default',
    showImage: false,
    isTooltipActive: false,
    cardData: [],
    detailReportData: [],
    ondeletePressed: false,
    canvasImage: undefined,
    resumeViewer: '',
    selectedIndex: 0,
    isLoading: false,
    downloadIcon: downloadIconBlue,
    buttonBackground: '#FFFFFF',
    buttonTextColor: '#468CF2',
    isPreviousScoreLoading: false,
    toggleAIResumeFullScreen: false,
    resetData: true,
    showResetButton: false,
    showSpinner: false,
    generatedResumeURL: null,
    resumeInHTMLString: null,
    isSample: window.location.pathname.includes('/sample-builder-report'),
    tabValue: tabsConstants.aiGeneratedResume,
    openConfirmationPopup: false,
    iconType: '',
    showTermsPopup: false,
  };

  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
    this.editorRef = React.createRef();
  }

  images_upload_handler = function (blobInfo, success, failure) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        // Convert the image to base64
        const base64Data = e.target.result;

        // Success callback with the base64 data
        resolve(base64Data);
      };
      reader.onerror = function (e) {
        // Failure callback with error message
        reject('Failed to upload image');
      };
      reader.readAsDataURL(blobInfo.blob());
    });
  };

  addPlugin = () => {
    tinymce.PluginManager.add('imageUpload', function (editor) {
      editor.ui.registry.addButton('imageUploadButton', {
        icon: 'image',
        tooltip: 'Upload Image',
        onAction: function () {
          // Trigger file input click
          var input = document.createElement('input');
          input.type = 'file';
          input.accept = 'image/*';
          input.onchange = async (event) => {
            var file = event.target.files[0];
            if (file) {
              // Check file size
              if (file.size > 1024 * 1024) { // Adjust size limit as needed
                alert('File size exceeds the limit (1MB).');
                return;
              }
              try {
                const base64String = await fileToBase64(file);
                console.log(base64String); // Base64 string of the file
                const img = document.createElement('img');
                img.src = 'data:image/png;base64,' + base64String;

                // Get the HTML markup for the image
                const imgHtml = img.outerHTML;

                // var imageUrl = URL.createObjectURL(file);
                console.log(imgHtml)
                editor.insertContent(imgHtml);
              } catch (error) {
                console.error('Error converting file to base64:', error);
              }
            }
          };
          input.click();
        }
      });


      const fileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result.split(',')[1];
            resolve(base64String);
          };
          reader.onerror = (error) => {
            reject(error);
          };
          reader.readAsDataURL(file);
        });
      };
    });

  }

  componentDidMount() {
    window.scrollTo(0, 0);

    const { aiResumeData, resumeHtmlUrl } = this.props.router.location.state || {};
    const { JD_skills_added } = aiResumeData || {};
    // this.convertResumeToBlob();
    if (JD_skills_added?.length > 0) {
      this.boldWords(JD_skills_added);
    }
    const { HistoryRowId } = this.props.router.location.state || {};
    const { isSample } = this.state;
    console.log('HistoryRowId:', HistoryRowId);
    if (!HistoryRowId && !isSample) {
      this.uploadAIGeneratedFile(HistoryRowId);
    }
    if (resumeHtmlUrl) {
      this.getResumeInHTML(resumeHtmlUrl);
    }
    this.addPlugin();
    tinymce.init({
      selector: '#aiResume',
      inline: true, // Enable inline mode
      branding: false,
      menubar: false,
      paste_data_images: true,
      image_uploadtab: true,
      toolbar_persist: true,
      fixed_toolbar_container: '#mytoolbar',
      images_upload_handler: this.images_upload_handler,
      license_key: 'gpl',
      content_style: 'body { max-width: 100%; }',
      plugins: "imageUpload link image print preview anchor textcolor lists",
      toolbar: 'undo redo | blocks | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright alignjustify |  outdent indent | link imageUploadButton',
      // link_context_toolbar: true,
      // file_browser_callback_types: 'image',
      //      file_picker_callback: this.handleImageUpload,
      paste_data_images: true,
      toolbar_mode: 'wrap',
      extended_valid_elements: "svg[*]",
      setup: (editor) => {
        this.editorRef = editor;
        editor.on('change', () => {
          console.log('Editor content changed:', editor.getContent());
          // Call the onEditorChange callback with the updated content
          this.handleChange();
        });
      }
      // Other configuration options...
    });

  }

  componentWillUnmount() {
    // Cleanup on component unmount
    tinymce.remove(this.editorRef.current);
  }

  handleImageUpload = (blobInfo, success, failure) => {
    const reader = new FileReader();
    reader.onload = () => {
      success(reader.result);
    };
    reader.onerror = (error) => {
      failure('Image upload failed');
    };
    reader.readAsDataURL(blobInfo.blob());
  };

  saveAIResumeEditedUrl = async (data) => {
    // console.log(data);
    // this.setState({ popup: { type: POPUP_CONSTANTS.SPINNER_POPUP } });
    this.setState({
      showSpinner: true
    })
    await API.getInstance().saveAIResumeEditedUrl(data)
      .then(res => {
        console.log(res);
        this.setState({
          showSpinner: false
        })
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showSpinner: false
        })
      });
  };


  saveAIResumeEditedResponse = async (data) => {
    // console.log(data);
    // this.setState({ popup: { type: POPUP_CONSTANTS.SPINNER_POPUP } });
    this.setState({
      showSpinner: true
    })
    await API.getInstance().saveAIResumeEditedResponse(data)
      .then(res => {
        console.log(res);
        this.setState({
          showSpinner: false
        })
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showSpinner: false
        })
        // const err = util.getErrorMessage(error);
        // this.showErrorPopup(err);
      });
  };

  boldWords = (JD_skills_added) => {
    const divContent = this.componentRef.innerHTML;
    if (!divContent) return;
    const keywords = JD_skills_added || []; // Specify the keywords to be made bold

    const regex = new RegExp(`\\b(${keywords.join('|')})\\b`, 'gi');
    this.componentRef.innerHTML = divContent.replace(regex, `<span style='font-weight: bold'>$1</span>`);

  }

  uploadAIGeneratedFile = (HistoryRowId) => {
    this.setState({
      showSpinner: true
    })
    const htmlContent = this.componentRef.innerHTML;
    const blob = new Blob([htmlContent], { type: 'text/html' });

    // eslint-disable-next-line no-undef
    const file = new File([blob], 'resume.html', {
      type: 'text/html'
    });
    // Utility.uploadFile(
    //   file,
    //   'html',
    //   response => {
    //     console.log('uploaded response:', response);
    //     this.setState({
    //       showSpinner: false,
    //       generatedResumeURL: response?.data?.s3DownloadLink
    //     })
    //     const data = {
    //       aiResumeEditedUrl: response?.data?.s3DownloadLink ? response?.data?.s3DownloadLink : "",
    //     }
    //     this.saveAIResumeEditedUrl(data);
    //   },
    //   error => {
    //     console.log(error);
    //     this.setState({
    //       showSpinner: false
    //     })
    //   }
    // );
    const formData = new FormData();
    formData.append('s3FolderName', 'company');
    formData.append('file', file);

    API.getInstance().uploadFilesToS3(formData)
      .then(response => {
        console.log(response);
        this.setState({
          showSpinner: false
        })
        const data = {
          aiResumeEditedUrl: response?.data ? response?.data : "",
        }
        this.saveAIResumeEditedUrl(data);
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showSpinner: false
        })
        // const err = util.getErrorMessage(error);
        // this.showErrorPopup(err);
      });


  };


  fetchScores = async () => { };
  onExportWordClick = async () => {
    let htmlString = this.componentRef.innerHTML;

    let preHtml =
      "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>Export HTML To Doc</title></head><body>";
    let postHtml = '</body></html>';
    let html = preHtml + htmlString + postHtml;

    let blob = new Blob(['\ufeff', html], {
      type: 'application/msword'
    });

    let url =
      'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    // Specify link url
    // var url = 'data:application/vnd.ms-word;charset=utf-8,' + encodeURIComponent(html);

    //   const blob = new Blob([htmlString], {
    //     type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    //   });

    const title = new Date().getTime();
    saveAs(url, `${title ? title : 'document'}.doc`);
  };

  toggleFullScreen = () => {
    const { toggleAIResumeFullScreen } = this.state;
    this.setState({ toggleAIResumeFullScreen: !toggleAIResumeFullScreen })
  }

  toggleOldResumeFullScreen = () => {
    const { oldResumeFullScreen } = this.state;
    this.setState({ oldResumeFullScreen: !oldResumeFullScreen })

  }

  getResumeInHTML = async S3HTMLLink => {
    const {
      dispatchGetResumeInHTML,
    } = this.props;

    await Utility.getResumeInHtml(
      S3HTMLLink,
      res => {
        this.setState({
          resumeInHTMLString: res.data
        })
        dispatchGetResumeInHTML(res.data);
      },
      error => {
        this.setState({
          resumeInHTMLString: null
        })
        dispatchGetResumeInHTML('Error');
        console.info('error in resume viewer', error, error.response);
      }
    );
  };

  handleChange = () => {
    // Handle logic when focus leaves the contenteditable div
    const { isSample } = this.state;
    if (!isSample) {
      this.setState({
        showResetButton: true,
      })
    }

  };

  saveChanges = () => {
    // Handle logic when focus leaves the contenteditable div
    const htmlContent = this.componentRef.innerHTML;
    const { HistoryRowId } = this.props.router.location.state || {};
    const { generatedResumeURL } = this.state;

    console.log('Focus left the contenteditable div:', HistoryRowId);
    const data = {
      id: HistoryRowId,
      aiResumeEditedUrl: generatedResumeURL ? generatedResumeURL : "",
      aiResumeEditedResponse: this.editorRef.getContent()
    }
    this.saveAIResumeEditedResponse(data);
  };

  reset = () => {
    console.log(this.editorRef);
    const { resetData } = this.state;
    this.setState({
      aiResumeEditedResponse: null,
    })

    if (this.editorRef) {
      this.editorRef.resetContent();
    }
    // setTimeout(() => {
    //   this.setState({
    //     resetData: true,
    //   })
    // }, 1000);
    // if (this.componentRef.current) {
    //   this.componentRef.current.reset();

    // }
  }

  handleTabChange = (newValue) => {
    this.setState({ tabValue: newValue });
    if (newValue === 'coverLetter') {
      this.getCoverLetter();
    }
  };

  handleOpenConfirmationPopup = (iconType) => {
    this.setState({
      openConfirmationPopup: true,
      iconType,
    });
  };

  handleCloseConfirmationPopup = () => {
    this.setState({
      openConfirmationPopup: false,
      iconType: '',
    });
  }

  handleTermsPopup = (val) => {
    this.setState({ showTermsPopup: val });
  };

  getCoverLetter = () => {
    const { s3DownloadLink, jdURL, coverLetterJson } = this.props.router.location.state || {};
    // this.initializeCL();
    if (coverLetterJson) {
      this.setState({
        showSpinner: false,
        coverLetterData: coverLetterJson
      })
    } else {
      const data = {
        resumeurl: s3DownloadLink,
        jdurl: jdURL
      }
      this.setState({
        showSpinner: true
      })
      API.getInstance().getCoverLetter(data)
        .then(res => {
          console.log(res);
          this.setState({
            showSpinner: false,
            coverLetterData: res.cover_letter
          })
          this.saveCoverLetter(res.cover_letter);
        })
        .catch(error => {
          console.log(error);
          this.setState({
            showSpinner: false
          })
        });
    }

  };

  saveCoverLetter = (jsonString) => {

    const { reduxProps } = this.props;
    const {
      cache: { userHistoryId }
    } = reduxProps;
    this.setState({
      showSpinner: true
    })

    const { HistoryRowId } = this.props.router.location.state || {};

    const data = {
      "scoreHistoryId": userHistoryId || HistoryRowId,
      "coverLetterJson": jsonString
    }
    API.getInstance().saveCoverLetter(data)
      .then(res => {
        console.log(res);
        this.setState({
          showSpinner: false
        })
      })
      .catch(error => {
        console.log(error);
        this.setState({
          showSpinner: false
        })
      });
  };

  initializeCL = () => {
    tinymce.init({
      selector: '#coverLetter',
      inline: true, // Enable inline mode
      branding: false,
      menubar: false,
      paste_data_images: true,
      image_uploadtab: true,
      toolbar_persist: true,
      fixed_toolbar_container: '#mytoolbar1',
      license_key: 'gpl',
      content_style: 'body { max-width: 100%; }',
      plugins: "link image print preview anchor textcolor lists",
      toolbar: 'undo redo | blocks | bold italic underline | forecolor backcolor | bullist numlist | alignleft aligncenter alignright alignjustify |  outdent indent | link',
      paste_data_images: true,
      toolbar_mode: 'wrap',
      extended_valid_elements: "svg[*]",
      setup: (editor) => {
        // this.coverLetterEditorRef = editor;
        editor.on('change', () => {
          // console.log('Editor content changed:', editor.getContent());
          // Call the onEditorChange callback with the updated content

        });
      }
      // Other configuration options...
    });
  }

  render() {
    const { reduxProps } = this.props;
    const { aiResumeData, prevScoreReport, name, email, phoneNumber, s3DownloadLink, HistoryRowId, aiResumeEditedResponse } = this.props.router.location.state || {};
    console.log('Prev Score Report', prevScoreReport);
    console.log('reduxProps:', reduxProps);
    console.log('aiResumeData', aiResumeData);
    // console.log('aiResumeEditedResponse', aiResumeEditedResponse);


    const { resetData, showResetButton, showSpinner, resumeInHTMLString, isSample, coverLetterData } = this.state;
    if (!aiResumeData) {
      window.location.href = BASEPATH + '/resume-build-home';
    }
    const {
      cache: { fullName, parsedResumeData, report, HTMLLink, resumeInHTML }
    } = reduxProps;
    //    const { email, phoneNumber, firstName, lastName, s3DownloadLink } = parsedResumeData || {};
    const htmlResume = resumeInHTML;
    const {
      isPreviousScoreLoading,
      toggleAIResumeFullScreen,
      oldResumeFullScreen,
      tabValue,
      openConfirmationPopup,
      iconType,
      showTermsPopup,
    } = this.state;
    const formattedCoverLetterContent = coverLetterData?.replace(/\n/g, '<br />');

    return (
      <div>
        <Navbar />
        <Helmet>
          <title>{metaData.builderReport.title}</title>
        </Helmet>

        {!isSample ? <ThumbsUpDownComponent /> : null}
        {showSpinner ? <Spinner show={true} /> : null}

        <div className={styles['tab-container']}>
          <ul>
            <li
              className={tabValue === tabsConstants.oldResume ? styles.active : ''}
              onClick={() => this.handleTabChange(tabsConstants.oldResume)}
            >
              Old Resume
            </li>
            <li
              className={tabValue === tabsConstants.aiGeneratedResume ? styles.active : ''}
              onClick={() => this.handleTabChange(tabsConstants.aiGeneratedResume)}
            >
              AI Generated <span className={tabValue === tabsConstants.aiGeneratedResume ? styles.spanScore : ''}>Resume</span>
            </li>
            <li
              className={tabValue === tabsConstants.coverLetter ? styles.active : ''}
              onClick={() => this.handleTabChange(tabsConstants.coverLetter)}
            >
              Cover Letter
            </li>
          </ul>
        </div>

        {tabValue === tabsConstants.oldResume && (
          <>
            <div className={styles['tab-contents']}>
              <div className={styles.scoreContainer}>
                <div className={styles.circle}>
                  <div className={styles.score}>
                    {aiResumeData['Original Resume Score'] + '%'}
                  </div>
                </div>
                <div className={styles.label}>
                  Your old resume Score
                </div>
              </div>
            </div>

            <div
              style={{ position: 'relative', height: '730px', border: '1px solid #c0c0c0' }}
              className={styles.oldResumeDiv}
            >
              {resumeInHTMLString?.length > 10 ? <div
                className={styles.resumeDescrition}
                dangerouslySetInnerHTML={{
                  __html: resumeInHTMLString
                }}
              /> : <GoogleDocsViewer documentUrl={s3DownloadLink} />
              }
            </div>
          </>
        )}

        {tabValue === tabsConstants.aiGeneratedResume && (
          <>
            <div className={styles['tab-contents']}>
              <div className={styles.scoreContainer}>
                <div className={styles.circle}>
                  <div className={styles.score}>
                    {aiResumeData['New Resume Score'] + '%'}
                  </div>
                </div>
                <div className={styles.label}>
                  Your AI generated resume Score
                </div>
              </div>
            </div>

            <div className="">
              <div className={styles.mobileDownloadButtonsContainer}>
                <div className={styles.downloadButtons}>
                  <div>
                    {showResetButton ? <div className={styles.resetButtons}>
                      <div className={styles.saveBtn} onClick={this.saveChanges}>Save</div>
                      <div className={styles.saveBtn} onClick={this.reset}>Reset</div>
                    </div> : null}
                  </div>
                  <div className={styles.iconContainer}>
                    <div className={styles.icon}>
                      <button
                        className={styles.CheckNavButton}
                        // onClick={this.onExportWordClick}
                        onClick={() => this.handleOpenConfirmationPopup('word')}
                      >
                        <img src={wordIcon}></img>
                      </button>
                    </div>
                    <div className={styles.icon}>
                      {/* <ReactToPrint
                        documentTitle={name}
                        trigger={() => {
                          return (
                            <button className={styles.CheckNavButton}>
                              <img src={pdfIcon}></img>
                            </button>
                          );
                        }}
                        content={() => this.componentRef}
                      /> */}
                      <button
                        className={styles.CheckNavButton}
                        onClick={() => this.handleOpenConfirmationPopup('pdf')}
                      >
                        <img src={pdfIcon}></img>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div id="mytoolbar" />
              {aiResumeData && (
                <div className={toggleAIResumeFullScreen ? styles.popUp : ''}>
                  {
                    resetData ?
                      <ComponentToPrint
                        ref={el => (this.componentRef = el)}
                        aiResumeData={aiResumeData}
                        name={name}
                        email={email}
                        phoneNumber={phoneNumber}
                        aiResumeEditedResponse={aiResumeEditedResponse}
                        onExportWordClick={this.onExportWordClick}
                      /> : null}
                </div>
              )}
              <div className={styles.infoLabel}>This resume prioritizes ATS-friendliness over design or visual elements.</div>

            </div>
          </>
        )}
        {tabValue === tabsConstants.coverLetter && (
          <>
            <div className={styles['tab-contents']}>
              <div id="mytoolbar1" className={styles.myToolBar1} />
              <div id="coverLetter" className={styles.coverLetterContainer} contentEditable={true}>
                <div dangerouslySetInnerHTML={{ __html: formattedCoverLetterContent }} />
              </div>
            </div>
          </>
        )}


        <ConfirmationDialog
          open={openConfirmationPopup}
          iconType={iconType}
          onClose={this.handleCloseConfirmationPopup}
          onWordDownload={this.onExportWordClick}
          pdfContent={this.componentRef}
          handleTermsPopup={this.handleTermsPopup}
        />
        {showTermsPopup ? <Privacy heading="Our Terms of Service" onClose={() => this.handleTermsPopup(false)} messageObject={termsData} showAsPopup={true} /> : null}
      </div>
    );
  }
}

export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { aiResumeData, name, email, phoneNumber, onExportWordClick, aiResumeEditedResponse } = props;
  const { authState, pannaAuth } = useAuth();
  // const education = JSON.parse(aiResumeData?.Education)?.education || JSON.parse(aiResumeData?.Education)?.qualifications;
  let experience = null;
  let skills = null;
  let education = null;
  let certification = null;
  let achievement = null;
  let candidateInfo = null || [];
  try {
    candidateInfo = aiResumeData?.Candidateinfo && Object.values(JSON.parse(aiResumeData?.Candidateinfo));
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  try {
    experience = aiResumeData?.Experience && Object.values(JSON.parse(aiResumeData?.Experience.replace(/None/g, 'null')));

  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  try {
    education = aiResumeData?.Education && JSON.parse(aiResumeData?.Education.replace(/None/g, 'null'));
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
  try {
    certification = aiResumeData?.Certification && JSON.parse(aiResumeData?.Certification.replace(/None/g, 'null'));

  } catch (error) {
    console.error('Error parsing JSON:', error);
  }
  try {
    achievement = aiResumeData?.Achievement && JSON.parse(aiResumeData?.Achievement.replace(/None/g, 'null'));

  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  try {
    // experience = aiResumeData?.Experience && Object.values(JSON.parse(aiResumeData?.Experience));
    skills = aiResumeData?.Skills && JSON.parse(aiResumeData?.Skills);
    // education = aiResumeData?.Education && JSON.parse(aiResumeData?.Education);
    // certification = aiResumeData?.Certification && JSON.parse(aiResumeData?.Certification);
    // achievement = aiResumeData?.Achievement && JSON.parse(aiResumeData?.Achievement);

    // alert(Array.isArray(skills));
    if (!Array.isArray(skills)) {
      skills = aiResumeData?.Skills && JSON.parse(aiResumeData?.Skills)?.skills;
    }
  } catch (error) {
    console.error('Error parsing JSON:', error);
  }

  return (
    <div className={styles.aiGeneratedContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start'
        }}
      >

        <div className={styles.aiGeneratedWrap}>
          {aiResumeEditedResponse ? <div id="aiResume" contentEditable={true} ref={ref}
            className={styles.resumeContainer}
            dangerouslySetInnerHTML={{
              __html: aiResumeEditedResponse
            }}
          /> : <div id="aiResume" contentEditable={true} ref={ref} className={styles.resumeContainer}>
            <p className={styles.resumeName}>{candidateInfo && candidateInfo[0]?.CandidateName ? (candidateInfo[0].CandidateName)?.toUpperCase() : (name)?.toUpperCase()} </p>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '10px',
                marginBottom: '30px',
                borderBottom: '3px solid #000',
                paddingBottom: '15px'

              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',
                  marginRight: '30px'
                }}
              >
                <span>
                  {candidateInfo && candidateInfo[0]?.Candidatecontactnumber ? <Phone /> : null}
                </span>
                <p style={{ fontSize: '0.8rem' }}>
                  {candidateInfo && candidateInfo[0]?.Candidatecontactnumber ? candidateInfo[0]?.Candidatecontactnumber : null}
                </p>
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '2px',

                }}
              >
                <span>
                  {email || (candidateInfo && candidateInfo[0]?.candidatemailid) ? <Envelope /> : null}

                </span>
                <p style={{ fontSize: '0.8rem' }}>
                  {(candidateInfo && candidateInfo[0]?.candidatemailid) ? candidateInfo[0]?.candidatemailid : email}
                </p>
              </div>
              {aiResumeData.Github && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px'
                  }}
                >
                  <span>
                    <Github />
                  </span>
                  <a
                    href={aiResumeData.Github}
                    target="_blank"
                    style={{ fontSize: '0.8rem', color: 'black' }}
                  >
                    Github
                  </a>
                </div>
              )}
              {aiResumeData.Linkedin && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '2px'
                  }}
                >
                  <span>
                    <LinkedIn />
                  </span>
                  <a
                    href={aiResumeData.Linkedin}
                    target="_blank"
                    style={{ fontSize: '0.8rem', color: 'black' }}
                  >
                    Linkedin
                  </a>
                </div>
              )}
            </div>

            <div className={styles.resumeRow}>
              <p className={styles.resumeTitle}>SUMMARY</p>
              <p
                className={styles.resumeDescrition}
                dangerouslySetInnerHTML={{
                  __html: aiResumeData?.Summary
                }}
              />
            </div>
            {education ? <div className={styles.resumeRow}>
              <p style={{ marginBottom: '1rem' }} className={styles.resumeTitle}>EDUCATION</p>

              {
                Array.isArray(education) && education.map(ele => {
                  return <div style={{ marginTop: '0rem', marginLeft: '3rem' }}
                    className={styles.resumeDescrition}>
                    <div><b style={{ marginRight: '5px' }}>{ele?.qualification}</b>{ele?.description ? ' ' + ele?.description : null}{ele?.institute ? ', ' + ele?.institute : null}{ele?.year ? ', ' + ele?.year : null}<b>{ele?.percentage ? ', ' + ele?.percentage : null}</b>
                    </div>
                  </div>
                })
              }

            </div> : null}
            {experience ? <div className={styles.resumeRow}>
              <div className={styles.resumeTitle}>EXPERIENCE</div>

              {
                experience && Array.isArray(experience) && experience.map(ele => {
                  return <div style={{ marginLeft: '3rem' }}
                    className={styles.resumeDescrition}>

                    {/* <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 'bold' }}>
                    <div>{ele?.companyname} {ele?.location ? ',' + ele?.location : null}</div>
                    <div>{ele?.timeduration}</div>
                  </div> */}
                    <div>
                      {ele?.role && Array.isArray(ele?.role) && ele?.role.map(val => {
                        return <>

                          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontWeight: 'bold', marginBottom: '10px', marginTop: '30px' }}>
                            <div>{val?.Designation}{ele?.companyname ? ', ' + ele?.companyname : null} {ele?.location ? ', ' + ele?.location : null}</div>
                            <div>{val?.duration}</div>
                          </div>
                          {/* <div>
                          {val?.Designation}, {ele?.companyname}  {val?.duration}
                        </div> */}

                          <div>
                            {val?.name_of_project_or_client_or_role ? <b>Project: </b> : null}
                            {val?.name_of_project_or_client_or_role ? val?.name_of_project_or_client_or_role : null}
                          </div>
                          <div>
                            {val?.responsibilities ? <b>Responsibilities: </b> : null}
                            {val?.responsibilities ? <div className={styles.respList}
                              dangerouslySetInnerHTML={{
                                __html: val?.responsibilities
                              }}
                            /> : null}
                          </div>
                          <div>
                            {val?.skills ? <b>Skills: </b> : null}
                            {val?.skills ? val?.skills.toString() : null}
                          </div>
                        </>
                      })
                      }
                    </div>
                  </div>
                })
              }

            </div> : null}
            {skills ? <div className={styles.resumeRow}>
              <div className={styles.resumeTitle}>SKILLS</div>

              {
                skills && Array.isArray(skills) && skills.map(ele => {
                  return <div style={{ marginLeft: '3rem' }}
                    className={styles.resumeDescrition}>
                    <div><b style={{ marginRight: '5px' }}>{ele?.skill}: </b>
                      {/* {ele?.description.toString()} */}
                      {Array.isArray(ele?.description) && ele?.description.map((value, i) => {
                        return <span>
                          {value && i !== 0 ? ', ' : null} {value}
                        </span>
                      })}
                    </div>
                  </div>
                })
              }


              {/* <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Skills
              }}
            /> */}
            </div> : null}
            {certification ?
              <div className={styles.resumeRow}>
                <p className={styles.resumeTitle}>CERTIFICATION</p>

                {
                  Array.isArray(certification) && certification.map(ele => {
                    return <div style={{ marginLeft: '3rem' }}
                      className={styles.resumeDescrition}>
                      <div><b style={{ marginRight: '5px' }}>{ele?.certificate ? ele?.certificate : ele?.certification} </b>
                        {ele?.description ? '' + ele?.description : null}{ele?.year ? ', ' + ele?.year : null}
                      </div>
                    </div>
                  })
                }

              </div> : null}
            {achievement ?
              <div className={styles.resumeRow}>
                <p className={styles.resumeTitle}>ACHIEVEMENT</p>

                {
                  Array.isArray(achievement) && achievement.map(ele => {
                    return <div style={{ marginLeft: '3rem' }}
                      className={styles.resumeDescrition}>
                      <div><b style={{ marginRight: '5px' }}>{ele?.achievement} </b>
                        {ele?.description ? '' + ele?.description : null}{ele?.year ? ', ' + ele?.year : null}
                      </div>
                    </div>
                  })
                }

              </div> : null}
          </div>
          }


        </div>
      </div>
    </div>
  );
});

const Phone = () => {
  return (

    <svg style={{
      height: '14px',
      width: '14px',
      marginRight: '5px',
      display: 'block'
    }}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24">
      <path d="M21.384,17.752a2.108,2.108,0,0,1-.522,3.359,7.543,7.543,0,0,1-5.476.642C10.5,20.523,3.477,13.5,2.247,8.614a7.543,7.543,0,0,1,.642-5.476,2.108,2.108,0,0,1,3.359-.522L8.333,4.7a2.094,2.094,0,0,1,.445,2.328A3.877,3.877,0,0,1,8,8.2c-2.384,2.384,5.417,10.185,7.8,7.8a3.877,3.877,0,0,1,1.173-.781,2.092,2.092,0,0,1,2.328.445Z" />
    </svg>



  );
};

const Envelope = () => {
  return (
    <svg style={{
      height: '14px',
      width: '14px',
      marginTop: '1px',
      display: 'block',
      marginRight: '5px',
    }} viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><path d="M256 352c-16.53 0-33.06-5.422-47.16-16.41L0 173.2V400C0 426.5 21.49 448 48 448h416c26.51 0 48-21.49 48-48V173.2l-208.8 162.5C289.1 346.6 272.5 352 256 352zM16.29 145.3l212.2 165.1c16.19 12.6 38.87 12.6 55.06 0l212.2-165.1C505.1 137.3 512 125 512 112C512 85.49 490.5 64 464 64h-416C21.49 64 0 85.49 0 112C0 125 6.01 137.3 16.29 145.3z" /></svg>

  );
};

export const LinkedIn = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-brand-linkedin"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{
        height: '14px',
        width: '14px',
        marginTop: '1px',
        display: 'block'
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2z" />
      <path d="M8 11l0 5" />
      <path d="M8 8l0 .01" />
      <path d="M12 16l0 -5" />
      <path d="M16 16v-3a2 2 0 0 0 -4 0" />
    </svg>
  );
};

export const Github = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="icon icon-tabler icon-tabler-brand-github"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      fill="none"
      stroke-linecap="round"
      stroke-linejoin="round"
      style={{
        height: '14px',
        width: '14px',
        marginTop: '1px',
        display: 'block'
      }}
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" />
    </svg>
  );
};

function ConfirmationDialog(props) {
  const { iconType, onClose, open, onWordDownload, pdfContent, handleTermsPopup } = props;

  const getIcon = () => {
    switch (iconType) {
      case 'pdf':
        return pdfIcon;
      case 'word':
        return wordIcon;
      default:
        return '';
    }
  }

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog className={styles.confirmationDialog} onClose={handleClose} open={open}>
      <DialogTitle className={styles.iconContainer}>
        <img className={styles.icon} src={getIcon()}></img>
      </DialogTitle>
      <DialogContent>
        <div className={styles.warningMessage}>By downloading, you accept the terms and conditions.</div>

        <div
          onClick={() => handleTermsPopup(true)}
          className={styles.terms}
        >
          terms and conditions
        </div>
      </DialogContent>
      <DialogActions className={styles.actionButtonContainer}>
        {iconType === 'pdf' && (
          <ReactToPrint
            documentTitle={name}
            trigger={() => {
              return (
                <button className={styles.actionButton}>
                  Download
                </button>
              );
            }}
            content={() => pdfContent}
            onAfterPrint={onClose}
          />
        )}
        {iconType === 'word' && (
          <button
            className={styles.actionButton}
            onClick={() => {
              onWordDownload();
              onClose();
            }}
          >
            Download
          </button>
        )}
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = reduxProps => ({
  reduxProps
});
const mapDispatchToProps = dispatch => ({
  dispatchReportData: reportData => {
    dispatch(cacheReportData(reportData));
  },
  dispatchUpdateUserName: userName => {
    dispatch(updateUserName(userName));
  },
  dispatchUpdateUserFile: userFile => {
    dispatch(updateUserFile(userFile));
  },
  // dispatchUpdateFeedback: reportData => {
  //     dispatch(updateFeedBack(reportData));
  // },
  dispatchGetResumeInHTML: userFiles => {
    dispatch(getResumeInHTML(userFiles));
  },
  dispatchUpdateHTMLLink: link => {
    dispatch(updateResumeHtmlLink(link));
  }
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ReportResumeBuilder);
