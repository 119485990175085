/* eslint-disable no-nested-ternary */
/* eslint-disable */
const commonConfig = {
  apiUrl: process.env.REACT_APP_API_URL,
};

const env = process.env.REACT_APP_ENVIRONMENT || 'dev';

let envConfig = {};

switch (env) {
  case 'prod':
    envConfig = {
      hostName: 'https://commonservices-prod.mroads.com/',
      homePage: 'https://www.panna.ai/score',
      organisationId: '44932',
      parseResume: 'commonservices/api/resume-upload-parse-service',
      releavanceCheck: 'https://resumematch.mroads.com/relevance_score',
      uploadResumeToSolar: 'https://solr.mroads.com/update_pannascore_resume',
      deleteSkills: 'https://resumematch.mroads.com/NewSkills_calculation',
      feedBack: 'https://score.panna.ai/feedback',
      postLogs: 'https://resumematch.mroads.com/postlogUI',
      organisationName: 'Pannaresumescore',
      userId: 1330281,
      googleClientId:
        '1094520159854-fm0qvqakkj9h3eq5gm0vd45bdiv34lo6.apps.googleusercontent.com',
      signUp: 'https://resume-services.mroads.com/resume-services/account/signUp',
      login: 'https://resume-services.mroads.com/resume-services/account/login',
      validate:
        'https://resume-services.mroads.com/resume-services/account/validate',
      session: 'https://resume-services.mroads.com/resume-services/session',
      improvedRes: 'https://resumematch.mroads.com/improved_res',
      getCoverLetter: 'https://resumematch.mroads.com/create_cover_letter',
      unsubscribe: 'https://resume-services.mroads.com/resume-services/account/unsubscribe',
    };
    break;
  case 'dev':
    envConfig = {
      hostName: 'https://play-commons.mroads.com/',
      homePage: 'https://panna-play.mroads.com/',
      organisationId: '44730',
      parseResume: 'commonservices/api/resume-upload-parse-service',
      releavanceCheck: 'https://dev-resumematch.mroads.com/relevance_score',
      uploadResumeToSolar: 'https://solr.mroads.com/update_pannascore_resume',
      deleteSkills: 'https://dev-resumematch.mroads.com/NewSkills_calculation',
      feedBack: 'https://score.panna.ai/feedback',
      postLogs: 'https://dev-resumematch.mroads.com/postlogUI',
      organisationName: 'pannaresumescoreTest',
      userId: 1330281,
      googleClientId:
        '1094520159854-t6oh558pskdcs9d8j45shugieu7c7kob.apps.googleusercontent.com',
      signUp:
        'https://dev-resume-services.mroads.com/resume-services/account/signUp',
      login: 'https://dev-resume-services.mroads.com/resume-services/account/login',
      validate:
        'https://dev-resume-services.mroads.com/resume-services/account/validate',
      session: 'https://dev-resume-services.mroads.com/resume-services/session',
      improvedRes: 'https://dev-resumematch.mroads.com/improved_res',
      getCoverLetter: 'https://dev-resumematch.mroads.com/create_cover_letter',
      unsubscribe: 'https://dev-resume-services.mroads.com/resume-services/account/unsubscribe',
    };
    break;
  case 'stage':
    envConfig = {
      hostName: 'https://play-commons.mroads.com/',
      homePage: 'https://panna-play.mroads.com/',
      organisationId: '44730',
      parseResume: 'commonservices/api/resume-upload-parse-service',
      releavanceCheck: 'https://dev-resumematch.mroads.com/relevance_score',
      uploadResumeToSolar: 'https://solr.mroads.com/update_pannascore_resume',
      deleteSkills: 'https://dev-resumematch.mroads.com/NewSkills_calculation',
      feedBack: 'https://score.panna.ai/feedback',
      postLogs: 'https://dev-resumematch.mroads.com/postlogUI',
      organisationName: 'pannaresumescoreTest',
      userId: 1330281,
      googleClientId:
        '1094520159854-t6oh558pskdcs9d8j45shugieu7c7kob.apps.googleusercontent.com',
      signUp:
        'https://dev-resume-services.mroads.com/resume-services/account/signUp',
      login: 'https://dev-resume-services.mroads.com/resume-services/account/login',
      validate:
        'https://dev-resume-services.mroads.com/resume-services/account/validate',
      session: 'https://dev-resume-services.mroads.com/resume-services/session',
      improvedRes: 'https://dev-resumematch.mroads.com/improved_res',
      getCoverLetter: 'https://dev-resumematch.mroads.com/create_cover_letter',
      unsubscribe: 'https://dev-resume-services.mroads.com/resume-services/account/unsubscribe',
    };
    break;
  default:
    break;
}

const config = {
  ...commonConfig,
  ...envConfig,
};

export default config;

