/* eslint-disable */

import { REHYDRATE } from 'redux-persist';
const defaults = {
  recentJds: [],
  reportData: null,
  userId: null,
  fullName: null,
  userFile: null,
  // isFeedback: false,
  resumeInHTML: null,
  HTMLLink: null,
  resumeDetails: null,
  parsedResumeData: null

};

export interface ActionType {
  type: string;
  payload: any;
}

export default (state = defaults, action: ActionType) => {
  switch (action.type) {
    case 'CACHE_RECENT_JDS': {
      const recentJds = action.payload;
      return { ...state, recentJds };
    }
    case 'CACHE_REPORT_DATA': {
      return { ...state, reportData: action.payload };
    }
    case 'UPDATE_USER_ID': {
      return { ...state, userId: action.payload };
    }
    case 'UPDATE_FULL_NAME': {
      return { ...state, fullName: action.payload };
    }
    case 'UPDATE_USER_FILE': {
      return { ...state, userFile: action.payload };
    }
    // case 'UPDATE_FEEDBACK': {
    //   return { ...state, isFeedback: action.payload };
    // }
    case 'GET_RESUME_IN_HTML': {
      return { ...state, resumeInHTML: action.payload };
    }
    case 'UPDATE_USER_HTML_RESUME': {
      return { ...state, HTMLLink: action.payload };
    }
    case 'UPDATE_RESUME_URL': {
      return { ...state, resumeDetails: action.payload };
    }
    case 'PARSED_RESUME_DATA': {
      return { ...state, parsedResumeData: action.payload };
    }
    case 'CLEAR_STORE': {
      console.log('clearing store data')
      return { ...defaults };
    }
    case 'UPDATE_LOCATION': {
      console.log('updating location')
      return { ...state, location: action.payload };
    }
    case 'RESET_WITH_NEW_DATA': {
      console.log('RESETTING THE STORE DATA')
      return { ...action.payload };
    }
    case 'UPDATE_USER_HISTORY_ID': {
      return { ...state, userHistoryId: action.payload };
    }
    case REHYDRATE: {
      if (action.payload && action.payload.cache) {
        return {
          ...state,
          ...action.payload.cache
        };
      }
      return {
        ...defaults
      };
    }
    default:
      return { ...state };
  }
};
