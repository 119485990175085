import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import getStripe from '../../stripe';
import API from '../../network';
import logo from '../../assets/Logo/pannaBlack.png'
import { BASEPATH } from '../../lib/constants'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion } from "framer-motion";
import Spinner from '../Spinner';
import Utility from '../../utils';


export const PaymentModal = ({ open, setOpen }) => {
  const [loading, setLoading] = useState(undefined);
  const [orderId, setOrderId] = useState(null);
  const [amount, setAmount] = useState(1);
  const [showSpinner, setShowSpinner] = useState(false);
  const containerRef = useRef();
  const data = useSelector(state => state);
  console.log('store data', data.cache?.location);
  const isIndia = BASEPATH.includes('/in') ? true : false;
  // const isIndia = BASEPATH.includes('/in') ? false : false;
  const proceedToCheckout = async priceId => {
    setLoading(priceId);
    const requestObject = {
      priceId
    };
    const stripe = await getStripe();
    try {
      const { sessionId } = await API.getInstance().checkout();

      console.log('sessionId', sessionId);
      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId
      });
    } catch (e) {
      console.log(e);
      let errorObj = e.response;
      const { sessionId } = errorObj.data;
      console.log('sessionId', sessionId);

      const { error } = await stripe.redirectToCheckout({
        sessionId: sessionId
      });
      console.log('error', error);
    } finally {
      setLoading(undefined);
    }
  };

  // const isIndia = () => {
  //   console.log('isIndia:', data.cache?.location === 'India');
  //   alert(data.cache?.location)
  //   return data.cache?.location === 'India';
  // }

  const createOrder = () => {
    setShowSpinner(true);

    API.getInstance().createOrder()
      .then(res => {
        console.log(res);
        setOrderId(res.orderId);
        setAmount(res.amount);
        console.log('Order created');
        displayRazorpay(res);
      })
      .catch(error => {
        console.log(error);
      });
    Utility.trackEvent('payment-popup', 'BUTTON_CLICK', 'Pay');
  };

  const paymentSuccess = (data) => {
    API.getInstance().paymentSuccess(data)
      .then(res => {
        console.log(res);

        window.location.href = BASEPATH + '/resume-builder';
      })
      .catch(error => {
        console.log(error);
      });
  };
  const paymentFailure = (data) => {
    API.getInstance().paymentFailure(data)
      .then(res => {
        console.log(res);
        setOrderId(res);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const loadRazorpay = () => {
    return new Promise(resolve => {
      const script = document.createElement('script');
      script.src = 'https://checkout.razorpay.com/v1/checkout.js';
      // document.body.appendChild(script);

      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };


  const displayRazorpay = async (orderDetails) => {
    console.info('display razorpay -->');
    const res = await loadRazorpay();
    setOpen(false);
    setShowSpinner(false);
    if (!res) {
      alert('Razorpay SDK Failed to load');
      return;
    }

    console.log('process.env.REACT_APP_RAZORPAY_API_KEY_ID', process.env.REACT_APP_RAZORPAY_API_KEY_ID);

    var options = {
      key: process.env.REACT_APP_RAZORPAY_API_KEY_ID, // Enter the Key ID generated from the Dashboard
      name: 'panna score',

      order_id: orderDetails.orderId,
      amount: orderDetails.amount,
      description: 'Thankyou!',
      image: { logo },
      image: {},
      handler: response => {
        console.log('RESPONSE...', response);
        let successDetails = {
          orderId: orderDetails.orderId,
          paymentId: response.razorpay_payment_id,
          signature: response.razorpay_signature,
        };
        // window.location.href = '/score/resume-builder';
        paymentSuccess(successDetails);
      },
      modal: {
        ondismiss: data => {
          const errorDetails = {
            orderId: orderDetails.orderId,
            paymentId: 'HARDCODED',
            code: 'FAILED-HARDCODED',
          };
          //  this.callFailureAPI(errorDetails);
          // this.setPaymentFailure
          // setPaymentLoading(false);
          paymentFailure(errorDetails);

        },
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const ArrowBox = ({ content, name }) => {
    return (
      <div className={styles.box}>
        <div className={styles.text}>{content}</div>
        <div className={styles.name}>- {name}</div>
      </div>
    );
  }
  const [autoplay, setAutoplay] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 5000,
    autoplay: autoplay,
    pauseOnHover: false,
    cssEase: 'linear',
    autoplaySpeed: 0,
  };
  const handleMouseEnter = () => {
    setAutoplay(false);
  };
  const handleSwipe = () => {
    setAutoplay(false);
  };

  const min = 400;
  const max = 500;
  // const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
  const randomNumber = () => {
    const min = 100;
    const max = 500;

    // Get the current date
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth() + 1; // 1-based
    const day = now.getDate();

    // Create a seed from the date
    const seed = year * 10000 + month * 100 + day;

    // Generate a pseudo-random number using the seed
    const random = (seed % (max - min + 1)) + min;

    return random;
  }



  return (
    <>

      {showSpinner ? <Spinner show={true} /> : null}

      {open && (
        <div
          style={{
            zIndex: 999999,
            backdropFilter: 'blur(10px)'
          }}
          className={styles.container}
        >
          <div
            style={{
              backdropFilter: 'blur(10px)'
            }}
            className={styles.content}
            ref={containerRef}
          >

            <div className={styles.header}>
              <div className={styles.heading}>Level up your career now</div>
              <div className={styles.label}><span className={styles.paymentNumber}>{randomNumber()} payments</span> in last 24 hours</div>
            </div>
            <div className={styles.checkContainer}>
              <div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>Pay as you go</p>

                </div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>ATS friendly</p>

                </div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>Free Cover Letter</p>

                </div>

              </div>
              <div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>No branding</p>
                </div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>Editable resume</p>
                </div>
                <div className={styles.checkBox}>
                  <Step />
                  <p className={styles.checkDescription}>AI-Powered rewriting</p>
                </div>
              </div>
            </div>
            <div className={styles.paymentBox}>
              <div className={styles.text}>Customise your Resume journey for just</div>
              <div className={styles.content1} style={{ paddingTop: 10, width: '100%' }}>
                {isIndia ? <div className={styles.newPrice}>  ₹499
                </div> : <div className={styles.newPrice}>  $4.99
                </div>}
                {isIndia ? <div className={styles.gstLabel}>+ 18% GST</div> : null}
                {isIndia ? <motion.button whileTap={{ scale: 0.85 }} id="rs199PopupBtn" className={styles.footerLink} onClick={createOrder}>
                  Continue to payment
                </motion.button> : <motion.button id="dollar1.99PopupBtn" className={styles.footerLink} onClick={proceedToCheckout}>
                  Continue to payment
                </motion.button>}
              </div>
            </div>
            <div style={{ width: '90%', margin: '0 auto', }} onMouseEnter={handleMouseEnter}>
              <Slider {...settings} onSwipe={handleSwipe} onMouseEnter={handleMouseEnter} style={{ width: '90%', margin: '0 auto', padding: '10px 20px 0px 20px' }}>
                <div>
                  <ArrowBox content="Impressive results! With just a few clicks, I had a professional resume that got me noticed by recruiters. Definitely worth it!" name="Emily Peterson" />
                </div>
                <div>

                  <ArrowBox content="The AI-built resume was good. which landed me more interviews." name="Sarah Jones " />
                </div>

                <div>
                  <ArrowBox content="Got more interview calls after submitting the resume built by this tool." name="Aditi Kashyap" />
                </div>

                <div>
                  <ArrowBox content="Tool analysed the job description and highlighted my skills perfectly. I landed multiple interviews within days of using it!" name="Lisa Webb" />
                </div>
                <div>
                  <ArrowBox content="I've tried other resume builders, but no other tool gave me a resume as per the job description. A good tool to generate focused resumes. Highly recommended!" name="Samantha Lawson" />
                </div>
              </Slider>

            </div>



            {/* <div className={styles.leftContent}>
              <div style={{ padding: '30px' }}>
                <div className={styles.header}>
                  Elevate Your Career Now
                </div>
                <div className={styles.leftContent1}>
                  Use our AI resume builder to build a resume as per the job description of the position you are looking for!We craft a tailor-made resume that highlights your most relevant skills and experiences, maximizing your chances of landing the interview.

                </div>
              </div>
              <Slider {...settings} style={{ width: '90%', margin: '0 auto', padding: '20px 20px 0px 20px' }}>
                <div>
                  <ArrowBox content="Impressive results! With just a few clicks, I had a professional resume that got me noticed by recruiters. Definitely worth it!" name="Emily Peterson" />
                </div>
                <div>

                  <ArrowBox content="The AI-built resume was good. which landed me more interviews." name="Sarah Jones " />
                </div>

                <div>
                  <ArrowBox content="Got more interview calls after submitting the resume built by this tool." name="Aditi Kashyap" />
                </div>

                <div>
                  <ArrowBox content="Tool analysed the job description and highlighted my skills perfectly. I landed multiple interviews within days of using it!" name="Lisa Webb" />
                </div>
                <div>
                  <ArrowBox content="I've tried other resume builders, but no other tool gave me a resume as per the job description. A good tool to generate focused resumes. Highly recommended!" name="Samantha Lawson" />
                </div>
              </Slider>
            </div>
            <div className={styles.rightContent}>
              <p
                className={styles.header}
                style={{
                  textAlign: 'center'
                }}
              >
                Your customized resume journey{' '}
                for just
              </p>
              <div className={styles.content1} style={{ paddingTop: 10 }}>
                {isIndia ? <div className={styles.newPrice}>  ₹499*
                </div> : <div className={styles.newPrice}>  $4.99
                </div>}
              </div>

              <div
                style={{
                  margin: '0rem 0 3rem 0'
                }}
              >
                <div className={styles.checkContainer}>
                  <Step />
                  <p className={styles.checkDescription}>AI-powered analysis</p>
                </div>
                <div className={styles.checkContainer}>
                  <Step />
                  <p className={styles.checkDescription}>
                    Job description specific resume
                  </p>
                </div>
                <div className={styles.checkContainer}>
                  <Step />
                  <p className={styles.checkDescription}>ATS & Recruiter Friendly Resume</p>
                </div>

                <div className={styles.checkContainer}>
                  <Step />
                  <p className={styles.checkDescription}>{'Build your resume in < 5mins'}</p>
                </div>

              </div>
              {isIndia ? <div><motion.button whileTap={{ scale: 0.85 }} id="rs199PopupBtn" className={styles.footerLink} onClick={createOrder}>
                Pay ₹499*
              </motion.button><div className={styles.gstLabel}>(*Plus 18% GST)</div></div> : <motion.button id="dollar1.99PopupBtn" className={styles.footerLink} onClick={proceedToCheckout}>
                Pay $4.99
              </motion.button>}
            </div> */}
          </div>
        </div >
      )}
    </>
  );
};

const Step = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C13.9706 18 18 13.9706 18 9C18 7.1666 17.4518 5.46124 16.5103 4.03891L9.35503 11.9893C8.66236 12.7589 7.49679 12.8726 6.66844 12.2513L3.4 9.8C2.95817 9.46863 2.86863 8.84183 3.2 8.4C3.53137 7.95817 4.15817 7.86863 4.6 8.2L7.86844 10.6513L15.214 2.48955C13.5986 0.947171 11.4099 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z" fill="#45DE82" />
    </svg>
  );
};
