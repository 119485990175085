/* eslint-disable */
import React from 'react';
import axios from 'axios';
import { AuthContext } from '../../context/AuthContext';

import API from '../../network';
import { BASEPATH } from '../../lib/constants';

class VerifyPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sample: ''
    };
  }
  async componentDidMount() {
    await this.validate();
  }

  async validate() {
    const { search } = this.props.router.location;

    const params = new URLSearchParams(search);
    const sessionId = params.get('session_id');

    console.log('sessionId', sessionId);
    const response = await API.getInstance().validatePayment(sessionId);

    console.log('response', response);
    if (response.paymentStatus === 'success') {
      localStorage.setItem('payment', 'success');
      console.log('redirecting to resume-builder page after payment success');
      window.location.replace(BASEPATH + '/resume-builder');
    } else {
      console.log('redirecting to resume-build-home page after payment failed...');
      localStorage.setItem('payment', 'failed');
      window.location.replace(BASEPATH + '/resume-build-home');
    }
  }

  render() {
    return null;
  }
}

export default VerifyPayment;
