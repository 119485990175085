/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import ScrollAnimation from 'react-animate-on-scroll';
import Loader from 'react-loader-spinner';
import FullPageView from '../../component/FullPageView';
import ResumeMatchReport from '../../component/ReportViewer';
import styles from './styles.module.scss';
import data from './data';
import FeedBack from '../../component/NewFeedBack';
import Footer from '../../component/Footer/Footer';
import Button from '../../component/Button';
import CustomErrorPopup from '../../component/CustomErrorPopup';
import { LoginModal } from '../../component/LoginModal';
import { PromptToSignupModal } from '../../component/PromptToSignupModal';
import { motion } from "framer-motion";
import MultiCircularProgressBar from '../../component/MultiCircularProgressBar';
import { BASEPATH } from '../../lib/constants';
import newResumeIcon from '../../assets/score-report/newResume.png'
import oldResumeIcon from '../../assets/score-report/oldResume.png'
import { useAuth } from '../../context/AuthContext';
import metaData from '../MetaData/data';
import { useNavigate } from 'react-router-dom';
// import { render } from 'node-sass';
import { Navbar } from '../../component/Navbar';
import Utility from '../../utils';

const TickMark = () => {
  return <div style={{ marginRight: '5px' }}>
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M12 21C16.9706 21 21 16.9706 21 12C21 10.1666 20.4518 8.46124 19.5103 7.03891L12.355 14.9893C11.6624 15.7589 10.4968 15.8726 9.66844 15.2513L6.4 12.8C5.95817 12.4686 5.86863 11.8418 6.2 11.4C6.53137 10.9582 7.15817 10.8686 7.6 11.2L10.8684 13.6513L18.214 5.48955C16.5986 3.94717 14.4099 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z" fill="#786ADE" />
    </svg>
  </div>
}

class Report extends React.Component {
  state = {
    isOpen: false,
    isRated: false,
    openLoginModal: true,
    signupModalOpen: false,
    isLoggedIn: localStorage.getItem('token') ? true : false,
  };

  componentDidMount() {
    const { location } = this.props;
    const { isSample } = this.props?.router?.location?.state || {};
    const { signupModalOpen } = this.state;
    if (!signupModalOpen) {
      // setTimeout(() => {
      //   this.setState({ signupModalOpen: true });
      // }, 7000)
    }


    // FIXME: Add back later, comment feedback for now
    // if (!isSample) {
    //   setTimeout(() => {
    //     const { isOpen } = this.state;
    //     if (!isOpen) {
    //       this.onToggle();
    //     }
    //   }, 20000);
    // }
    Utility.trackEvent('score-report', 'PAGE_VISIT');
  }

  // componentDidUpdate(prevProps) {
  //   const {
  //     location,
  //     reduxProps: {
  //       cache: { reportData }
  //     }
  //   } = this.props;
  //   const { data: cardData } = location;
  //   const report = cardData || reportData;

  //   if (prevProps.report !== report && report) {
  // setTimeout(() => {
  //   const { openLoginModal } = this.state;
  //   if (!openLoginModal) {
  //     this.setState({ openLoginModal: false });
  //   }
  // }, 4000);
  //   }
  // }

  setPopup = popupType => {
    this.setState({ popupType });
  };

  // signUpToNavigate = () => {
  //   const { isLoggedIn } = this.state;
  //   if (isLoggedIn) {
  //     window.location.href = BASEPATH + '/resume-build-home';
  //   } else {
  //     window.location.href = BASEPATH + '/signup';
  //   }
  //   // this.props.history.push('/path/to/redirect');

  // }

  // toggleSignUpTo = (isOpen) => {
  //   const { signupModalOpen } = this.state;

  //   let containerDiv = document.getElementById('signUpToContainer');
  //   let signUpTo = document.getElementById('signUpTo');
  //   if (signupModalOpen && signUpTo && containerDiv) {
  //     signUpTo.style.transform = `translate(${(containerDiv.clientWidth - signUpTo.clientWidth) / 2}px, ${(containerDiv.clientHeight - signUpTo.clientHeight) / 2}px)`;
  //     signUpTo.style.overflow = 'hidden';
  //     containerDiv.style.background = 'transparent';
  //     setTimeout(() => {
  //       signUpTo.style.width = '300px';
  //       signUpTo.style.height = '100px';
  //       signUpTo.style.background = '#48abe0';
  //       setTimeout(() => {
  //         signUpTo.style.transform = `translate(${(containerDiv.clientWidth - signUpTo.clientWidth) / 2}px, ${(containerDiv.clientHeight - signUpTo.clientHeight) / 2}px)`;
  //         this.setState({ signupModalOpen: !signupModalOpen });
  //       }, 100)
  //     }, 200)
  //   } else {
  //     this.setState({ signupModalOpen: !signupModalOpen });
  //   }

  // }

  onToggle = () => {
    const { isOpen } = this.state;
    const FeedBackContainerDiv = document.getElementById('FeedBackContainer');
    const FeedParentDiv = document.getElementById('FeedParentDiv');
    if (isOpen) {
      if (FeedParentDiv && FeedBackContainerDiv) {
        FeedParentDiv.style.transform = `translateX(${FeedBackContainerDiv.clientWidth -
          10}px)`;
      }
    } else {
      this.setState({ isRated: false });
      if (FeedParentDiv) {
        FeedParentDiv.style.transform = `translateX(0)`;
      }
    }
    this.setState(prevState => ({ isOpen: !prevState.isOpen }));
  };

  renderPopup = () => {
    const { popupType } = this.state;
    if (popupType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    switch (popupType) {
      case 'error': {
        return (
          <div className={styles.popup1Style}>
            <CustomErrorPopup
              heading="Oops!"
              title="Something went wrong from our side, Please try again in a little while..."
              onOk={() => this.setState({ popupType: undefined })}
            />
          </div>
        );
      }
      case 'loading': {
        return (
          <div className={styles.popup1Style}>
            <div className={styles.loaderWrap}>
              <Loader
                height={
                  window.innerWidth || window.innerHeight <= 500 ? '30' : '50'
                }
                radius="5"
                type="Oval"
                color="#457BEF"
              />
              <div>
                <div className={styles.loaderTitle}>Deleting Skills...</div>
                <div className={styles.loaderMessage}>
                  Please wait for while
                </div>
              </div>
            </div>
          </div>
        );
      }
      default:
        return null;
    }
  };

  onRatingsClicked = () => {
    this.setState({ isRated: true });
  };

  isBottom = el => {
    return el.getBoundingClientRect().bottom.toFixed(0) <= window.innerHeight;
  };

  render() {
    const { isOpen, isRated, openLoginModal, signupModalOpen } = this.state;
    const {
      location,
      reduxProps: {
        cache: { reportData }
      },
      history,
      router
    } = this.props;
    console.log('this.props.reporttt', this.props);

    const { data: cardData, isSample, aiResumeData } = router?.location?.state || {};

    const report = cardData || reportData;
    if (!report) {
      console.log('report data not available redirecting resume builder home page');
      window.location.href = BASEPATH + '/resume-build-home'
    }
    const modalStyle = isOpen ? styles.popupstyles : styles.popupClose;
    console.log('report data....', report['Skill Match']['Jd Relevance score']);
    const { isLoggedIn } = this.state;
    return (
      <div className={styles.reportContainer}>
        <Helmet>
          <title>{metaData.scoreReport.title}</title>
          <meta name='title' content={metaData.scoreReport.metaTitle} />
          <meta name='description' content={metaData.scoreReport.metaDescription} />
        </Helmet>
        <div className={modalStyle} />
        {!isSample ? (
          <FeedBack
            isOpen={isOpen}
            isRated={isRated}
            onToggle={this.onToggle}
            onSelectSmileys={this.onRatingsClicked}
          />
        ) : null}
        <Navbar />
        <Slider report={report} isLoggedIn={isLoggedIn} />
        {/* {!isSample && !signupModalOpen ? <div className={styles.signUpToBtn} onClick={() => this.toggleSignUpTo(signupModalOpen)}>Do you want to build a new resume using AI?</div> : null} */}
        {/* {!isSample && signupModalOpen ? <PromptToSignupModal onClick={this.toggleSignUpTo} /> : null} */}
        {this.renderPopup()}
        {report ? (
          <>
            <ScrollAnimation
              animateIn="fadeInUp"
              animateOnce
              className={styles.resumeReportContainer}
              offset={0}
            >
              <FullPageView
                id="reportRoot"
                style={styles.MatchReportWrap}
                history={history}
              >
                <ResumeMatchReport
                  {...this.props}
                  reportData={report}
                  setPopup={this.setPopup}
                  aiResumeData={aiResumeData}
                />

              </FullPageView>

            </ScrollAnimation>
            <Footer />
          </>
        ) : (<>
          <FullPageView history={history}>
            <div className={styles.redirectContainer}>
              <div className={styles.content}>
                Click here to upload your resume
              </div>
              <Button
                type="button"
                text={data.measureAgain}
                className={styles.uploadButton}
                onClick={() => {
                  router.navigate('/');
                }}
              />
            </div>

          </FullPageView>
          <Footer />
        </>
        )}
      </div>
    );
  }
}



const Slider = ({ report, isLoggedIn }) => {
  const { authState, loading } = useAuth();

  const [minSlider, setMinSlider] = useState(false);

  const isPaid = authState?.paymentSessionId;
  console.log('authState:', authState);
  const navigate = useNavigate();


  const signUpToNavigate = () => {
    try {
      if (authState) {
        Utility.trackEvent('score-report', 'BUTTON_CLICK', 'Build AI Resume');
      } else {
        Utility.trackEvent('score-report', 'BUTTON_CLICK', 'Sign Up(from slider)');
      }
    } catch (e) {
      console.error('error in tracking in event', e);
    }
    if (authState) {
      navigate('/resume-build-home');
      // window.location.href = BASEPATH + '/resume-build-home';
    } else {
      navigate('/signup');
      // window.location.href = BASEPATH + '/signup';
    }
    // this.props.history.push('/path/to/redirect');

  }

  const toggle = () => {
    console.log(minSlider);
    setMinSlider(!minSlider)
  }



  return <>
    <div className={[styles.sliderContainer, minSlider ? styles.animateFadeInLeft : styles.animateFadeInRight].join(' ')} >
      <div className={styles.arrowIcon} onClick={toggle}>
        <svg style={{
        }} width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <circle cx="15" cy="15" r="11.25" fill="#7E869E" fill-opacity="0.25" />
          <path d="M12.25 9.5L17.75 15L12.25 20.5" stroke="#33363F" stroke-width="2" />
        </svg>
      </div>

      <div className={styles.heading}>Boost your resume relevance score</div>
      <div className={styles.imagesContainer}>
        <div className={styles.newResume}>
          <MultiCircularProgressBar barStyle={{ width: '20%', position: 'absolute' }} percentScore={report['Skill Match']['Jd Relevance score']} />
          <img src={oldResumeIcon} />

        </div>
        <div className={styles.oldResume}>
          <MultiCircularProgressBar barStyle={{ width: '20%', position: 'absolute' }} percentScore={report['Skill Match']['Jd Relevance score'] > 80 ? 95 : report['Skill Match']['Jd Relevance score'] + 15} />
          <img src={newResumeIcon} />
        </div>

      </div>
      <div className={styles.bottomDiv}>
        <div className={styles.content1}>
          Use our AI resume builder to build a resume as per the job description of the position you are looking for! We craft a tailor-made resume that highlights your most relevant skills and experiences, maximizing your chances of landing the interview.
        </div>
        <div className={styles.points}>
          <div className={styles.point}> <TickMark /> <span>AI-powered analysis</span></div>
          <div className={styles.point}> <TickMark /> Job description specific resume</div>
          <div className={styles.point}><TickMark /> ATS & Recruiter Friendly Resume</div>
          <div className={styles.point}><TickMark />  Build your resume in {'<'} 5mins</div>
        </div>
        <div className={styles.button}>
          <Button id={authState ? "build-ai-resume" : "sign-up"} text={authState ? "Build AI Resume" : "Sign up"} onClick={signUpToNavigate} />

        </div>
      </div>
    </div>




  </>
}

const mapStateToProps = reduxProps => ({
  reduxProps
});

export default connect(mapStateToProps)(Report);
