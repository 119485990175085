export default [{
    question: 'How does the AI resume builder work?',
    answer: 'Pañña Resume Builder leverages the power of OpenAI\'s GPT-4 Turbo model to meticulously analyze your provided resume and job description.It then refines and augments your resume, ensuring it is optimized for Applicant Tracking Systems(ATS) and thus helps in improving your chances of landing an interview.'
},
{
    question: 'Do I need to create an account to use the resume builder?',
    answer: 'Yes, you need to sign up with your full name and email address. An account activation link will be sent to your email, which will direct you to the resume builder page upon activation.'
},
{
    question: 'Is there a trial version available?',
    answer: 'While we do not offer a trial version, you can use our free resume score feature to get an idea of how our AI evaluates resumes.'
},
{
    question: 'Can I build more than one resume?',
    answer: 'Each payment allows for the creation of one tailored resume. If you need to create additional resumes for different job applications, a new payment will be required for each.'
},
{
    question: 'What do I need to start building my resume?',
    answer: 'You\'ll need your current resume and the job description for the position you are applying for. You can upload the resume and copy-paste the job description text directly into our resume builder.'
},
{
    question: 'Can I edit or download my resume after it’s generated?',
    answer: 'Absolutely! Our resume editor allows you to make updates and edits to any section of your resume before downloading.'
},
{
    question: 'What is the cover letter feature and how much does it cost?',
    answer: 'Our cover letter feature allows you to create a customized cover letter that complements your resume and matches the job description you are applying for. Best of all, this feature is completely free to use.'
},

]