/** eslint-disable no-undef */
/* eslint-disable */
import React from 'react';
import {
  useLocation,
  useNavigate,
  useParams,
  Params,
  Location,
  NavigateFunction
} from 'react-router-dom';

function withRouter(Component) {
  function ComponentWithRouterProp(props) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return (
      <Component key={1} {...props} router={{ location, navigate, params }} />
    );
  }
  return ComponentWithRouterProp;
}

export default withRouter;
