/* eslint-disable */
export default {
  pageTitle: 'Panna score',
  shortContent: "Use our FREE AI-powered platform to tailor your resume for a specific job role and make yourself stand out from the competition!",
  invalidResumeFormat: 'Invalid file format',
  resumeSizeError: 'size more than 5mb',
  saveJdPopupTitle: 'Save Job Description',
  saveJdInputLabel: 'Please enter Job Description name',
  trySampleResponse: {
    "Action Verbs": {
      "Achievement": {
        "Found": [
          "Secured",
          "Completed"
        ],
        "Recommended Words": [
          "Awarded",
          "Earned",
          "Surpassed",
          "Outperformed",
          "Showcased",
          "Exceeded",
          "Reached",
          "Attained"
        ]
      },
      "Analytical": {
        "Found": [],
        "Recommended Words": [
          "Investigated",
          "Assessed",
          "Compared",
          "Concluded",
          "Examined",
          "Analysed",
          "Analyzed",
          "Estimated",
          "Evaluated",
          "Forecasted"
        ]
      },
      "Communication": {
        "Found": [],
        "Recommended Words": [
          "Drafted",
          "Addressed",
          "Convinced",
          "Explained",
          "Presented",
          "Communicated",
          "Persuaded",
          "Promoted",
          "Wrote",
          "Informed"
        ]
      },
      "Creativity": {
        "Found": [],
        "Recommended Words": [
          "Authored",
          "Brainstormed",
          "Shaped",
          "Illustrated",
          "Constructed",
          "Fabricated",
          "Engineered",
          "Visualized",
          "Envisioned",
          "Designed"
        ]
      },
      "Flexibility": {
        "Found": [
          "Converted"
        ],
        "Recommended Words": [
          "Accommodated",
          "Adapted",
          "Amended",
          "Tailored",
          "Adjusted",
          "Balanced",
          "Incorporated",
          "Grew",
          "Altered"
        ]
      },
      "Initiative": {
        "Found": [
          "Created",
          "Established"
        ],
        "Recommended Words": [
          "Accelerated",
          "Implemented",
          "Expanded",
          "Initiated",
          "Volunteered",
          "Demonstrated",
          "Improved",
          "Boosted"
        ]
      },
      "Leadership": {
        "Found": [
          "Managed"
        ],
        "Recommended Words": [
          "Monitored",
          "Handled",
          "Instructed",
          "Coordinated",
          "Approved",
          "Guided",
          "Controlled",
          "Owned",
          "Motivated"
        ]
      },
      "Organization": {
        "Found": [
          "Maintained",
          "Classified",
          "Assembled"
        ],
        "Recommended Words": [
          "Compiled",
          "Consolidated",
          "Planned",
          "Regulated",
          "Arranged",
          "Centralized",
          "Organized"
        ]
      },
      "Problem Solving": {
        "Found": [],
        "Recommended Words": [
          "Quantified",
          "Customized",
          "Improvised",
          "Strengthened",
          "Automated",
          "Streamlined",
          "Explored",
          "Debugged",
          "Solved",
          "Refined"
        ]
      },
      "Teamwork": {
        "Found": [],
        "Recommended Words": [
          "Collaborated",
          "Helped",
          "Networked",
          "Assisted",
          "Cooperated",
          "Mediated",
          "Participated",
          "Consulted",
          "Partnered",
          "Contributed"
        ]
      },
      "The remaining list of action verbs": [
        "required",
        "added",
        "confirmed",
        "outlined",
        "migrated",
        "racked",
        "included"
      ]
    },
    "Comments": {
      "action_verbs_comment": "Starting your resume bullet points with action verbs creates a stronger and more appealing tone to hiring managers. We suggest including action verbs throughout your resume to demonstrate initiative while keeping your descriptions short and compelling.",
      "estimated_time_to_read_comment": "Needs work! It takes 6 min to read your resume which is much longer than the recommended time range. The time it takes to read your resume is calculated from the number of words present in your resume. On average, it is recommended to have between 500 to 700 words, which takes approximately 2 to 2.5 mins to read. However, if you have over 10 years of experience or are applying for a federal position a higher word count is acceptable.",
      "experience_comment": "We find that you have total work experience of 15 years 9 months without any gaps in between",
      "last_modified_comment": "Excellent ! It looks like your resume has been updated recently. Recruiters and the Application Tracking System (ATS) shortlist resumes that are recently modified so it is important to keep your resume current with the latest skill sets and industry knowledge.",
      "relevance_score_comment": "Excellent! Your score is 94% which falls into the ideal range. This means that your resume is well-targeted and on track. You can improve your score by including more keywords and skills that have been recommended in the skill match report table.",
      "resume_complexity_comment": "Excellent! Your resume is easy to read and fits the recommended readability index. The index is calculated from the average length of your sentences and the number of syllables in each word.",
      "resume_percentile_comment": "Excellent! Your Resume Percentile is 90% which means that you are amongst the top 10% of market applicants for this job. ",
      "skill_match_comment": "Excellent! Looks like your resume has most of the skills that are required for the job. You are on track and doing a great job.",
      "summary_comment": "Excellent! Your resume includes a summary/objective section which is recommended as it helps employers quickly determine if you have the skills and background they require."
    },
    "Estimated Time to Read": "6 min",
    "Experience_Consistency": {
      "Candidate_consistency": "True",
      "Candidate_experience": "15 years 9 months"
    },
    "JD Type Class": "T",
    "Jd Skill frequency": {
      "Acl": 1,
      "Active Directory": 5,
      "Altiris": 1,
      "Bmc Remedy": 1,
      "C": 1,
      "Compliance": 1,
      "Computer Software": 1,
      "Data Center": 2,
      "Design": 1,
      "Dfs": 1,
      "Dhcp": 2,
      "Dns": 4,
      "Domain Controllers": 3,
      "End User Computing": 3,
      "Go": 1,
      "Gpo": 4,
      "Imaging": 1,
      "Infrastructure": 4,
      "Integration": 1,
      "Ip": 1,
      "Itsm": 1,
      "Mdt": 1,
      "Mvp": 1,
      "Netbackup": 1,
      "Network Design": 1,
      "Network Monitoring": 1,
      "Powershell": 1,
      "Professional Development": 1,
      "Provision": 1,
      "Prtg": 1,
      "R": 8,
      "San": 2,
      "Security": 5,
      "Solarwinds": 1,
      "Sql": 1,
      "Switches": 2,
      "Tcp": 1,
      "Tcp/Ip": 3,
      "Technical Documentation": 1,
      "Training": 3,
      "Vlan": 1,
      "Vms": 2,
      "Vmware Esxi": 3,
      "Windows": 13
    },
    "Last Modified": "Recently Modified",
    "Percentile score": 90,
    "Readability": "Easy read",
    "Resume Skill frequency": {
      "Acl": 2,
      "Active Directory": 13,
      "Altiris": 1,
      "Bmc Remedy": 1,
      "C": 1,
      "Compliance": 1,
      "Computer Software": 1,
      "Data Center": 3,
      "Design": 1,
      "Dfs": 3,
      "Dhcp": 6,
      "Dns": 9,
      "Domain Controllers": 6,
      "End User Computing": 3,
      "Go": 1,
      "Gpo": 4,
      "Imaging": 1,
      "Infrastructure": 6,
      "Integration": 1,
      "Ip": 2,
      "Itsm": 1,
      "Mdt": 2,
      "Mvp": 0,
      "Netbackup": 3,
      "Network Design": 1,
      "Network Monitoring": 1,
      "Powershell": 2,
      "Professional Development": 1,
      "Provision": 2,
      "Prtg": 2,
      "R": 21,
      "San": 0,
      "Security": 16,
      "Solarwinds": 2,
      "Sql": 3,
      "Switches": 4,
      "Tcp": 2,
      "Tcp/Ip": 6,
      "Technical Documentation": 1,
      "Training": 4,
      "Vlan": 1,
      "Vms": 5,
      "Vmware Esxi": 5,
      "Windows": 38
    },
    "Skill Match": {
      "Jd Relevance score": 94,
      "Skill match score": "42/44",
      "matched": [
        "Tcp/Ip",
        "Technical Documentation",
        "Security",
        "Netbackup",
        "Design",
        "Acl",
        "Powershell",
        "Mdt",
        "Solarwinds",
        "Vmware Esxi",
        "Dfs",
        "Bmc Remedy",
        "End User Computing",
        "Go",
        "Domain Controllers",
        "Infrastructure",
        "Altiris",
        "Switches",
        "Dns",
        "Data Center",
        "Compliance",
        "Itsm",
        "Vms",
        "Prtg",
        "Network Design",
        "Vlan",
        "C",
        "Provision",
        "Training",
        "Tcp",
        "Active Directory",
        "Integration",
        "Dhcp",
        "Windows",
        "Imaging",
        "Network Monitoring",
        "Ip",
        "R",
        "Sql",
        "Gpo",
        "Computer Software",
        "Professional Development"
      ],
      "unmatched": [
        "Mvp",
        "San"
      ]
    },
    "Summary/Objective": "Yes",
    "Uid": "5f7eac82e0e7bbc170ffb13e",
    "status_code": 1
  }
}