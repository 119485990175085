import hero from '../../../../assets/insights/craftingResume/hero.png';

export const meta = {
  author: "",
  date: "2024-04-08",
  type: "Insights",
  title: "Crafting the Perfect Resume",
  subTitle: " Tailoring for Every Job Application",
  description: "",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/crafting-the-perfect-resume",
};