import React from 'react';
import { LoginModal } from '../../component/LoginModal';
import { Navbar } from '../../component/Navbar';
import CheckYourEmail from '../../component/CheckYourMail'
import Helmet from 'react-helmet';
import Utility from '../../utils';

class LoginPage extends React.Component {
  state = {
    isLoginOpen: true,
  };

  onLinkClick = () => {
    const { isLoginOpen } = this.state;
    this.setState({ isLoginOpen: !isLoginOpen });
  }
  componentDidMount() {
    Utility.trackEvent('login', 'PAGE_VISIT');
  }

  render() {
    const { isLoginOpen } = this.state;
    return (
      <>
        <Helmet>
          <title>paññã Login</title>
          {/* <meta name={data.metaName} content={data.metaContent} />
          <meta name="theme-color" content="#008f68" /> */}
        </Helmet>
        <Navbar />
        <LoginModal open={isLoginOpen} onSuccess={this.onLinkClick} />
        {!isLoginOpen ? <CheckYourEmail onLinkClick={this.onLinkClick} /> : null}
      </>
    );
  }
}

export default LoginPage;
