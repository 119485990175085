import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import Resume1 from '../../../../../assets/insights/resumeGap/resume1.png';
import Resume2 from '../../../../../assets/insights/resumeGap/resume2.png';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's fast-paced, competitive job market, it is not uncommon for professionals to have 
        gaps in their resumes. Whether it's due to personal reasons, further education, or job search 
        challenges, a career break can often raise questions and concerns among potential employers.
      </div>

      <div className={styles.text}>
        However, addressing these gaps with authenticity and confidence can help you stand out and 
        showcase your value as a professional. With a little strategic reframing, a resume gap can 
        become a compelling narrative of resilience, adaptability, and renewed purpose.
      </div>

      <div className={styles.title}>
        Why Career Gaps Are No Longer Red Flags
      </div>

      <div className={styles.text}>
        Once viewed with skepticism, career breaks are now increasingly recognized as valuable tools 
        for personal and professional development. Employers are beginning to understand that a 
        well-rounded individual is often one who has taken time to explore different paths, pursue 
        personal passions, or prioritize well-being.
      </div>

      <div className={styles.text}>
        A career break can be a golden opportunity to acquire new skills or deepen existing ones. 
        Whether it's mastering a new programming language, delving into project management methodologies, 
        or honing your communication prowess, the knowledge and experience gained during this period can 
        be invaluable. This newfound expertise can open doors to freelance work or even pave the way for 
        starting a new career, turning your sabbatical into a launchpad for new career.
      </div>

      <div className={styles.text}>
        There are many valid reasons for an employment gap:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Family Matters: </strong>
            Family is a top priority for many, and recruiters understand that. A 2022 survey by FlexJobs 
            found that 84% of working parents have considered leaving their jobs for more family time. 
            If your break was for family reasons, be transparent. Emphasize that the situation is resolved 
            and you're ready to focus on your career. (e.g., "After a fulfilling period of childcare, my 
            children are now in school, and I'm eager to re-engage with my professional goals.")
          </li>

          <li className={styles.listItem}>
            <strong>Personal Exploration and Growth: </strong>
            Taking time for self-discovery is a sign of self-awareness. In fact, a study by the 
            American Gap Association found that 82% of people who take career breaks report increased 
            self-confidence and clarity about their career goals. Explain how this period clarified 
            your career path and goals, demonstrating your commitment to your chosen field. (e.g., 
            "My career break allowed me to reflect on my passions and refine my career objectives, 
            leading me to this exciting opportunity in [your field].")
          </li>

          <li className={styles.listItem}>
            <strong>Health and Recovery: </strong>
            Whether it was a physical (illness, injury etc.) or mental health challenge, be upfront 
            about your reasons for taking time off. The World Health Organization recognizes burnout 
            as an occupational phenomenon, and a 2021 Indeed survey found that 52% of respondents 
            experienced burnout in the past year. Assure potential employers that you're fully 
            recovered and ready to contribute. (e.g., "I took a temporary leave to address a health 
            issue, and I'm happy to report that I'm fully recovered and energized to return to work.")
          </li>

          <li className={styles.listItem}>
            <strong>Entrepreneurial Pursuits: </strong>
            Even if your venture didn't succeed as planned, highlight the valuable skills and lessons 
            you gained. According to the Small Business Administration, about 20% of small businesses 
            fail in their first year, but the experience gained is invaluable. This demonstrates initiative, 
            resilience, and a willingness to take calculated risks. (e.g., "My entrepreneurial experience 
            taught me invaluable lessons in [specific skills], which I'm eager to apply in a collaborative 
            team environment.")
          </li>

          <li className={styles.listItem}>
            <strong>Burnout Prevention and Well-being: </strong>
            Burnout is a recognized occupational hazard. A Gallup study found that 76% of employees 
            experience burnout on the job at least sometimes. Explain how your break allowed you to 
            recharge and prioritize your well-being, making you a more focused and productive employee. 
            (e.g., "I proactively took time off to address burnout and prioritize my well-being. I'm 
            returning with renewed energy and a commitment to maintaining a healthy work-life balance.")
          </li>

          <li className={styles.listItem}>
            <strong>Educational Pursuit: </strong>
            Investing in your education is always a plus. The World Economic Forum's Future of Jobs 
            Report 2020 indicates that 50% of all employees will need reskilling by 2025. Highlight 
            the new skills or knowledge you acquired and how they align with the position you're 
            seeking. (e.g., "During my career break, I completed a [degree/course] in [field], 
            enhancing my expertise in [specific skills] and positioning me for success in this role.")
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Crafting Your Career Break Story
      </div>

      <div className={styles.text}>
        The key to addressing a career break on your resume is to focus on the skills and experiences 
        you gained during that time. A functional resume format, which prioritizes skills over 
        chronological work history, can be particularly effective.
      </div>

      <div className={styles.text}>
        For example, if you took time off during maternity and to raise a kid, you might highlight 
        parental skills like time management, problem-solving and multi-tasking. Taking up a 
        freelancing job in the line of related work could be a great way to bridge the career gap.
      </div>

      <div>
        <img
          src={Resume1}
          alt='Sample Resume Image'
          className={styles.sampleResumeImage}
        />
      </div>

      <div className={styles.title}>
        Strategies for Addressing Employment Gaps on Your Resume
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Strategic Omission for Outdated Gaps</strong><br />
            If your employment gap occurred many years ago and falls outside the typical 8-15-year 
            timeframe, you can often omit it. Focus on keeping your resume concise and relevant to 
            your current career goals.
          </li>

          <li className={styles.listItem}>
            <strong>Explain the Gap with Clarity</strong><br />
            Simply listing dates won't suffice. Provide a brief, professional explanation for the gap 
            but don't overdo it with unnecessary details.<br />

            <div className={styles.exampleContainer}>
              <strong>Example:</strong>

              <div>
                <span className={styles.bgYellow}>2017 - 2019</span>
              </div>

              <div className={styles.bgYellow}>
                Career Break to pursue a master's degree in business administration (MBA) at 
                [University Name]. Graduated with honors and gained expertise in [specific skills].
              </div>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Explain the Gap with Clarity</strong><br />
            Whether you traveled, cared for a family member, or focused on personal development, frame 
            your career break as a period of growth and learning. Highlight the transferable skills you 
            acquired, such as project management, communication, or problem-solving.
          </li>

          <li className={styles.listItem}>
            <strong>Leverage Alternative Resume Formats </strong><br />
            If your gap is substantial, consider a functional or combination resume format. These 
            formats emphasize skills and accomplishments over a strict chronological timeline, 
            allowing you to showcase your qualifications while minimizing the visual impact of the gap.
          </li>

          <li className={styles.listItem}>
            <strong>
              Utilize Your Time Off Productively
            </strong><br />
            If you're currently experiencing an employment gap, be proactive. Engage in professional 
            development activities like online courses, freelance projects, or volunteer work. This 
            demonstrates continuous growth and a commitment to your field.
          </li>

          <li className={styles.listItem}>
            <strong>
              Honesty is the Best Policy
            </strong>
            <br />
            Your resume reflects your professional journey, and honesty is paramount. Omitting a 
            significant employment gap can raise red flags during interviews. Be upfront about your 
            employment history, as potential employers are likely to discover any discrepancies. 
            Remember, recruiters are human too, and a straightforward explanation can go a long way.
            <br />
            <br />
            By addressing your employment gap strategically and honestly, you can transform it from a 
            potential liability into an asset that showcases your resilience, adaptability, and 
            commitment to your career.
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Elevating Your Cover Letter
      </div>

      <div className={styles.text}>
        Your cover letter is your chance to tell your career break story in more detail. Explain the 
        reasons for your break, highlight the skills and experiences you gained, and express your 
        enthusiasm for returning to work.
      </div>

      <div className={styles.text}>
        <strong>Example Cover Letter:</strong>
      </div>

      <div>
        <img
          src={Resume2}
          alt='Sample Resume Image'
          className={styles.sampleResumeImageNormal}
        />
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        FAQs About Resume Gaps
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Should I include a career break on my resume?</strong><br />
            Yes, transparency is key. Omitting a gap can raise more questions than it answers. Briefly 
            explain the reason for the break, focusing on skills or experiences gained during that time.
          </li>

          <li className={styles.listItem}>
            <strong>How do I explain a career break in a job interview?</strong><br />
            Frame your answer positively. Highlight transferable skills developed during your break, 
            such as project management, communication, or problem-solving. Emphasise your enthusiasm 
            for returning to work and contributing to the company.<br />
          </li>

          <li className={styles.listItem}>
            <strong>Will a career break hurt my chances of getting a job?</strong><br />
            Not necessarily. Many employers value diverse experiences and understand that career breaks 
            can be valuable for personal and professional growth. Focus on showcasing your skills and 
            qualifications.
          </li>

          <li className={styles.listItem}>
            <strong>What if my career break wasn't for traditional reasons (e.g., travel, family)?</strong><br />
            Be honest and concise. Whether you were dealing with a personal challenge, exploring a 
            passion project, or simply taking time for self-discovery, frame your experience positively 
            and highlight any transferable skills you gained.
          </li>

          <li className={styles.listItem}>
            <strong>
              How can I make my resume stand out with a career break?
            </strong><br />
            Tailor your resume and cover letter to each job application, emphasizing relevant skills 
            and experiences. Consider using a functional resume format to highlight your skills over 
            a strict chronological timeline.
          </li>

          <li className={styles.listItem}>
            <strong>
              What's the best way to address a career break on my resume?
            </strong><br />
            Briefly explain the reason for the break in a professional manner. For example, "Career 
            Break to pursue a Master's Degree" or "Sabbatical for Personal Development and Travel." 
            Focus on any skills or experiences gained during this time.
          </li>

          <li className={styles.listItem}>
            <strong>
              How long is too long of an employment gap?
            </strong>
            <br />
            There's no magic number, but gaps longer than a year may require more explanation. Focus 
            on demonstrating your continued engagement with your field through professional development, 
            volunteer work, or freelance projects.
          </li>

          <li className={styles.listItem}>
            <strong>
              Is it ever okay to omit a career break from my resume?
            </strong>
            <br />
            If the gap is from many years ago (e.g., 10+ years) and doesn't significantly impact your 
            current qualifications, you may choose to omit it. However, be prepared to address it if 
            asked during an interview.
          </li>
        </ol>
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
