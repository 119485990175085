import React from 'react';
import { Navbar } from '../../../component/Navbar';
import Footer1 from '../../../component/Footer1';
import ResumeTips from '../../../component/Insights/Insight/top-resume-writing-tips/index';
import { Helmet } from 'react-helmet';
class ResumeTipsInsight extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>paññã Insights</title>
          <meta name="description" content="Explore expert resume writing tips to enhance your job application success. Learn how to craft a standout resume that catches the eye of employers and increases your chances of landing your dream job." />
          <meta name="title" content={'Top Resume Writing Tips for Landing Your Dream Job'} />
        </Helmet>
        <Navbar />
        <ResumeTips />
        <Footer1 />
      </>
    );
  }
}

export default ResumeTipsInsight;
