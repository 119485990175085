import React from 'react';
// import ReactDOM from 'react';
import Slider from 'react-slick';
// import './styles.css';
import styles from './styles.module.css';
import smartSkillsImg from '../../assets/LandingPage/SmartSkills.png';
import resumeAnalysisImg from '../../assets/LandingPage/ResumeAnalysis.png';
import similarityScoreImg from '../../assets/LandingPage/SimilarityScore.png';
import skillDot from '../../assets/LandingPage/SkillsDot.png';
import resumeAnalysisDot from '../../assets/LandingPage/AnalysisDot.png';
import similarityDot from '../../assets/LandingPage/SimilarityDot.png';
import ScrollAnimation from 'react-animate-on-scroll';
import staticData from './data'

const data = [
  {
    img: resumeAnalysisImg,
    style: styles.resumeAnalysisImgStyle
  },
  {
    img: smartSkillsImg,
    style: styles.smartSkillsStyle
  },
  {
    img: similarityScoreImg,
    style: styles.similarityScoreImgStyle
  }
];

const logoImage = [
  {
    title: 'Resume Analysis',
    img: resumeAnalysisDot,
    style: styles.resumeAnalysisDotStyles
  },
  {
    title: 'Smart Skill Scoring',
    img: skillDot,
    style: styles.skillDotStyles
  },
  {
    title: 'Resume Relevance Score',
    img: similarityDot,
    style: styles.similarityDotStyles,
  }
];

export default class Expertise extends React.Component {
  slider;
  secondSlider;
  constructor(props) {
    super(props);
    this.slider = React.createRef;
  }
  state = {
    logoIndex: 0,
    logoHover: true
  };

  componentDidMount() {
    const contentTag = document.querySelector('#content');
    contentTag.className = styles.contentParentAfter;
  }

  componentWillUnmount() {
    clearInterval(this.timeout);
  }

  render() {
    const { logoIndex } = this.state;
    let attachedStyles = [styles.sliderHref];
    const settings = {
      appendDots: dots => (
        <div
          style={{
            position: 'relative',
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <ul className={styles.sliderUl}> {dots} </ul>
        </div>
      ),
      customPaging: function (i) {
        if (logoIndex === i) {
          attachedStyles = [styles.sliderHref, styles.slideActive]
        } else {
          attachedStyles = [styles.sliderHref]
        }
        return (
          <a
            className={attachedStyles.join(' ')}
          >
            <img src={logoImage[i].img} className={logoImage[i].style} alt="" />
            <div className={styles.dotSectionTitle}>{logoImage[i].title}</div>
          </a>
        );
      },
      dots: true,
      infinite: true,
      autoplay: true,
      focusOnSelect: true,
      pauseOnDotsHover: true,
      useTransform: true,
      pauseOnHover: true,
      speed: 1500,
      autoplaySpeed: 5000,
      slidesToShow: 1,
      slidesToScroll: 1,
    };
    return (
      <div className={styles.main}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
          }}
        >
          {/* <ScrollAnimation animateIn="slideInUp" animateOnce> */}
          <div id="content" className={this.contentTag}>
            <Slider
              ref={slider => (this.slider = slider)}
              {...settings}
              className={styles.sliderWrapper}
              easing='ease-in'
              arrows={false}
              initialSlide={0}
              beforeChange={(oldIndex, newIndex) => {
                if (oldIndex !== newIndex) {
                  this.secondSlider.slickGoTo(newIndex);
                  this.setState({
                    logoIndex: newIndex
                  })
                }
              }}
            >
              {data.map((content, index) => {
                return (
                  <ScrollAnimation key={`MainSlide${index}`} delay={10} animateIn="fadeIn" className={styles.imageWrap} offset={0}>
                    <img
                      src={content.img}
                      className={content.style}
                      alt=""
                    />
                  </ScrollAnimation>
                );
              })}
            </Slider>
            <Slider
              ref={slider => (this.secondSlider = slider)}
              className={styles.sliderWrapper}
              easing='ease-in'
              arrows={false}
              initialSlide={0}
              dots={false}
              speed={1500}
              draggable={false}
            >
              {staticData.description.map((content, index) => {
                return (
                  <ScrollAnimation key={`SlideText${index}`} offset={0} animateIn="fadeIn">
                    <div className={styles.corousalDescription}>{content}</div>
                  </ScrollAnimation>
                )
              })}
            </Slider>
          </div>
          {/* </ScrollAnimation> */}
        </div>
      </div>
    );
  }
}
