import React from 'react'
import ReactHtmlParser from 'react-html-parser'

class ResumeViewer extends React.Component {

    componentDidMount() {
        const pages = document.getElementsByClassName('t');
        Array.from(pages).forEach(element => {
            element.style.zIndex = '99';
        })
        this.normaliseResumeInHTMLNode(document.getElementById('ResumeViewerUnique'))
    }

    componentWillReceiveProps(nextProps) {
        const {id} = this.props;
        const {id: nextId} = nextProps;
        if(id !== nextId) {
            this.normaliseResumeInHTMLNode(document.getElementById('ResumeViewerUnique'))
        }
    }

    normaliseResumeInHTMLNode = node => {
        if (node === undefined || !node) {
            return;
        }
        if (node.hasChildNodes()) {
            node.normalize();
            for (var i = 0; i < node.childNodes.length; i++) {
                this.normaliseResumeInHTMLNode(node.childNodes[i]);
            }
        }
    }

    transform = (node, index) => {
        // const skipNodeWithClass = ['_ _0','_ _1','_ _2','_ _3','_ _4','_ _5','_ _6','_ _7','_ _8','_ _9','_ _10'];
        const skipNodeWithClass = ['_ _0','_ _1','_ _2','_ _3','_ _4','_ _6','_ _7'];
        if (node.type === 'tag' && node.name === 'span' && !node.data && skipNodeWithClass.includes(node.attribs && node.attribs.class)) {
            return null;
        } 
        if (node.name === 'html' || node.name === 'body' || node.name === 'head') {
            node.name = 'div';
        }
    }
    render() {
        const {resumeInHTML} = this.props;
        const options = {
            decodeEntities: true,
            transform:(node, index) => this.transform(node, index),
        }
        return(
            <React.Fragment>
                {ReactHtmlParser(resumeInHTML, options)}
            </React.Fragment>
        );
    }
}

export default ResumeViewer;