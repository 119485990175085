/* eslint-disable */

import { ActionTypes } from '../constant';

// TODO add flow typings where needed
export function cacheRecentJDs(payload: Array) {
  return {
    type: ActionTypes.CACHE_RECENT_JDS,
    payload
  };
}

export function cacheReportData(payload: Object) {
  return {
    type: ActionTypes.CACHE_REPORT_DATA,
    payload
  };
}

export function updateUserId(payload: String) {
  return {
    type: ActionTypes.UPDATE_USER_ID,
    payload
  };
}

export function updateUserName(payload: String) {
  return {
    type: ActionTypes.UPDATE_FULL_NAME,
    payload
  };
}

export function updateUserFile(payload: String) {
  return {
    type: ActionTypes.UPDATE_USER_FILE,
    payload
  };
}

export function updateResumeHtmlLink(payload: String) {
  return {
    type: ActionTypes.UPDATE_USER_HTML_RESUME,
    payload
  };
}

export function updateResumeUrl(payload: String) {
  return {
    type: ActionTypes.UPDATE_RESUME_URL,
    payload
  };
}



// export function updateFeedBack(payload: String) {
//   return {
//     type: ActionTypes.UPDATE_FEEDBACK,
//     payload,
//   };
// }

export function getResumeInHTML(payload) {
  return {
    type: ActionTypes.GET_RESUME_IN_HTML,
    payload
  };
}

export function updateParsedResumeData(payload) {
  return {
    type: ActionTypes.PARSED_RESUME_DATA,
    payload
  };

};

export function clearStore() {
  return {
    type: ActionTypes.CLEAR_STORE
  };
}

export function resetStoreWithNewData(payload: Object) {
  return {
    type: ActionTypes.RESET_WITH_NEW_DATA,
    payload
  };
}

export function updateUserHistoryId(payload: String) {
  return {
    type: ActionTypes.UPDATE_USER_HISTORY_ID,
    payload
  };
}


export function updateLocation(payload) {
  return {
    type: ActionTypes.UPDATE_LOCATION,
    payload
  };

};

