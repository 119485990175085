import React, { useState } from 'react';
import {
  Button
} from '@mui/material';
import styles from './styles.module.scss';
//import Config from '../../config';
import axios from 'axios';
import API from '../../network';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import GoogleIcon from '../../assets/social/google/GoogleIcon';
import LinkedInIcon from '../../assets/social/linkedin/LinkedinIcon';

export const LoginModal = ({ open, onClose, onSuccess }) => {
  console.log('LoginModal open', open);

  const [mode, setMode] = React.useState('login');

  const renderPage = () => {
    switch (mode) {
      case 'login':
        return <Login mode={mode} setMode={setMode} onSuccess={onSuccess} onClose={onClose} />;
      default:
        return <Login mode={mode} setMode={setMode} onSuccess={onSuccess} onClose={onClose} />;
    }
  };
  return <>{open && renderPage()}</>;
};

export const Login = ({ mode, onClose, setMode, onSuccess }) => {
  console.log('test deployement on 17-09-2024');
  const [input, setInput] = React.useState('');
  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [email, setEmail] = useState('');
  const [isValid, setIsValid] = useState(true);

  const validateEmail = (email) => {
    // Regular expression for validating email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    console.log(e.target.value);
    if (validateEmail(e.target.value)) {
      setIsValid(true);
      setError('');

    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      // Email is valid
      setIsValid(true);
      setError('');
      // Proceed with form submission or other action
    } else {
      // Email is invalid
      setIsValid(false);
      setError('Please enter valid email address');
      return;
    }
    try {
      const response = await API.getInstance().signIn(email);

      if (response.status === 200) {
        setSuccess('Check your email for login link');
        onSuccess();
        setError('');
      }
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message;
      console.log('CATCH BLOCK', error);
      setError(error);
      setSuccess(false);
    }
  };
  const navigation = useNavigate();

  const handleGoogleLogin = async () => {
    try {
      const googleLoginLink = await API.getInstance().google();
      window.location.href = googleLoginLink;
    } catch (e) {
      if (e) {
        if (e.response?.status === 401) {
          // eslint-disable-next-line
          console.log('e :: ', e);
        }
      }
      throw e;
    }
  };

  const handleLinkedinLogin = async () => {
    try {
      const linkedinLoginLink = await API.getInstance().linkedin();
      window.location.href = linkedinLoginLink;
    } catch (e) {
      if (e) {
        if (e.response?.status === 401) {
          // eslint-disable-next-line
          console.log('e :: ', e);
        }
      }
      throw e;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={styles.title}>Login to Resume Score</p>
        {/* <button className={styles.btn} onClick={onClose}>
            <CloseIcon />
          </button> */}
        <div className={styles.formInputContainer} style={{ border: isValid ? '0px solid #ccc' : '1px solid red' }}>
          <motion.input
            id="email"
            type="email"
            value={email}
            onChange={onChangeEmail}
            placeholder="Email Address"
            className={styles.input}
            animate={isValid ? { x: 0 } : { x: [-10, 10, -10, 10, 0] }} // Shake animation
            transition={{ duration: 0.3 }}
          />
        </div>
        <button
          onClick={handleSubmit}
          id="login"
          className={styles.CheckNavButton}
        >
          Login with email
        </button>
        <button
          id="go-to-signup"
          className={styles.footerLink}
          onClick={() => navigation('/signup')}
        >
          Go to Sign Up
        </button>
        {error && <p className={styles.error}>{error}</p>}
        {success && <p className={styles.success}>{success}</p>}

        <div className={styles.seperator}>
            <div className={styles.seperatorLine} />
            <div className={styles.seperatorText}>OR</div>
            <div className={styles.seperatorLine} />
          </div>

          <div className={styles.socialLogin}>
            <Button
              size="medium"
              fullWidth
              className={styles.googleButton}
              onClick={handleGoogleLogin}
            >
              <GoogleIcon />
              Continue with Google
            </Button>
            <Button
              size="medium"
              fullWidth
              className={styles.linkedinButton}
              onClick={handleLinkedinLogin}
            >
              <LinkedInIcon />
              Continue with LinkedIn
            </Button>
          </div>
      </div>
    </div>
  );
};

const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={styles.icon}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};
