import hero from '../../../../assets/insights/resumeTips/hero.png';


export const meta = {
  author: "",
  date: "2024-05-07",
  type: "Insights",
  title: "Top Resume Writing Tips for Landing Your Dream Job.",
  description: "Your resume is your first impression on a potential employer. In a competitive job market, making it outstanding is essential to landing interviews and securing your dream job. This guide provides expert tips on crafting a compelling resume that showcases your skills and aligns seamlessly with the jobs you desire.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/top-resume-writing-tips",
};
