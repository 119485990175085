import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Your resume is your first impression on a potential employer. In a competitive job market, 
        making it outstanding is essential to landing interviews and securing your dream job. This 
        guide provides expert tips on crafting a compelling resume that showcases your skills and 
        aligns seamlessly with the jobs you desire.
      </div>

      <div className={styles.title}>
        The Rise and Fall of the Paper Avalanche: How ATS Took the Stage
      </div>

      <div className={styles.text}>
        Before the digital age, the hiring process was a labour-intensive affair. Resumes piled up, 
        often leading to missed opportunities for talented individuals whose qualifications got 
        buried beneath the sheer volume of applications.<br/>
        Enter the Applicant Tracking System (ATS). These digital platforms revolutionized recruitment 
        by allowing companies to electronically post jobs, receive applications, and screen resumes 
        for relevant keywords.<br/>
        While ATS offered a sigh of relief for overwhelmed recruiters, it wasn't without limitations. 
        Resumes often became a game of keyword bingo, with applicants strategically stuffing their 
        documents with terms pulled from the job description in hopes of getting past the initial 
        screening. This approach could lead to qualified but creatively phrased resumes getting 
        tossed aside, while candidates who excelled at keyword stuffing but lacked the necessary 
        skills might slip through the cracks.<br/>
      </div>

      <div className={styles.title}>
        Beyond Keyword Soup: AI's Starring Role
      </div>

      <div className={styles.text}>
        AI brings a much more nuanced performance to the hiring stage. It goes beyond the limitations 
        of keyword-based screening, offering a deeper understanding of a candidate's potential. Here's 
        a glimpse into the exciting world of AI-powered hiring:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Skills Assessments Beyond the Resume: </strong>
            AI can analyze writing samples, code, or project descriptions submitted by candidates. 
            This allows for a more comprehensive evaluation of their competencies than a simple 
            resume skim. Imagine an AI system that can assess the elegance and efficiency of a 
            programmer's code, or the persuasive power of a writer's proposal, providing a much 
            more accurate picture of their skills than a list of keywords.
          </li>

          <li className={styles.listItem}>
            <strong>Unleashing the Power of Language: Natural Language Processing Takes Center Stage </strong>
            Natural Language Processing (NLP) is a subfield of AI that allows computers to understand 
            human language. In the realm of hiring, NLP can be a game-changer. NLP-powered AI can 
            analyze the intent and tone of a candidate's application materials, providing a deeper 
            understanding of their fit for the role. For instance, an AI system could analyze a cover 
            letter to see if the candidate demonstrates genuine enthusiasm for the company and the 
            position, or if their writing style aligns with the company culture.
          </li>

          <li className={styles.listItem}>
            <strong>Interview Intelligence: AI Analyzes, Recruiters Strategize</strong>
            Video interviews are becoming increasingly popular, but sifting through hours of footage 
            can be time-consuming. AI can step in and analyze these interviews, identifying key 
            strengths and potential red flags. Imagine an AI system that can analyze a candidate's 
            body language, communication style, and responses to specific questions, flagging areas 
            for further exploration during a live interview. This frees up valuable time for 
            recruiters, allowing them to focus on building rapport with top talent and delving deeper 
            into the insights provided by AI.
          </li>

          <li className={styles.listItem}>
            <strong>Diversity and Inclusion Champion: AI Levels the Playing Field</strong>
            Unconscious bias can creep into the hiring process at any stage. AI can help remove this 
            bias by analyzing job descriptions and candidate profiles for hidden biases. For example, 
            AI might identify language in a job description that could be perceived as gendered, 
            prompting the hiring team to revise the wording to ensure a wider pool of qualified 
            candidates apply.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        The Future of Hiring: A Blockbuster Collaboration Between Man and Machine
      </div>

      <div className={styles.text}>
        The future of hiring is not about robots taking over the HR department. It's about a powerful 
        collaboration between human expertise and AI's analytical capabilities. Imagine a world where 
        AI handles the repetitive tasks of resume screening, interview scheduling, and initial 
        candidate assessment. This frees up recruiters to focus on the strategic aspects of hiring, 
        such as building relationships with top talent, conducting in-depth interviews, and making 
        informed hiring decisions. This dream team can unlock a future of hiring that's:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Faster and More Efficient: </strong>
            By automating resume screening and scheduling, AI can significantly reduce time-to-hire. 
            This gives companies a competitive edge in a tight job market, allowing them to fill open 
            positions quickly with qualified candidates.
          </li>

          <li className={styles.listItem}>
            <strong>Enhanced Candidate Experience: </strong>
            AI-powered chatbots can keep candidates informed throughout the hiring process, providing 
            updates on their application status and answering basic questions. This creates a positive 
            candidate experience that fosters employer branding and attracts top talent. Imagine a 
            world where candidates no longer feel like they have fallen into a black hole, never to 
            hear back from a company they applied to.
          </li>

          <li className={styles.listItem}>
            <strong>Data-Driven Decisions: </strong>
            AI can analyze vast amounts of hiring data, providing recruiters with valuable insights to 
            make better hiring decisions. Imagine being able to see trends in applicant demographics, 
            retention rates for different hiring strategies, and the effectiveness of various job 
            descriptions. This data can be used to refine the hiring process, ensuring it attracts and 
            retains the best talent.
          </li>
        </ul>
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        The Closing Credits: Building a Fair and Efficient Future
      </div>

      <div className={styles.text}>
        While AI offers tremendous potential for the future of hiring, ethical considerations are 
        paramount. Here's how to ensure a fair and successful AI-powered hiring process:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Transparency is Key: </strong>
            Be upfront with candidates about how AI is used in the hiring process. Clearly explain 
            what data is collected, how it's analyzed, and how it's used to make decisions.
          </li>

          <li className={styles.listItem}>
            <strong>Human Oversight: </strong>
            The Irreplaceable Human Touch AI should be a powerful tool, but it should never replace 
            human judgment and intuition. Recruiters bring a wealth of experience and emotional 
            intelligence to the hiring process, and their role will remain crucial in evaluating a 
            candidate's cultural fit and overall suitability for the role.
          </li>

          <li className={styles.listItem}>
            <strong>Continuous Improvement: </strong>
            Keeping the AI Performance in Tune AI algorithms are not static. They need to be 
            continuously monitored and audited to ensure they remain unbiased and effective. 
            Regularly reviewing the data used to train the AI and evaluating its performance are 
            essential steps in mitigating bias and ensuring the AI is delivering fair and accurate 
            results.
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        The future of work is rapidly changing, and the way we hire needs to adapt as well. By 
        embracing AI as a powerful collaborator, we can create a hiring process that is efficient, 
        fair, and effective for both companies and candidates. The curtain is rising on a new era in 
        recruitment, and the performance promises to be nothing short of extraordinary.
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        { ...meta }
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
