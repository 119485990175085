import { meta as craftingResumeMeta } from './Insight/crafting-the-perfect-resume/data';
import { meta as digitalFrontierMeta } from './Insight/navigating-the-digital-frontier/data';
import { meta as optimiseResumeMeta } from './Insight/tips-to-optimize-resume/data';
import { meta as resumeWritingTipsMeta } from './Insight/top-resume-writing-tips/data';
import { meta as futureOfAiMeta } from './Insight/future-of-ai-in-hiring/data';
import { meta as atsResumeBuilder } from './Insight/best-ats-resume-builder/data';
import { meta as jobHuntMeta } from './Insight/evolution-of-job-hunting/data';
import { meta as resumeGapMeta } from './Insight/the-resume-gap/data';
import { meta as perfectCoverLetterMeta } from './Insight/guide-to-perfect-cover-letter/data';
import { meta as resumeReadingPsychologyMeta } from './Insight/resume-reading-psychology/data';
import { meta as skillStackingMeta } from './Insight/skill-stacking/data';
import { meta as resumeSectionsMeta } from './Insight/guide-to-resume-sections/data';
import { meta as crackingLinkedinCodeMeta } from './Insight/cracking-linkedin-code/data';
import { meta as scienceBehindATSMeta } from './Insight/science-behind-ats/data';
import { meta as winningTechnicalResumeMeta } from './Insight/winning-technical-resume/data';
import { meta as greatIndianJobHuntMeta } from './Insight/great-indian-job-hunt/data';
import { meta as jobSearchTimeAuditMeta } from './Insight/job-search-time-audit/data';

export function getAllInsights() {

  let blogs = [
    { ...resumeGapMeta },
    { ...craftingResumeMeta },
    { ...digitalFrontierMeta },
    { ...optimiseResumeMeta },
    { ...resumeWritingTipsMeta },
    { ...futureOfAiMeta },
    { ...atsResumeBuilder },
    { ...jobHuntMeta},
    { ...perfectCoverLetterMeta },
    { ...resumeReadingPsychologyMeta },
    { ...skillStackingMeta },
    { ...resumeSectionsMeta },
    { ...crackingLinkedinCodeMeta },
    { ...scienceBehindATSMeta },
    { ...winningTechnicalResumeMeta },
    { ...greatIndianJobHuntMeta },
    { ...jobSearchTimeAuditMeta },
  ];

  return blogs.sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB.getTime() - dateA.getTime();
  }).map((blog, index) => ({ ...blog, index }));
};

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];
