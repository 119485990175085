import React, { useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import Footer1 from '../../Footer1';
import { useMediaQuery } from 'react-responsive';
import calender from '../../../assets/insights/common/calender.png';
import { motion, AnimatePresence } from 'framer-motion';

import { getAllInsights, monthNames } from '../utils';

const InsightTiles = (props) => {
  const {
    insights,
  } = props;

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          delay: 1,
        }}
      >
        <div className={styles.insightTilesContainer}>
          {
            insights.map((insight) => {
              const insightDate = new Date(insight.date);

              return (
                <Link to={insight.link} className={styles.link}>
                  <div className={styles.insightTileContainer}>
                    <img src={insight.image} className={styles.insightImage} />

                    <h2 className={styles.mainInsightTitle}>
                      <span className={styles.title}>{insight.title}</span>
                      {insight.subTitle && (
                        <span className={styles.subTitle}>:&nbsp;{insight.subTitle}</span>
                      )}
                    </h2>

                    {insight.date && (
                      <div className={styles.dateContainer}>
                        <img src={calender} alt="Calender Image" className={styles.calenderImg} />
                        <span className={styles.calenderText}>
                          {monthNames[insightDate.getMonth()]}&nbsp;{insightDate.getFullYear()}
                        </span>
                      </div>
                    )}
                  </div>
                </Link>
              )
            })}
        </div>
      </motion.div>
    </AnimatePresence>
  );
}

const Insights = () => {
  const navigate = useNavigate();
  const allInsights = getAllInsights();
  const [latestInsight, ...restInsights] = allInsights;
  const latestInsightDate = new Date(latestInsight.date);

  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1 }}
        >

          <div className={styles.insightsContainer}>

            <h1 className={styles.mainHeading}>
              Blogs & Insights
            </h1>

            <div className={styles.mainInsight}>
              <div className={styles.leftContainer}>
                {latestInsight.date && (
                  <div className={styles.dateContainer}>
                    <img src={calender} alt="Calender Image" className={styles.calenderImg} />
                    <span className={styles.calenderText}>
                      {monthNames[latestInsightDate.getMonth()]}&nbsp;{latestInsightDate.getFullYear()}
                    </span>
                  </div>
                )}

                <h2 className={styles.mainInsightTitle}>
                  <span className={styles.title}>{latestInsight.title}</span>
                  {latestInsight.subTitle && (
                    <span className={styles.subTitle}>:&nbsp;{latestInsight.subTitle}</span>
                  )}
                </h2>

                <div className={styles.description}>
                  {latestInsight.description}
                </div>

                <Link to={latestInsight.link}>
                  <button className={styles.linkButton}>
                    Read more
                  </button>
                </Link>
              </div>

              <div className={styles.rightContainer}>
                <img src={latestInsight.image} className={styles.mainInsightImage} />
              </div>
            </div>
          </div>

          <div className={styles.latestArticlesContainer}>
            <div className={styles.latestArticlesSubContainer}>
              <h3 className={styles.mainTitle}>
                Latest Articles
              </h3>

              <div className={styles.insightTiles}>
                <InsightTiles
                  insights={restInsights}
                />
              </div>
            </div>
          </div>

          <Footer1 />
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Insights;
