/* eslint-disable */
import React, { useEffect } from 'react';
import styles from './instructions.module.scss';
import step1Img from '../assets/LandingPage/UploadResume.png';
import step2Img from '../assets/LandingPage/PasteJobDesc.png';
import step3Img from '../assets/LandingPage/getScore.png';

const data = {
  label: 'See how you can strengthen your resume in three simple steps!',
  step1: 'Upload Resume',
  step2: 'Paste or Select JD',
  step3: 'Build Resume'
};
const steps = [
  {
    id: 1,
    name: data.step1,
    styles: styles.step1ImgWrap,
    image: step1Img,
    imgStyles: styles.step1ImgStyle
  },
  {
    id: 2,
    name: data.step2,
    styles: styles.step2ImgWrap,
    image: step2Img,
    imgStyles: styles.step2ImgStyle
  },
  {
    id: 3,
    name: data.step3,
    styles: styles.step3ImgWrap,
    image: step3Img,
    imgStyles: styles.step3ImgStyle
  }
];

const InstructionsResumeBuilder = ({ currentStep }) => {

  return (
    <div className={styles.instructionWrap}>
      {steps.map((step, index) => {
        return (
          <div className={styles.step}>
            <div key={`${step.name}`} className={[styles.stepContainer, step.id === currentStep ? styles.active : ''].join(' ')}>
              <div className={styles.number}>{step.id}</div>
              <div id={`stepText${index}`} className={styles.stepLabel}>
                {step.name}
              </div>
            </div>
            {index < steps.length - 1 ? <div className={styles.horLine} /> : null}
          </div>
        );
      })}
    </div>
  );
};

export default InstructionsResumeBuilder;
