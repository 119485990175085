// SpinnerMaterialUI.js
import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './styles.module.scss';

const Spinner = ({ show }) => {
    return (
        <div>
            <div className={styles.overlay}>
                <CircularProgress className={styles.spinner} />
            </div>
        </div>
    );
};

export default Spinner;
