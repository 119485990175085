/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import {
  BrowserRouter as Router,
  Route,
  Outlet,
  Routes,
  useLocation,
  useNavigate,
  useParams
} from 'react-router-dom';
import { store, persistor } from './store';
import UploadResumeJd from './pages/Upload-Resume-JD';
import UploadResumeJd1 from './pages/Upload-Resume-JD1';

import Report from './pages/Report';
import AuthProvider from './context/AuthContext';
import PrivateRoute from './component/PrivateRoute';
import LoginPage from './pages/Login';
import ValidateUser from './pages/Validate';
import PublicRoute from './component/PublicRoute';
import VerifyPayment from './pages/VerifyPayment';
import SignupPage from './pages/Signup';
import AccountPage from './pages/Account';
import InsightsPage from './pages/Insights';

// import { ResumeBuilder } from './component/ResumeBuilderBack';
import ResumeBuilderPage from './pages/ResumeBuilder';
import ReportResumeBuilder from './component/ReportResumeBuilder';
// import ResumeTemplatesPage from './pages/ResumeTemplates';
import packageJson from '../package.json';
import { useAuth } from './context/AuthContext';
import SignedInMessage from './component/SignedInMessage';
import Profile from './pages/Profile';
import History from './pages/History';
import Unsubscribe from './pages/Unsubscribe';
import { BASEPATH, IS_PATH2, IS_PATH1 } from './lib/constants';
import { meta as craftingResumeMeta } from './component/Insights/Insight/crafting-the-perfect-resume/data';
import { meta as digitalFrontierMeta } from './component/Insights/Insight/navigating-the-digital-frontier/data';
import { meta as optimiseResumeMeta } from './component/Insights/Insight/tips-to-optimize-resume/data';
import { meta as resumeWritingTipsMeta } from './component/Insights/Insight/top-resume-writing-tips/data';
import { meta as futureOfAIMeta } from './component/Insights/Insight/future-of-ai-in-hiring/data';
import { meta as atsResumeBuilder } from './component/Insights/Insight/best-ats-resume-builder/data';
import { meta as jobHuntMeta } from './component/Insights/Insight/evolution-of-job-hunting/data';
import { meta as resumeGapMeta } from './component/Insights/Insight/the-resume-gap/data';
import { meta as perfectCoverLetterMeta } from './component/Insights/Insight/guide-to-perfect-cover-letter/data';
import { meta as resumeReadingPsychologyMeta } from './component/Insights/Insight/resume-reading-psychology/data';
import { meta as skillStackingMeta } from './component/Insights/Insight/skill-stacking/data';
import { meta as resumeSectionsMeta } from './component/Insights/Insight/guide-to-resume-sections/data';
import { meta as crackingLinkedinCodeMeta } from './component/Insights/Insight/cracking-linkedin-code/data';
import { meta as scienceBehindATSMeta } from './component/Insights/Insight/science-behind-ats/data';
import { meta as winningTechnicalResumeMeta } from './component/Insights/Insight/winning-technical-resume/data';
import { meta as greatIndianJobHuntMeta } from './component/Insights/Insight/great-indian-job-hunt/data';
import { meta as jobSearchTimeAuditMeta } from './component/Insights/Insight/job-search-time-audit/data';
import OptimizeResumeInsight from './pages/InsightFull/OptimizeResume';
import DigitalFrontierInsight from './pages/InsightFull/DigitalFrontier';
import ResumeTipsInsight from './pages/InsightFull/ResumeTips';
import CraftResumeInsight from './pages/InsightFull/CraftResume';
import FutureOfAI from './pages/InsightFull/FutureOfAI';
import AtsResumeBuilder from './pages/InsightFull/ATSResumeBuilder';
import JobHunt from './pages/InsightFull/EvolutionOfJobHunting';
import ResumeGap from './pages/InsightFull/ResumeGap';
import PerfectCoverLetter from './pages/InsightFull/PerfectCoverLetter';
import ResumePsychologyInsight from './pages/InsightFull/ResumeReadingPsychology';
import SkillStacking from './pages/InsightFull/SkillStacking';
import ResumeSectionsInsight from './pages/InsightFull/ResumeSections';
import CrackingLinkedinCodeInsight from './pages/InsightFull/CrackingLinkedinCode';
import ScienceBehindATSInsight from './pages/InsightFull/ScienceBehindATS';
import WinningTechnicalResumeInsight from './pages/InsightFull/WinningTechnicalResume';
import GreatIndianJobHuntInsight from './pages/InsightFull/GreatIndianJobHunt';
import JobSearchTimeAuditInsight from './pages/InsightFull/JobSearchTimeAudit';
import ResumeScore from './pages/ResumeScore';
import axios from 'axios';
import Utility from './utils';
import ScrollToTop from './component/ScrollTop';
import Privacy from './Privacy';
import { privacyData, termsData } from './component/Footer/Data';
function App() {
  console.log('App version:', packageJson.version);


  return (
    <Router basename={BASEPATH}>
      {/* <PublicRoute> */}
      <ScrollToTop />
      <AppCore />
      {/* </PublicRoute> */}
    </Router>
  );
}

const AppCore = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();
  const { authState, pannaAuth } = useAuth();

  let props = {
    router: { location, navigate, params }
  };
  console.log('app js props', props);
  const searchParams = new URLSearchParams(location.search);
  if (!localStorage.getItem('source') && localStorage.getItem('source') !== location.search) {
    localStorage.setItem('source', location.search);
  }
  const [prevLocation, setPrevLocation] = useState(null);
  useEffect(() => {
    setPrevLocation(location.pathname);
    console.log('prevLocation:', prevLocation);
    localStorage.setItem('prevLocation', prevLocation);
  }, [location]);

  const [ip, setIP] = useState('');

  useEffect(() => {
    const fetchIP = async () => {
      try {
        const response = await axios.get('https://api.ipify.org?format=json');
        setIP(response.data.ip);
        sessionStorage.setItem('clientIP', response.data.ip);
      } catch (error) {
        console.error('Error fetching the IP address:', error);
      }
    };
    fetchIP();
  }, []); // Empty dependency array ensures this runs only once

  useEffect(() => {
    if (JSON.parse(localStorage.getItem('isSocialLogin'))) {
      if (JSON.parse(localStorage.getItem('isPathFromScoreReport'))) {
        navigate('/report');
      } else {
        navigate('/resume-build-home');
      }
    }
    localStorage.setItem('isPathFromScoreReport', JSON.stringify(false));
    localStorage.setItem('isSocialLogin', JSON.stringify(false));
  }, []);


  return (
    <Routes>
      {/* Route for "/" */}

      <Route
        exact
        path="/"
        element={IS_PATH2 ? <ResumeScore {...props} /> : <ResumeScore {...props} />}
      />

      {/* Route for "/check-resume-score" */}
      <Route exact path="/check-resume-score" element={IS_PATH2 ? <ResumeScore {...props} /> : <ResumeScore {...props} />} />
      {/* Route for "/resume-build-home" */}
      <Route exact path="/resume-build-home" element={<AccountPage {...props} />} />
      <Route exact path="/insights" element={<InsightsPage {...props} />} />
      <Route exact path="/privacy-policy" element={<Privacy heading="Privacy Policy" messageObject={privacyData} />} />
      <Route exact path="/terms-of-service" element={<Privacy heading="Our Terms of Service" messageObject={termsData} />} />

      <Route exact path={craftingResumeMeta.link} element={<CraftResumeInsight />} />
      <Route exact path={digitalFrontierMeta.link} element={<DigitalFrontierInsight />} />
      <Route exact path={optimiseResumeMeta.link} element={<OptimizeResumeInsight />} />
      <Route exact path={resumeWritingTipsMeta.link} element={<ResumeTipsInsight />} />
      <Route exact path={futureOfAIMeta.link} element={<FutureOfAI />} />
      <Route exact path={atsResumeBuilder.link} element={<AtsResumeBuilder />} />
      <Route exact path={jobHuntMeta.link} element={<JobHunt />} />
      <Route exact path={resumeGapMeta.link} element={<ResumeGap />} />
      <Route exact path={perfectCoverLetterMeta.link} element={<PerfectCoverLetter />} />
      <Route exact path={resumeReadingPsychologyMeta.link} element={<ResumePsychologyInsight />} />
      <Route exact path={skillStackingMeta.link} element={<SkillStacking />} />
      <Route exact path={resumeSectionsMeta.link} element={<ResumeSectionsInsight />} />
      <Route exact path={crackingLinkedinCodeMeta.link} element={<CrackingLinkedinCodeInsight />} />
      <Route exact path={scienceBehindATSMeta.link} element={<ScienceBehindATSInsight />} />
      <Route exact path={winningTechnicalResumeMeta.link} element={<WinningTechnicalResumeInsight />} />
      <Route exact path={greatIndianJobHuntMeta.link} element={<GreatIndianJobHuntInsight />} />
      <Route exact path={jobSearchTimeAuditMeta.link} element={<JobSearchTimeAuditInsight />} />
      <Route exact path="/unsubscribe" element={<Unsubscribe {...props} />} />

      {/* Route for "/auth/redirect" */}
      <Route exact path="/auth/redirect" element={<ValidateUser {...props} />} />

      <Route exact path="/sample-builder-report" element={<ReportResumeBuilder {...props} />} />

      {/* Route for other paths */}
      <Route path="/" element={<Outlet />}>
        {/* Route for "/report" */}
        <Route exact path="/report" element={<Report {...props} />} />

        {/* Route for "/login" */}
        {!authState ? (
          <Route exact path="/login" element={<SignupPage {...props} />} />
        ) : (
          <Route exact path="/login" element={<SignedInMessage />} />
        )}

        {/* Route for "/signup" */}
        {!authState ? (
          <Route exact path="/signup" element={<SignupPage {...props} />} />
        ) : (
          <Route exact path="/signup" element={<SignedInMessage />} />
        )}

        {/* Route for authenticated users */}
        {authState ? (
          <>

            {/* Route for "/builder-report" */}
            <Route exact path="/builder-report" element={<ReportResumeBuilder {...props} />} />

            {/* Route for "/resume-builder" */}
            <Route exact path="/resume-builder" element={<ResumeBuilderPage {...props} />} />

            {/* Route for "/profile" */}
            <Route exact path="/profile" element={<Profile {...props} />} />

            {/* Route for "/history" */}
            <Route exact path="/history" element={<History {...props} />} />

            {/* Route for "/payment" */}
            <Route exact path="/payment" element={<VerifyPayment {...props} />} />
          </>
        ) : <Route exact path="/login" element={<LoginPage {...props} />} />}
      </Route>
    </Routes>

  );
};

export default App;
