import hero from '../../../../assets/insights/digitalFrontier/hero.png';


export const meta = {
  author: "",
  date: "2024-03-09",
  type: "Insights",
  title: "Navigating the Digital Frontier",
  subTitle: "The Transformative Power of Technology in Modern Resume Building",
  description: "",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/navigating-the-digital-frontier",
};
