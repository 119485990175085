import React, { createContext, useContext, useEffect, useState } from 'react';
import { store } from '../store';
import pannaAuth from '../lib/auth';
import API from '../network';
import { resetStoreWithNewData } from '../store/actions/cache';

// Create the context
export const AuthContext = createContext(undefined);

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState(null);
  const [loading, setLoading] = useState(true);

  const getSession = async () => {
    try {
      const session = await pannaAuth.currentSession();
      setAuthState({ ...session });
      sessionStorage.setItem('emailAddress', session?.userInfo?.emailAddress);
      sessionStorage.setItem('userID', session?.userInfo?.id);
      return session;
    } catch (e) {
      setAuthState(null);
      console.log(e);
      // pannaAuth.redirectToSignIn();
    } finally {
      setLoading(false);
    }
  };

  const signOut = async options => {
    try {
      await pannaAuth.signOut(options);
      setAuthState(null);
      localStorage.clear();
    } catch (e) {
      setAuthState(null);
      console.log(e);
      // pannaAuth.redirectToSignIn();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if (token) {
      localStorage.setItem('token', token);
    }
    getSession();

    if (JSON.parse(localStorage.getItem('isPathFromScoreReport'))) {
      fetchReportScore(token);
    }
  }, []);

  const fetchReportScore = async (token) => {
    try {
      const response = await API.getInstance().fetchScore(token);
      store.dispatch(resetStoreWithNewData(JSON.parse(response?.scoreHistory?.scoreJson)));
    } catch (e) {
      console.log('catch block fetchReportScore', e);
    }
  }

  return (
    <AuthContext.Provider
      value={{ getSession, signOut, authState, pannaAuth, loading }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
