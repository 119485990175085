import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
// import API from '../../network';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Spinner from '../../Spinner';


export const JDView = ({ description, showJDFn }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const containerRef = useRef();
  const [open, setOpen] = useState(true);
  const formattedJobDescription = description?.replace(/\n/g, '<br />');

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        showJDFn(false)
        setOpen(false);

      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);




  return (
    <>

      {showSpinner ? <Spinner show={true} /> : null}

      {open && <div
        style={{
          zIndex: 999999,
          backdropFilter: 'blur(10px)'
        }}
        className={styles.container}
      >
        <div
          style={{
            backdropFilter: 'blur(10px)'
          }}
          className={styles.content}
          ref={containerRef}
        >
          <div className={styles.contentContainer}>
            <h2>Job Description</h2>
            <div
              className={styles.jobDescription}
              dangerouslySetInnerHTML={{
                __html: formattedJobDescription
              }}
            />
          </div>
        </div>
      </div >
      }
    </>
  );
};


