import React from 'react';
import { connect } from 'react-redux';
import ScrollAnimation from 'react-animate-on-scroll';
// import JdPagination from '../JdPagination';
import DropDownList from '../../component/DropDownList';
import PlaceholderImg from '../../assets/JobDescriptionField/PastejDPlaceholder.png';
import quitImg from '../../assets/JobDescriptionField/quit.png';
import viewfullIcon from '../../assets/JobDescriptionField/view-full-screen-alt.png';
import data from './data';
import styles from './styles.module.css';
import Button from '../Button';

class JobDescriptionField extends React.Component {
  state = {
    showPlaceholder: true,
    showDefaultJds: false
  };

  onTextAreaFocused = () => {
    this.setState({ showPlaceholder: false, showDefaultJds: true });
  };
  onTextAreaBlured = () => {
    const { jobDescription } = this.props;
    if (!jobDescription) {
      this.setState({ showPlaceholder: true });
    } else {
      this.setState({ showPlaceholder: false });
    }
  };
  resetJdField = () => {
    const { resetJd } = this.props;
    resetJd();
    this.setState({ showPlaceholder: true });
  };
  render() {
    const { showPlaceholder, showDefaultJds } = this.state;
    const {
      jobDescription,
      onRemoveSavedJd,
      selectedJd,
      selectedSavedJd,
      onJdSelected,
      onSavedJdSelected,
      onChange,
      openJDViewer,
      isChecked,
      onCheckboxClicked,
      onMeasureClick,
      recentJds,
      reduxProps: { cache }
    } = this.props;
    const { recentJds: savedJds } = cache;
    const clearJD = jobDescription ? (
      <div
        title="clear"
        role="button"
        className={styles.resetButton}
        onClick={this.resetJdField}
        onKeyPress={this.resetJdField}
        tabIndex="0"
      >
        <img src={quitImg} alt="" className={styles.quiteStyle} />
      </div>
    ) : null;
    const viewFullScreen = (
      <div
        title="view full screen"
        role="button"
        className={styles.viewFullButton}
        onClick={openJDViewer}
        onKeyPress={this.resetJdField}
        tabIndex="0"
      >
        <img src={viewfullIcon} alt="" className={styles.viewFullStyle} />
      </div>
    );
    return (
      <ScrollAnimation
        animateIn="fadeInUp"
        delay={300}
        className={styles.jdContentContainer}
        animateOnce
      >
        <div className={styles.lableWrap}>
          <div className={styles.jdLable}>{data.jdLable}</div>
          {showDefaultJds || jobDescription ? (
            <DropDownList
              list={savedJds}
              placeHolderText="Select Job Descriptions"
              jobDescription={jobDescription}
              selectedJd={selectedJd}
              onSelectJd={onJdSelected}
              onSavedJdSelected={onSavedJdSelected}
              placeHolderStyle={styles.placeholderText}
              containerStyle={styles.dropDownContainer}
              onRemoveSavedJd={onRemoveSavedJd}
            />
          ) : (
            <div />
          )}
        </div>
        <div className={styles.textAreaContainer}>
          {clearJD}
          {jobDescription ? viewFullScreen : null}
          {showPlaceholder && !jobDescription ? (
            <div className={styles.placeholderWrap}>
              <img
                src={PlaceholderImg}
                alt=""
                className={styles.PlaceholderImg}
              />
              <div className={styles.placeholderText}>
                {data.placeholderText}
              </div>
              <div className={styles.placeholderText}>{data.or}</div>
              <div className={styles.placeholderText}>
                Select from Sample
                {`${savedJds && savedJds.length !== 0 ? '/Saved' : ''}`} job
                description
              </div>
              {!showDefaultJds ? (
                <DropDownList
                  list={savedJds}
                  placeHolderText="Select Job Descriptions"
                  jobDescription={jobDescription}
                  selectedJd={selectedJd}
                  onSelectJd={onJdSelected}
                  onSavedJdSelected={onSavedJdSelected}
                  placeHolderStyle={styles.placeholderText}
                  containerStyle={styles.dropDownContainer}
                  onRemoveSavedJd={onRemoveSavedJd}
                />
              ) : null}
            </div>
          ) : null}
          <textarea
            value={jobDescription}
            id="jdTextArea"
            placeholder={!showPlaceholder ? data.textAreaPLaceholderText : ''}
            className={styles.textAreaStyle}
            onChange={onChange}
            onFocus={this.onTextAreaFocused}
            onBlur={this.onTextAreaBlured}
          />
        </div>
        <div className={styles.savedJdWrap}>
          <Checkbox
            textClassName={styles.checkboxText}
            className={styles.checkbox}
            label="Save Job Description"
            checked={isChecked}
            onChange={onCheckboxClicked}
            modifier="red"
            disable
          />
        </div>
        <Button
          id="MeasureButton"
          text={this.props.text || 'MEASURE'}
          onClick={onMeasureClick}
          disable={!jobDescription}
          style={{ marginBottom: '20px', backgroundColor: '#ff5d47' }}
        />
      </ScrollAnimation>
    );
  }
}

const mapStateToProps = reduxProps => ({
  reduxProps
});

export default connect(mapStateToProps, null)(JobDescriptionField);

const Checkbox = ({ label, checked, onChange }) => {
  return (
    <div className={styles.checkbox}>
      <label className={styles.checkboxText}>
        <input
          type="checkbox"
          checked={checked}
          onChange={onChange}
          className={styles.checkboxInput}
        />

        {label}
      </label>
    </div>
  );
};
