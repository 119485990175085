import React, { useEffect} from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useMediaQuery } from 'react-responsive';

/**
 * This component is to render precall screen in different platforms
 * @param {viewRenderProps} props
 * @return {JSX.Element}
 */
const InsightContainer = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    console.log('props :: ', props);
    const isMobile = useMediaQuery({ maxWidth: 767 });
    if (isMobile) {
        return <Mobile {...props} />;
    } else {
        return <Desktop {...props}>{props.children}</Desktop>;
    }
}

export default InsightContainer;