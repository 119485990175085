export default {
    ActionColor: [
        {
            textColor: '#2673F1',
            countColor: '#DAE8FF',
        },
        {
            textColor: '#15A637',
            countColor: '#DFF9D4',
        },
        {
            textColor: '#591FC5',
            countColor: '#E7DAFF',
        },
        {
            textColor: '#F04A4A',
            countColor: '#FDE8E3',
        },
        {
            textColor: '#2673F1',
            countColor: '#DAE8FF',
        },
        {
            textColor: '#15A637',
            countColor: '#DFF9D4',
        },
        {
            textColor: '#591FC5',
            countColor: '#E7DAFF',
        },
        {
            textColor: '#F04A4A',
            countColor: '#FDE8E3',
        },
        {
            textColor: '#2673F1',
            countColor: '#DAE8FF',
        },
        {
            textColor: '#15A637',
            countColor: '#DFF9D4',
        },
        {
            textColor: '#591FC5',
            countColor: '#E7DAFF',
        },
        {
            textColor: '#F04A4A',
            countColor: '#FDE8E3',
        },
    ],
}