import React, { useEffect } from 'react';
import {
  Button
} from '@mui/material';
import styles from './styles.module.scss';
import axios from 'axios';
import API from '../../network';
import { useNavigate } from 'react-router-dom';
import { BASEPATH } from '../../lib/constants';
import { useSelector } from 'react-redux';
import Utility from '../../utils';
import GoogleIcon from '../../assets/social/google/GoogleIcon';
import LinkedInIcon from '../../assets/social/linkedin/LinkedinIcon';

export const SignupModal = ({ open, onClose, onSuccess }) => {
  const [mode, setMode] = React.useState('signup');

  const renderPage = () => {
    switch (mode) {
      case 'signup':
        return <Signup mode={mode} setMode={setMode} onSuccess={onSuccess} onClose={onClose} />;

      default:
        return <Signup mode={mode} setMode={setMode} onSuccess={onSuccess} onClose={onClose} />;
    }
  };
  return <>{open && renderPage()}</>;
};

const Signup = ({ mode, onClose, setMode, onSuccess }) => {
  console.log('test deployement on 17-09-2024');
  const [email, setEmail] = React.useState('');
  const [name, setName] = React.useState('');
  const [phone, setPhone] = React.useState('');

  const [emailValid, setEmailValid] = React.useState(true);
  const [nameValid, setNameValid] = React.useState(true);
  const [phoneValid, setPhoneValid] = React.useState(true);

  const [error, setError] = React.useState('');
  const [success, setSuccess] = React.useState('');
  const [isLoginOpen, setIsLoginOpen] = React.useState(window.location.pathname.includes('/login'));

  const storeData = useSelector(state => state);
  const { cache } = storeData;

  const { reportData } = cache || {};

  const validateEmail = (email) => {
    // Regular expression for validating email
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  };

  const validateName = (name) => {
    const regex = /^[A-Za-z\s]*$/;
    return regex.test(name);
  }

  const validatePhoneNumber = (phoneNumber) => {
    // Regular expression for validating phone number (10 digits)
    const regex = /^\d{10}$/;
    return regex.test(phoneNumber);
  };

  const onChangeEmail = (email) => {
    setEmail(email);
    console.log(email);
    if (validateEmail(email)) {
      setEmailValid(true);
      setError('');
    }
  }

  const onChangeName = (name) => {
    setName(name);
    console.log(name);
    if (name.length > 2) {
      setNameValid(true);
      setError('');
    }
  }

  const onChangePhone = (number) => {
    setPhone(number);
    console.log(name);
    if (validatePhoneNumber(number)) {
      setPhoneValid(true);
      setError('');
    }
  }



  const handleSubmit = async () => {
    console.log({
      email,
      name,
      phone
    });
    try {
      Utility.trackEvent('signup', 'BUTTON_CLICK', 'Sign-up')
    } catch (e) {
      console.log('error Sign-up click tracking', e);
    }
    let isPathFromScoreReport = false;
    let sourcePath = '';
    const jdRelevanceScore = reportData?.['Skill Match']?.['Jd Relevance score'];
    const data = {
      "emailAddress": email,
      "scoreJson": JSON.stringify(cache),
      "resumeScore": jdRelevanceScore ? jdRelevanceScore : '',
    }
    if (localStorage.getItem('source')) {
      sourcePath = localStorage.getItem('source');
    }

    if (!validateName(name)) {
      setError('Please use letters only for the name.');
      setNameValid(false);
      return;
    }
    if (name.length < 3) {
      setError('Please enter name');
      setNameValid(false);
      return;
    }
    if (!validateEmail(email)) {
      setError('Please enter valid email address');
      setEmailValid(false);
      return;
    }

    // if (!validatePhoneNumber(phone)) {
    //   setError('Please enter valid phone number');
    //   setPhoneValid(false);
    //   return;
    // }
    if (localStorage.getItem('prevLocation') && localStorage.getItem('prevLocation') === '/report') {
      isPathFromScoreReport = true;
    }
    try {
      const response = await API.getInstance().signUp(email, phone, name, isPathFromScoreReport, sourcePath);
      setSuccess('Check your email for login link');

      setMode('login');
      onSuccess();
      setError('');
      setEmail('');
      setPhone('');
      setName('');
      if (isPathFromScoreReport) {
        saveResumeScore(data);
      }
      localStorage.clear();
    } catch (err) {
      console.log(err.response);
      let error = err.response ? err.response.data.message : err?.message;
      error = error ? error : err.response.data;
      console.log('CATCH BLOCK', error);
      setSuccess('');
      setError(error);
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      Utility.trackEvent('login', 'BUTTON_CLICK', 'Login with email')
    } catch (e) {
      console.log('error login button click tracking', e);
    }


    if (validateEmail(email)) {
      // Email is valid
      //  setIsValid(true);
      setError('');
      // Proceed with form submission or other action
    } else {
      // Email is invalid
      // setIsValid(false);
      setError('Please enter valid email address');
      setEmailValid(false);
      return;
    }
    try {
      const response = await API.getInstance().signIn(email);

      if (response.status === 200) {
        setSuccess('Check your email for login link');
        onSuccess();
        setError('');
        localStorage.clear();
      }
    } catch (err) {
      const error = err.response ? err.response.data.message : err.message;
      console.log('CATCH BLOCK', error);
      setError(error);
      setSuccess(false);
    }
  };

  const saveResumeScore = (data) => {
    API.getInstance().saveResumeScore(data)
      .then(res => {
        console.log(res);
      })
      .catch(error => {
        console.log(error);
      });
  };

  const handleGoogleLogin = async () => {
    let isPathFromScoreReport = false;
    localStorage.setItem('isSocialLogin', JSON.stringify(true));
    if ((localStorage.getItem('prevLocation')
      && localStorage.getItem('prevLocation') === '/report')
      || JSON.parse(localStorage.getItem('isPathFromScoreReport'))
    ) {
      localStorage.setItem('isPathFromScoreReport', JSON.stringify(true));
    } else {
      localStorage.setItem('isPathFromScoreReport', JSON.stringify(false));
    }
    try {
      const googleLoginLink = await API.getInstance().google(isPathFromScoreReport);
      window.location.href = googleLoginLink;
    } catch (e) {
      if (e) {
        if (e.response?.status === 401) {
          // eslint-disable-next-line
          console.log('e :: ', e);
        }
      }
      throw e;
    }
  };

  const handleLinkedinLogin = async () => {
    let isPathFromScoreReport = false;
    localStorage.setItem('isSocialLogin', JSON.stringify(true));
    if ((localStorage.getItem('prevLocation')
      && localStorage.getItem('prevLocation') === '/report')
      || JSON.parse(localStorage.getItem('isPathFromScoreReport'))
    ) {
      localStorage.setItem('isPathFromScoreReport', JSON.stringify(true));
    } else {
      localStorage.setItem('isPathFromScoreReport', JSON.stringify(false));
    }
    try {
      const linkedinLoginLink = await API.getInstance().linkedin(isPathFromScoreReport);
      window.location.href = linkedinLoginLink;
    } catch (e) {
      if (e) {
        if (e.response?.status === 401) {
          // eslint-disable-next-line
          console.log('e :: ', e);
        }
      }
      throw e;
    }
  };

  const navigate = useNavigate();
  return (
    <div className={styles.container}>

      <div className={styles.left}>
        <div className={styles.content1}>
          <div className={styles.heading}>
            Land your dream job with <br />AI-powered resume building.
          </div>
          <div className={styles.text}>
            paññã Resume Builder utilises cutting-edge AI technology to analyze your current resume and target job description. We then craft a tailor made resume that highlights your most relevant skills and experiences, maximizing your chances of landing the interview.          </div>
        </div>
        <div className={styles.content2}>

          <div className={styles.left}>
            <div className={styles.head}>Features</div>
            <div className={styles.points}>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> AI-powered analysis
              </div>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Effortless resume creation
              </div>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Applicant Tracking System
              </div>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Focus on achievements
              </div>

            </div>
          </div>

          <div className={styles.right}>
            <div className={styles.head}>Benefits</div>
            <div className={styles.points}>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Save time
              </div>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Stand out from the crowd
              </div>
              <div className={styles.point}>
                <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAABlklEQVR4nN1VzUoDQQwuivgYQlH8Ab34GqKXeugkevTmM/RWb4JQM/SqnvQlfAm19iKKP2hBsEo7idCVGexau7Pdn540MCzMzH75knyTFAr/2uo7wZQGUyKQE0K5IuAPAm4TyoVGOSYw6/ZOLnCtzAahNDVKMGoRyDWpz7XUwJVKMKGR95KAdcQR759uBpPJzHOAa7uAX0h1iynSwr084IfbvNLPgBfcFitNzrUHvK542WLUVGdGA58f7AbTUfZgSuMwJ9UtEsitq4diFXFgpZgXvIbdWY1yFxYc5cxTXGl4lKEJuRXZR271wXXZzBHI/ZB0bzwRcHuIIdl9CzToZBCcwMxrlIdohNxJdoD8SkpWnZMts6iBn37lPBbc/fuWLkX4w9Z+w7SAWXIO42vUyFBkDiNxci6bBUJ+HC0AOcokU/qOxOpdAz8nK8yUMj80Qm75FBW9J83YDpu7VWBIomdbuBd87GaH7t1UC0lmm5W9mCUScsy5GtvofGZDTTVwUJqJaUk7MjXyu1sgl3Zk2rPcI/PP2BeFrY+oEiA8dAAAAABJRU5ErkJggg==" /> Increase your confidence
              </div>
            </div>
          </div>

        </div>
        <div className={styles.content3}>
          <div className={styles.head}>Privacy</div>
          <div className={styles.text1}>
            Your information is safe with us.
            paññã utilizes industry standard security practices to ensure the confidentiality of your resume and job description uploads.
          </div>
        </div>
      </div>

      <div className={styles.right}>
        {!isLoginOpen ? <div className={styles.text}>Sign up today and experience the power of <br /><b>paññã resume builder</b></div> :
          <div className={styles.text}>  login and experience the power of <br /><b>paññã resume builder</b></div>}

        <div className={styles.content}>

          <div className={styles.form}>
            {!isLoginOpen ? <div
              style={{
                marginTop: '20px', border: nameValid ? '0px solid #ccc' : '1px solid red'
              }}
              className={styles.formInputContainer}
            >
              <input
                id="name"
                type="text"
                value={name}
                onChange={e => onChangeName(e.target.value)}
                placeholder="Name"
                className={styles.input}
              />
            </div> : null}
            <div className={styles.formInputContainer} style={{ border: emailValid ? '0px solid #ccc' : '1px solid red' }}>
              <input
                id="email"
                type="text"
                value={email}
                onChange={e => onChangeEmail(e.target.value)}
                placeholder="Email Address"
                animate={emailValid ? { x: 0 } : { x: [-10, 10, -10, 10, 0] }}
                className={styles.input}
              />
            </div>
            {/* <div className={styles.formInputContainer} style={{ border: phoneValid ? '0px solid #ccc' : '1px solid red' }}>
              <input
                id="phone"
                type="text"
                value={phone}
                onChange={e => onChangePhone(e.target.value)}
                placeholder="Phone Number"
                animate={phoneValid ? { x: 0 } : { x: [-10, 10, -10, 10, 0] }}
                className={styles.input}
              />
            </div> */}
            {!isLoginOpen ? <button
              onClick={handleSubmit}
              id="signup"
              className={styles.CheckNavButton}
            >
              Sign-up
            </button> : null}
            {!isLoginOpen ? <button
              id="go-to-login"
              className={styles.footerLink}
              onClick={() => {
                setError('');
                setIsLoginOpen(true);
                navigate('/login');

              }}
            >
              Go to login
            </button> : null}
            {isLoginOpen ? <button
              onClick={handleLoginSubmit}
              id="login"
              className={styles.CheckNavButton}
            >
              Login with email
            </button> : null}
            {isLoginOpen ? <button
              id="go-to-login"
              className={styles.footerLink}
              onClick={() => {
                setError('');
                setIsLoginOpen(false);
                navigate('/signup');
              }}
            >
              Go to Sign Up
            </button> : null}
            {error && <p className={styles.error}>{error}</p>}
            {success && <p className={styles.success}>{success}</p>}
          </div>

          <div className={styles.seperator}>
            <div className={styles.seperatorLine} />
            <div className={styles.seperatorText}>OR</div>
            <div className={styles.seperatorLine} />
          </div>

          <div className={styles.socialLogin}>
            <Button
              size="medium"
              fullWidth
              className={styles.googleButton}
              onClick={handleGoogleLogin}
            >
              <GoogleIcon />
              Continue with Google
            </Button>
            <Button
              size="medium"
              fullWidth
              className={styles.linkedinButton}
              onClick={handleLinkedinLogin}
            >
              <LinkedInIcon />
              Continue with LinkedIn
            </Button>
          </div>
        </div>
      </div>
    </div >
  );
};
const CloseIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={styles.icon}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};
