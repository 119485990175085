import hero from '../../../../assets/insights/winningTechnicalResume/hero.png';

export const meta = {
  author: "",
  date: "2024-08-28",
  type: "Insights",
  title: "The Art of Crafting a Winning Technical Resume",
  subTitle: "",
  description: "In an era where artificial intelligence can generate code and chatbots can explain quantum computing, standing out in the tech job market has never been more challenging - or more crucial. Your resume is no longer just a list of jobs and skills.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/art-of-winning-technical-resume",
};