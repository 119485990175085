/* eslint-disable */
import axiosPackage from 'axios';
import { auth, session } from './configuration';
import { BASEPATH } from '../lib/constants';

/**
 * Requests
 */
class Requests {
  static client;

  axios;
  countryPath = 'us'
  constructor() {
    this.axios = axiosPackage.create({
      baseURL: process.env.REACT_APP_RESUME_SERVICES
      // baseURL: 'https://resume-services.mroads.com/resume-services'
    });
    // this.axios.defaults.withCredentials = true;
    const token = localStorage.getItem('token');
    if (token) {
      this.setBearerToken(token);
    }
    this.axios.defaults.timeout = 35000;
    this.setCountryPath();
  }

  setCountryPath = () => {
    if (BASEPATH.includes('/us1')) {
      this.countryPath = 'us1';
    } else if (BASEPATH.includes('/in1')) {
      this.countryPath = 'in1';
    } else if (BASEPATH.includes('/in')) {
      this.countryPath = 'in';
    } else {
      this.countryPath = 'us';
    }
  }

  setBearerToken = token => {
    this.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  };

  static getInstance = () => {
    if (!Requests.client) {
      Requests.client = new Requests();
    }
    return Requests.client;
  };

  google = (isPathFromScoreReport) => this.axios.get(
    auth.google
    + `?isFromScoreReport=${isPathFromScoreReport}`
    + `&countryPath=${this.countryPath}`
  )
    .then((response) => response.data);

  linkedin = (isPathFromScoreReport) => this.axios.get(
    auth.linkedin
    + `?isFromScoreReport=${isPathFromScoreReport}`
    + `&countryPath=${this.countryPath}`
  )
    .then((response) => response.data);

  signIn = email =>
    this.axios
      .post(auth.login, { emailAddress: email, countryPath: this.countryPath })
      .then(response => response.data);

  signUp = (emailAddress, phoneNumber, name, isPathFromScoreReport, sourcePath) => {
    console.log('signUp:', emailAddress, phoneNumber, name, isPathFromScoreReport, sourcePath);
    return this.axios
      .post(
        auth.signUp,
        { emailAddress, phoneNumber, name, countryPath: this.countryPath, isPathFromScoreReport, sourcePath },
        {
          // Override the default headers for this request
          headers: {
            Authorization: '' // Clear the Authorization header
          }
        }
      )
      .then(response => response.data);
  };

  getSession = () => this.axios.get(session).then(response => response.data);

  // signOut = () => this.axios.delete(auth.logout);
  signOut = async () => {
    return this.axios.delete(auth.logout).then(() => {
      localStorage.setItem('token', '');
      this.axios.defaults.headers.common.Authorization = '';
    });
  };

  checkout = () => {
    return this.axios
      .post(auth.createPaymentSession, {
        callBackUrl: auth.callbackUrl
      })
      .then(response => response.data);
  };
  validatePayment = sessionId => {
    return this.axios
      .post(auth.validatePayment, {
        sessionId: sessionId
      })
      .then(response => response.data);
  };

  validate = async token => {
    const config = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    };

    return this.axios
      .get(`${auth.validate} `, config)
      .then(response => response.data);
  };


  updateResumeBuiltStatus = paymentSessionId => {
    return this.axios
      .post(auth.updateResumeBuiltStatus, {
        sessionId: paymentSessionId,
        status: true
      })
      .then(response => response.data);
  };


  saveAIResumeResponse = data => {
    return this.axios
      .post(auth.saveUserHistory, data)
      .then(response => response.data);
  };

  saveAIResumeEditedResponse = data => {
    return this.axios
      .post(auth.saveAIResumeEditedResponse, data)
      .then(response => response.data);
  };

  saveAIResumeEditedUrl = data => {
    return this.axios
      .post(auth.saveAIResumeEditedUrl, data)
      .then(response => response.data);
  };

  fetchUserHistory = () => {
    return this.axios
      .get(auth.fetchUserHistory)
      .then(response => response.data);
  };
  saveUserFeedBack = data => {
    return this.axios
      .post(auth.saveUserFeedBack, data)
      .then(response => response.data);
  };

  createOrder = data => {
    return this.axios
      .get(auth.createOrder, data)
      .then(response => response.data);
  };

  paymentSuccess = data => {
    return this.axios
      .post(auth.paymentSuccess, data)
      .then(response => response.data);
  };

  paymentFailure = data => {
    return this.axios
      .post(auth.paymentFailure, data)
      .then(response => response.data);
  };


  uploadFilesToS3 = formData => {
    return this.axios.post(auth.uploadFilesToS3, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  saveResumeScore = formData => {
    return this.axios
      .post(auth.saveScore, { countryPath: this.countryPath, ...formData }, {
        // Override the default headers for this request
        headers: {
          Authorization: '' // Clear the Authorization header
        }
      })
      .then(response => response.data);
  };
  fetchScore = async (token) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token} `
      }
    };
    return this.axios
      .get(auth.fetchScore, config)
      .then(response => response.data);
  };

  saveEvent = data => {
    return this.axios
      .post(auth.saveEvent, data)
      .then(response => response.data);
  };

  getCoverLetter = data => {
    return this.axios
      .post(auth.getCoverLetter, data)
      .then(response => response.data);
  };

  getJobDescription = url => {
    return this.axios
      .get(url, {
        // Override the default headers for this request
        responseType: 'text',
        headers: {
          Authorization: '', // Clear the Authorization header
          'Access-Control-Allow-Origin': '*',
        }
      })
      .then(response => response.data);
  };

  saveCoverLetter = data => {
    return this.axios
      .post(auth.saveCoverLetter, data)
      .then(response => response.data);
  };

  unsubscribe = (email) => {
    return this.axios
      .post(`${auth.unsubscribe}?email=${email}`)
      .then(response => response.data);
  };
}

export default Requests;
