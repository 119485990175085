import React from 'react';
import { Navbar } from '../../component/Navbar';
import UserProfile from '../../component/Profile';
import Helmet from 'react-helmet';
import data from '../MetaData/data';
import Utility from '../../utils';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0
        };
    }
    componentDidMount() {
        Utility.trackEvent('profile', 'PAGE_VISIT');
    }
    render() {
        return (<div>
            <Helmet>
                <title>{data.profilePage.title}</title>
                {/* <meta name="title" content={data.historyPage.metaTitle} />
                <meta name="description" content={data.historyPage.metaDescription} /> */}
            </Helmet>
            <Navbar></Navbar>
            <UserProfile />
        </div>);
    }
}

export default Profile;
