export default {
    jobDescriptions: [
        {
            name: 'Java Developer',
            description: `
Description:
-----------------

-Backend Java Developer

-This position will be critical in helping us build our application using Java, Spring and modern software design principles.

-This role will have three components:

Help us establish backend design patterns
Help us enhance and grow our existing components
Help us support, troubleshoot, and manage our application design and integrations

Technologies We Use:
------------------------------
Java
DB2, Amazon RDS
AWS
JavaScript/NodeJS


Responsibilities:
----------------------
- Be a team player, contribute to our culture, invent new ways to address industry needs, criticize constructively, and help create a world-changing company

- Write automated tests via TDD to foster effective, reusable, and flexible code

- Build logic in isolated, independent, reusable components

- Ability to tackle problems, independently and as part of a team

- Debug and troubleshoot integration with new API’s

- You know and care about modern software development and design tools and techniques

- Make changes to and create data models for persisting application data

Required:
-------------
- A minimum of three years of professional web development or programming experience is required
- Passionate about creating high-quality, flexible code
- Strong java skills (2+ years experience), including understanding of Java APIs, such as Spring, Hibernate, log4j
- Strong understanding of REST API principles and best practices
- Proficient with Git
- Familiarity with data structures and the ability to pick and use the right one for the task.
- Experience with enterprise databases (Oracle, MS SQL Server, Postgres, MySQL, or DB2)
- Experience with using APIs including data CRUD operations, JSON data structures, authentication, and documentation
- Ability to meet deadlines.
- Ability to work independently and within a team of diverse skills. Ability to receive and provide constructive feedback.
    
Major Plus:
--------------
- Modern Java experience
- Basic understanding of design patterns
- Proficiency in building modular applications
- Knowledgeable on Microservices and/or Service-Oriented Architecture
- Knowledgeable on security principles (OWASP)
- Experience with AWS
- Experience with Docker
- Experience with Kubernetes`,
        },
        {
            name: 'Business Systems Analyst',
            description: `
JOB DESCRIPTION:
----------------------------
-Collaborate with business partners to analyze and understand business problem/need
-Leverage thorough understanding of business and system processes to make recommendations and propose technical/non-technical solutions to meet business requirements
-Collaborate with business partners to define project objectives and scope
-Document current state business process (“as – is” process map) and future state business process (“to-be” process map)
-Facilitate requirements gathering sessions with business stakeholders and key technical resources
-Use a variety of industry standard/best practices techniques to elicit requirements from the Business
-Document, categorize and prioritize business, user and system requirements
-Validate requirements for quality attributes (e.g., clarity, conciseness, correctness, completeness, testability and traceability)

Creating and Executing Test Scenarios
--------------------------------------------------
-Ensure QA, downstream teams, vendors, and business users create and finalize test plan based upon finalized requirements
-Ensure QA scenarios are complete
-Ensure QA executes testing cycles, documents results, and obtains testing sign-off documentation

Production and Operational Support
------------------------------------------------
-Review designs, test scenarios and results conducted and documented by outsourced service provider to ensure TFS business practices are represented
-Ensure that proactive, adaptive, corrective and preventive changes conducted by outsourced service provider are functionally sound and consistent with business needs
-Ensure cross-team impacts and dependencies of production activities have been addressed by outsourced service provider
-Assist team lead/functional manager in outsourced service provider oversight, SLA tracking, and Scorecard reporting
-Provide subject matter expertise to support outsourced service provider as needed.  Ensure that business practices are represented in support scripts and incident management activates. 
-Proactively identify and suggest improvement areas for Operational Support.
-Support internal/external audit requests

    Deliver End User Demos and Facilitate Training
----------------------------------------------------------------
-Provide system demos to end users
-Provide training and guidance to new department team members to facilitate knowledge sharing and cross training
-Help coordinate and facilitate end user training sessions

Requirements:
-------------------
“Must Have”

-Strong understanding of all aspects of the Software Development Life Cycle (SDLC)
-Knowledge and understanding of industry standard/best practices requirements gathering methodologies
-Knowledge and understanding of Information Technology systems and software development
-Strong understanding of testing methodology
-Ability to understand technical architecture design of business applications
-Expertise in usage of business analysis tools such as, Microsoft office suite, visualization, and requirements management tools to interpret, condense, and communicate complex graphical and statistical information 
-Working knowledge of requirements management tools
-Ability to work with limited supervision, having significant latitude for independent judgement
-High-Level communication skills to collaborate, influence, and persuade others
-Strong verbal and written communication skills with the ability to create and present concepts and recommendations to senior management
-Ability to adapt to rapidly changing technology and apply it to business needs

“Advantage”
Agile/Scrum certification`,
        },
        {
            name: 'Data scientist',
            description: `
We are looking for data scientists to be based in Hyderabad, India with 3-5 years of experience in statistical modelling and data science.


Responsibilities Include
            
            
As part of the Data Science team, work on creating opportunities to improve company operations for speed and efficiency. 
-Collaborate with different teams, engineers, program managers, analysts to define right analytical framework and identify improvement opportunities
-Design, experiment, and scale data driven solutions
-Take initiative and recommend improvements to existing software architecture
-Design and develop solutions across the enterprise
-Create and maintain bug free code, Unit tests, Integration tests
-Code reviews and testing
            
            
Your Expertise
-Senior level experience working with Python, AI, ML, DL and model training
-Data analysis and structuring experience is preferred
-Deploy and monitor data science solutions
-Strong experience in building NLP and deep learning applications
-Knowledge of SQL and NoSQL databases; experience in working with GitHub
-Ability to work independently and multi-task effectively
-Good understanding of AWS or other cloud platforms
-Experience in AWS-machine learning tools like sagemaker,Rekognition,Translate is plus
            
            
Basic Qualifications
-Bachelors or Masters in Computer Science, Operational Research, Statistics or related quantitative field
-3-5 years of hands-on experience in data driven analytics and statistical modelling in Python, R  or similar platform
-Proficiency in model development
-Strong CS fundamentals in data structures, problem solving, algorithm design and complexity analysis 
-Superior verbal and written communication and presentation skills, ability to convey rigorous mathematical concepts and considerations to non-experts
            
             
            
Mandatory Skills - Python, Machine Learning and Deep Learning Algorithms, NLP,SQL, Basic CS Fundamentals
Preferred Skills - AWS, AWS-ML tools,NoSQL DB, Computer Vision`
        },
        {
            name: 'QA Automation ',
            description: `
Job Description:
--------------------
As a QA Automation Engineer, the individual will be responsible for building and maintaining testing automation scripts and frameworks using products like Selenium, Test Complete. The individual should be proficient in evaluating quality assurance test requirements to determine scope to be covered by the automated testing suite, creating and maintaining automated test scripts and executing automated test suites. The automation engineer should be highly proficient and must have a good understanding of best practices in software development following Agile methodology.

Required skills :
-----------------
-Analyzing the requirements from clients 
-Arranging Test Environment to execute the test cases 
-Exposure to Test tools such as Qtest and JIRA
-Analyzing , creating & executing Test Cases 
-Defect Reporting and Tracking 
-Very good in UI and API automation with Selenium Web Driver
-User Acceptance Testing
-Exposure to Automation framework creation & Updation 
-CI/CD tool - Jenkins
-Agile
-Version control - GIT
-Very good verbal and written communication skills 
-Good Debugging skills for Automation scripts

Mandatory /Must skills : 
----------------------
-Web application(UI) & WebService(API)testing experience with Selenium WebDriver , CoreJava 
-Version Control - GIT
-Jenkins
-Automation Framework creation & updation knowledge`
        },
        {
            name: 'AWS/Cloud Engineer',
            description: `
Description:
-----------------
RESPONSIBILITIES:
----------------------------
- Create AWS instances using both internal automation tools and vendor specific tools
- Provision and configure AWS storage services
- Recognize automation opportunities and choose the appropriate scripting language
- Perform security management tasks to include managing encryption keys and troubleshooting AWS security groups
- Create/Troubleshoot Ansible playbooks
- Define and implement monitoring and alerting
- Automate all Level 1 support tasks
- Respond to Incident tickets and work to resolve incidents in AWS
- Utilize ITIL methodologies such as Change Management, Configuration Management, etc.
- Understand Agile/Scrum methodologies and terminology
- Maintain a routine patching schedule and utilize patch deployment tools for AWS instances


REQUIRED SKILLS:
----------------------------
- Experience creating AWS systems using standard and customized AMIs
- AWS expertise in provisioning, configuring AWS storage services such as
- S3
- RDS
- EBS
- Shell Scripting Experience – Bash / Python / PowerShell
- AWS expertise with Encryption and Encryption Key management using KMS or equivalent products
- AWS expertise with Cloud Formation or other provisioning automation products
- Expertise with Ansible
- Expertise with security concepts around Security group management
- Expertise with Monitoring and Alerting platforms such that they can create and adjust necessary monitoring scripts or services
- Expertise with AWS RDS and Aurora offerings such as instance:
- Deployment
- Encryption
- Configuration
- Management
- Experience with ITIL methodologies such as Change Management
- Understand Agile/Scrum methodologies and terminology
            

PREFERRED SKILLS:
--------------------------------

- Experience with OpenShift, Kubernetes
- Experience with managing Infrastructure as code environments at AWS
- Experience working in Agile and DevOps environments
- Experience working in an Agile Environment
- Familiarity with DataDog and SCOM
- Experience with SCCM
- Experience with AWS ECS (Amazon Container Services)
- Apache / Java expertise / Couchbase / MySQL / Aurora`,
        },
        {
            name: 'Front End UI Developer',
            description: `      
Job Description :
----------------------

Our organization is looking for a Front-End Developer who can develop effective user interfaces on the company website and mobile apps released to the public and Windows applications for internal or our customers use. The successful candidate will work closely with other software developers to design or update the front end of our various applications and make them more efficient and maintain consistency across them. The front-end developer will also be responsible for creating innovative solutions to front end issues to help make our applications more visually appealing and enhance usability.

Responsibilities:
----------------------

- Determine the structure and design of our front-end applications on various devices including mobile devices.
- Design a framework for creating user apps that grows with the needs of the company
- Utilize a variety of markup languages to write web pages and mobile app pages.
- Implement responsible web design principles to ensure that our company website renders well across multiple devices
- Develop features to enhance the user experience.
- Monitor website performance, watch for site usability problems and rectify the issues
- Build reusable code for future use and shared among the front-end applications.
- Optimize web pages and mobile pages for maximum speed and scalability.
- Maintain brand consistency throughout design.
- Assist in the development of applications and features that will be put on the website and in internal functions as well

Requirements:
------------------
- Hands-on web development experience required.
- Hands-on mobile app development experience required.
- Hands-on Windows application development experience required.
- Strong experience in Visual Studio and source version control.
- Strong experience in one or more of the programming languages: C#, Java, AngularJS, NodeJS, Python, PHP, etc., preferably C#.
- Strong experience in OOP.
- Strong experience in most of these technologies: MVC, MVVM, Xamarin, WinForm, WPF, etc.
- Proficiency with HTML, CSS, JavaScript, jQuery, and bootstrap; Understanding of server-side CSS.
- Experience with graphic design applications such as Adobe Illustrator.
- Experience with responsive and adaptive design.
- Keen on putting ideas into programming codes.
- Good problem-solving skills.
- Excellent verbal communication skills.
- Good interpersonal skills.`,
        },
    ]
}