import React from 'react'
import axios from 'axios';
import GooglePicker from 'react-google-picker';
import Loader from 'react-loader-spinner';
import creds from '../../credentials.json';
import styles from './styles.module.css'
import constants from './data'
import driveIcon from '../../assets/drive/Google Drive.png';
import config from '../../config';

const SCOPE = ['https://www.googleapis.com/auth/drive.file']

const httpClient = axios.create();

const GooglePickerAPI = props => {
    const { onChange, onError, inProgress, isUploadingInProgress } = props
    const DEVELOPER_KEY = creds.developer_key;
    const CLIENT_ID = config.googleClientId;
    const PROJECT_NO = creds.project_no;
    console.log(config);
    return (
        <GooglePicker clientId={CLIENT_ID}
            developerKey={DEVELOPER_KEY}
            scope={SCOPE}
            onChange={data => console.log('on change:', data)}
            onAuthFailed={data => console.log('on auth failed:', data)}
            navHidden={true}
            authImmediate={false}
            viewId={'DOCS'}
            disabled={isUploadingInProgress}
            createPicker={(google, oauthToken) => {
                const googleViewId = google.picker.ViewId.DOCS;
                const docsView = new google.picker.View(googleViewId)
                    .setMimeTypes('application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,text/plain,text/rtf')

                const picker = new google.picker.PickerBuilder()
                    .addView(docsView)
                    .setOAuthToken(oauthToken)
                    .setAppId(PROJECT_NO)
                    .addView(new google.picker.DocsUploadView())
                    .setCallback((data) => {
                        if (data.action == google.picker.Action.PICKED) {
                            inProgress(true);
                            const fileId = data.docs[0].id;
                            const config = {
                                headers: {
                                    "Authorization": `Bearer ${oauthToken}`,
                                }
                            }
                            httpClient
                                .get(`https://www.googleapis.com/drive/v2/files/${fileId}?key=${DEVELOPER_KEY}`, config)
                                .then(response => {
                                    let gDoxBlob = null;
                                    const xhr = new XMLHttpRequest();
                                    xhr.open("GET", response.data.downloadUrl); //file.url

                                    xhr.setRequestHeader('Authorization', 'Bearer ' + oauthToken);

                                    xhr.responseType = "blob";
                                    xhr.onload = function () {
                                        gDoxBlob = xhr.response;
                                        const uploadFile = new File([gDoxBlob], response.data.title, { type: response.data.mimeType })
                                        onChange({ target: { files: [uploadFile] } })
                                    }
                                    xhr.onerror = function () {
                                        console.info('error while downloading drive file -', xhr.response)
                                        onError(constants.errorDownloadingDriveFile)
                                    }
                                    xhr.send();
                                })
                                .catch(error => {
                                    console.info('error', error.response);
                                    onError(constants.errorFetchingFileMetaData);
                                    inProgress(false);
                                })
                        }
                    });
                window.innerWidth < 550 ? picker.setSize(566, 400) : null;
                picker.build().setVisible(true);
            }}
        >
            <div className={styles.pickButton}>
                <span>
                    <img src={driveIcon} alt="" className={styles.driveIconStyle} />
                    {!isUploadingInProgress ?
                        <React.Fragment>Upload from drive</React.Fragment>
                        : <React.Fragment>
                            Loading File &nbsp;
                            <Loader height={20} width={20} type="Ball-Triangle" color="#000000" />
                        </React.Fragment>
                    }
                </span>
                <div className="google"></div>
            </div>
        </GooglePicker>
    );
}

export default GooglePickerAPI;