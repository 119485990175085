import React from 'react';
import Loader from 'react-loader-spinner';
import styles from './styles.module.css';
import ToolTip from '../CustomTooltip/src';
import infoIcon from '../../assets/report/info.png'

class Card extends React.Component {
    state = {
        renderField: false,
        isTooltipActive: false,
    }
    componentDidMount() {
        const { timeOut } = this.props;
        setTimeout(() => this.setState({ renderField: true }), timeOut);
    }

    componentWillReceiveProps(nextProps) {
        const { id, timeOut } = nextProps;
        if (this.props.id !== id) {
            this.setState({ renderField: false }, () => {
                setTimeout(() => this.setState({ renderField: true }), timeOut);
            })
        }
    }

    showTooltip() {
        this.setState(prevState => ({ isTooltipActive: !prevState.isTooltipActive }))
    }
    hideTooltip() {
        this.setState({ isTooltipActive: false })
    }

    render() {
        const { renderField } = this.state;
        const { data, id, renderItem } = this.props;
        const containerStyles = [this.props.ContainerStyle, styles.containerStyle];
        const loaderHeight = window.innerWidth <= 414 ? '20' : '40';
        const cardName = data.name === 'skill_match' ? 'Skill Match' : data.name;
        let style = {
            style: {
                width: window.innerWidth <= 500 ? '75%' : window.innerWidth <= 850 ? '60%' : '40%',
                background: '#1a3149',
                padding: window.innerWidth <= 414 ? 10 : 15,
                boxShadow: '0 2px 10px 0 rgba(224,220,220,0.50)',
                zIndex: '100000',
                transition: 'none',
            },
            arrowStyle: {
                color: '#1a3149',
                borderColor: false,
                transition: 'none',
            }
        }
        return (
            <React.Fragment>
                <div id={id} onMouseLeave={this.hideTooltip.bind(this)} className={containerStyles.join(' ')}>
                    {renderField ?
                        <div className={styles.ContentWrapper}>
                            <div className={styles.title}>{cardName}</div>
                            <div style={{ backgroundColor: data.cardColor }} className={styles.renderItemwrap}>
                                <img title="info" role="button" src={infoIcon} alt="" onClick={this.showTooltip.bind(this)} onKeyPress={this.showTooltip.bind(this)} className={styles.infoIcon} tabIndex="0" />
                                {renderItem && renderItem()}
                            </div>
                        </div> :
                        <div className={styles.placeholderContainer}>
                            <div className={styles.placeholder1} />
                            <div className={styles.placeholder2} />
                            <div className={styles.LoaderWrapper}><Loader type="TailSpin" color="green" height={loaderHeight} /></div>
                        </div>
                    }
                </div>
                <ToolTip active={this.state.isTooltipActive} position="bottom" arrow="center" parent={`#${id}`} style={style} className={styles.ToolTipCustom}>
                    <div className={styles.description}>{data.description}</div>
                </ToolTip>
            </React.Fragment>
        );
    }
}

export default Card;