import React from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import { Line } from 'rc-progress';
import CountUp from 'react-countup';
import smiley1Fill from '../../assets/feedback/Group 12_filled.png';
import smiley1Blur from '../../assets/feedback/Group 12_light.png';
import smiley2Fill from '../../assets/feedback/Group 13_filled.png';
import smiley2Blur from '../../assets/feedback/Group 13_light.png';
import smiley3Fill from '../../assets/feedback/Group 14_filled.png';
import smiley3Blur from '../../assets/feedback/Group 1524_light.png';
import smiley4Fill from '../../assets/feedback/Group 15_filled.png';
import smiley4Blur from '../../assets/feedback/Group 15_light.png';
import smiley5Fill from '../../assets/feedback/Group 16_filled.png';
import smiley5Blur from '../../assets/feedback/Group 16_light.png';
import feedbackHam from '../../assets/feedback/Feedback.png';
import closeCross from '../../assets/feedback/closeCross.png';
import faceBook from '../../assets/jdToResume/facebook.png';
import twitter from '../../assets/jdToResume/twitter.png';
import linkedin from '../../assets/jdToResume/linkedin.png';
import styles from './styles.module.css'
import config from '../../config';
// import { updateFeedBack } from '../../store/actions/cache';

const httpClient = axios.create();

httpClient.defaults.timeout = 10000;

class NewFeedBack extends React.Component {
    feedBackRef;
    smileys = [
        {
            img: smiley1Blur,
            onClick: index => {
                this.props.onSelectSmileys();
                this.setState({ smiley1: smiley1Fill, ratings: index * 20 })
            },
            onMouseOver: index => this.setState({ smiley1: smiley1Fill, ratings: index * 20 }),
            onFocus: index => this.setState({ smiley1: smiley1Fill, ratings: index * 20 }),
            onMouseLeave: () => this.setState({ smiley1: smiley1Blur, ratings: 0 })
        },
        {
            img: smiley2Blur,
            onClick: index => {
                this.props.onSelectSmileys();
                this.setState({ smiley2: smiley2Fill, ratings: index * 20 })
            },
            onMouseOver: index => this.setState({ smiley2: smiley2Fill, ratings: index * 20 }),
            onFocus: index => this.setState({ smiley2: smiley2Fill, ratings: index * 20 }),
            onMouseLeave: () => this.setState({ smiley2: smiley2Blur, ratings: 0 })
        },
        {
            img: smiley3Blur,
            onClick: index => {
                this.props.onSelectSmileys();
                this.setState({ smiley3: smiley3Fill, ratings: index * 20 })
            },
            onMouseOver: index => this.setState({ smiley3: smiley3Fill, ratings: index * 20 }),
            onFocus: index => this.setState({ smiley3: smiley3Fill, ratings: index * 20 }),
            onMouseLeave: () => this.setState({ smiley3: smiley3Blur, ratings: 0 })
        },
        {
            img: smiley4Blur,
            onClick: index => {
                this.props.onSelectSmileys();
                this.setState({ smiley4: smiley4Fill, ratings: index * 20 })
            },
            onMouseOver: index => this.setState({ smiley4: smiley4Fill, ratings: index * 20 }),
            onFocus: index => this.setState({ smiley4: smiley4Fill, ratings: index * 20 }),
            onMouseLeave: () => this.setState({ smiley4: smiley4Blur, ratings: 0 })
        },
        {
            img: smiley5Blur,
            onClick: index => {
                this.props.onSelectSmileys();
                this.setState({ smiley5: smiley5Fill, ratings: index * 20 })
            },
            onMouseOver: index => this.setState({ smiley5: smiley5Fill, ratings: index * 20 }),
            onFocus: index => this.setState({ smiley5: smiley5Fill, ratings: index * 20 }),
            onMouseLeave: () => this.setState({ smiley5: smiley5Blur, ratings: 0 })
        }
    ]
    state = {
        smiley1: smiley1Blur,
        smiley2: smiley2Blur,
        smiley3: smiley3Blur,
        smiley4: smiley4Blur,
        smiley5: smiley5Blur,
        ratings: 0,
        comments: '',
        adjustForKeyboard: false,
    }

    componentDidMount() {
        const FeedBackContainerDiv = document.getElementById('FeedBackContainer');
        const FeedParentDiv = document.getElementById('FeedParentDiv');
        this.feedBackRef = FeedParentDiv;
        if (FeedParentDiv && FeedBackContainerDiv) {
            FeedParentDiv.style.transform = `translateX(${FeedBackContainerDiv.clientWidth}px)`;
        }
        window.addEventListener('resize', () => {
            const { isOpen } = this.props;
            if (!isOpen) {
                if (FeedParentDiv) {
                    FeedParentDiv.style.transform = `translateX(${FeedBackContainerDiv.clientWidth}px)`;
                }
            } else {
                if (FeedParentDiv) {
                    FeedParentDiv.style.transform = `translateX(0)`;
                }
            }
            this.adjustfeedbackForm()
        })
    }

    adjustfeedbackForm = () => {
        if (window.innerWidth <= 550 && window.innerHeight <= 340) {
            this.setState({ adjustForKeyboard: true })
        } else {
            this.setState({ adjustForKeyboard: false })
        }
    }

    formatValue = (data) => {
        return `${data.toFixed(0)}%`;
    }

    onChangeText = e => {
        this.setState({ comments: e.target.value });
    }

    onToggle = () => {
        const { isOpen, onToggle } = this.props;
        if (!isOpen) {
            this.setState({
                smiley1: smiley1Blur,
                smiley2: smiley2Blur,
                smiley3: smiley3Blur,
                smiley4: smiley4Blur,
                smiley5: smiley5Blur,
                ratings: 0,
                comments: '',
            })
        }
        onToggle();
    }

    onSubmitClicked = () => {
        const { ratings, comments } = this.state;
        const { reduxProps, onToggle } = this.props;
        const { cache: { userId } } = reduxProps;
        const url = config.feedBack;
        const requestBody = {
            Uid: userId,
            rating: ratings,
            application: 'panna_score_api',
            comment: comments || 'NULL',
        }
        onToggle();
        httpClient
            .post(url, requestBody)
            .then(response => {
                // dispatchUpdateFeedback(true);
                console.info('response for feed back', response);
            })
            .catch(error => {
                // eslint-disable-next-line
                console.info('error while sending feedback', error);
            });
    }

    render() {
        const { smiley1, smiley2, smiley3, smiley4, smiley5, ratings, comments, adjustForKeyboard } = this.state;
        const smileysImages = [smiley1, smiley2, smiley3, smiley4, smiley5];
        const { isRated, isOpen } = this.props;
        return (
            <div id="FeedParentDiv" className={styles.contentContainer}>
                <div className={styles.feedBackToggleWrap}>
                    {isOpen ?
                        <div title="close" role="button" className={styles.toggleButton} onClick={this.onToggle} onKeyPress={this.onToggle} tabIndex="0">
                            <img src={closeCross} alt="" className={styles.closeCrossImg} />
                        </div> :
                        <div title="feedback" role="button" className={styles.toggleButton} onClick={this.onToggle} onKeyPress={this.onToggle} tabIndex="0">
                            <img src={feedbackHam} alt="" className={styles.feedBackImg} />
                        </div>
                    }
                    <div className={styles.emptySpace}></div>
                </div>
                <div id="FeedBackContainer" className={styles.FeedBackContainer}>
                    {!isRated ? (
                        <React.Fragment>
                            <div className={styles.ratingLabel}>How does our product meet your needs?</div>
                            <div className={styles.ratingWrap}>
                                <div className={styles.smileyWrap}>
                                    {
                                        this.smileys.map((smiley, index) =>
                                            <img
                                                key={`smiley-${index}`}
                                                role="button"
                                                src={smileysImages[index]}
                                                alt=""
                                                className={styles.smileyImg}
                                                onClick={() => smiley.onClick(index + 1)}
                                                onKeyPress={() => smiley.onClick(index + 1)}
                                                onMouseOver={() => smiley.onMouseOver(index + 1)}
                                                onFocus={() => smiley.onFocus(index + 1)}
                                                onMouseLeave={smiley.onMouseLeave}
                                                tabIndex="0" />
                                        )
                                    }
                                </div>
                                <Line percent={ratings} strokeWidth="2" strokeColor="#fd5656" trailWidth="2" />
                                <CountUp delay={0.5} end={ratings} duration={1} formattingFn={value => this.formatValue(value)} />
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            {!adjustForKeyboard ? <div className={styles.thankYoutext}>Thank You!</div> : null}
                            <div className={styles.commentWrap}>
                                {!adjustForKeyboard ? <div className={styles.commentLable}>Do you have any other comments about how we can improve our website?</div> : null}
                                <textarea
                                    placeholder="type here..."
                                    className={styles.feedBackTextArea}
                                    onChange={this.onChangeText} />
                                <div className={styles.socialImgWrap}>
                                    <a href="https://www.facebook.com/sharer/sharer.php?u=https%3A//bit.ly/34TlPsh" target="_blank">
                                        <img src={faceBook} alt="" className={styles.shareImg} />
                                    </a>
                                    <a href="https://www.linkedin.com/shareArticle?mini=true&url=https%3A//bit.ly/34TlPsh&title=Pa%C3%B1%C3%B1%C3%A3%20Resume%20Score&summary=&source=" target="_blank" rel="noopener noreferrer">
                                        <img src={linkedin} alt="" className={styles.shareImg} />
                                    </a>
                                    <a href="https://twitter.com/intent/tweet?text=https%3A//bit.ly/34TlPsh" target="_blank" rel="noopener noreferrer">
                                        <img src={twitter} alt="" className={styles.shareImg} />
                                    </a>
                                </div>
                            </div>
                            <button id="SubmitFeedback" className={styles.buttonContactUs} onClick={this.onSubmitClicked}>Submit</button>
                        </React.Fragment>
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = reduxProps => ({
    reduxProps,
});
// const mapDispatchToProps = dispatch => ({
//     dispatchUpdateFeedback: reportData => {
//         dispatch(updateFeedBack(reportData));
//     },
// });

export default connect(mapStateToProps)(NewFeedBack);