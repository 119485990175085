import React from 'react'
import styles from './styles.module.css'
import Button from '../Button'

const CustomErrorPopup = props => {
    const { heading, title, onCancel, onOk } = props;
    return (
        <div className={styles.saveJdContainer}>
            {heading ? <div className={styles.headingStyle}>{heading}</div> : null}
            <div className={styles.contentWrap}>
                <div className={styles.labelStyle}>{title}</div>
                <div className={styles.buttonWrap}>
                    {onCancel ? <Button text="Cancel" buttonStyle={styles.cancelButtonStyle} onClick={onCancel} /> : null}
                    <Button text="Ok" buttonStyle={styles.saveButtonStyle} onClick={onOk} />
                </div>
            </div>
        </div>
    );
}

export default CustomErrorPopup;