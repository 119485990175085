import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's competitive job market, your resume is your ticket to getting noticed. But with so many 
        potential sections to include, how do you know which ones are essential and which are optional? And 
        more importantly, how do you organize them for maximum impact? Don't worry - we've got you covered! 
        This comprehensive guide will walk you through everything you need to know about resume sections, 
        from the must-haves to the nice-to-haves, and how to arrange them like a pro.
      </div>

      <div className={styles.title}>
        The Golden Rule of Resume Writing: Brevity is the Key
      </div>

      <div className={styles.text}>
        Before we dive into the nitty-gritty, remember this: “Less is more”. A cluttered resume can 
        overwhelm recruiters, who spend an average of just 7.4 seconds scanning each resume, according to a 
        study by Ladders. So, focus on including relevant, impactful information that showcases your 
        qualifications for the specific job you're applying for.
      </div>

      <div className={styles.title}>
        Essential Resume Sections
      </div>

      <div className={styles.text}>
        Every resume should include these five key sections:
      </div>

      <div className={styles.listWithNestedContent}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Personal Information</strong><br />
            <strong>Purpose: </strong>To introduce yourself and provide contact details.

            <div className={styles.nestedListTitle}><strong>What to include:</strong></div>
            <ul className={styles.nestedList}>
              <li>Full name</li>
              <li>Phone number (with country code if applying internationally)</li>
              <li>Professional email address</li>
              <li>Location (city and state/country)</li>
            </ul>
            <br />

            <strong>Optional: </strong>LinkedIn profile, portfolio website (if relevant)<br />
            <strong>Pro tip: </strong>Use a professional email address. A study by TopResume found that 76% 
            of resumes are rejected due to unprofessional email addresses. <br />
          </li>

          <li className={styles.listItem}>
            <strong>Resume Summary or Objective</strong><br />
            <strong>Purpose: </strong> To provide a brief overview of your qualifications or career goals.

            <div className={styles.nestedListTitle}><strong>When to use:</strong></div>
            <ul className={styles.nestedList}>
              <li>Summary: For experienced professionals</li>
              <li>Objective: For students, recent graduates, or career changers</li>
            </ul>
            <br />

            <strong>Pro tip: </strong> Tailor this section to the job description, incorporating relevant 
            keywords. According to JobScan, resumes with high keyword matches are 8 times more likely 
            to get an interview.
          </li>

          <li className={styles.listItem}>
            <strong>Work Experience</strong><br />
            <strong>Purpose: </strong> To showcase your professional history and achievements.

            <div className={styles.nestedListTitle}><strong>What to include:</strong></div>
            <ul className={styles.nestedList}>
              <li>Company name and location</li>
              <li>Your job title</li>
              <li>Employment dates</li>
              <li>3-5 bullet points highlighting key achievements</li>
            </ul>
            <br />

            <strong>Pro tip: </strong> Use action verbs and quantify your achievements. For example, 
            "Increased sales by 30% over 6 months" is more impactful than "Responsible for increasing sales."
          </li>

          <li className={styles.listItem}>
            <strong>Education</strong><br />
            <strong>Purpose: </strong> To demonstrate your academic background.

            <div className={styles.nestedListTitle}><strong>What to include:</strong></div>
            <ul className={styles.nestedList}>
              <li>Name and location of institution</li>
              <li>Degree and field of study</li>
              <li>Graduation date (or expected graduation date)</li>
            </ul>
            <br />

            <strong>Pro tip: </strong> If you're a recent graduate, you can expand this section to include 
            relevant coursework, academic awards, and your GPA (if it's 3.5 or higher).
          </li>

          <li className={styles.listItem}>
            <strong>Skills</strong><br />
            <strong>Purpose: </strong> To highlight your relevant abilities and expertise.

            <div className={styles.nestedListTitle}><strong>What to include:</strong></div>
            <ul className={styles.nestedList}>
              <li>Hard skills (technical abilities)</li>
              <li>Soft skills (interpersonal abilities)</li>
            </ul>
            <br />

            <strong>Pro tip: </strong> Divide your skills into categories for better organization. For 
            example, "Technical Skills," "Language Skills," and "Interpersonal Skills."
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Optional Resume Sections
      </div>

      <div className={styles.text}>
        While the essential sections form the backbone of your resume, optional sections can add that extra 
        oomph to make you stand out. Let's dive into these sections and see how they can elevate your resume 
        game:
      </div>

      <div className={styles.listWithNestedContent}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Achievements and Awards</strong><br />
            <strong>Why include it: </strong>Awards demonstrate recognition in your field and can set you 
            apart from other candidates.
            <br />
            <br />
            <strong>How to include it: </strong>List awards relevant to your target job or industry. Include 
            the name of the award, the awarding organization, and the year received.<br />

            <div className={styles.highlight}>
              <strong>Example</strong>
              <ul className={styles.highlightList}>
                <li>Employee of the Year, TechStart Inc., 2022</li>
                <li>Best Digital Marketing Campaign, Marketing Association of America, 2021</li>
              </ul>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Certifications and Licenses</strong><br />
            <strong>Why include it: </strong>Certifications show your commitment to professional development 
            and can be especially valuable in technical fields.
            <br />
            <br />
            <strong>How to include it: </strong>List certifications in reverse chronological order, including the 
            name, issuing organization, and date of obtainment.
            <br />

            <div className={styles.highlight}>
              <strong>Example</strong>
              <ul className={styles.highlightList}>
                <li>Google Analytics Individual Qualification, Google, 2023</li>
                <li>Project Management Professional (PMP), Project Management Institute, 2022</li>
              </ul>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Projects</strong><br />
            <strong>Why include it: </strong>Projects showcase your practical skills and initiative, 
            especially valuable for recent graduates or career changers.
            <br />

            <div className={styles.nestedListTitle}>
              <strong>How to include it:</strong>
            </div>

            <ul className={styles.nestedList}>
              <li>List relevant projects, whether personal, academic, or professional</li>
              <li>Briefly describe each project and your role</li>
              <li>Highlight key technologies or skills used</li>
              <li>Include links to project websites or GitHub repositories if applicable</li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example</strong><br />
              E-commerce Website Redesign
              <ul className={styles.highlightList}>
                <li>Led a team of 3 to redesign an e-commerce platform, increasing user engagement by 40%</li>
                <li>Technologies used: React.js, Node.js, MongoDB</li>
                <li>GitHub: github.com/yourusername/ecommerce-redesign</li>
              </ul>
              <br />
              
              Machine Learning Stock Predictor
              <ul className={styles.highlightList}>
                <li>Developed an AI model to predict stock prices with 85% accuracy</li>
                <li>Technologies used: Python, TensorFlow, pandas</li>
                <li>Project link: mlstockpredictor.com</li>
              </ul>
              <br />

              <strong>Pro Tip: </strong>Focus on projects that are relevant to the job you're applying for 
              and demonstrate skills mentioned in the job description.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Social Media</strong><br />
            <strong>Why include it: </strong> Relevant social media profiles can showcase your professional 
            brand and industry engagement.
            <br />
            <br />
            <strong>How to include it: </strong>Include links to professional profiles like LinkedIn or 
            industry-specific platforms. For creative fields, consider adding links to portfolios on 
            platforms like Behance or GitHub.
            <br />

            <div className={styles.highlight}>
              <strong>Example</strong>
              <div>Professional Profiles</div>
              <div>LinkedIn: linkedin.com/in/yourname</div>
              <div>GitHub: github.com/yourusername</div>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Publications</strong><br />
            <strong>Why include it: </strong> Publications demonstrate your expertise and contributions to 
            your field, particularly important in academic or research-intensive roles.
            <br />
            <br />
            <strong>How to include it: </strong>
            <br />
            <ul className={styles.nestedList}>
              <li>List publications in reverse chronological order</li>
              <li>Use the citation style appropriate for your field (e.g., APA, MLA, Chicago)</li>
              <li>Include DOI or URL if available</li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example</strong>
              <div>Smith, J., & Johnson, A. (2022). The Impact of AI on Modern Marketing Strategies.<br />
              Journal of Digital Marketing, 15(2), 112-128. https://doi.org/10.1234/jdm.2022.15.2.112</div>
              <br />
              <div>Johnson, A. (2021). Blockchain Technology: Revolutionizing Supply Chain Management.<br />
              In D. Brown (Ed.), Emerging Technologies in Business (pp. 78-95). TechPress.</div>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>References</strong><br />
            <strong>Why include it: </strong> While "References available upon request" used to be a resume 
            staple, including actual references can demonstrate confidence and preparedness.
            <br />

            <div className={styles.nestedListTitle}>
              <strong>How to include it:</strong>
            </div>

            <ul className={styles.nestedList}>
              <li>List 2-3 professional references</li>
              <li>Include their name, title, company, and contact information</li>
              <li>Always ask permission before listing someone as a reference</li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example</strong><br />
              <ol className={styles.highlightOrderedList}>
                <li>
                  <div>Jane Smith</div>
                  <div>Marketing Director, TechCorp Inc.</div>
                  <div>jsmith@techcorp.com | (555) 123-4567</div>
                </li>
                <br />
                <li>
                  <div>Dr. John Doe</div>
                  <div>Professor of Computer Science, State University</div>
                  <div>jdoe@stateuniv.edu | (555) 987-6543</div>
                </li>
              </ol>
              <br />

              <strong>Pro Tip: </strong>Only include references if they're specifically requested in the job 
              posting or if you have particularly impressive references that could sway a hiring decision.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Volunteering</strong><br />
            <strong>Why include it: </strong> Volunteer experience can demonstrate your values, leadership 
            skills, and community involvement.
            <br />
            <br />

            <strong>How to include it:</strong> Treat this section similarly to your work experience, 
            highlighting specific achievements and skills gained.
            <br />

            <div className={styles.highlight}>
              <strong>Example</strong><br />

              <div>Volunteer Experience</div>
              <div>Habitat for Humanity, Team Leader</div>
              <div>June 2021 - Present</div>
              <ul className={styles.highlightList}>
                <li>
                  Led a team of 10 volunteers in building 3 homes for low-income families
                </li>
                <li>
                  Organized fundraising events, raising over $50,000 for the organization
                </li>
              </ul>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Hobbies and Interests</strong><br />
            <strong>Why include it: </strong> Hobbies can provide a more rounded picture of you as a person 
            and can be great conversation starters in interviews.
            <br />
            <br />

            <strong>How to include it: </strong>
            <br />
            <ul className={styles.nestedList}>
              <li>Keep it brief</li>
              <li>Focus on unique or interesting hobbies</li>
              <li>Consider how your hobbies might relate to the job or company culture</li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example</strong><br />
              <ul className={styles.highlightList}>
                <li>
                  Amateur astronomy: Built a personal observatory and contribute to citizen science projects
                </li>
                <li>
                  Marathon runner: Completed 5 marathons, including Boston and New York
                </li>
                <li>
                  Improv comedy performer: Regular performer at local comedy club, developing quick thinking 
                  and public speaking skills
                </li>
              </ul>
              <br />
              <strong>Pro Tip: </strong>Avoid listing generic hobbies like "reading" or "watching movies." 
              Instead, be specific and showcase hobbies that demonstrate valuable skills or interesting aspects of your personality.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Languages</strong><br />
            <strong>Why include it: </strong>Language skills can be a valuable asset in our globalized world, 
            especially for roles involving international communication.
            <br />
            <br />

            <strong>How to include it: </strong>List languages with clear proficiency levels. Avoid vague 
            terms like "conversational."
            <br />

            <div className={styles.highlight}>
              <strong>Example</strong><br />
              <div>Languages</div>
              <ul className={styles.highlightList}>
                <li>English: Native proficiency</li>
                <li>Spanish: Professional working proficiency</li>
                <li>Mandarin: Elementary proficiency</li>
              </ul>
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Custom Sections</strong><br />
            <strong>Why include it: </strong>Custom sections allow you to highlight unique aspects of your 
            background that don't fit neatly into other categories.
            <br />
            <br />

            <strong>How to include it: </strong>
            <br />
            <ul className={styles.nestedList}>
              <li>Create a section title that clearly describes the content</li>
              <li>Keep it relevant to the job or industry you're targeting</li>
              <li>Use the same formatting as other sections for consistency</li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example: For a software developer</strong>
              <br />
              Hackathons and Coding Competitions
              <br />
              <br />
              <ul className={styles.highlightList}>
                <li>
                  First Place, TechCrunch Disrupt Hackathon 2022
                  <br />
                  Created an AI-powered app for reducing food waste in restaurants
                </li>
                <li>
                  Runner-up, Google Code Jam 2021
                  <br />
                  Solved complex algorithmic challenges, finishing in the top 1% of participants
                </li>
              </ul>
              <br />
              <strong>Pro Tip: </strong>Use custom sections to tell a story about your unique experiences 
              or skills that set you apart from other candidates.
            </div>
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Integrating Optional Sections with Standard Ones
      </div>

      <div className={styles.text}>
        Think of your resume as a gourmet meal - the standard sections are your main course, but it's the 
        optional sections that add the spice, the flavor, and that je ne sais quoi that makes your resume 
        unforgettable.
        <br />
        Let's dive into the art of seamlessly blending optional sections with the standard ones, creating a 
        resume that's greater than the sum of its parts.
      </div>

      <div className={styles.text}>
        <strong>The Professional Summary: Your Resume's Elevator Pitch</strong>
        <br />
        Your professional summary is the perfect place to start incorporating elements from optional 
        sections. It's your chance to give the hiring manager a taste of what's to come.
      </div>

      <div className={styles.highlight}>
        <strong>Example 1: The Award-Winning Professional</strong>
      </div>

      <div className={styles.text}>
        <strong>Instead of a bland:</strong>
        <br />
        "Experienced marketing professional with 7 years in the industry."
      </div>

      <div className={styles.text}>
        <strong>Try this zesty alternative:</strong>
        <br />
        "Award-winning marketing maverick with 7 years of experience crafting viral campaigns. Named 
        'Digital Innovator of the Year' by MarketWatch, with a track record of increasing client ROI by an 
        average of 150%."
      </div>

      <div className={styles.text}>
        Here, we've seamlessly integrated the "Achievements and Awards" section into the professional 
        summary, immediately grabbing attention.
      </div>

      <div className={styles.text}>
        <strong>Work Experience: The Main Course with a Twist</strong>
        <br />
        Your work experience section doesn't have to be a dry recitation of duties. Spice it up by 
        incorporating elements from optional sections.
      </div>

      <div className={styles.highlight}>
        <strong>Example 2: The Multilingual Project Manager</strong>
      </div>

      <div className={styles.text}>
        <strong>Instead of:</strong>
        <br />
        "Led a team of 10 in developing a new product line."
      </div>

      <div className={styles.text}>
        <strong>Serve up this flavorful description:</strong>
        <br />
        "Orchestrated a diverse, multilingual team of 10 to develop our revolutionary 'EcoChic' product 
        line, resulting in a 200% increase in our APAC market share. Leveraged fluency in Mandarin and 
        Japanese to navigate complex cross-cultural negotiations."
      </div>

      <div className={styles.text}>
        In this example, we've skillfully woven in language skills and specific achievements, giving a 
        richer context to the work experience.
      </div>

      <div className={styles.text}>
        <strong>Education: More Than Just Degrees</strong>
        <br />
        Your education section can be more than just a list of degrees. It's an opportunity to showcase 
        relevant coursework, academic achievements, and even volunteer experiences.
      </div>

      <div className={styles.highlight}>
        <strong>Example 3: The Socially Conscious Graduate</strong>
      </div>

      <div className={styles.text}>
        <strong>Instead of:</strong>
        <br />
        "Bachelor of Science in Environmental Engineering, Green University, 2022"
      </div>
      <br />

      <div className={styles.listText}>
        <strong>Try this well-rounded presentation:</strong>
        <br />
        "Bachelor of Science in Environmental Engineering, Green University, 2022
        <ul className={styles.list}>
          <li>
            Thesis: 'Microplastic Filtration in Urban Water Systems' - Published in the Journal of 
            Environmental Sciences
          </li>
          <li>
            Founded the 'Campus Cleanup Crew' - Organized monthly volunteer events, engaging 500+ students 
            in local conservation efforts"
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Here, we've integrated publications and volunteer experience into the education section, painting a 
        picture of a well-rounded, socially conscious candidate.
      </div>

      <div className={styles.text}>
        <strong>Skills: Your Professional Flavor Profile</strong>
        <br />
        The skills section is ripe for integration with other optional sections. It's not just about listing 
        skills; it's about contextualizing them.
      </div>

      <div className={styles.highlight}>
        <strong>Example 4: The Certified Tech Guru</strong>
      </div>

      <div className={styles.text}>
        <strong>Instead of a plain list:</strong>
        <br />
        "Python, Java, C++, Machine Learning"
      </div>
      <br />

      <div className={styles.listText}>
        <strong>Spice it up with:</strong>
        <br />
        "Technical Proficiencies:
        <ul className={styles.list}>
          <li>
            Programming: Python (Certified Python Developer), Java, C++
          </li>
          <li>
            Machine Learning: Developed an AI-powered crop yield prediction model, increasing harvest 
            efficiency by 30% for local farmers (Featured in 'Tech for Good' Annual Report, 2023)"
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        This example integrates certifications and projects into the skills section, providing concrete 
        evidence of the candidate's abilities.
      </div>

      <div className={styles.text}>
        <strong>The Power of Custom Sections: Your Resume's Secret Sauce</strong>
        <br />
        Sometimes, standard sections just don't cut it. That's when it's time to get creative with custom 
        sections that highlight your unique blend of experiences and skills.
      </div>

      <div className={styles.highlight}>
        <strong>Example 5: The Globe-Trotting Consultant</strong>
      </div>
      <br />

      <div className={styles.listText}>
        <strong>Create a custom section like:</strong>
        <br />
        "Global Business Insights:
        <ul className={styles.list}>
          <li>Conducted market research in 15 countries across 5 continents</li>
          <li>Fluent in English, Mandarin, and Spanish; conversational in Arabic</li>
          <li>Published 'Cross-Cultural Negotiation Tactics' in Harvard Business Review, 2023</li>
          <li>
            Volunteer consultant for 'Entrepreneurs Without Borders' - Mentored 50+ startups in developing 
            countries"
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        This custom section beautifully integrates language skills, publications, international experience, 
        and volunteer work, creating a powerful narrative of a globally minded professional.
      </div>
      <br />
      <br />

      <div className={styles.listText}>
        <strong>The ATS-Friendly Approach: Having Your Cake and Eating It Too</strong>
        <br />
        While creativity is key, remember that your resume often needs to pass through Applicant Tracking 
        Systems (ATS) before reaching human eyes. Here's how to stay creative while remaining ATS-friendly:
        <ol className={styles.list}>
          <li>
            Use clear, standard section headings (e.g., "Professional Experience" instead of "My Journey")
          </li>
          <li>
            Incorporate keywords from the job description naturally throughout your integrated sections
          </li>
          <li>
            Avoid using graphics, tables, or complex formatting that ATS might not understand
          </li>
        </ol>
      </div>

      <div className={styles.highlight}>
        <strong>Example 6: The ATS-Savvy Designer</strong>
      </div>
      <br />

      <div className={styles.text}>
        Instead of separate sections for skills, software proficiencies, and projects, create an integrated, 
        ATS-friendly section:
      </div>
      <br />

      <div className={styles.listText}>
        "Professional Skills and Projects:
        <ul className={styles.list}>
          <li>
            Graphic Design: Created brand identity for Fortune 500 tech company, increasing brand 
            recognition by 40%
          </li>
          <li>Software: Adobe Creative Suite (Certified Expert), Sketch, Figma</li>
          <li>
            UX/UI: Redesigned e-commerce platform, resulting in 25% increase in user engagement and 15% 
            boost in sales conversion
          </li>
          <li>
            Project Management: Led 5-person team in developing award-winning app 'EcoTrack', featured in 
            Apple's 'App of the Day'"
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        This integrated approach ensures that all key information is present in a format that both ATS and 
        human recruiters can easily parse and appreciate.
      </div>

      <div className={styles.text}>
        <strong>
          Let's dive deeper into how to do this effectively, considering different career stages and job 
          requirements.
        </strong>
      </div>

      <div className={styles.title}>
        For Recent Graduates
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>Contact Information</li>
          <li>Professional Objective</li>
          <li>Education</li>
          <li>Relevant Coursework/Projects</li>
          <li>Skills</li>
          <li>Internships/Part-time Jobs</li>
          <li>Volunteer Experience</li>
          <li>Awards and Honors</li>
          <li>Extracurricular Activities</li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Why This Works: </strong>This layout emphasizes the recent graduate's educational 
        achievements and relevant skills, while also showcasing any practical experience gained through 
        internships and volunteer work. The awards and extracurricular activities demonstrate leadership 
        and initiative, qualities highly valued by employers hiring fresh graduates.
      </div>

      <div className={styles.title}>
        For Experienced Professionals
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>Contact Information</li>
          <li>Professional Summary</li>
          <li>Core Competencies</li>
          <li>Professional Experience</li>
          <li>Key Achievements</li>
          <li>Education</li>
          <li>Certifications</li>
          <li>Professional Affiliations</li>
          <li>Languages (if relevant)</li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Why This Works: </strong>This order puts the professional's experience, and achievements 
        front and center. The core competencies section provides a quick overview of key skills, while the 
        detailed work experience demonstrates how these skills have been applied. Additional sections like 
        certifications and professional affiliations reinforce the candidate's expertise and industry 
        involvement.
      </div>

      <div className={styles.title}>
        For Career Changers
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>Contact Information</li>
          <li>Professional Summary (focusing on transferable skills)</li>
          <li>Skills Summary</li>
          <li>Relevant Experience (including volunteer work, projects)</li>
          <li>Professional Experience</li>
          <li>Education</li>
          <li>Certifications</li>
          <li>Languages (if relevant to the new field)</li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Why This Works: </strong>This order emphasizes the career changer's transferable skills and 
        any relevant experience in the new field, including volunteer work. The professional summary clearly 
        states the transition and how existing skills apply to the new career. The traditional work 
        experience is included but given less prominence.
      </div>

      <div className={styles.title}>
        Conclusion
      </div>

      <div className={styles.text}>
        Integrating optional sections with standard ones is where the magic happens in resume writing. It's 
        how you transform a standard chronology of your career into a compelling narrative that showcases 
        the unique value you bring to the table.
      </div>

      <div className={styles.text}>
        Creating a standout resume is both an art and a science. It requires a deep understanding of your 
        own skills and experiences, knowledge of industry expectations, and the ability to present 
        information in a clear, compelling manner. By following these guidelines and investing time in 
        tailoring your resume for each application, you're not just creating a document - you're crafting a 
        powerful personal marketing tool.
      </div>

      <div className={styles.text}>
        So go ahead, get creative, and start mixing those sections. Your perfect-blend resume is waiting to 
        be written!
      </div>

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>FAQs: The Ultimate Guide to Resume Sections</strong><br />
      </div>

      <div className={styles.text}>
        <strong>1. Q: What are the essential sections every resume should include?</strong><br />
        A: Every resume should include Personal Information, Resume Summary or Objective, Work Experience, 
        Education, and Skills sections.
      </div>

      <div className={styles.text}>
        <strong>2. Q: How long should a resume be?</strong><br />
        A: Keep your resume concise. Recruiters spend an average of 7.4 seconds scanning each resume, so 
        focus on relevant, impact information.
      </div>

      <div className={styles.text}>
        <strong>3. Q: What's the difference between a resume summary and objective?</strong><br />
        A: A resume summary is for experienced professionals, while an objective is for students, recent 
        graduates, or career changers.
      </div>

      <div className={styles.text}>
        <strong>4. Q: How can I make my resume stand out to recruiters?</strong><br />
        A: Use action verbs, quantify achievements, tailor your resume to the job description, and include 
        relevant keywords to increase your chances of getting an interview.
      </div>

      <div className={styles.text}>
        <strong>5. Q: What optional sections can I include in my resume?</strong><br />
        A: Optional sections include Achievements and Awards, Certifications, Projects, Social Media profiles, 
        Publications, References, Volunteering, Hobbies and Interests, and Languages.
      </div>

      <div className={styles.text}>
        <strong>6. Q: How do I list my skills on a resume?</strong><br />
        A: Divide your skills into categories such as Technical Skills, Language Skills, and Interpersonal 
        Skills. Focus on skills relevant to the job you're applying for.
      </div>

      <div className={styles.text}>
        <strong>7. Q: Should I include references on my resume?</strong><br />
        A: Only include references if they're specifically requested in the job posting or if you have 
        particularly impressive references that could influence the hiring decision.
      </div>

      <div className={styles.text}>
        <strong>8. Q: How do I make my resume ATS-friendly?</strong><br />
        A: Use clear, standard section headings, incorporate keywords from the job description naturally, 
        and avoid using graphics, tables, or complex formatting that ATS might not understand.
      </div>

      <div className={styles.text}>
        <strong>9. Q: How should I organize my resume as a recent graduate?</strong><br />
        A: Recent graduates should prioritize Education, Relevant Coursework/Projects, Skills, 
        Internships/Part-time Jobs, and Volunteer Experience sections.
      </div>

      <div className={styles.text}>
        <strong>10. Q: How can I structure my resume for a career change?</strong><br />
        A: Career changers should focus on transferable skills, relevant experience (including volunteer 
        work and projects), and a professional summary that clearly states the transition.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;
