import React from 'react';
import styles from './styles.module.scss';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>
        Top Resume Writing Tips for Landing Your Dream Job
      </div>

      <div className={styles.text}>
        Your resume is your first impression on a potential employer. In a competitive job market, 
        making it outstanding is essential to landing interviews and securing your dream job. This 
        guide provides expert tips on crafting a compelling resume that showcases your skills and 
        aligns seamlessly with the jobs you desire.
      </div>

      <div className={styles.title}>
        Why Does Your Resume Matter?
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>The First Hurdle: </strong>
            For many jobs, your resume is the initial screening tool. It must quickly convey your 
            value to hiring managers or their software.
          </li>

          <li className={styles.listItem}>
            <strong>ATS Optimization: </strong>
            Applicant Tracking Systems (ATS) are used by many companies to filter resumes. 
            Your resume should be keyword-rich to make it through the ATS and into human hands.
          </li>

          <li className={styles.listItem}>
            <strong>Making an Impression: </strong>
            A well-organized, concise resume highlights your achievements and sets you apart from 
            the competition.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Crafting a Compelling Resume
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>The Structure is Key</strong>
          </li>

          <li className={styles.listItem}>
            <strong>Formatting for Impact</strong>
          </li>

          <li className={styles.listItem}>
            <strong>Keywords Matter</strong>
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Writing Powerful Content
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Action Verbs Start Strong: </strong>
            Instead of "managed projects," try "Led multi-functional teams to deliver projects on
            time and under budget."
          </li>

          <li className={styles.listItem}>
            <strong>Quantify Your Success: </strong>
            Whenever possible, use numbers. Instead of "Improved customer satisfaction," try 
            "Increased customer satisfaction scores by 20%."
          </li>

          <li className={styles.listItem}>
            <strong>Tailor to the Job: </strong>
            Carefully modify your resume for each job application, emphasizing skills relevant to 
            the specific role.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Proofreading and Editing - The Final Polish
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Typos are a No-Go: </strong>
            A single typo can send your resume to the rejection pile. Proofread meticulously for 
            errors.
          </li>

          <li className={styles.listItem}>
            <strong>Grammar Check Tools: </strong>
            Use tools like Grammarly to catch subtle errors.
          </li>

          <li className={styles.listItem}>
            <strong>Get Another Pair of Eyes: </strong>
            Have a friend, mentor, or career counselor offer feedback on both content and readability.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Additional Tips
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Cover Letter Power: </strong>
            Tailored cover letters demonstrate your interest in the specific position and company.
          </li>

          <li className={styles.listItem}>
            <strong>Go Visual (Sometimes): </strong>
            For creative fields, consider a visual resume (infographic or similar format) to showcase 
            your design skills, but also keep a traditional version.
          </li>

          <li className={styles.listItem}>
            <strong>Online Profiles: </strong>
            Include links to a well-maintained LinkedIn profile, portfolio website, 
            or other professional platforms.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Take Your Resume to the Next Level with paññã score
      </div>

      <div className={styles.text}>
        Don't just write a resume, write a resume that gets noticed! paññã score is your AI-powered 
        resume analysis tool that provides personalized feedback in seconds. paññã analyzes your 
        resume for clarity, impact, and Applicant Tracking System (ATS) friendliness. With actionable 
        insights and suggestions, paññã can help you optimize your resume to land the interview you 
        deserve.
      </div>

      <div className={styles.title}>
        <a href='https://www.panna.ai/score/' target='_blank'>Get your free resume analysis today!</a>
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        Conclusion
      </div>

      <div className={styles.text}>
        Your resume is an essential tool in your job search arsenal. By following these tips, 
        tailoring them to each opportunity, and utilizing resources like Paññã, you'll create a 
        resume that opens doors and helps you land your dream job.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        { ...meta }
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;
