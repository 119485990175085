/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
/* eslint-disable */
import React from 'react';
import { Navbar } from '../../component/Navbar';
import HistoryTable from '../../component/HistoryTable';
import API from '../../network';
import Spinner from '../../component/Spinner';
import Helmet from 'react-helmet';
import data from '../MetaData/data';
import Utility from '../../utils';
class History extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            count: 0,
            userHistory: null,
            showHistoryTable: false

        };
    }

    componentDidMount() {
        this.fetchUserHistory();
        Utility.trackEvent('History', 'PAGE_VISIT');
    }


    fetchUserHistory = () => {
        API.getInstance().fetchUserHistory()
            .then(res => {
                console.log(res);
                this.setState({ userHistory: res?.userHistory, showHistoryTable: true });
            })
            .catch(error => {
                console.log(error);
            });
    };


    render() {
        const { userHistory, showHistoryTable } = this.state;
        return (<div>
            <Helmet>
                <title>{data.historyPage.title}</title>
                {/* <meta name="title" content={data.historyPage.metaTitle} />
                <meta name="description" content={data.historyPage.metaDescription} /> */}
            </Helmet>
            <Navbar></Navbar>
            {!showHistoryTable ? <Spinner show={true} /> : null}
            {showHistoryTable ? <HistoryTable userHistory={userHistory} /> : null}
        </div>);
    }
}

export default History;
