import React from 'react';
import Mobile from './Mobile';
import Desktop from './Desktop';
import { useMediaQuery } from 'react-responsive';
import { useEffect } from 'react';
import Utility from '../../utils';

/**
 * This component is to render precall screen in different platforms
 * @param {viewRenderProps} props
 * @return {JSX.Element}
 */
const ReportResumeBuilder = (props) => {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    Utility.trackEvent('resume-builder-report', 'PAGE_VISIT');
  }, [])
  if (isMobile) {
    return <Mobile {...props} />;
  } else {
    return <Desktop {...props} />;
  }
}

export default ReportResumeBuilder;