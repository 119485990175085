import React from 'react';
import { useHover, ToggleLayer } from "react-laag";
import { AnimatePresence } from "framer-motion";
import ResizeObserver from "resize-observer-polyfill";
import Menu from "../Popover/menu";
import constant from './data';
import styles from './styles.module.css'

const Popover = props => {
  const { data, title, uniqueKey } = props;
  const [show, hoverProps] = useHover({ delayEnter: 300, delayLeave: 200 });
  return (
    <ToggleLayer
      ResizeObserver={ResizeObserver}
      isOpen={show}
      renderLayer={props => {
        return (
          <AnimatePresence>
            {props.isOpen ? (
              <Menu
                ref={props.layerProps.ref}
                style={props.layerProps.style}
                arrowStyle={props.arrowStyle}
                layerSide={props.layerSide}
              >
                <div className={styles.tooltipTitle}>{title}</div>
                <div className={styles.KeywordStyle}>
                  {
                    title === 'Skill Match'
                      ? (
                        <React.Fragment>
                          <div className={styles.descStyle}><span className={styles.boldStyles}>{data}</span>{` ${constant.skillTooltip}`}</div>
                          <div className={styles.descStyle}>{constant.skillTooltip2}</div>
                        </React.Fragment>
                      )
                      : (
                        <React.Fragment>
                          <div className={styles.descStyle}><span className={styles.boldStyles}>{data}</span>{` ${constant.actionTooltip}`}</div>
                          {/* <div className={styles.descStyle}>{constant.actionTooltip2}</div> */}
                        </React.Fragment>
                      )
                  }
                </div>
              </Menu>
            ) : null}
          </AnimatePresence>
        );
      }}
      placement={{
        anchor: "BOTTOM_CENTER",
        autoAdjust: true,
        snapToAnchor: false,
        triggerOffset: 12,
        scrollOffset: 16,
        preferX: "RIGHT"
      }}
    >
      {({ triggerRef }) => {
        return (
          <div
            ref={triggerRef}
            style={{ position: 'absolute', width: '100%', top: '0', left: '0', border: '0px', height: '100%', zIndex: '20000', background: 'transparent' }}
            {...hoverProps}
          >
            {show ? "" : ""}
          </div>
        )
      }}
    </ToggleLayer>
  );
}

export default Popover;