import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';
import Arrow from '../../../../../assets/insights/winningTechnicalResume/arrow.png';
import resume from '../../../../../assets/insights/winningTechnicalResume/resume.png';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In an era where artificial intelligence can generate code and chatbots can explain 
        quantum computing, standing out in the tech job market has never been more 
        challenging - or more crucial. Your resume is no longer just a list of jobs and 
        skills; it's a carefully orchestrated narrative of your technological journey, a 
        testament to your ability to innovate, adapt, and excel in an ever-evolving digital 
        landscape.
      </div>

      <div className={styles.text}>
        Imagine you're a master coder, effortlessly weaving intricate algorithms and crafting 
        elegant solutions to complex problems. Now, imagine distilling your vast digital 
        expertise into a concise, compelling narrative that fits on a sheet or two of paper. 
        Sounds like a Herculean task, doesn't it? This is the biggest concern with technical 
        resumes.
      </div>

      <div className={styles.title}>
        The Unique Challenges of Technical Resumes
      </div>

      <div className={styles.text}>
        Technical resumes differ significantly from other types of resumes due to the rapidly 
        evolving nature of the tech industry. With new programming languages, frameworks, and 
        tools emerging constantly, your resume needs to reflect your ability to stay current 
        and adapt quickly. Moreover, the tech industry's employment rate is growing 25% faster 
        than the overall job market, making it crucial to craft a resume that sets you apart 
        from the competition.
      </div>

      <div className={styles.text}>
        Crafting a technical resume for entry level jobs is a different ball game, but let's 
        keep the focus on the experienced professionals.
      </div>

      <div className={styles.text}>
        Experienced professionals stack up vast amount of information related to skills and 
        job experience where they handle multiple projects and undertake several job 
        responsibilities. But let's go through the article emphasizing each section and how 
        they can be concise without compromising valuable information.
      </div>

      <div className={styles.title}>
        Crafting an Impactful Summary for Your Tech Resume
      </div>

      <div className={styles.text}>
        Your resume summary is the biggest chance to hook the employer. Never skip a resume summary, 
        it's the only possible opportunity to express yourself and showcase yourself in a few lines.
      </div>

      <div className={styles.text}>
        Recommended tip: Don't blast your summary with all technical jargon and keep it to 3-4 lines 
        maximum.
      </div>

      <div className={styles.text}>
        Here's how to make it count:
      </div>
      
      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Highlight your expertise in key technologies relevant to the job.
          </li>
          <li>
            Showcase your biggest professional achievement.
          </li>
          <li>
            Express your passion for technology and continuous learning.
          </li>
          <li>
            Demonstrate your adaptability to new challenges.
          </li>
          <li>
            Include a line expressing genuine interest in the company you're applying to.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Example:</strong>
      </div>

      <div className={styles.highlight}>
        "Seasoned Full Stack Developer with 8+ years of experience in Python, React, and AWS. Led a 
        team that increased web application performance by 40%, resulting in a 25% boost in user 
        engagement. Passionate about solving complex problems and eager to contribute to [Company Name]'s 
        innovative projects in cloud computing and AI."
      </div>

      <div className={styles.title}>
        Prioritizing and Structuring Your Experience Section
      </div>

      <div className={styles.text}>
        The experience section is the heart of your technical resume. Here's how to make it shine:
      </div>
      <br />
      
      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Start with job-relevant experiences, even if they're not your most recent roles.
          </li>
          <li>
            Begin each job entry with a concise summary of your role and responsibilities.
          </li>
          <li>
            Include relevant technologies and skills used in each role. This lets the employer get a 
            picture of your skills expertise based on the duration and scale of the project.
          </li>
          <li>
            Focus on quantifiable achievements that align with the job description.
          </li>
          <li>
            Limit each job to 3-5 key points to keep it concise. Choose the most relevant achievements 
            and tasks that also relate to the desired job role.
          </li>
        </ol>
      </div>

      <div className={styles.text}>Use strong action verbs to describe your accomplishments:</div>
      <br />

      <table className={styles.table}>
        <tbody>
          <tr>
            <td>
              Implemented
            </td>
          </tr>
          <tr>
            <td>
              Optimized
            </td>
          </tr>
          <tr>
            <td>
              Architected
            </td>
          </tr>
          <tr>
            <td>
              Debugged
            </td>
          </tr>
          <tr>
            <td>
              Deployed
            </td>
          </tr>
          <tr>
            <td>
              Streamlined
            </td>
          </tr>
          <tr>
            <td>
              Innovated
            </td>
          </tr>
        </tbody>
      </table>

      <div className={styles.text}>
        Quantify your Technical Achievements. Numbers speak louder than words. Here are some ways to 
        quantify your technical achievements:
      </div>

      <div className={styles.arrowText}>
        <img
          src={Arrow}
          alt="arrow"
        />
        "Increased web traffic by 150% through SEO optimization and performance enhancements"
      </div>

      <div className={styles.arrowText}>
        <img
          src={Arrow}
          alt="arrow"
        />
        "Saved $500,000 annually by refactoring legacy code and improving system efficiency"
      </div>

      <div className={styles.arrowText}>
        <img
          src={Arrow}
          alt="arrow"
        />
        "Reduced application load times by 60% through database optimization and caching strategies"
      </div>

      <div className={styles.arrowText}>
        <img
          src={Arrow}
          alt="arrow"
        />
        "Improved code coverage from 65% to 95%, resulting in a 40% decrease in production bugs"
      </div>

      <div className={styles.arrowText}>
        <img
          src={Arrow}
          alt="arrow"
        />
        "Scaled microservices architecture to handle 10x increase in concurrent users without downtime"
      </div>

      <div className={styles.text}>
        Remember, authenticity is key. Only include achievements that you can confidently discuss in 
        an interview and deliver on in your new role.
      </div>

      <div className={styles.text}>
        Consider you're a full stack software developer and what factors would you consider 
        quantifying? Let's see:
      </div>
      <br />

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Increase of web traffic
          </li>
          <li>
            Reduction in load time
          </li>
          <li>
            Money saved through coding
          </li>
          <li>
            Specify revenue growth
          </li>
          <li>
            Decrease in bug reports
          </li>
          <li>
            Scale of managed data………….
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        So based on your job role and project, do some research on your project responsibilities and 
        tasks accomplished. It's always wise to update your resume on the completion of every project 
        or contract to keep yourself updated in the job market.
      </div>

      <div className={styles.title}>
        Showcasing Your Technical Skills Effectively
      </div>

      <div className={styles.text}>
        Your technical skills are your most valuable assets. Remember, out of the few seconds the 
        recruiter and ATS takes time to scan the resume, skills section is most focused section. 
        Here's how to present them:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            Categorize your skills (e.g., Programming Languages, Frameworks, DevOps Tools).
          </li>
          <li>
            List skills relevant to the job description first.
          </li>
          <li>
            Include both hard and soft skills.
          </li>
          <li>
            Consider placing skills near the top of your resume for maximum visibility. Or can be 
            placed at the bottom but make sure to incorporate a few skills used in the job into the 
            experience section.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>
          <u>
            Technical Skills Examples:
          </u>
        </strong>
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            <strong>Languages: </strong>
            Python, JavaScript, Java, C++
          </li>
          <li>
            <strong>Frameworks: </strong>
            React, Django, Spring Boot
          </li>
          <li>
            <strong>DevOps: </strong>
            Docker, Kubernetes, Jenkins
          </li>
          <li>
            <strong>Cloud: </strong>
            AWS, Azure, Google Cloud Platform
          </li>
          <li>
            <strong>Databases: </strong>
            PostgreSQL, MongoDB, Redis
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Many technical resumes often ignore soft skills but around 62% of job descriptions explicitly 
        mention the need for applicants with leadership and management skills. It is recommended to 
        state and exemplify soft skills in your projects.
      </div>

      <div className={styles.text}>
        <strong>
          <u>
            Soft Skills Examples:
          </u>
        </strong>
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Team Leadership
          </li>
          <li>
            Agile Methodologies
          </li>
          <li>
            Technical Writing
          </li>
          <li>
            Problem-solving
          </li>
          <li>
            Cross-functional Collaboration
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Avoid using skill bars or ratings, as they lack objectivity and can be misinterpreted.
      </div>

      <div className={styles.title}>
        Showcasing Your Educational Background
      </div>

      <div className={styles.text}>
        While experience often takes center stage in a technical resume, your education section can 
        still play a crucial role in demonstrating your foundational knowledge and continuous learning.
        <br />
        For experienced professionals, place your education section after your work experience. This 
        ensures that your practical skills and achievements are the first things a recruiter sees. 
        However, if you're a recent graduate or your degree is particularly relevant to the position, 
        consider placing it higher on your resume.
        <br />
        What to Include
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            <strong>Degree and Major: </strong>
            List your highest degree first. For example, "Master of Science in Computer Science" or 
            "Bachelor of Engineering in Software Engineering."
          </li>
          <li>
            <strong>Institution Name and Location: </strong>
            Include the full name of your university and its location.
          </li>
          <li>
            <strong>Graduation Date: </strong>
            For recent graduates, include the month and year. If you graduated more than 10 years ago, 
            you could omit the date to avoid potential age bias.
          </li>
          <li>
            <strong>GPA: </strong>
            If it's 3.5 or higher, you may want to include it. Otherwise, it's generally best to leave 
            it off.
          </li>
          <li>
            <strong>Relevant Coursework: </strong>
            For entry-level positions or career changers, listing relevant courses can demonstrate 
            your knowledge in specific areas.
          </li>
          <li>
            <strong>Academic Honors: </strong>
            Include any academic achievements, such as graduating summa cum laude or being on the 
            Dean's List.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>Example:</strong>
      </div>

      <div className={styles.highlight}>
        <strong>EDUCATION</strong>
        <br />
        Master of Science in Computer Science
        <br />
        Stanford University, Stanford, CA
        <br />
        Graduated: May 2020
        <br />
        <ul>
          <li>
            Specialization in Machine Learning and Artificial Intelligence
          </li>
          <li>
            Thesis: "Implementing Efficient Neural Networks for Edge Computing Devices"
          </li>
        </ul>
        <br />
        Bachelor of Science in Software Engineering
        <br />
        Massachusetts Institute of Technology, Cambridge, MA
        <br />
        Graduated: June 2018
        <br />
        <ul>
          <li>
            GPA: 3.8/4.0
          </li>
          <li>
            Honors: Cum Laude, Dean's List (all semesters)
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Remember, your education section should complement your experience and skills sections, not 
        overshadow them. Tailor this section to highlight educational experiences that are most 
        relevant to the job you're applying for, and don't be afraid to leave out older or less 
        relevant degrees if you're running short on space.
      </div>

      <div className={styles.title}>
        Highlighting Certifications and Awards
      </div>

      <div className={styles.text}>
        Include relevant certifications and awards after your experience or education section. Focus 
        on those that demonstrate your expertise in areas crucial to the job you're applying for.
        <br />
        Example:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>AWS Certified Solutions Architect - Professional</li>
          <li>Google Cloud Professional Data Engineer</li>
          <li>Winner, Best Innovation at [Company] Hackathon 2023</li>
        </ul>
      </div>

      <div className={styles.text}>
        Don't neglect your certifications unless you are a senior executive or have relevant experience 
        to catch up with the job requirement.
      </div>

      <div className={styles.title}>
        Key Tips for a Standout Technical Resume
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>Keep it concise: Limit your resume to two pages maximum.</li>
          <li>
            Tailor it: Customize your resume for each job application, highlighting skills and 
            experiences most relevant to the position.
          </li>
          <li>
            Format wisely: Use a clean, professional layout. A two-column format can be effective but 
            ensure its ATS-friendly.
          </li>
          <li>
            Think like a recruiter: Highlight problem-solving skills, adaptability, and collaboration 
            experiences.
          </li>
          <li>
            Proofread meticulously: Technical accuracy is crucial in the tech industry. Ensure all 
            technical terms, programming languages, and tool names are spelled correctly.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>
          Let's see an example of a real time technical resume:
        </strong>
      </div>

      <img
        className={styles.applicationImage}
        src={resume}
        alt="Example of winning technical resume"
      />

      <div className={styles.text}>
        <strong>
          Two Unique Strategies for Tech Resume Success
        </strong>
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Open-Source Contributions: Include links to your GitHub profile or significant open-source 
            projects you've contributed to.
          </li>
          <li>
            Technical Blog or Portfolio: If you maintain a technical blog or portfolio, include a 
            link. This can demonstrate your expertise and communication skills.
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>
          Crafting Your Path to Tech Career Success
        </strong>
        <br />
        Creating a compelling technical resume is both an art and a science. By following these 
        guidelines, you'll be well on your way to crafting a resume that not only passes the ATS test 
        but also catches the eye of recruiters and hiring managers.
      </div>

      <div className={styles.text}>
        Remember, your resume is your personal marketing document. It should tell the story of your 
        professional journey, highlight your unique value proposition, and demonstrate why you're the 
        perfect fit for the role you're applying for.
      </div>

      <div className={styles.text}>
        Ready to take your technical resume to the next level? Try the Panna Resume Builder today and 
        create a resume that opens doors to exciting tech opportunities!
      </div>
      <br />

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>
          Frequently Asked Questions
        </strong>
      </div>

      <div className={styles.text}>
        <strong>1. How long should a technical resume be?</strong><br />
        A. Ideally, one to two pages maximum.
      </div>

      <div className={styles.text}>
        <strong>2. What skills should I highlight on my technical resume?</strong><br />
        A. Prioritize skills specifically mentioned in the job description, including both hard and 
        soft skills.
      </div>

      <div className={styles.text}>
        <strong>3. Should I include my GPA on my technical resume?</strong><br />
        A. Only if it's 3.5 or higher, otherwise, it's optional.
      </div>

      <div className={styles.text}>
        <strong>4. How can I make my technical resume stand out?</strong><br />
        A. Tailor your resume to each job, quantify achievements, and showcase projects or 
        contributions.
      </div>

      <div className={styles.text}>
        <strong>5. What's the best way to list my technical skills?</strong><br />
        A. Categorize them (e.g., languages, frameworks) and prioritize those most relevant to the job.
      </div>

      <div className={styles.text}>
        <strong>6. Should I include references on my technical resume?</strong><br />
        A. No, it's generally unnecessary unless specifically requested.
      </div>

      <div className={styles.text}>
        <strong>7. How can I ensure my resume passes through ATS?</strong><br />
        A. Use keywords from the job description, avoid complex formatting, and save your resume as 
        a PDF.
      </div>

      <div className={styles.text}>
        <strong>8. Is it important to proofread my technical resume?</strong><br />
        A. Absolutely! Technical accuracy is vital in the tech industry.
      </div>

      <div className={styles.text}>
        <strong>9. Where can I get help creating a strong technical resume?</strong><br />
        A. Consider using a resume builder like Panna Resume Builder for professional guidance.
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
