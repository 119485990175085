/* eslint-disable */
import React from 'react';
import Fade from 'react-reveal/Fade';
import { Line } from 'rc-progress';
import styles from './styles.module.css';
import measureFirstImg from '../../assets/animation/loadingAnimation.svg';
import measureSecondImg from '../../assets/animation/Resume.png';
import measureThirdImg from '../../assets/animation/Job Description.png';
import softSkill from '../../assets/animation/skills/softSkill.png';
import hardSkill from '../../assets/animation/skills/hardskills.png';
import experienceSkill from '../../assets/animation/skills/experience.png';
import eductionSkill from '../../assets/animation/skills/eduction.png';
import certificateSkill from '../../assets/animation/skills/certificate.png';
import requirementsSkill from '../../assets/animation/skills/requirements.png';
import contentImg from '../../assets/animation/skills/content.png';
import doneImg from '../../assets/animation/skills/correct_saved.png';
import data from './data';

const SkillData = [
  { id: 'softSkill', style: styles.scaleImgAnimation },
  { id: 'hardSkill', style: styles.scaleImgAnimation },
  { id: 'experienceSkill', style: styles.scaleImgAnimation },
  { id: 'eductionSkill', style: styles.scaleImgAnimation },
  { id: 'certificateSkill', style: styles.scaleImgAnimation },
  { id: 'hardSkill2', style: styles.scaleImgAnimation },
  { id: 'softSkill2', style: styles.scaleImgAnimation },
  { id: 'requirementsSkill', style: styles.scaleImgAnimation }
];

const contentData = [
  { id: 'contentImg1', style: styles.contentImg1ImgAnimation },
  { id: 'contentImg2', style: styles.contentImg2ImgAnimation },
  { id: 'contentImg3', style: styles.contentImg3ImgAnimation },
  { id: 'contentImg4', style: styles.contentImg4ImgAnimation },
  { id: 'contentImg5', style: styles.contentImg5ImgAnimation },
  { id: 'jdContentImg1', style: styles.jdContentImg1ImgAnimation },
  { id: 'jdContentImg2', style: styles.jdContentImg2ImgAnimation },
  { id: 'jdContentImg3', style: styles.jdContentImg3ImgAnimation }
];

const finalAnimationData = [
  { id: 'contentImg1', style: styles.contentImg1ImgFinalAnimation },
  { id: 'contentImg2', style: styles.contentImg2ImgFinalAnimation },
  { id: 'contentImg3', style: styles.contentImg3ImgFinalAnimation },
  { id: 'contentImg4', style: styles.contentImg4ImgFinalAnimation },
  { id: 'contentImg5', style: styles.contentImg5ImgFinalAnimation },
  { id: 'jdContentImg1', style: styles.jdContentImg1ImgFinalAnimation },
  { id: 'jdContentImg2', style: styles.jdContentImg2ImgFinalAnimation },
  { id: 'jdContentImg3', style: styles.jdContentImg3ImgFinalAnimation }
];

class LoadingAnimation extends React.Component {
  state = {
    percent: 0,
    show: false,
    loadingText: []
  };

  counterForAnimation = 0;

  finalSectionCounter = 0;

  componentDidMount() {
    console.log('measuring props', this.props);
    const MeasureSection1Img = document.getElementById('MeasureSection1Img');
    const MeasureSection2Img = document.getElementById('MeasureSection2Img');
    const secondSectionDiv = document.getElementById('requirementsSkill');
    const thirdSectionDiv = document.getElementById('jdContentImg3');
    this.renderTextRecursively(0);
    MeasureSection1Img.addEventListener('animationend', () => {
      MeasureSection1Img.classList.add(styles.SecondImgAfterAnimation);
    });
    MeasureSection2Img.addEventListener('animationend', () => {
      MeasureSection2Img.classList.add(styles.ThirdImgAfterAnimation);
      this.beginSectionThreeAnimation();
    });
    secondSectionDiv.addEventListener('transitionend', () => {
      contentData.forEach(content => {
        const element = document.getElementById(content.id);
        element.classList.add(content.style);
      });
    });
    thirdSectionDiv.addEventListener('transitionend', () => {
      this.counterForAnimation = this.counterForAnimation + 1;
      finalAnimationData.forEach(finaldata => {
        const element = document.getElementById(finaldata.id);
        element.classList.add(finaldata.style);
      });
    });
  }

  componentWillReceiveProps(newProps) {
    const { percent } = this.state;
    const { isDone } = newProps;
    this.onDone(percent, isDone);
  }

  componentWillUnmount() {
    const MeasureSection1Img = document.getElementById('MeasureSection1Img');
    const MeasureSection2Img = document.getElementById('MeasureSection2Img');
    const secondSectionDiv = document.getElementById('requirementsSkill');
    const thirdSectionDiv = document.getElementById('jdContentImg3');
    const doneSectionWrapper = document.getElementById('doneSectionWrapper');
    MeasureSection1Img.removeEventListener('animationend', () => { });
    MeasureSection2Img.removeEventListener('animationend', () => { });
    secondSectionDiv.removeEventListener('transitionend', () => { });
    thirdSectionDiv.removeEventListener('transitionend', () => { });
    doneSectionWrapper.removeEventListener('transitionend', () => { });
  }

  // TODO synchronize the settimeout function here / clear timeout

  renderTextRecursively = count => {
    const counter = count || 0;
    if (counter <= data.loadingText.length) {
      setTimeout(() => {
        this.setState({ loadingText: data.loadingText[counter], show: true });
      }, 200);
      this.increamentProgress(14.29 * (counter + 1));
      if (counter < data.loadingText.length - 1) {
        setTimeout(() => {
          this.setState({ show: false });
          this.renderTextRecursively(counter + 1);
        }, 2200);
      }
    }
  };

  // TODO maintain different function for transition of second section and done section
  onDone = (value, isDone) => {
    if (value > 99 && isDone) {
      const element = document.getElementById('SecondSectionWrapper');
      const progressWrapper = document.getElementById('progressWrapper');
      const loadingTextWrap = document.getElementById('loadingTextWrap');
      setTimeout(() => {
        const doneSectionWrapper = document.getElementById(
          'doneSectionWrapper'
        );
        if (progressWrapper && loadingTextWrap) {
          progressWrapper.classList.add(styles.progressWrapAnimation);
          element.classList.add(styles.hideSecondSection);
          loadingTextWrap.classList.add(styles.loadingTextWrapAnimation);
        }
        if (doneSectionWrapper) {
          doneSectionWrapper.addEventListener('transitionend', () => {
            setTimeout(() => {
              const { onAnimationEnd } = this.props;
              // eslint-disable-next-line no-unused-expressions
              onAnimationEnd && onAnimationEnd();
            }, 1500);
          });
          const doneImgDiv = document.getElementById('doneImg');
          doneSectionWrapper.classList.add(styles.DoneImageWrapAnimation);
          doneImgDiv.classList.add(styles.doneImgStyleAnimation);
        }
      }, 1500);
    }
  };

  increamentProgress = value => {
    const { isDone } = this.props;
    this.onDone(value, isDone);
    this.setState({ percent: value });
  };

  beginSectionThreeAnimation = () => {
    SkillData.forEach(skill => {
      const element = document.getElementById(skill.id);
      element.classList.add(skill.style);
    });
  };

  render() {
    const { percent, show, loadingText } = this.state;
    return (
      <div id="loadingContainer" className={styles.loadingContainer}>
        <div id="loadingTextWrap" className={styles.loadingTextWrap}>
          <Fade
            left
            opposite
            collapse
            distance={window.innerWidth <= 550 ? '20%' : '40%'}
            when={show}
          >
            <div className={styles.loadingText}>{loadingText}</div>
          </Fade>
        </div>
        <img
          id="FirstSectionImg"
          src={measureFirstImg}
          alt=""
          className={styles.FirstImg}
        />
        <div id="SecondSectionWrapper" className={styles.secondSection}>
          {/* TODO create separate component or add loop for rendering the below section */}
          <div className={styles.secondSectionWrap}>
            <img
              id="MeasureSection1Img"
              src={measureSecondImg}
              alt=""
              className={styles.SecondImg}
            />
            <img
              id="softSkill"
              src={softSkill}
              alt=""
              className={styles.softSkillImg}
            />
            <img
              id="hardSkill"
              src={hardSkill}
              alt=""
              className={styles.hardSkillImg}
            />
            <img
              id="experienceSkill"
              src={experienceSkill}
              alt=""
              className={styles.experienceSkillImg}
            />
            <img
              id="eductionSkill"
              src={eductionSkill}
              alt=""
              className={styles.eductionSkillImg}
            />
            <img
              id="certificateSkill"
              src={certificateSkill}
              alt=""
              className={styles.certificateSkillImg}
            />
            <img
              id="contentImg1"
              src={contentImg}
              alt=""
              className={styles.contentImg1Img}
            />
            <img
              id="contentImg2"
              src={contentImg}
              alt=""
              className={styles.contentImg2Img}
            />
            <img
              id="contentImg3"
              src={contentImg}
              alt=""
              className={styles.contentImg3Img}
            />
            <img
              id="contentImg4"
              src={contentImg}
              alt=""
              className={styles.contentImg4Img}
            />
            <img
              id="contentImg5"
              src={contentImg}
              alt=""
              className={styles.contentImg5Img}
            />
          </div>
          <div className={styles.ThirdSectionWrap}>
            <img
              id="MeasureSection2Img"
              src={measureThirdImg}
              alt=""
              className={styles.ThirdImg}
            />
            <img
              id="hardSkill2"
              src={hardSkill}
              alt=""
              className={styles.hardSkill2Img}
            />
            <img
              id="softSkill2"
              src={softSkill}
              alt=""
              className={styles.softSkill2Img}
            />
            <img
              id="requirementsSkill"
              src={requirementsSkill}
              alt=""
              className={styles.requirementsSkillImg}
            />
            <img
              id="jdContentImg1"
              src={contentImg}
              alt=""
              className={styles.jdContentImg1Img}
            />
            <img
              id="jdContentImg2"
              src={contentImg}
              alt=""
              className={styles.jdContentImg2Img}
            />
            <img
              id="jdContentImg3"
              src={contentImg}
              alt=""
              className={styles.jdContentImg3Img}
            />
          </div>
        </div>
        <div id="doneSectionWrapper" className={styles.DoneImageWrap}>
          <img
            id="doneImg"
            src={doneImg}
            alt=""
            className={styles.doneImgStyle}
          />
          <div className={styles.doneText}>Done!</div>
        </div>
        <div id="progressWrapper" className={styles.progressBarWrap}>
          <Line
            percent={percent}
            strokeWidth="2"
            strokeColor="#fd5656"
            trailWidth="2"
          />
          <div className={styles.measuringText}>
            {this?.props?.title || 'Measuring...'}
            {/* <span className={styles.loadingText}>
                            <Fade top left distance="5px" cascade forever duration={800}>
                                ...
                            </Fade>
                        </span> */}
          </div>
        </div>
      </div>
    );
  }
}

export default LoadingAnimation;
