import React from 'react';
import styles from './styles.module.css';
import { motion } from "framer-motion";
const Button = props => {
  const { onClick, buttonStyle, disable, id } = props;
  return (<>
    <motion.button whileTap={{ scale: 0.85 }}
      id={id}
      className={[styles.buttonStyle, buttonStyle].join(' ')}
      onClick={onClick}
      disabled={disable}
      {...props}
    >
      {props.text}
    </motion.button>
  </>

  );
};

export default Button;
