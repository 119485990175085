import React from 'react'
import styles from './styles.module.css'
import Button from '../Button';

const ConfirmPopup = props => {
    const { title, onCancel, onOk } = props;
    return (
        <div className={styles.saveJdContainer}>
            <div className={styles.labelStyle}>{title}</div>
            <div className={styles.buttonWrap}>
                <Button text="Cancel" buttonStyle={styles.cancelButtonStyle} onClick={onCancel} />
                <Button text="Ok" buttonStyle={styles.saveButtonStyle} onClick={onOk} />
            </div>
        </div>
    );
}

export default ConfirmPopup;