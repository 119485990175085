/* eslint-disable */

import Stripe from 'stripe';
import { Stripe as StripePublic, loadStripe } from '@stripe/stripe-js';
// export class StripeService {
//   static instance;
//   stripe;

//   constructor(secretKey) {
//     this.stripe = new Stripe(`sk_test_jK8dLndjCuecuOEhOe8pek3W`, {
//       apiVersion: '2022-11-15'
//     });
//   }

//   static getInstance(secretKey) {
//     if (!StripeService.instance) {
//       StripeService.instance = new StripeService(secretKey);
//     }
//     return StripeService.instance.stripe;
//   }

//   getStripe() {
//     return this.stripe;
//   }
// }

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);
  }
  return stripePromise;
};

export default getStripe;
