import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';



// const rows = [
//     { date: 'March 11th 2024 3:30 PM', name: 'Row 1', email: 'test1@yopmail.com', jobDescription: "Job Description", },
//     { date: 'March 11th 2024 3:31 PM', name: 'Row 2', email: 'test2@yopmail.com', jobDescription: 'Description of Row 2' },
//     { date: 'March 11th 2024 3:32 PM', name: 'Row 3', email: 'test3@yopmail.com', jobDescription: 'Description of Row 3' },
// ];


const HistoryTable = ({ userHistory }) => {
    const navigate = useNavigate();
    const [rows, setRows] = useState(userHistory);
    console.log('userHistory:', userHistory);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const handleRowClick = (row) => {
        console.log('Clicked Row:', row);
        // Do something when a row is clicked, e.g., navigate to a detail page
        const { builtResumeAI_Response, resumeUrl, resumeHtmlUrl, contact, name, emailAddress, id, aiResumeEditedResponse, jobDescription, coverLetterJson, jobTitle } = row;
        const { aiResumeData, prevScoreReport } = JSON.parse(builtResumeAI_Response) || {};
        console.log('from history table', aiResumeData, prevScoreReport);
        navigate('/builder-report', {
            state: {
                data: {},
                aiResumeData: aiResumeData,
                prevScoreReport: prevScoreReport,
                name: name,
                email: emailAddress,
                phoneNumber: contact,
                s3DownloadLink: resumeUrl,
                resumeHtmlUrl: resumeHtmlUrl,
                HistoryRowId: id,
                aiResumeEditedResponse: aiResumeEditedResponse,
                jdURL: jobDescription,
                jobDescription: null,
                jobTitle: jobTitle,
                coverLetterJson: coverLetterJson

            }
        })
    };

    const handleCellClick = (event, row) => {
        event.stopPropagation(); // 
        console.log(row);
    }

    return (
        <div className={styles.container}>
            <div className={styles.head} style={{ marginBottom: '30px' }}>Your activity</div>
            {/* <div className={styles.content}>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. </div> */}
            <TableContainer component={Paper}>
                <Table style={{ minWidth: '680px' }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Date</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Job Title</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows && (rowsPerPage > 0
                            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            : rows
                        ).map((row, index) => (
                            <TableRow key={index} className={index % 2 === 0 ? styles.evenRow : ''} hover onClick={() => handleRowClick(row)}>
                                <TableCell>{moment(row.updatedDate).format('MMMM Do YYYY, HH:mm')}</TableCell>

                                <TableCell>{row.name}</TableCell>
                                <TableCell>{row.emailAddress}</TableCell>
                                <TableCell className={styles.clickableCell} onClick={(event) => handleCellClick(event, row)}>{row.jobTitle}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                component="div"
                count={rows.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
            />
        </div>
    );
};

export default HistoryTable;
