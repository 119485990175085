import React from 'react';
import data from './data';
import styles from './styles.module.css';
import step1Img from '../../assets/LandingPage/UploadResume.png';
import step2Img from '../../assets/LandingPage/PasteJobDesc.png';
import step3Img from '../../assets/LandingPage/getScore.png';

const steps = [
  {
    name: data.step1,
    styles: styles.step1ImgWrap,
    image: step1Img,
    imgStyles: styles.step1ImgStyle
  },
  {
    name: data.step2,
    styles: styles.step2ImgWrap,
    image: step2Img,
    imgStyles: styles.step2ImgStyle
  },
  {
    name: data.step3,
    styles: styles.step3ImgWrap,
    image: step3Img,
    imgStyles: styles.step3ImgStyle
  }
];

const Instructions = () => {
  return (
    <div className={styles.instructionWrap}>
      <div className={styles.lable}>{data.lable}</div>
      <div className={styles.stepsWrap}>
        <div className={styles.dashedLine} />
        {steps.map((step, index) => {
          return (
            <div key={`${step.name}`} className={styles.stepContainer}>
              <div id={step.name} className={step.styles}>
                <img src={step.image} alt="" className={step.imgStyles} />
              </div>
              <div id={`stepText${index}`} className={styles.stepLable}>
                {step.name}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Instructions;
