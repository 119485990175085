import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';
import resumeSnap from '../../../../../assets/insights/scienceBehindATS/resumeSnap.png';
import atsDistribution from '../../../../../assets/insights/scienceBehindATS/atsDistribution.png';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's job market, your resume often meets artificial intelligence before human 
        eyes. Applicant Tracking Systems (ATS) are the digital gatekeepers of the hiring 
        world, and understanding how they work is your secret weapon in the job hunt. Let's 
        dive into the world of ATS and discover how you can make technology work for you, 
        not against you.
      </div>

      <div className={styles.title}>
        The AI Recruitment Boom
      </div>

      <div className={styles.text}>
        Before we dive into the nitty-gritty of ATS, let's talk numbers:
      </div>
      <br />
      
      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            A staggering 99% of Fortune 500 companies are now using AI in their hiring process [1].
          </li>
          <li>
            The AI recruitment software industry, currently valued at $590.5 million, is 
            projected to skyrocket to $890.51 million by 2028 [2].
          </li>
          <li>
            85% of HR professionals believe AI will replace parts of the recruitment 
            process in the near future [3].
          </li>
          <li>
            65% of recruiters are already leveraging AI in their workflow [4].
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        These aren't just statistics; they're a clarion call for job seekers to adapt or 
        risk being left behind.
      </div>

      <div className={styles.title}>
        Inside the ATS: The Science of Getting Noticed
      </div>

      <div className={styles.text}>
        Forget everything you thought you knew about how your resume is processed. The 
        modern ATS is a sophisticated system, powered by machine learning algorithms that 
        do far more than simple keyword matching. Let's pull back the curtain on how these 
        systems typically work:
      </div>





      <div className={styles.listWithNestedContent}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Application Intake and Parsing</strong><br />
            When you submit your application through an ATS-powered careers page, the 
            system immediately goes to work:
            <br />
            <br />

            <ul className={styles.nestedList}>
              <li>
                <strong>Document Conversion: </strong>
                The ATS converts your resume into a machine-readable format, typically plain 
                text. This is why submitting ATS-friendly file types (like .docx or .pdf) is 
                crucial.
              </li>
              <li>
                <strong>Data Extraction: </strong>
                Using Natural Language Processing (NLP), the ATS extracts key information 
                such as your name, contact details, work history, education, and skills.
              </li>
              <li>
                <strong>Structured Data Creation: </strong>
                The extracted information is organized into a structured candidate profile 
                within the ATS database.
              </li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example: </strong>
              Your work experience might be broken down into fields like "Company Name",
              "Job Title", "Start Date", "End Date" and "Responsibilities"
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Semantic Analysis and Skill Mapping</strong><br />
            Modern ATS goes beyond simple keyword matching:
            <br />
            <br />

            <ul className={styles.nestedList}>
              <li>
                <strong>Contextual Understanding: </strong>
                The system uses advanced semantic analysis to understand the context of 
                your experiences and skills.
              </li>
              <li>
                <strong>Skill Inference: </strong>
                The ATS can infer skills you may have based on your job titles and 
                descriptions, even if you haven't explicitly listed them.
              </li>
              <li>
                <strong>Industry-Specific Knowledge: </strong>
                The AI is often trained on vast datasets of industry-specific terminology, 
                allowing it to recognize niche skills and experiences.
              </li>
            </ul>
            
            <div className={styles.highlight}>
              <strong>Example: </strong>
              The phrase "lead a team of 5" might be interpreted as leadership experience,
              even if "leadership" isn't explicitly mentioned.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Job Requisition Matching</strong><br />
            This is where the ATS's machine learning algorithms truly shine:
            <br />
            <br />

            <ul className={styles.nestedList}>
              <li>
                <strong>Requirement Analysis: </strong>
                The system analyzes the job requisition, breaking it down into required 
                skills, experiences, and qualifications.
              </li>
              <li>
                <strong>Weighted Scoring: </strong>
                Each element of the job requirements is assigned a weight based on its 
                importance, as determined by the hiring team.
              </li>
              <li>
                <strong>Candidate Scoring: </strong>
                Your profile is then scored against these weighted requirements. This isn't 
                a simple checklist; the AI considers factors like the recency of your 
                experience and the relevance of your skills to the specific role.
              </li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example: </strong>
              For a developer role, "Python Experience" might be weighted more heavily than 
              communication skills.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Candidate Ranking and Presentation</strong><br />
            The final step in the ATS process:

            <div>
              <img
                src={resumeSnap}
                className={styles.image}
                alt='resume Relevance example'
              />
            </div>

            <ul className={styles.nestedList}>
              <li>
                <strong>Dynamic Ranking: </strong>
                Candidates are ranked based on their overall score, but this ranking is 
                dynamic. As new applications come in or job requirements are adjusted, the 
                rankings can shift.
              </li>
              <li>
                <strong>Diversity Considerations: </strong>
                Many companies configure their ATS to support diversity initiatives, 
                ensuring diverse candidate slates are presented to hiring managers.
              </li>
              <li>
                <strong>Recruiter Interface: </strong>
                The ATS presents the ranked candidates to recruiters through an intuitive 
                interface, highlighting key qualifications and potential areas of strong 
                match.
              </li>
            </ul>

            <div className={styles.highlight}>
              <strong>Example: </strong>
              A recruiter might see a dashboard showing your match percentage for key skills,
              with a "90% match" for Python programming prominently displayed.
            </div>
            <br />

            <div>
              Understanding this process gives you a strategic advantage. By tailoring your 
              resume to highlight relevant skills and experiences in a way that aligns with 
              the ATS analysis process, you can significantly improve your chances of rising 
              to the top of the candidate pool.
            </div>
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Trending ATS Software: The Global Players
      </div>

      <div className={styles.text}>
        As the AI recruitment market expands, several ATS platforms have emerged as 
        frontrunners. Let's look at some of the most popular options and their market 
        presence:
      </div>

      <div>
        <img
          src={atsDistribution}
          className={styles.applicationImage}
          alt='ATS Distribution'
        />
      </div>

      <div className={styles.listText}>
        <ul className={styles.alphaList}>
          <br />
          <li>
            <a
              href="https://www.workday.com/en-us/products/talent-management/talent-acquisition.html"
              className={styles.linkTitle}
              target="_blank"
            >
              <strong>
                Workday
              </strong>
            </a>
            : Used by many Fortune 500 companies, Workday is a cloud-based system that 
            offers comprehensive HR management, including recruitment [5].
          </li>
          <br />

          <li>
            <a
              href="https://www.oracle.com/in/human-capital-management/taleo/"
              className={styles.linkTitle}
              target="_blank"
            >
              <strong>
                Taleo (Oracle):
              </strong>
            </a>
            : One of the most widely used ATS globally, particularly among large enterprises [6].
          </li>

          <br />
          <li>
            <a
              href="https://www.greenhouse.com/"
              className={styles.linkTitle}
              target="_blank"
            >
              <strong>
                Greenhouse
              </strong>
            </a>
            : Popular among tech startups and mid-sized companies, Greenhouse has seen 
            significant growth in recent years [7].
          </li>

          <br />
          <li>
            <a
              href="https://www.lever.co/"
              className={styles.linkTitle}
              target="_blank"
            >
              <strong>
                Lever
              </strong>
            </a>
            : A favorite for companies prioritizing user experience and integration capabilities [8].
          </li>

          <br />
          <li>
            <a
              href="https://www.icims.com/"
              className={styles.linkTitle}
              target="_blank"
            >
              <strong>
                iCIMS
              </strong>
            </a>
            : Used by approximately 4,000 companies worldwide, iCIMS processes over 75 
            million applications annually [9].
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        The Human Element: Where AI Meets Intuition
      </div>

      <div className={styles.text}>
        Here's a crucial piece of the puzzle that many job seekers overlook: While ATS 
        organizes and ranks applications, humans still make the final decisions. A 2020 
        TalentBoard survey found that 60% of recruiters always review applications flagged 
        as 'poor matches' by their ATS [10].
        <br />
        Why? Because experienced recruiters understand that no system is perfect. They're 
        looking for those diamond-in-the-rough candidates that might slip through the 
        cracks of even the most sophisticated AI. 
      </div>

      <div className={styles.text}>
        Take Sarah, a senior recruiter at a Fortune 500 tech company: "Our ATS is incredibly 
        helpful in managing the sheer volume of applications we receive. But I've found some 
        of our best hires in the 'low match' category. There's an art to reading between the 
        lines that AI hasn't mastered yet."
      </div>

      <div className={styles.text}>
        This insight is gold for job seekers. It means that while optimizing for ATS is 
        crucial, crafting a resume that speaks to human readers is equally important. Your 
        goal? To create an application that sails through ATS and then captivates the human 
        recruiter on the other side.
      </div>

      <div className={styles.titleBlue}>
        Cracking the Code: Your ATS Strategy Playbook
      </div>

      <div className={styles.text}>
        So, how do you become the candidate that both ATS and recruiters love? It's a 
        balancing act, but here's your game plan:
      </div>
      <br />

      <div className={styles.listText}>
        <ul className={styles.alphaList}>
          <li>
            <strong>Keyword Optimization (Without the Stuffing): </strong>
            Analyze the job description like a detective. Identify key skills and phrases, 
            then naturally weave them into your resume. Remember, you're writing for humans 
            who happen to use ATS, not the other way around. Example: Instead of listing 
            "proficient in data analysis," try "Leveraged advanced data analysis techniques 
            to increase department efficiency by 35%."
          </li>
          <li>
            <strong>Format for Success: </strong>
            Clean, standard formatting isn't just aesthetically pleasing - it's ATS gold. 
            Stick to common section headings like "Work Experience" and "Education." Fancy 
            graphics might impress humans, but they confuse ATS.
          </li>
          <li>
            <strong>Quantify Your Achievements: </strong>
            Numbers are the universal language of impact. "Increased sales" is good, but 
            "Increased quarterly sales by 50%, outperforming team average by 30%" is ATS 
            catnip and recruiter candy.
          </li>
          <li>
            <strong>Tailor, Don't Spam: </strong>
            Customizing your resume for each application isn't just a good practice - it's 
            essential. Use the job description as your guide but maintain your authentic 
            voice.
          </li>
          <li>
            <strong>The Power of a Strong Summary: </strong>
            Lead with a punchy professional summary that encapsulates your value proposition. 
            This is prime real estate for strategic keyword placement and setting the tone 
            for both ATS and human readers.
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>Beyond the Resume: The Holistic Application Approach</strong>  
        <br />  
        Remember, your resume is just one piece of the puzzle. Many ATS now incorporate 
        data from your entire application package. This means every interaction - from your 
        cover letter to how you fill out application forms - matters.
        <br />
        <span style={{ color: '#9A0000' }}>Pro Tip: </span>
        When filling out online applications, treat every field as an opportunity 
        to showcase   your fit for the role. Those seemingly mundane forms are often the 
        first thing an ATS analyzes.
        <br />
      </div>

      <div className={styles.title}>
        The Future of ATS: AI and Beyond
      </div>

      <div className={styles.text}>
        As we peer into the future, the line between ATS and AI is blurring. We're seeing 
        the emergence of systems that can:
      </div>
      <br />

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li>
            Analyze the sentiment and tone of your application materials
          </li>
          <li>
            Predict job performance based on complex data models
          </li>
          <li>
            Even assess cultural fit through natural language processing
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        But don't let this intimidate you. The core principle remains: Present your 
        authentic, qualified self in a way that resonates with both digital systems and 
        human decision-makers.
      </div>

      <div className={styles.title}>
        Your Next Move: Empowered and Informed
      </div>

      <div className={styles.text}>
        Armed with this insider knowledge, you're no longer at the mercy of a mysterious 
        digital gatekeeper. You're a savvy job seeker, ready to navigate the complex world 
        of modern recruiting.
      </div>

      <div className={styles.text}>
        Remember, at its core, ATS is a tool to help humans find the right talent. By 
        understanding how it works, you're not gaming the system - you're mastering the art 
        of modern job hunting.
      </div>

      <div className={styles.text}>
        So, as you sit down to craft your next application, do so with confidence. Your 
        dream job awaits, and now you have the key to unlock the ATS gates standing in 
        your way.
      </div>

      <div className={styles.text}>
        Build your ATS-friendly resume now with Panna - the smart resume builder that puts 
        you ahead of the competition!
      </div>
      <br />

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>
          Frequently Asked Questions about ATS and Resumes
        </strong>
      </div>

      <div className={styles.text}>
        <strong>Q1: What is an ATS-friendly resume?</strong><br />
        A: A resume optimized for easy parsing by Applicant Tracking Systems, using simple 
        formatting and relevant keywords.
      </div>

      <div className={styles.text}>
        <strong>Q2: How do modern ATS systems analyze resumes?</strong><br />
        A: They use advanced techniques like Natural Language Processing and semantic 
        search to understand context.
      </div>

      <div className={styles.text}>
        <strong>Q3: Do ATS systems automatically reject resumes?</strong><br />
        A: No, ATS systems typically organize and rank applications rather than 
        automatically rejecting them.
      </div>

      <div className={styles.text}>
        <strong>Q4: Can ATS systems understand the context of my experience?</strong><br />
        A: Yes, modern ATS can understand context and relationships between concepts.
      </div>

      <div className={styles.text}>
        <strong>Q5: Do ATS systems only look for exact keyword matches?</strong><br />
        A: No, they can recognize synonyms and related concepts beyond exact matches.
      </div>

      <div className={styles.text}>
        <strong>Q6: How does ATS rank candidates?</strong><br />
        A: Based on relevance of skills, experience, education, career progression, and 
        sometimes external data.
      </div>

      <div className={styles.text}>
        <strong>Q7: Can ATS systems learn and adapt over time?</strong><br />
        A: Yes, many use machine learning to refine their screening criteria over time.
      </div>

      <div className={styles.text}>
        <strong>Q8: Do all companies use the same ATS?</strong><br />
        A: No, companies choose from various ATS platforms based on their specific needs.
      </div>

      <div className={styles.title}>
        References
      </div>

      <div className={styles.text}>
        [1]
        &nbsp;
        Jobscan, "Fortune 500 ATS: The Definitive List" (2021)
        <br />
        [2]
        &nbsp;
        Grand View Research, "Artificial Intelligence In Recruitment Market Size Report, 
        2021-2028" (2021)
        <br />
        [3]
        &nbsp;
        LinkedIn Global Recruiting Trends Report (2018)
        <br />
        [4]
        &nbsp;
        Jobvite Recruiter Nation Survey (2018)
        <br />
        [5]
        &nbsp;
        Workday, "Workday Named a Leader in Gartner Magic Quadrant for Cloud HCM Suites for 
        1,000+ Employee Enterprises for Fifth Consecutive Year" (2020)
        <br />
        [6]
        &nbsp;
        Ongig blog, "Top 10 ATS Systems (Applicant Tracking Software) for 2023" (2023)
        <br />
        [7]
        &nbsp;
        Greenhouse, "Greenhouse Announces Record Growth and $130 Million Series F Funding 
        Round at $3 Billion Valuation" (2021)
        <br />
        [8]
        &nbsp;
        Lever official website (2023)
        <br />
        [9]
        &nbsp;
        iCIMS official website (2023)
        <br />
        [10]
        &nbsp;
        TalentBoard, "2020 North American Candidate Experience Research Report" (2021)
        <br />
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
