// Menu.js

import React, { useState, useRef, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import './Menu.css';
import betaLogo from '../../../assets/Logo/pannaBlackBeta.png';
import PannaRBLogo from '../../../assets/Logo/pannaBlack.png';
import PannaLogo from '../../../assets/Logo/pannaLogo.png';
import PannaRCLogo from '../../../assets/Logo/panna-resume-score.png';

import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearStore, updateLocation } from '../../../store/actions/cache';
import { useLocation } from 'react-router-dom';
import Utility from '../../../utils';

const styles = {};
const privateTabs = [
    { name: "Build AI Resume", to: "/resume-build-home", id: 1, isActive: false },
    { name: "Free Resume Score", to: "/check-resume-score", id: 2, isActive: false },
    { name: "Insights", to: "/insights", id: 3, isActive: false },
    { name: "History", to: "/history", id: 4, isActive: false },
    { name: "Profile", to: "/profile", id: 5, isActive: false },


];

const publicTabs = [
    { name: "Build AI Resume", to: "/resume-build-home", id: 1, isActive: false },
    { name: "Free Resume Score", to: "/check-resume-score", id: 2, isActive: false },
    { name: "Insights", to: "/insights", id: 3, isActive: false },
];

const betaPages = ['resume-builder', '/history', '/resume-build-home', '/builder-report', '/insights', '/login', '/signup', '/profile']



const Menu = () => {
    const [isOpen, setIsOpen] = useState(false);
    const menuRef = useRef(null);
    const { authState, pannaAuth } = useAuth();
    const location = useLocation();
    let isRBPage = betaPages.some(element => window.location.pathname.includes(element));

    useEffect(() => {
        console.log('URL changed:', location.pathname);
        // Add your logic here to handle URL changes
        isRBPage = betaPages.some(element => window.location.pathname.includes(element));

    }, [location]);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const clearStoreData = () => {
        dispatch(clearStore());
        signOut();
    }

    const signOut = async () => {
        try {
            Utility.trackEvent('nav-bar', 'BUTTON_CLICK', 'Logout');
        } catch (error) {
            console.error('Failed to track event:', error);
        }
        await pannaAuth.signOut();
    }

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    return (

        <div className="menu" ref={menuRef}>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                    alignItems: 'center',
                    zIndex: 50,
                    maxWidth: '90%',
                    margin: '0 auto'
                }}
            >
                <a href="/score" id="panna-logo" rel="noopener noreferrer">
                    <img
                        role="button"
                        src={isRBPage ? PannaRBLogo : PannaRCLogo}
                        alt="Paññãlogo"
                        className={isRBPage ? 'pannaRBLogo' : 'pannaRBLogo'}
                    />
                </a>
                <button className="menu-toggle" onClick={toggleMenu}>
                    <span className="menu-icon"></span>
                </button>
            </div>
            <AnimatePresence>
                {isOpen && (
                    <motion.nav
                        className="menu-items"
                        initial={{ opacity: 0, x: '100%' }}
                        animate={{ opacity: 1, x: 0 }}
                        exit={{ opacity: 0, x: '100%' }}
                        transition={{ type: 'spring', stiffness: 200, damping: 25 }}
                    >
                        <button className="menu-toggle" onClick={toggleMenu}>
                            <span className="close-icon">X</span>
                        </button>

                        {authState && (
                            <ul>

                                {
                                    privateTabs.map(item => {
                                        return (
                                            <>
                                                <li
                                                    id={item.to}
                                                    className={[styles.menuBtn, location.pathname === item.to ? styles.menuBtnActive : ''].join(' ')}
                                                    onClick={() => navigate('' + item.to)}
                                                >
                                                    {item.name}
                                                </li>
                                            </>
                                        )


                                    })
                                }

                                {/* <div className={styles.profileLinkContainer}>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
              </div> */}
                                <li
                                    id={'mobile-log-out'}
                                    onClick={clearStoreData}
                                >
                                    Logout
                                </li>


                            </ul>
                        )}

                        {!authState && (
                            <ul>

                                {
                                    publicTabs.map(item => {
                                        return (
                                            <>
                                                <li
                                                    id={item.to}
                                                    className={[styles.menuBtn, location.pathname === item.to ? styles.menuBtnActive : ''].join(' ')}
                                                    onClick={() => navigate('' + item.to)}
                                                >
                                                    {item.name}
                                                </li>
                                            </>
                                        )
                                    })
                                }
                            </ul>
                        )}

                        {!authState && location.pathname !== '/login' && (
                            <ul><li
                                style={{
                                    zIndex: 50
                                }}
                                className={styles.loginBtn}
                                id={'login-btn'}
                                onClick={() => navigate('/login')}
                            >Login</li></ul>
                        )}
                    </motion.nav>
                )}
            </AnimatePresence>
        </div>
    );
};

export default Menu;



