import React from 'react'
import styles from './styles.module.css'
import Button from '../Button'

class SaveJdPopup extends React.Component {
    state = {
        value: '',
        adjustForKeyboard: false,
    }

    componentDidMount() {
        window.addEventListener('resize', () => this.adjustfeedbackForm())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.adjustfeedbackForm());
    }

    adjustfeedbackForm = () => {
        if (window.innerHeight <= 200) {
            this.setState({ adjustForKeyboard: true })
        } else {
            this.setState({ adjustForKeyboard: false })
        }
    }

    onChange = e => {
        this.setState({ value: e.target.value });
    }

    render() {
        const { value, adjustForKeyboard } = this.state;
        const { title, label, onCancel, onSave } = this.props;
        return (
            <div id="saveJdContainer" className={styles.saveJdContainer}>
                {/* TODO check with others regarding this popup */}
                {!adjustForKeyboard ? <div className={styles.titleStyle}>{title}</div> : null}
                <div className={styles.inputWrap}>
                    <div className={styles.labelStyle}>{label}</div>
                    <input value={value} placeholder="Type here..." onChange={this.onChange} className={styles.inputFieldStyle} />
                </div>
                {!adjustForKeyboard ?
                    <div className={styles.buttonWrap}>
                        <Button text="Cancel" buttonStyle={styles.cancelButtonStyle} onClick={onCancel} />
                        <Button id="SaveAndMeasure" text="Save" buttonStyle={styles.saveButtonStyle} onClick={() => onSave(value)} disable={!value} />
                    </div>
                    : null}
            </div>
        );
    }
}

export default SaveJdPopup;