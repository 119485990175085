import React, { useState, useEffect } from 'react';
import styles from './styles.module.scss'

const GoogleDocsViewer = ({ documentUrl }) => {

  const [isLoaded, setIsLoaded] = useState(false);
  const [hasReloaded, setHasReloaded] = useState(false);

  useEffect(() => {
    const iframe = document.getElementById('google-docs-viewer');
    console.log('readyState:', iframe?.contentDocument?.readyState);
    const checkDocLoaded = () => {
      if (iframe && iframe?.contentDocument?.readyState === 'complete') {
        setIsLoaded(true);
        clearTimeout(timeout);
      }
    };

    const reloadDocViewer = () => {
      if (iframe && !hasReloaded) {
        iframe.src = iframe.src; // Reload the iframe
        setHasReloaded(true);
      }
    };

    const timeout = setTimeout(() => {
      checkDocLoaded();
      if (!isLoaded) {
        reloadDocViewer();
      }
    }, 5000); // Adjust the delay as needed

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div style={{ width: '100%' }}>
      <iframe
        id="google-docs-viewer"
        title="Document Viewer"
        src={`https://docs.google.com/viewer?url=${encodeURIComponent(documentUrl)}&embedded=true`}
        width="100%"
        height="780"
        frameBorder="0"
        scrolling="no"
      ></iframe>
    </div>
  );
};

export default GoogleDocsViewer;
