import hero from '../../../../assets/insights/optimizeResume/hero.png';


export const meta = {
  author: "",
  date: "2024-02-10",
  type: "Insights",
  title: "Tips to optimise your resume for ATS.",
  description: "In today's fiercely competitive job market, the utilization of Applicant Tracking Systems (ATS) has surged among employers. These sophisticated software applications are meticulously designed to streamline the hiring process by efficiently managing and sorting through the copious resumes received for job openings.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/tips-to-optimize-your-resume",
};
