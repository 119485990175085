import { BASEPATH } from '../../lib/constants';
const isIndia = BASEPATH.includes('/in') ? true : false;


export const privacyData = [
    {
        content:
            'Our Privacy Policy explains how we handle your data on Pañña Resume Score.',
    },
    {
        title: '1. Collected Information',
        content:
            'User Input: Includes resumes and job descriptions you provide.',
    },
    {
        title: '',
        content:
            'Technical Data: Information on how you use our website, like IP address, browser type, and visited pages.',
    },
    {
        title: '2.Use of Information',
        content:
            'We use your information to:',
    },
    {
        title: '',
        content:
            'Generate resumes.',
    },
    {
        title: '',
        content:
            'Improve our service through analysis of usage patterns.',
    },
    {
        title: '',
        content:
            'Communicate updates or offers, with your consent.',
    },

    {
        title: '3.Data Retention',
        content:
            'User Input is kept only long enough to process your request, then deleted unless saved by you. ',
    },
    {
        title: '',
        content:
            'Technical data is kept longer for analysis.',
    },

    {
        title: '4.Sharing Your Data',
        content:
            'User Input may be shared with OpenAI for processing.',
    },
    {
        title: '',
        content:
            'We won’t share your information with others without your consent, except as required by law.',
    },
    {
        title: '5.Security',
        content:
            'We take measures to protect your data, employing appropriate technical and organizational safeguards.',
    },
    {
        title: '6.Your Rights',
        content:
            'You have the right to access, modify, or delete your data. Contact us at support@panna.ai for assistance.',
    },
    {
        title: '7.Policy Updates',
        content:
            'We may update this policy. Any changes will be posted on our website.',
    },
];


export const termsData = [
    {
        content:
            'Welcome to Pañña Resume Score! Accessing and using our website at  https://www.panna.ai/score means you accept the following terms and conditions. Please read them carefully.',
    },
    {
        title: '1. Our Service',
        content:
            'Pañña Resume Score provides a cutting-edge resume generation service. Leveraging OpenAI\'s GPT-4 Turbo model, we craft resumes tailored to your existing resume and specific job descriptions. Please note:',
    },
    {
        title: '',
        content:
            'The resumes we generate are designed to serve as a guide. It\'s up to you to review, refine, and confirm their accuracy before use.',
    },
    {
        title: '2. Your Contributions',
        content:
            'When using our service, you are responsible for the accuracy and completeness of the information you provide, including your resume and job descriptions (\'User Input\'). Ensure that your User Input does not:',
    },
    {
        title: '',
        content:
            'Violate any laws, be harmful, offensive, defamatory, discriminatory, or infringe on others rights.',
    },
    {
        title: '',
        content:
            'Include personal identifiable information (PII) without proper consent.',
    },
    {
        title: '',
        content:
            'Infringe on any intellectual property rights.',
    },

    {
        title: '3.Intellectual Property',
        content:
            'The resumes generated by Pañña Resume Score, which may be similar for different users, remain our property. ',
    },
    {
        title: '',
        content:
            'You retain ownership of your original resumes and job descriptions.',
    },
    {
        title: '',
        content:
            'We use information to send you transactional communications:  We might send You emails or SMS about Your account or a ticket purchase.',
    },
    {
        title: '',
        content:
            'The intellectual property rights of the GPT-4 Turbo model belong to OpenAI.',
    },
    {
        title: '4. Website Content',
        content:
            `All content and materials available on https://www.panna.ai/score, including but not limited to text, graphics, website name, code, images and logos are the intellectual property of ${isIndia ? 'KR Mroads India Pvt. Ltd.' : 'Mroads LLC.'}, and are protected by applicable copyright and trademark law. Any inappropriate use, including but not limited to the reproduction, distribution, display, or transmission of any content on this site is strictly prohibited, unless specifically authorized by- "${isIndia ? 'KR Mroads India Pvt. Ltd.' : 'Mroads LLC.'}"`,
    },

    {
        title: '5. No Warranty',
        content:
            'Our services are offered "as is" We can\'t promise that the resumes generated will be accurate, suitable, or successful for your intended purposes. We are not responsible for any outcomes from using our services.',
    },
    {
        title: '6. Limitation of Liability',
        content:
            'Our liability for any issues arising from our services is limited to the service fee paid, if any.',
    },
    {
        title: '7. Changes to Terms',
        content:
            'We may update these Terms, our website, or services without prior notice. Any changes will be posted on our site.',
    },
    {
        title: '8. Refund Policy',
        content:
            'At Pañña Resume Score, we are committed to ensuring satisfaction with our resume generation service. Should our service not meet your expectations, we offer a refund under the following conditions:',
    },
    {
        title: '',
        content:
            'Refunds are considered when:',
    },
    {
        title: '',
        content:
            'The generated resume significantly deviates from the provided User Input.',
    },
    {
        title: '',
        content:
            'Technical issues prevent proper generation or delivery of the resume, which cannot be resolved by our support team.',
    },
    {
        title: '',
        content:
            `Refunds must be requested within 7 days of receiving the generated resume. Eligible refunds are processed and credited to the customer\'s original method of payment within 5-7 working days. This Refund Policy applies solely to the resume generation service offered by Pañña Resume Score. Other products or services provided by ${isIndia ? 'KR Mroads India Pvt. Ltd.' : 'Mroads LLC.'}. may have different policies.`,
    },

    {
        title: '9. Contact',
        content:
            'Email - support@panna.ai',
    },
    {
        title: '',
        content:
            `${isIndia ? 'Address - Block D, Cyber Gateway, Madhapur, Hitec City, Hyderabad, 500081' : ''}`,
    },

];

