import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { PaymentModal } from '../../PaymentModal';
import { useSelector } from 'react-redux';
import Utility from '../../../utils';


import { BASEPATH } from '../../../lib/constants';
import bgImage from '../../../assets/build-resume-home/bg.png'
import img1 from '../../../assets/build-resume-home/resumes-image.png'

import video1 from '../../../assets/videos/slide1.mov';
import video2 from '../../../assets/videos/slide2.mov';
import video3 from '../../../assets/videos/slide3.mov';

import resumeBuilderVideo from '../../../assets/videos/resumeBuilder.mov';


import save from '../../../assets/build-resume-home/save.png';
import star from '../../../assets/build-resume-home/star.png';
import time from '../../../assets/build-resume-home/time.png';
import prepareResume from '../../../assets/build-resume-home/prepareResume.png';

import ai from '../../../assets/build-resume-home/features/ai.png';
import ats from '../../../assets/build-resume-home/features/ats.png';
import customization from '../../../assets/build-resume-home/features/customization.png';
import easyEdit from '../../../assets/build-resume-home/features/easyEdit.png';
import instantMatch from '../../../assets/build-resume-home/features/instantMatch.png';
import keywords from '../../../assets/build-resume-home/features/keywords.png';
import puzzle from '../../../assets/build-resume-home/features/puzzle.png';
import templates from '../../../assets/build-resume-home/features/templates.png';

import youtube from '../../../assets/build-resume-home/footer/youtube.png';
import linkedin from '../../../assets/build-resume-home/footer/linkedin.png';

import rect1 from '../../../assets/build-resume-home/rectangle1.png'
import rect2 from '../../../assets/build-resume-home/rectangle2.png'
import rect3 from '../../../assets/build-resume-home/rectangle3.png'
import rect4 from '../../../assets/build-resume-home/rectangle4.png'
import Footer1 from '../../Footer1';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../Button';
import FaqData from '../faqs';
import JdAnimation from '../../../animation/LoadingAnimation';
import sampleData from '../data';
import { useMediaQuery } from 'react-responsive';

const VideoAnimations = () => {
  const videos = [video1, video2, video3];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
      return () => {
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentVideoIndex, videos.length]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <div className={styles.videoContainer}>
      <video
        ref={videoRef}
        width="100%"
        muted
        playsInline
        key={currentVideoIndex} // This key forces the video element to re-render on index change
      >
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

const ResumeVideoAnimation = () => {
  const videos = [resumeBuilderVideo, resumeBuilderVideo];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
      return () => {
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentVideoIndex, videos.length]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <div className={styles.img}>
      <video
        ref={videoRef}
        width="100%"
        muted
        playsInline
        key={currentVideoIndex} // This key forces the video element to re-render on index change
      >
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

const HeroSection = (props) => {
  const { trySample, handleBuildResume } = props;
  const videoSliderRef = useRef(null);
  const videoRefs = [useRef(null), useRef(null), useRef(null)];

  const videoSettings = {
    arrows: false,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    // autoplaySpeed: 10000,
    beforeChange: (current, next) => {
      if (videoRefs[current].current) {
        videoRefs[current].current.pause();
        videoRefs[current].current.currentTime = 0;
      }
    },
  };

  useEffect(() => {
    videoRefs.forEach((videoRef, index) => {
      const videoElement = videoRef.current;

      if (videoElement) {
        videoElement.addEventListener('ended', () => {
          if (videoSliderRef.current) {
            const slick = videoSliderRef.current;
            console.log('Slick :: ', videoSliderRef);
            if (slick) {
              slick.slickGoTo(0);
            }
          }
        });
      }
    });

    return () => {
      videoRefs.forEach((videoRef) => {
        const videoElement = videoRef.current;
        if (videoElement) {
          videoElement.removeEventListener('ended', () => { });
        }
      });
    };
  }, []);

  return (
    <div className={styles.heroSection}>
      {/* <Slider {...settings}>
        <div>
          <video
            // height={320}
            // width={200}
            key={`video-1`}
            className={styles.video}
            autoPlay
            muted
            loop
          >
            <source src={video1}></source>
          </video>
        </div>
        <div>
          <video
            // height={320}
            // width={200}
            key={`video-2`}
            className={styles.video}
            autoPlay
            muted
            loop
          >
            <source src={video2}></source>
          </video>
        </div>
        <div>
          <video
            // height={320}
            // width={200}
            key={`video-3`}
            className={styles.video}
            autoPlay
            muted
            loop
          >
            <source src={video3}></source>
          </video>
        </div>
      </Slider> */}
      {/* <div className={styles.animationVideos}>
        <Slider ref={videoSliderRef} {...videoSettings}>
          <div>
            <video ref={videoRefs[0]} width="600" autoPlay muted className={styles.video}>
              <source src={video1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <video ref={videoRefs[1]} width="600" autoPlay muted className={styles.video}>
              <source src={video2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div>
            <video ref={videoRefs[2]} width="600" autoPlay muted className={styles.video}>
              <source src={video3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </Slider>
      </div> */}
      <VideoAnimations />
      <h1 className={styles.primatyText}>
        Get shortlisted for your next Interview!
      </h1>
      <div
        className={styles.secondaryText}
      >
        Build a tailored resume with a free cover letter as per the job requirements and beat Applicant
        Tracking Systems (ATS) with ease.
      </div>

      <div className={styles.buttonsContainer}>
        <button onClick={handleBuildResume} className={styles.primaryButton}>
          Build Your Resume
        </button>
        <button onClick={trySample} className={styles.secondaryButton}>
          Try Sample
        </button>
      </div>
    </div>
  );
};

const FeaturesSection = () => {
  return (
    <div className={styles.featuresContainer}>
      <div className={styles.feature}>
        <img className={styles.img} src={time} alt="time" />
        <div className={styles.featuresText}>
          Perfect resume and cover letter<br /> in
          <span className={styles.highlight}>&nbsp;&lt; 5mins</span>
        </div>
      </div>

      <div className={styles.feature}>
        <img className={styles.img} src={save} alt="time" />
        <div className={styles.featuresText}>
          Improve Resume Score <br /> by
          <span className={styles.highlight}>&nbsp;&gt; 30%</span>
        </div>
      </div>

      <div className={styles.feature}>
        <img className={styles.img} src={star} alt="time" />
        <div className={styles.featuresText}>
          Highly Rated by  <br />
          <span className={styles.highlight}>&nbsp;HR Professionals</span>
        </div>
      </div>
    </div>
  );
};

const PrepareResumeSection = (props) => {
  const { trySample } = props;
  return (
    <div className={styles.prepareResumeContainer}>
      <div className={styles.title}>
        Resume Builder
      </div>


      <div className={styles.imageContainer}>
        {/* <img
          className={styles.img}
          src={prepareResume}
          alt="Preparing Resume image"
        /> */}

        <ResumeVideoAnimation />

        <div className={styles.description}>
          <div className={styles.text}>
            By using advanced AI algorithms, paññã optimizes your resume for ATS screenings and
            gets noticed by the hiring managers. Our platform tailors resumes to each job, integrating keywords,
            skills, and formatting for maximum impact. Our resume builder prioritizes making your resume ATS-friendly,
            rather than focusing on its design or visual elements.          </div>

          <div className={styles.text}>
            Whether you're seasoned or starting out, our tool helps you create standout resumes in today's job market.
          </div>
          <div className={styles.text}>
            Boost Your Chances with AI-Generated Cover Letter for Free!
          </div>
        </div>
      </div>

      <div className={styles.buttonContainer}>
        <button className={styles.primaryButton} onClick={trySample}>
          Try sample AI resume
        </button>
      </div>
    </div>
  );
}

const SubFeatures = () => {
  return (
    <div className={styles.subFeaturesContainer}>
      <div className={styles.subTitle}>FEATURES</div>
      <div className={styles.title}>Discover our tool</div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={puzzle} alt="puzzle" className={styles.img} />
          <div className={styles.heading}>Instant Job Match Analysis</div>
        </div>

        <div className={styles.featureText}>
          Get a clear, percentage-based score on how well your resume aligns with your desired role.
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={ai} alt="AI" className={styles.img} />
          <div className={styles.heading}>AI-Powered Rewriting</div>
        </div>

        <div className={styles.featureText}>
          Our intelligent system rewrites your resume, tailoring it to the specific job description
          and showcasing relevant skills.
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={customization} alt="Customization" className={styles.img} />
          <div className={styles.heading}>Customized Resume Building</div>
        </div>

        <div className={styles.featureText}>
          Input a job description, and our tool generates a tailored resume instantly.
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={templates} alt="template" className={styles.img} />
          <div className={styles.heading}>Professional Template</div>
        </div>

        <div className={styles.featureText}>
          Template that is professional and also is readable by the Application tracking systems (ATS)
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={keywords} alt="keywords" className={styles.img} />
          <div className={styles.heading}>Keyword Optimization</div>
        </div>

        <div className={styles.featureText}>
          Ensure your resume includes relevant industry-specific keywords to pass ATS checks.
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={ats} alt="" className={styles.img} />
          <div className={styles.heading}>ATS-Friendly Formatting</div>
        </div>

        <div className={styles.featureText}>
          Focus on generating the resume in the format maximising the chances of clearing ATS-screening and not on visually attractive elements.        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={instantMatch} alt="instantMatch" className={styles.img} />
          <div className={styles.heading}>Instant Job Match Scoring</div>
        </div>

        <div className={styles.featureText}>
          Tailor your resume instantly to any job description and get a score, ensuring you
          stand out to employers like never before.
        </div>
      </div>

      <div className={styles.feature}>
        <div className={styles.headingContainer}>
          <img src={easyEdit} alt="easyEdit" className={styles.img} />
          <div className={styles.heading}>Easy Editing</div>
        </div>

        <div className={styles.featureText}>
          Update and customize your resume effortlessly as your career evolves.
        </div>
      </div>
    </div>
  )
}

const QuestionAnswerToggle = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAnswer = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  return (
    <div className={styles.faqsContainer}>
      <div className={styles.heading}>FAQs</div>

      <div className={styles.questionContainer}>
        {FaqData.map((data, index) => (
          <div className={styles.questionAns} key={index}>
            <div onClick={() => toggleAnswer(index)} className={styles.question}>
              <div>{data.question}</div>
              <div>
                {openIndex === index ? <div className={styles.close}>
                  -
                </div> :
                  <div className={styles.open}>
                    +
                  </div>}
              </div>

            </div>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  key="answer"
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  style={{ overflow: 'hidden' }}
                >
                  <div className={styles.answer}>{data.answer}</div>
                </motion.div>
              )}
            </AnimatePresence>

          </div>
        ))}
      </div>
    </div>
  );
};

const ArrowBox1 = ({ content, name }) => {
  return (
    <div className={styles.box1}>
      <div className={styles.parentShadow}>
        <div className={styles.arrowBox}>
          <div>{content}</div>
          <div className={styles.name}>- {name}</div>
          <div className={styles.triangleContainer}>
            <div className={styles.triangle}></div>
          </div>
        </div>
        <div className={styles.triangleContainer1}>
          <div className={styles.triangle1}></div>
        </div>
      </div>
    </div>
  );
}

const ArrowBox2 = ({ content, name }) => {
  return (
    <div className={styles.box2}>
      <div className={styles.parentShadow}>
        <div className={styles.arrowBox}>
          <div>{content}</div>
          <div className={styles.name}>- {name}</div>
          <div className={styles.triangleContainer}>
            <div className={styles.triangle}></div>
          </div>
        </div>
        <div className={styles.triangleContainer1}>
          <div className={styles.triangle1}></div>
        </div>
      </div>
    </div>);
}

const Testimonial = (props) => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 35000,
    autoplaySpeed: 10,
    autoplay: true,
    rtl: true,
    pauseOnHover: true,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  };

  const { loading } = props;
  const sliderRef = useRef(null);

  useEffect(() => {
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay
    }
  }, [loading]);

  return (
    <div className={styles.slider}>
      <div
        className={styles.heading}
        style={{
          fontSize: '32px',
          fontWeight: 700,
          lineHeight: '40px',
          width: '90%',
          margin: '0 auto',
          marginBottom: '50px'
        }}
      >
        Testimonials
      </div>
      <Slider {...settings} ref={sliderRef}>
        <div>
          <ArrowBox1 content="Impressive results! With just a few clicks, I had a professional resume that got me noticed by recruiters. Definitely worth it!" name="Emily Peterson" />
        </div>

        <div>
          <ArrowBox2 content="The AI-built resume was good. which landed me more interviews." name="Sarah Jones " />
        </div>

        <div>
          <ArrowBox1 content="Got more interview calls after submitting the resume built by this tool." name="Aditi Kashyap" />
        </div>

        <div>
          <ArrowBox2 content="Tool analysed the job description and highlighted my skills perfectly. I landed multiple interviews within days of using it!" name="Lisa Webb" />
        </div>

        <div>
          <ArrowBox1 content="I've tried other resume builders, but no other tool gave me a resume as per the job description. A good tool to generate focused resumes. Highly recommended!" name="Samantha Lawson" />
        </div>
      </Slider>
    </div>
  );
}

const PurchaseSection = (props) => {
  const { isIndia, handleBuildResume } = props;

  return (
    <div className={styles.content3}>
      <div className={styles.text}>
        Get started <br /> transforming your <br /> resume today!
      </div>
      {isIndia ? (
        <div className={styles.heading}>

          &nbsp;<span className={styles.priceNew}>₹499</span>
        </div>
      ) : (
        <div className={styles.heading}>
          <span className={styles.priceNew}>$4.99</span>
        </div>
      )}
      <div className={styles.btnContainer}>
        <button className={styles.primaryButton}
          id="optimize-My-Resume-Now2"
          onClick={() => {
            handleBuildResume();
          }}
        >
          Optimize My Resume Now
        </button>
      </div>
    </div>
  );
};

const Footer = () => {

  const navigate = useNavigate();

  const termsPopup = () => {
      navigate("/terms-of-service");
  }

  const privacyPopup = () => {
      navigate("/privacy-policy");
  }

  return (
    <>
      <footer className={styles.footer}>
        <div className={styles.contentWrap}>
          <div className={styles.copyRight}>© Copyright {new Date().getFullYear()}. Paññã. All rights reserved</div>
          <div onClick={() => privacyPopup()} className={styles.privacy}>Privacy</div>
          <div onClick={() => termsPopup()} className={styles.privacy}>Terms</div>
          <a id="supportMail" href="mailto:support@panna.ai" className={styles.reachOut}>support@panna.ai</a>

          <div className={styles.socialLinks}>
            <a id="pannawayLinkedin" href="https://www.linkedin.com/company/pannaway/" target="_blank" rel="noopener noreferrer">
              <img src={linkedin} alt="" className={styles.socialLink} />
            </a>
            <a id="pannaYoutube" href="https://www.youtube.com/channel/UCOMclJ0qdIbGonee1r-hGWQ" target="_blank" rel="noopener noreferrer">
              <img src={youtube} alt="" className={styles.socialLink} />
            </a>
          </div>
        </div>
      </footer>
    </>
  );
};

const BuildAIResumeView = () => {
  const { authState, loading } = useAuth();
  const data = useSelector(state => state);
  const navigate = useNavigate();
  const isPaid = authState?.paymentSessionId;
  const [open, setOpen] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const isIndia = BASEPATH.includes('/in') ? true : false;

  // const isIndia = BASEPATH.includes('/in') ? false : false;
  const isMobile = useMediaQuery({ maxWidth: 768 });

  // const ArrowBox3 = ({ content, name }) => {
  //   return (
  //     <div className={styles.box3}>
  //       <div className={styles.parentShadow}>
  //         <div className={styles.arrowBox}>
  //           <div>{content}</div>
  //           <div className={styles.name}>- {name}</div>
  //           <div className={styles.triangleContainer}>
  //             <div className={styles.triangle}></div>
  //           </div>
  //         </div>
  //         <div className={styles.triangleContainer1}>
  //           <div className={styles.triangle1}></div>
  //         </div>
  //       </div>
  //     </div>);
  // }


  const trySample = () => {
    try {
      Utility.trackEvent('resume-build-home', 'BUTTON_CLICK', 'Try Sample');
    } catch (error) {
      console.error('Failed to track event:', error);
    }
    setIsSample(true);
  }
  const handleBuildResume = () => {
    try {
      Utility.trackEvent('resume-build-home', 'BUTTON_CLICK', 'Build Your Resume');
    } catch (error) {
      console.error('Failed to track event:', error);
    }
    if (!authState) {
      navigate('/signup');
      return;
    }

    const emailList = ['tejaswini.vemulapally@mroads.com', 'madhav.vs@mroads.com', 'santhosh.bolla@yopmail.com', 'venkatakrishna.avula@mroads.com', 'lalitha.jampana@mroads.com', 'pradeep.dantuluri@mroads.com']
    const skipPayment = emailList.includes(authState?.userInfo?.emailAddress);
    if (isPaid || skipPayment) {
      navigate('/resume-builder');
    } else {
      setOpen(true);
    }
  };

  const checkResumeScore = () => {
    navigate('/check-resume-score');
  };

  return (
    <>
      {isSample ? (<div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '0px',
        height: '80vh'
      }} id="one">
        <JdAnimation
          title="Building Resume..."
          isDone={true}
          onAnimationEnd={() => {
            if (true) {
              navigate('/sample-builder-report', {
                state: {
                  data: {},
                  aiResumeData: sampleData,
                  prevScoreReport: {},
                  name: 'JOHN ANDREWS',
                  email: 'johnandrews123@gmail.com',
                  phoneNumber: '+1 123 456 7890',
                  s3DownloadLink: 'https://pannaoregon.s3-us-west-2.amazonaws.com/resume/test/f64b61a3-1a0f-452b-b49b-b01a7ea5500dResumebuildersamplecopyOLD.docx',
                }
              })
            }
          }}
        />
      </div>) : (
        <>
          <HeroSection trySample={trySample} handleBuildResume={handleBuildResume} />
          <FeaturesSection />
          <PrepareResumeSection trySample={trySample} />
          <SubFeatures />
          <QuestionAnswerToggle />
          <Testimonial loading={loading} />
          {/* <PurchaseSection
            isIndia={isIndia}
            handleBuildResume={handleBuildResume}
          /> */}
          <Footer1 />
          <PaymentModal open={open} setOpen={setOpen} />
        </>
      )}
    </>
  );
};

const Box = () => {
  return (<>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect1}></img>
      <div>
        <div className={styles.head}>Instant Job Match Analysis</div>
        <div className={styles.text}>Get a clear, percentage-based score on how well your resume aligns with your desired role.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect2}></img>
      <div>
        <div className={styles.head}>AI-Powered Rewriting</div>
        <div className={styles.text}>Our intelligent system doesn't just suggest changes; it rewrites your resume, tailoring it to the specific job description.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect3}></img>
      <div>
        <div className={styles.head}>Keyword Optimization</div>
        <div className={styles.text}>Boost your visibility in recruiter searches with the right keywords and phrases for your industry.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect4}></img>
      <div>
        <div className={styles.head}>ATS-Friendly Formatting</div>
        <div className={styles.text}>Focus on generating the resume in the format maximising the chances of clearing ATS-screening and not on visually attractive elements.</div>
      </div>
    </div>

  </>)
}

export default BuildAIResumeView;