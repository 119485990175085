import React from 'react';
import { Navbar } from '../../../component/Navbar';
import Footer1 from '../../../component/Footer1';
import DigitalFrontier from '../../../component/Insights/Insight/navigating-the-digital-frontier/index';
import { Helmet } from 'react-helmet';
class DigitalFrontierInsight extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>paññã Insights</title>
          <meta name="description" content="Explore how technology is revolutionizing resume writing. Learn about AI-driven tools, automated formatting, and keyword optimization to enhance your resume's effectiveness and appeal to employers. Discover more on Paññã Insights!" />
          <meta name="title" content={'Role of Technology in Modern Resume Writing - Panna Insights'} />
        </Helmet>
        <Navbar />
        <DigitalFrontier />
        <Footer1 />
      </>
    );
  }
}

export default DigitalFrontierInsight;
