/* eslint-disable */
export default {
    'Action Verbs': 'action_verbs_comment',
    'Estimated Time to Read': 'estimated_time_to_read_comment',
    'Experience_Consistency': 'experience_comment',
    'Last Modified': 'last_modified_comment',
    'Jd Relevance score': 'relevance_score_comment',
    'Readability': 'resume_complexity_comment',
    'Percentile score': 'resume_percentile_comment',
    'Skill Match': 'skill_match_comment',
    'Summary/Objective': 'summary_comment',
    'Quantitative_Matrixes': 'quantitative_matrixes',
    'Qualification': 'qualification',
}