import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import statistics from '../../../../../assets/insights/evolutionOfJobHunt/statistics.png';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Remember the days of circling job ads in newspapers? Or tirelessly scouring through endless 
        search results on primitive search engines? The way we hunt for jobs has evolved dramatically, 
        transitioning from traditional methods to sophisticated, AI-driven platforms. Today, job 
        aggregators are the cornerstone of the modern job search, offering a centralized hub for a vast 
        array of opportunities.
      </div>

      <div className={styles.title}>
        Job Boards vs. Job Aggregators: Understanding the Difference
      </div>

      <div className={styles.text}>
        While both job boards and job aggregators serve the purpose of connecting employers and job 
        seekers, there are key differences. Job boards typically host listings directly from employers, 
        while job aggregators pull listings from various sources, including company websites, job boards, 
        and even social media. This comprehensive approach gives job aggregators a significant edge, 
        providing a wider range of opportunities and saving job seekers valuable time and effort.
      </div>

      <div className={styles.title}>
        The Power of Job Aggregators: Unlocking a World of Opportunities
      </div>

      <div className={styles.text}>
        Job aggregators offer a plethora of benefits that make them an indispensable tool for job seekers:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Vast Reach: </strong>
            Aggregators cast a wide net, pulling in job postings from numerous sources, ensuring you 
            don't miss out on hidden gems.
          </li>

          <li className={styles.listItem}>
            <strong>Time Efficiency: </strong>
            Instead of hopping from site to site, aggregators consolidate listings in one place, streamlining 
            your search.
          </li>

          <li className={styles.listItem}>
            <strong>Advanced Search Filters: </strong>
            Refine your search based on location, industry, salary, and more, tailoring results to your 
            specific needs.
          </li>

          <li className={styles.listItem}>
            <strong>Job Alerts: </strong>
            Stay ahead of the curve with email alerts for new postings that match your criteria.
          </li>

          <li className={styles.listItem}>
            <strong>Career Resources: </strong>
            Many aggregators offer additional resources like resume builders, interview tips, and salary 
            comparisons.
          </li>
        </ul>
      </div>

      <div className={styles.highlightBg}>
        <div className={styles.highlightText}>
          What is the best job aggregator? How to choose a job aggregator?
        </div>
      </div>

      <div className={styles.title}>
        Choosing the Right Job Aggregator: Factors to Consider
      </div>

      <div className={styles.text}>
        Selecting the right job aggregator can significantly impact your job search success. Consider 
        these factors when making your choice:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Industry Focus: </strong>
            Some aggregators cater to specific industries, while others offer a broader range of opportunities.
          </li>

          <li className={styles.listItem}>
            <strong>Geographic Reach: </strong>
            If you're open to relocating, choose an aggregator with a wide geographic scope.
          </li>

          <li className={styles.listItem}>
            <strong>User Interface: </strong>
            A user-friendly interface can make your search more efficient and enjoyable.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            Look for aggregators that offer extra features like salary comparisons, company reviews, 
            and career advice.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Optimizing Your Job Aggregator Experience: Tips and Tricks
      </div>

      <div className={styles.text}>
        To maximize your chances of success on job aggregators, follow these tips:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Create a Strong Profile: </strong>
            A well-crafted profile with a clear career objective and relevant skills can attract 
            potential employers.
          </li>

          <li className={styles.listItem}>
            <strong>Tailor Your Resume: </strong>
            Customize your resume for each application, highlighting skills and experiences that align 
            with the job description.
          </li>

          <li className={styles.listItem}>
            <strong>Use Keywords Strategically: </strong>
            Incorporate relevant keywords into your profile and resume to increase visibility in search 
            results.
          </li>

          <li className={styles.listItem}>
            <strong>Set Up Job Alerts: </strong>
            Receive notifications for new postings that match your criteria, ensuring you're among the 
            first to apply.
          </li>

          <li className={styles.listItem}>
            <strong>Network Actively: </strong>
            Connect with other users and recruiters on the platform to expand your professional network.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Top Job Aggregators in India and the USA: A Comparative Analysis
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>1.</span> 
        <a href='https://www.indeed.com' className={styles.linkTitle} target='_blank'>
          Indeed
        </a>
        <span className={styles.linkTitle}>: </span>
        The global giant, Indeed, boasts an extensive database of job postings across various industries 
        and locations. Its user-friendly interface and powerful search filters make it a popular choice 
        for job seekers worldwide.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, resume search, company reviews, salary information, job alerts.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Sponsored job postings, resume access, candidate search, employer branding, analytics.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            Indeed Resume, Indeed Assessments, Indeed Company Pages, Indeed Hiring Events.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            All types of job seekers and employers, especially those looking for a wide range of 
            opportunities.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Large database of job postings, user-friendly interface, powerful search filters, free 
            features for job seekers.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Can be overwhelming due to the sheer number of listings, sponsored job postings can be 
            expensive.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          Indeed is the most visited job site in the world, with over 250 million unique visitors each 
          month.
        </div>
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>2.</span> 
        <a href='https://www.naukri.com/' className={styles.linkTitle} target='_blank'>
          Naukri
        </a>
        <span className={styles.linkTitle}>: </span>
        A leading job portal in India, Naukri caters specifically to the Indian job market, 
        offering a wide range of opportunities across various sectors.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, resume search, company reviews, salary information, job alerts.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Sponsored job postings, resume access, candidate search, employer branding, analytics.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            Naukri Fast-forward, Naukri Learning, Naukri Gulf.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            Indian job seekers and employers, especially those looking for jobs in India.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Large database of Indian job postings, user-friendly interface, strong brand recognition 
            in India.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Limited to the Indian job market, sponsored job postings can be expensive.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          Naukri has over 76 million registered job seekers and 51,000+ corporate customers.
        </div>
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>3.</span> 
        <a href='https://www.linkedin.com/' className={styles.linkTitle} target='_blank'>
          LinkedIn
        </a>
        <span className={styles.linkTitle}>: </span>
        While primarily a professional networking platform, LinkedIn also features a robust 
        job board with listings from top companies. Its focus on networking and building 
        professional connections makes it a valuable resource for job seekers.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, limited resume search, company pages, groups, messaging.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Unlimited resume search, InMail credits, profile views, salary insights, job 
            seeker guidance.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            LinkedIn Learning, LinkedIn Salary, LinkedIn Recruiter.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            Professionals and job seekers looking to network and build professional connections.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Strong focus on networking, large database of professionals, valuable insights 
            into company culture.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Limited number of job postings compared to other aggregators; premium features 
            can be expensive.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          LinkedIn has over 875 million members in more than 200 countries and territories 
          worldwide.
        </div>
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>4.</span> 
        <a href='https://www.shine.com/' className={styles.linkTitle} target='_blank'>
          Shine.com
        </a>
        <span className={styles.linkTitle}>: </span>
        Another popular job portal in India, Shine.com offers a variety of job listings 
        across different industries and experience levels.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, resume search, company reviews, salary information, job alerts.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Sponsored job postings, resume access, candidate search, employer branding, 
            analytics.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            Shine Learning, Shine Profile, Shine Career Plus.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            Indian job seekers and employers, especially those looking for entry-level and 
            mid-level jobs.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Wide range of job listings, user-friendly interface, affordable premium features.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Smaller database of job postings compared to Naukri and Indeed, limited to the 
            Indian job market.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          Shine.com has over 30 million registered users and 300,000+ job postings.
        </div>
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>5.</span> 
        <a href='https://www.glassdoor.co.in/' className={styles.linkTitle} target='_blank'>
          Glassdoor
        </a>
        <span className={styles.linkTitle}>: </span>
        Known for its company reviews and salary information, Glassdoor also features a job 
        board with listings from various companies. Its transparency and insights into 
        company culture make it a valuable resource for job seekers.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, company reviews, salary information, interview questions, benefits 
            reviews.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Sponsored job postings, employer branding, analytics.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            Glassdoor Interview Prep, Glassdoor Salaries, Glassdoor Company Pages.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            Job seekers who value transparency and insights into company culture.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Valuable insights into company culture, salary information, and interview 
            questions.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Smaller database of job postings compared to other aggregators, limited 
            employer branding features.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          Glassdoor has over 1.3 million employer reviews and 55 million company ratings.
        </div>
      </div>

      <div className={styles.text}>
        <span className={styles.linkTitle}>6.</span> 
        <a href='https://www.ziprecruiter.com/' className={styles.linkTitle} target='_blank'>
          ZipRecruiter
        </a>
        <span className={styles.linkTitle}>: </span>
        A popular job aggregator in the USA, ZipRecruiter aggregates listings from various 
        sources and distributes them to a network of job boards. Its AI-powered matching 
        technology helps connect job seekers with relevant opportunities.
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Free Features: </strong>
            Job posting, limited resume search, job alerts.
          </li>

          <li className={styles.listItem}>
            <strong>Premium Features: </strong>
            Sponsored job postings, resume access, candidate search, employer branding, 
            analytics.
          </li>

          <li className={styles.listItem}>
            <strong>Additional Features: </strong>
            ZipRecruiter Traffic Boost, ZipRecruiter Apply, ZipRecruiter Interview.
          </li>

          <li className={styles.listItem}>
            <strong>ATS: </strong>
            Yes
          </li>

          <li className={styles.listItem}>
            <strong>Best For: </strong>
            US job seekers and employers, especially those looking for a wide range of 
            opportunities.
          </li>

          <li className={styles.listItem}>
            <strong>Pros: </strong>
            Large database of job postings, AI-powered matching technology, wide range of 
            features for employers.
          </li>

          <li className={styles.listItem}>
            <strong>Cons: </strong>
            Can be expensive for employers, limited free features for job seekers.
          </li>
        </ul>
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Interesting Statistic: </strong>
          ZipRecruiter has over 30 million job seekers and 1.8 million employees using its 
          platform.
        </div>
      </div>

      <div className={styles.title}>
        Proceed with Caution: Navigating the Pitfalls of Job Aggregators
      </div>

      <div className={styles.text}>
        While job aggregators offer immense value, it's important to use them wisely. Avoid 
        these common pitfalls:
      </div>

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Relying Solely on Aggregators: </strong>
            Don't limit your search to aggregators alone. Explore other avenues like 
            networking, company websites, and social media.
          </li>

          <li className={styles.listItem}>
            <strong>Applying to Every Job: </strong>
            Focus on quality over quantity. Tailor your applications to specific job 
            descriptions to increase your chances of success.
          </li>

          <li className={styles.listItem}>
            <strong>Ignoring Red Flags: </strong>
            Be wary of suspicious job postings or companies with negative reviews.
          </li>

          <li className={styles.listItem}>
            <strong>Neglecting Your Online Presence: </strong>
            Maintain a professional online presence on platforms like LinkedIn to attract 
            potential employers.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        The Importance of Cover Letters and Optimized Resumes
      </div>

      <div className={styles.text}>
        A well-crafted cover letter and an optimized resume are crucial for standing out in 
        the competitive job market. Your cover letter should highlight your unique qualifications 
        and demonstrate your enthusiasm for the position, while your resume should be tailored 
        to the specific job description, showcasing your relevant skills and experiences.
      </div>

      <div className={styles.statisticsContainer}>
        <div>
          <img
            src={statistics}
            className={styles.statisticsIcon}
            alt='statistics icon'
          />
        </div>
        <div className={styles.statisticsText}>
          <strong>Expert Insight from Seasoned Professional</strong><br />
          "Job aggregators are a powerful tool, but they're just one piece of the puzzle. 
          Networking, referrals, and direct outreach to companies are equally important for 
          a successful job search." - John Smith, Senior Recruiter.
        </div>
      </div>

      <div className={styles.title}>
        Conclusion: Embrace a Multi-Faceted Approach to Job Hunting
      </div>

      <div className={styles.text}>
        In the ever-evolving landscape of job hunting, it's crucial to adopt a multi-faceted 
        approach. While job aggregators offer a convenient and efficient way to explore 
        opportunities, don't neglect other avenues like networking, social media, referrals, 
        and recruiters. Research individual job aggregators related to your domain and tailor 
        your approach accordingly. By combining these strategies, you can maximize your 
        chances of landing your dream job.
      </div>

      <div className={styles.title}>
        Alternative Job Search Strategies: Exploring Beyond Aggregators
      </div>

      <div className={styles.text} />

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            <strong>Networking: </strong>
            Attend industry events, join professional organizations, and connect with people 
            in your field.
          </li>

          <li className={styles.listItem}>
            <strong>Career Fairs: </strong>
            Meet with recruiters from various companies and learn about potential job 
            openings.
          </li>

          <li className={styles.listItem}>
            <strong>Company Websites: </strong>
            Check the career pages of companies you're interested in for job postings.
          </li>

          <li className={styles.listItem}>
            <strong>Social media: </strong>
            Follow companies on social media for updates on job openings and company culture.
          </li>

          <li className={styles.listItem}>
            <strong>Referrals: </strong>
            Ask friends, family, and former colleagues if they know of any open positions.
          </li>

          <li className={styles.listItem}>
            <strong>Career Coach: </strong>
            Consult a career coach for personalized guidance and support in your job search.
          </li>
        </ul>
      </div>

      <div className={styles.title}>
        Niche Job Sites: Catering to Specific Industries and Interests
      </div>

      <div className={styles.text} />

      <div>
        <ul className={styles.listWithLink}>
          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.idealist.org/'
              target='_blank'
            >
              Idealist
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Focuses on non-profit organizations and social impact careers.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.snagajob.com/'
              target='_blank'
            >
              Snagjob
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Caters to hourly workers and service industry jobs.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.theladders.com/'
              target='_blank'
            >
              Ladders
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Specializes in high-paying executive and management positions.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.healthecareers.com/'
              target='_blank'
            >
              Health eCareers
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Focuses on healthcare industry jobs.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.iimjobs.com/'
              target='_blank'
            >
              iimjobs.com
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Exclusive to management professionals.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.freshersworld.com/'
              target='_blank'
            >
              Freshersworld.com
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Primary focus is on entry-level jobs.
            </span>
          </li>

          <li className={styles.listItem}>
            <a
              className={styles.linkTitle}
              href='https://www.internshala.com/'
              target='_blank'
            >
              Internshala
            </a>
            <span className={styles.linkTitle}>: </span>
            <span className={styles.text}>
              Focuses on career services to students and employers.
            </span>
          </li>
        </ul>
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        FAQs: Your Burning Questions Answered
      </div>

      <div className={styles.text} />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Are job aggregators free to use?</strong><br />
            Most job aggregators are free for job seekers. However, some may offer premium 
            features for a fee.
          </li>

          <li className={styles.listItem}>
            <strong>How often are job postings updated on aggregators? </strong><br />
            Job postings are typically updated daily or weekly, depending on the aggregator.
          </li>

          <li className={styles.listItem}>
            <strong>Can I apply for jobs directly through job aggregators? </strong><br />
            Yes, most job aggregators allow you to apply for jobs directly through their 
            platform.
          </li>

          <li className={styles.listItem}>
            <strong>Are job aggregators safe to use? </strong><br />
            Yes, reputable job aggregators take measures to protect user data and prevent 
            fraudulent job postings.
          </li>

          <li className={styles.listItem}>
            <strong>
              How can I increase my chances of getting noticed by employers on job aggregators?
            </strong><br />
            Create a strong profile, tailor your resume for each application, use keywords 
            strategically, and set up job alerts.
          </li>
        </ol>
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;
