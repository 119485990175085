import React from 'react'
import FullPageView from '../FullPageView'
import Custombutton from '../CustomButton'
import styles from './styles.module.css'
import RenderTitle from '../RenderTitle'
// TODO remove errorImage from State, render it based on error type
//  TODO remove hard code strings and move to contants
const Errorpopup = props => {
  const { errorMessage, errorImage, onErrorRetry, history } = props;
  return (
    <FullPageView style={styles.errorContentContainer} history={history}>
      <RenderTitle />
      <div className={styles.errorContainer}>
        <img src={errorImage} alt="" className={styles.unDrawStyle} />
        <div className={styles.errorMessageWrap}>
          <div className={styles.errorMessage}>{errorMessage}</div>
          <Custombutton
            containerStyles={styles.TryAgainButton}
            arrowStyle={styles.tryAgainArrow}
            textStyle={styles.tryAgain}
            text="TRY AGAIN"
            onClick={onErrorRetry}
            onKeyPress={onErrorRetry}
          />
        </div>
      </div>
    </FullPageView>
  )
}

export default Errorpopup;