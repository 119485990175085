import React from 'react';
import styles from './styles.module.scss';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's fiercely competitive job market, the utilization of Applicant Tracking Systems 
        (ATS) has surged among employers. These sophisticated software applications are meticulously 
        designed to streamline the hiring process by efficiently managing and sorting through the 
        copious resumes received for job openings. While ATS can undoubtedly offer benefits to 
        employers by enhancing efficiency, they also pose formidable challenges for job seekers. 
        Successfully navigating ATS necessitates a strategic approach to ensure that your resume 
        stands out amidst the numerous applications and reaches the eyes of hiring managers. In 
        this comprehensive guide, we'll delve into the intricacies of ATS and provide invaluable 
        tips on optimizing your resume to maximize your chances of success in landing your dream job.
      </div>

      <div className={styles.title}>
        Understanding Applicant Tracking Systems
      </div>

      <div className={styles.text}>
        ATS is a potent tool employed by employers to automate and oversee the recruitment process. 
        These systems are intricately programmed to scan resumes submitted by candidates and 
        meticulously filter them based on specific criteria set by the employer. By meticulously 
        analyzing keywords, skills, and qualifications relevant to the job opening, ATS empowers 
        recruiters to swiftly identify top candidates with precision and efficiency. However, to 
        ensure that your resume clears the initial screening process, it's imperative to understand 
        how ATS operates and meticulously tailor your resume accordingly.
      </div>

      <div className={styles.title}>
        Tips for Optimizing Your Resume for ATS
      </div>

      <div className={styles.textFlex}>
        <div>
          1.
        </div>
        <div>
          Use Relevant Keywords: Arguably the most pivotal aspect of optimizing your resume for ATS 
          is the strategic integration of keywords. Delve into the job description with meticulous 
          attention to detail, identifying keywords and phrases commonly used in the industry. 
          Seamlessly incorporate these keywords throughout your resume, with a particular emphasis 
          on the professional summary, skills section, and work experience.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          2.
        </div>
        <div>
          Format Your Resume Appropriately: When formatting your resume for ATS, simplicity reigns 
          supreme. Opt for a clean and straightforward layout written with standard fonts and 
          headings. Do not incorporate images, charts, or intricate formatting that may be ignored 
          by the system or lead to misinterpretation of your resume. Instead, embrace bullet points 
          and crisp sentences to effectively present your qualifications.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          3.
        </div>
        <div>
          Include a Professional Summary: Kickstart your resume with a concise professional summary 
          that serves as a compelling narrative of your key skills, experiences, and career 
          objectives. This section should be built with relevant keywords and offer a sufficient 
          overview of your qualifications to captivate the attention of both ATS and recruiters. 
          Try to customize your professional summary for each job application to ensure seamless 
          alignment with the specific job requirements.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          4.
        </div>
        <div>
          Optimize Your Job Titles and Headings: Embrace standard job titles and headings that 
          accurately encapsulate your experience and qualifications. Sidestep abbreviations or 
          unconventional job titles that may elude recognition by ATS. Maintain consistency in 
          your use of terminology throughout your resume and ascertain that your job titles aptly 
          reflect the roles you've previously held.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          5.
        </div>
        <div>
          Quantify Your Achievements: Infuse your resume with quantifiable achievements to augment 
          its impact and facilitate seamless parsing by ATS. Utilize numbers, percentages, or metrics 
          to quantify the outcomes of your accomplishments in past roles. For instance, rather than 
          simply stating "increased sales," state the percentage by which you augmented sales and 
          furnish concrete examples of strategies or initiatives you spearheaded to achieve that 
          growth.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          6.
        </div>
        <div>
          Proofread Your Resume: Prior to submitting your resume, undertake meticulous proofreading 
          to eradicate any possible spelling or grammatical errors. ATS may penalize resumes tainted 
          by such errors, thereby underscoring the paramount importance of diligent proofreading. 
          Consider utilizing a spell-checking tool or enlisting the assistance of a trusted friend 
          or colleague to scrutinize your resume for any imperfections. Additionally, ensure that 
          your resume boasts a correct format and is effortlessly digestible, both for ATS and human 
          recruiters.
        </div>
      </div>

      <div className={styles.textFlex}>
        <div>
          7.
        </div>
        <div>
          Submit in the Right Format: Adhere to the employer's stipulations concerning resume 
          submission. Certain ATS may have a preference for resumes in specific file formats such 
          as .docx or .pdf; hence, it will need you to conform to their preferences to avert 
          compatibility issues. Furthermore, contemplate incorporating relevant keywords into the 
          file name of your resume to further optimize its resonance with ATS.
        </div>
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        Conclusion
      </div>

      <div className={styles.text}>
        While navigating ATS may seem like a formidable undertaking, with the right strategies and 
        techniques, you can optimize your resume to resonate with both ATS and discerning hiring 
        managers. By gaining a nuanced understanding of how ATS operates and diligently implementing 
        these optimization strategies, you can exponentially improve your prospects of clearing the 
        initial screening process and getting shortlisted for the interviews. Maintain a proactive 
        stance, stay attuned to industry trends, and keep working towards your goal for career 
        advancement and fulfilment.
      </div>

      <div className={styles.text}>
        <a href="https://www.linkedin.com/company/pannaway/" target='_blank'>
        The Paññã Way</a> can build you a resume which ranks high in the ATS system in 3 easy steps and 
        in less than 5mins. Its advanced AI algorithms seamlessly weave the right skills and 
        keywords from the job description into the resume, so that the ATS systems pick them up 
        and ultimately the recruiters notice you.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        { ...meta }
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;