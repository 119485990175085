import React from 'react'
import styles from './styles.module.css'
import Button from '../../component/Button'
import sortArrow from '../../assets/jdToResume/sortArrow.png';
import quitImg from '../../assets/JobDescriptionField/quit.png';
import starEmpty from '../../assets/jdToResume/survey-star-empty.png'
import starFill from '../../assets/jdToResume/survey-star-filled.png'
import staticContent from './data';

const tableColumns = [
    { name: 'Skills in job description', styles: styles.col1 },
    { name: 'Job description Count', styles: styles.col2 },
    { name: 'Resume Count', styles: styles.col3 },
    { name: '', styles: styles.col4 },
];

const MatchUnmatchedCols = [
    { name: 'Matched', styles: styles.col1 },
    { name: 'Unmatached', styles: styles.col2 },
]

class TabularTable extends React.Component {
    parsedSkillData = [];
    state = {
        skillData: [],
        sortJd: 'desc',
        sortResume: '',
        showMore: false,
        showSkills: true,
    }
    componentDidMount() {
        this.parseSkillWithFrequency()
    }

    componentDidUpdate(nextProps) {
        const { jdSkills, onDoneDeleteSuccess } = this.props;
        const skills = Object.keys(jdSkills);
        const newSkills = Object.keys(nextProps.jdSkills);
        if (newSkills.length !== skills.length) {
            this.parseSkillWithFrequency();
        }
    }

    parseSkillWithFrequency = () => {
        const { jdSkills, resumeSkills, skillCategoryData } = this.props;
        const skills = Object.keys(jdSkills);
        const tempSkillData = [];
        skills.forEach(skill => {
            tempSkillData.push({
                skill: skill,
                jdSkillCount: jdSkills[skill],
                resumeSkillCount: resumeSkills[skill],
            })
        })
        const sortDescending = tempSkillData.sort((a, b) => {
            return b.jdSkillCount - a.jdSkillCount;
        });
        this.parsedSkillData = sortDescending;
        this.totalSkills = skillCategoryData;
        this.setState({ skillData: this.parsedSkillData })
    }

    sortCol = index => {
        const { sortJd, sortResume, skillData } = this.state;
        if (index == 1) {
            let sortDescending = [];
            if (sortJd === 'desc') {
                sortDescending = skillData.sort((a, b) => {
                    return a.jdSkillCount - b.jdSkillCount;
                });
                this.setState({ skillData: sortDescending, sortJd: 'asc', sortResume: '' })
            } else {
                sortDescending = skillData.sort((a, b) => {
                    return b.jdSkillCount - a.jdSkillCount;
                });
                this.setState({ skillData: sortDescending, sortJd: 'desc', sortResume: '' })
            }
        } else if (index == 2) {
            let sortDescending = [];
            if (sortResume === 'desc') {
                sortDescending = skillData.sort((a, b) => {
                    return a.resumeSkillCount - b.resumeSkillCount;
                });
                this.setState({ skillData: sortDescending, sortResume: 'asc', sortJd: '' })
            } else {
                sortDescending = skillData.sort((a, b) => {
                    return b.resumeSkillCount - a.resumeSkillCount;
                });
                this.setState({ skillData: sortDescending, sortResume: 'desc', sortJd: '' })
            }
        }
    }

    renderTableContent = () => {
        const { showMore } = this.state;
        this.setState({ showMore: !showMore }, () => {
            const { showMore } = this.state;
            let tempSkill = showMore ? this.parsedSkillData : this.parsedSkillData.filter((_Skill, index) => index <= 5);
            this.setState({ skillData: tempSkill });
        })
    }

    onSkillClick = () => {
        this.setState({ showSkills: true })
    }

    onSkillCountClick = () => {
        this.setState({ showSkills: false })
    }

    onDeleteClick = () => {
        const { ondeletePressed } = this.props;
        ondeletePressed();
    }

    onCancleClicked = () => {
        const { onDeleteCancel } = this.props;
        onDeleteCancel();
    }

    deleteSkills = () => {
        const { onDoneDelete } = this.props;
        onDoneDelete();
    }

    render() {
        const { skillData, sortJd, sortResume, showSkills } = this.state;
        const { skillCategoryData: skills, onDeleteSkills, deletedSkillCount } = this.props;
        return (
            <div className={styles.tableContainer}>
                {/* <div className={styles.tableCol1}>
                    <div role="button" style={{background: showSkills ? '#DAE8FF' : '#FFFFFF', border: showSkills ? '1px solid dodgerblue' : '1px solid #CFCFCF'}} className={styles.skillTab} onClick={this.onSkillClick} onKeyPress={this.onSkillClick} tabIndex="0">Skills</div>
                    <div role="button" style={{background: showSkills ? '#FFFFFF' : '#DAE8FF', border: showSkills ? '1px solid #CFCFCF' : '1px solid dodgerblue'}} className={styles.skillCountTab} onClick={this.onSkillCountClick} onKeyPress={this.onSkillCountClick} tabIndex="0">Skill Count Comparison</div>
                </div> */}
                <div className={styles.tableWrap}>
                    <TableHeader skillData={skills || []} sortCol={index => this.sortCol(index)} sortJd={sortJd} sortResume={sortResume} showSkills={showSkills} />
                    <RenderRows
                        {...this.props}
                        onDeleteClick={this.onDeleteClick}
                        onDeleteSkill={onDeleteSkills}
                        skillData={skills || []}
                        data={skillData}
                        showSkills={showSkills}
                        onCancel={this.onCancleClicked}
                        onDone={this.deleteSkills}
                        deletedSkillCount={deletedSkillCount} />
                </div>
            </div>
        )
    }
}

const TableHeader = props => {
    const { sortCol, sortJd, sortResume, showSkills, skillData } = props;
    const sortImgStyle = sortJd === 'desc' || sortResume === 'desc' ? styles.downArrow : styles.upArrow;
    return (
        <div className={styles.tableHeader}>
            {showSkills ?
                MatchUnmatchedCols.map((skills, index) => {
                    return (
                        <div role="button" key={`${skills.name}${index}`} onClick={() => sortCol(index)} onKeyPress={() => sortCol(index)} className={skills.styles} tabIndex="0">
                            <div className={styles.colHeader}>{skills.name}<div className={styles.skillLength}>{skillData && skillData.length !== 0 && Object.keys(skillData.value[index].computedSkills).length}</div></div>
                        </div>
                    )
                })
                : tableColumns.map((col, index) => {
                    return (
                        <div role="button" key={`${col.name}${index}`} onClick={() => sortCol(index)} onKeyPress={() => sortCol(index)} className={col.styles} tabIndex="0">
                            <div style={{ cursor: index !== 0 ? 'pointer' : '' }} className={styles.colHeader}>{col.name}</div>
                            {index === 1 && sortJd !== '' ? <img src={sortArrow} alt="" className={sortImgStyle} /> : null}
                            {index === 2 && sortResume !== '' ? <img src={sortArrow} alt="" className={sortImgStyle} /> : null}
                        </div>
                    )
                })}
        </div>
    );
}

const RenderRows = props => {
    const { data, showSkills, skillData, onDeleteClick, onCancel, onDone, onDeleteSkill, deletedSkillCount, onDoneDeleteSuccess, isSample } = props;
    const matchedSkills = skillData.value[0].computedSkills
    const unMatchedSkills = skillData.value[1].computedSkills
    return (
        <React.Fragment>
            {showSkills ? (
                <React.Fragment>
                    <div className={styles.ConentContainer}>
                        <div className={[styles.col2, styles.col2Skill].join(' ')}>{
                            matchedSkills && matchedSkills.length !== 0 && matchedSkills.map(matchSkill => {
                                return (
                                    <div key={`${matchSkill}`} className={styles.skillTextStyle}>
                                        {matchSkill}
                                        {/* <img src={skillData.value[0].computedSkills[matchSkill] === 'T' ? starFill : starEmpty} alt="" className={styles.starStyle}/> */}
                                        {onDoneDeleteSuccess ? (
                                            <div title="clear" role="button" className={styles.resetButton} onClick={() => onDeleteSkill('matched', matchSkill)} onKeyPress={() => onDeleteSkill('matched', matchSkill)} tabIndex="0">
                                                <img src={quitImg} alt="" className={styles.quiteStyle} />
                                            </div>
                                        ) : null}
                                    </div>
                                )
                            })
                        }</div>
                        <div className={[styles.col3, styles.col3Skill].join(' ')}>{
                            unMatchedSkills && unMatchedSkills.length !== 0 && unMatchedSkills.map(matchSkill => {
                                return (
                                    <div key={`${matchSkill}`} className={styles.skillTextStyle}>
                                        {matchSkill}
                                        {/* <img src={skillData.value[1].computedSkills[matchSkill] === 'T' ? starFill : starEmpty} alt="" className={styles.starStyle}/> */}
                                        {onDoneDeleteSuccess ? (
                                            <div title="clear" role="button" className={styles.resetButton} onClick={() => onDeleteSkill('unmatched', matchSkill)} onKeyPress={() => onDeleteSkill('unmatched', matchSkill)} tabIndex="0">
                                                <img src={quitImg} alt="" className={styles.quiteStyle} />
                                            </div>
                                        ) : null}
                                    </div>
                                )
                            })
                        }</div>
                    </div>
                    {!isSample ?
                        <div className={styles.deleteSkillsWrap}>
                            {!onDoneDeleteSuccess ?
                                <div className={styles.deletebuttonWrap}>
                                    <div className={styles.skillDeleteLabel}>{staticContent.deleteLabel}</div>
                                    <Button id="DeleteSkills" text="Delete Skills" buttonStyle={styles.deleteSkill} onClick={onDeleteClick} />
                                </div>
                                : (
                                    <React.Fragment>
                                        <div className={styles.buttonWrap}>
                                            <Button id="CancelDelete" text="Cancel" buttonStyle={[styles.deleteSkill, styles.CancelButton].join(' ')} onClick={onCancel} />
                                            <Button id="UpdateScore" text="Update Score" buttonStyle={[styles.deleteSkill, styles.doneButton].join(' ')} onClick={onDone} disable={deletedSkillCount === 0} />
                                        </div>
                                        <div className={styles.deleteCount}>{`(${deletedSkillCount} skills deleted)`}</div>
                                    </React.Fragment>
                                )
                            }
                        </div> : null
                    }
                </React.Fragment>
            )
                : (
                    <div className={styles.skillCountWrap}>
                        {
                            data.map((skill, index) => {
                                return (
                                    <div key={`${data.skill}${index}`} className={styles.ConentContainer}>
                                        <div className={[styles.col1, styles.skillCountRow, styles.skillCountRowBorder1].join(' ')}>{skill.skill}</div>
                                        <div className={[styles.col2, styles.skillCountRow, styles.skillCountRowBorder2].join(' ')}>{skill.jdSkillCount}</div>
                                        <div className={[styles.col3, styles.skillCountRow, styles.skillCountRowBorder3].join(' ')}>{skill.resumeSkillCount}</div>
                                    </div>
                                )
                            })
                        }
                    </div>
                )}
        </React.Fragment>
    )
}

export default TabularTable;