import React from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const CircularProgress = ({ percentage }) => {
    return (
        <div style={{ width: '100px', height: '100px' }}>
            <CircularProgressbar
                value={percentage}
                text={`${percentage}%`}
                styles={buildStyles({
                    pathColor: getColor(percentage),
                    textColor: '#000',
                    trailColor: '#d6d6d6',
                    fontWeight: 700,
                    textSize: 30,
                    backgroundColor: '#3e98c7',
                })}
            />
        </div>
    );
};

const getColor = (percentage) => {
    if (percentage > 70) {
        return '#80E0F7';
    }
    else if (percentage > 50) {
        return '#dbdb4c';
    }
    else {
        return '#FF8E80';
    }
}


export default CircularProgress;
