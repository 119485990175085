import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
// import API from '../../network';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Spinner from '../../Spinner';
import ReactToPrint from 'react-to-print';
import pdfIcon from '../../../assets/icons/pdf.png'
import wordIcon from '../../../assets/icons/doc.png'
import RadioButton from './radioButton';
import Button from '../../Button';
import API from '../../../network'
import Privacy from '../../../Privacy';
import { termsData } from '../../../component/Footer/Data';


export const DownloadPopup = ({ name, showFn, onExportWordClick, onExportCoverLetter, componentRef, downLoadPdf, downLoadDoc }) => {
  const [showSpinner, setShowSpinner] = useState(false);
  const containerRef = useRef();
  const [open, setOpen] = useState(true);
  const [selectedValue, setSelectedValue] = useState('option2');
  const [textValue, setTextValue] = useState('');
  const [showTermsPopup, setShowTermsPopup] = useState(false);

  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const downLoadResumeWithCoverLetter = () => {
    onExportWordClick();
    onExportCoverLetter();
    handleFeedbackSubmit();
    showFn(false)
    setOpen(false);
  }

  useEffect(() => {
    const handleClickOutside = event => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        showFn(false)
        setOpen(false);
      }
    };

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [setOpen]);

  const handleFeedbackSubmit = () => {
    if (!textValue) {
      return;
    }
    // Add logic to submit feedback
    // if (thumbsUp) {
    //     console.log('submitting the feedback:', thumbsUp)
    //     const data = {
    //         rating: 5,
    //         comments: ''
    //     }
    //     saveUserFeedBack(data);
    // }
    console.log('submitting the feedback:')
    const data = {
      rating: 5,
      comments: textValue
    }
    saveUserFeedBack(data);
  };

  const saveUserFeedBack = (data) => {
    API.getInstance().saveUserFeedBack(data)
      .then(res => {
        console.log(res);
        console.log('Feedback submitted!');
      })
      .catch(error => {
        console.log(error);

      });
  };

  const onClose = () => {
    setShowTermsPopup(false);
  }
  const openTermsPopUp = () => {
    setShowTermsPopup(true);
  }

  return (
    <>
      {showSpinner ? <Spinner show={true} /> : null}


      {open && <div
        style={{
          zIndex: 999999,
          backdropFilter: 'blur(10px)'
        }}
        className={styles.container}
      >
        <div
          style={{
            backdropFilter: 'blur(10px)'
          }}
          className={!showTermsPopup ? styles.content : styles.termsPopup}
          ref={containerRef}
        >
          {showTermsPopup ? <Privacy heading="Our Terms of Service" onClose={onClose} messageObject={termsData} showAsPopup={true} /> : null}


          <div className={styles.contentContainer}>
            <div className={styles.heading}>
              Download AI generated Resume
              and Cover Letter
            </div>
            <div className={styles.downLoadSection}>
              <div className={styles.topSec}>
                <RadioButton
                  id="radio1"
                  name="customRadio"
                  value="option1"
                  label="Option 1"
                  checked={selectedValue === 'option1'}
                  onChange={handleChange}
                >

                  <div className={styles.wordIcon} onChange={handleChange}>
                    <img src={wordIcon}></img>
                    <span className={styles.span}>Download<br /> word document</span>
                  </div>
                </RadioButton>
                <RadioButton
                  id="radio2"
                  name="customRadio"
                  value="option2"
                  label="Option 2"
                  checked={selectedValue === 'option2'}
                  onChange={handleChange}
                >
                  <div className={styles.pdfIcon} onChange={handleChange}>
                    <img src={pdfIcon}></img>
                    <span className={styles.span}>Download <br />PDF document</span>
                  </div>
                </RadioButton>
              </div>
              <div className={styles.text}>
                Disclaimer Text : By downloading the document you are agreeing to our <span className={styles.terms} onClick={openTermsPopUp}>term and conditions</span>.
              </div>
            </div>
            <div className={styles.feedBackBoxContainer}>
              <textarea className={styles.feedbackBox} value={textValue}
                onChange={(event) => setTextValue(event.target.value)} placeholder="We appreciate your feedback!..." />

              {
                selectedValue === 'option1' ? <Button buttonStyle={styles.btn} text="Download" onClick={downLoadResumeWithCoverLetter} /> :
                  <ReactToPrint
                    documentTitle={name}
                    trigger={() => {
                      return (<div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button onClick={onExportCoverLetter} buttonStyle={styles.btn} text="Download" />
                      </div>
                      );
                    }}
                    content={() => componentRef}
                  />
              }
            </div>
          </div>
        </div>
      </div >
      }
    </>
  );
};


