import React from 'react';
import { useAuth } from '../context/AuthContext';
import { BASEPATH } from '../lib/constants';

function PrivateRoute(props) {
  const { component } = props;
  const context = useAuth();

  const { loading, authState, pannaAuth } = context;

  if (loading) {
    return null;
  }

  if (authState) {
    return <>{component || null}</>;
  }

  // pannaAuth.redirectToSignIn(window.location.href);
  window.location.replace(BASEPATH + `/login`);
  return null;
}

export default PrivateRoute;
