import React from 'react'
import { connect } from 'react-redux';
import styles from './styles.module.css'
import data from './data'
import dropDownImg from '../../assets/jdToResume/sortArrow.png';
import quitImg from '../../assets/JobDescriptionField/quit.png';
import { cacheRecentJDs } from '../../store/actions/cache';

const activeButtonStyle = {
    background: '#777777',
    color: '#ffffff',
    border: '0 solid #EFEFEF',
}

class DropDownList extends React.Component {
    myRef;

    suggestionStyleClass = styles.individualJd;

    state = {
        showDropDown: false,
        dataList: data.jobDescriptions,
        savedJds: [],
        showDeleteIcon: false,
        mouseEnter: true,
    }

    componentDidMount() {
        const { list } = this.props;
        this.setState({ savedJds: list })
        window.addEventListener('click', () => {
            const { showDropDown, mouseEnter } = this.state;
            if (showDropDown && !mouseEnter) {
                this.setState({ showDropDown: false })
            }
        })
    }

    removeSavedJd = jdName => {
        const { reduxProps: { cache: { recentJds } }, dispatchRecentJds, onRemoveSavedJd } = this.props;
        onRemoveSavedJd();
        const tempJDs = recentJds.filter(jd => jd.name !== jdName);
        this.setState({ dataList: tempJDs && tempJDs.length === 0 ? data.jobDescriptions : tempJDs, showDeleteIcon: tempJDs.length !== 0 });
        dispatchRecentJds(tempJDs);
    }

    onDropDownClicked = () => {
        this.setState(prevState => {
            return { showDropDown: !prevState.showDropDown }
        })
    }

    onJDSelected = index => {
        const { dataList, showDeleteIcon } = this.state;
        const { onSelectJd, onSavedJdSelected } = this.props;
        showDeleteIcon ? onSavedJdSelected(dataList[index].description, dataList[index].name) : onSelectJd(dataList[index].description, dataList[index].name)
        this.setState({ showDropDown: false })
    }

    onBlur = () => {
        setTimeout(() => this.setState({ showDropDown: false }), 200)
    }

    onDefault = () => {
        this.setState({ dataList: data.jobDescriptions, showDeleteIcon: false, showDropDown: true })
    }

    onSaved = () => {
        const { list } = this.props;
        this.setState({ dataList: list, showDeleteIcon: true, showDropDown: true })
    }

    render() {
        const { dataList, showDropDown, showDeleteIcon } = this.state;
        const { containerStyle, selectedJd, list, placeHolderText } = this.props;
        // let defaultDescriptionStyle = [styles.defaultDescription, placeHolderStyle]
        let dropDownList;
        dropDownList = (
            <ul
                role="button"
                ref={this.myRef}
                id="list"
                className={this.suggestionStyleClass}
                onMouseEnter={() => this.setState({ mouseEnter: true })}
                onMouseLeave={() => this.setState({ mouseEnter: false })}
                tabIndex="0"
            >
                <div className={styles.buttonWrap}>
                    <div
                        id="defaultJd"
                        role="button"
                        style={!showDeleteIcon ? activeButtonStyle : null}
                        className={styles.defaultButton}
                        tabIndex="0"
                        onClick={this.onDefault}
                        onKeyPress={this.onDefault}
                    >
                        Sample
                    </div>
                    {list && list.length !== 0 ?
                        <div
                            role="button"
                            style={showDeleteIcon ? activeButtonStyle : null}
                            className={styles.savedButton}
                            tabIndex="0"
                            onClick={this.onSaved}
                            onKeyPress={this.onSaved}
                        >
                            Saved
                        </div> : null
                    }
                </div>
                {dataList && dataList.length !== 0 ? dataList.map((jd, index) => {
                    return (
                        <div key={`${jd.name}`} className={styles.ListItemWrap}>
                            <li
                                onClick={() => this.onJDSelected(index)}
                                tabIndex={index}
                            >
                                {jd.name}
                            </li>
                            {showDeleteIcon ? (
                                <div title="clear" role="button" className={styles.resetButton} onClick={() => this.removeSavedJd(jd.name)} onKeyPress={() => onRemoveSavedJd(jd.name)} tabIndex="0">
                                    <img src={quitImg} alt="" className={styles.quiteStyle} />
                                </div>
                            ) : null}
                        </div>
                    );
                }) : (
                    <div className={styles.emptyList}>No saved job descriptions found</div>
                )}
            </ul>
        )
        return (
            <div className={[containerStyle, styles.ContentContainerStyle].join(' ')}>
                {/* <div className={defaultDescriptionStyle.join(' ')} style={{color: labelColor}}>Select from sample Job Description</div> */}
                <div
                    role="button"
                    id="dropDownBtn"
                    tabIndex="0"
                    className={styles.dropDown}
                    onClick={this.onDropDownClicked}
                    onKeyPress={this.onDropDownClicked}
                    onMouseEnter={() => this.setState({ mouseEnter: true })}
                    onMouseLeave={() => this.setState({ mouseEnter: false })}
                >
                    <div>{selectedJd || placeHolderText}</div>
                    <img src={dropDownImg} alt="" className={styles.dropDownImg} />
                </div>
                {showDropDown ? dropDownList : null}
            </div>
        )
    }
}

const mapStateToProps = reduxProps => ({
    reduxProps,
});

const mapDispatchToProps = dispatch => ({
    dispatchRecentJds: recentJd => {
        dispatch(cacheRecentJDs(recentJd));
    }
});

export default connect(mapStateToProps, mapDispatchToProps)(DropDownList);