import hero from '../../../../assets/insights/perfectCoverLetter/hero.png';

export const meta = {
  author: "",
  date: "2024-07-25",
  type: "Insights",
  title: "A Comprehensive Guide to a Perfect Cover Letter",
  subTitle: "",
  description: "You've spent weeks polishing your resume, researching dream companies, and practicing interview questions until you can recite them in your sleep. You're ready to conquer the job market! But wait - there's one crucial piece of the puzzle missing.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/guide-to-perfect-cover-letter",
};