import hero from '../../../../assets/insights/jobSearchTimeAudit/hero.png';

export const meta = {
  author: "",
  date: "2024-09-13",
  type: "Insights",
  title: "Mastering the Job Search Time Audit",
  subTitle: "",
  description: "In today's hyper-competitive job market, time isn't just money - it's the difference between landing your dream job and missing out on life-changing opportunities.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/job-search-time-audit",
};