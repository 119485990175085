import React, { useState, useEffect, useRef } from 'react';
import styles from './styles.module.scss';
import { AnimatePresence, motion, useCycle } from "framer-motion";

import PannaRBLogo from '../../../assets/Logo/pannaBlack.png';
import PannaLogo from '../../../assets/Logo/pannaLogo.png';
import PannaRCLogo from '../../../assets/Logo/panna-resume-score.png';

import betaLogo from '../../../assets/Logo/pannaBlackBeta.png';
import { useAuth } from '../../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearStore, updateLocation } from '../../../store/actions/cache';
import { useLocation } from 'react-router-dom';
import Utility from '../../../utils';

export const Desktop = ({ children }) => {
  const { authState, pannaAuth } = useAuth();
  console.log(authState, pannaAuth);
  const location = useLocation();
  console.log('location:', location.pathname);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearStoreData = () => {
    dispatch(clearStore());
    signOut();
  }

  const [country, setCountry] = useState(null);

  useEffect(() => {
    // Function to retrieve timezone offset
    const getTimezoneOffset = () => {
      return new Date().getTimezoneOffset();
    };

    // Function to determine country from timezone offset
    const getCountryFromOffset = (offsetMinutes) => {
      // Map timezone offsets to countries
      const timezoneToCountry = {
        '-480': 'USA',   // Example: Pacific Time (UTC-08:00)
        '-420': 'USA',   // Example: Mountain Time (UTC-07:00)
        '-360': 'USA',   // Example: Central Time (UTC-06:00)
        '-300': 'USA',   // Example: Eastern Time (UTC-05:00)
        '-330': 'India', // Example: India Standard Time (UTC+05:30)
        // Add more mappings as needed
      };

      // Lookup country based on timezone offset
      return timezoneToCountry[offsetMinutes.toString()] || 'Unknown';
    };

    // Get timezone offset
    const offsetMinutes = getTimezoneOffset();

    // Determine country from offset
    const countryFromOffset = getCountryFromOffset(offsetMinutes);

    // Set country state
    setCountry(countryFromOffset);
    // dispatch(updateLocation(countryFromOffset));

  }, []); // Empty dependency array to run the effect only once
  const privateTabs = [
    { name: "Build AI Resume", to: "/resume-build-home", id: 1, isActive: false },
    { name: "Free Resume Score", to: "/check-resume-score", id: 2, isActive: false },
    { name: "Insights", to: "/insights", id: 3, isActive: false },
    { name: "History", to: "/history", id: 4, isActive: false },

  ];

  const publicTabs = [
    { name: "Build AI Resume", to: "/resume-build-home", id: 1, isActive: false },
    { name: "Free Resume Score", to: "/check-resume-score", id: 2, isActive: false },
    { name: "Insights", to: "/insights", id: 3, isActive: false },
  ];

  const betaPages = ['resume-builder', '/history', '/resume-build-home', '/builder-report', '/insights', '/login', '/signup', '/profile']

  const isRBPage = betaPages.some(element => window.location.pathname.includes(element));


  const signOut = async () => {
    try {
      Utility.trackEvent('nav-bar', 'BUTTON_CLICK', 'Logout');
    } catch (error) {
      console.error('Failed to track event:', error);
    }
    await pannaAuth.signOut();
  }

  return (
    <div className={styles.container}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
          alignItems: 'center',
          zIndex: 50,
          margin: '0 auto'
        }}
      >
        <a href="/score" className={styles.pannalogoLink} id="panna-logo" rel="noopener noreferrer">
          <img
            role="button"
            src={isRBPage ? PannaRBLogo : PannaRCLogo}
            alt="Paññãlogo"
            className={isRBPage ? styles.pannaRBLogo : styles.pannaRBLogo}
          />
          {/* <span className={styles.logoLabel}>| resume builder </span> */}
          {/* <img
              role="button"
              src={betaLogo}
              alt="Paññãlogo"
              className={styles.betaLogo}
            /> */}
        </a>
        <div style={{
          display: 'flex'
        }}>
          {authState && (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}
            >

              {
                privateTabs.map(item => {
                  return (
                    <>
                      <button
                        style={{
                          outline: 'none',
                          border: 'none',
                          background: 'transparent',
                          padding: '0 1rem',
                          cursor: 'pointer'
                        }}
                        id={item.to}
                        className={[styles.menuBtn, location.pathname === item.to ? styles.menuBtnActive : ''].join(' ')}
                        onClick={() => navigate('' + item.to)}
                      >
                        {item.name}
                      </button>
                    </>
                  )


                })
              }

              {/* <div className={styles.profileLinkContainer}>
                <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
              </div> */}


              <ProfileLogoutButton logOutClick={clearStoreData} />

            </div>
          )}

          {!authState && (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center'
              }}
            >

              {
                publicTabs.map(item => {
                  return (
                    <>
                      <button
                        style={{
                          outline: 'none',
                          border: 'none',
                          background: 'transparent',
                          padding: '0 1rem',
                          cursor: 'pointer'
                        }}
                        id={item.to}
                        className={[styles.menuBtn, location.pathname === item.to ? styles.menuBtnActive : ''].join(' ')}
                        onClick={() => navigate('' + item.to)}
                      >
                        {item.name}
                      </button>
                    </>
                  )
                })
              }
            </div>
          )}

          {!authState && location.pathname !== '/login' && (
            <div
              style={{
                display: 'flex',
                gap: '10px',
                alignItems: 'center',
                padding: '0 1rem',
              }}
            >
              <button
                style={{
                  zIndex: 50
                }}
                className={styles.loginBtn}
                id={'login-btn'}
                onClick={() => navigate('/login')}
              >Login</button>
            </div>
          )}
        </div>
      </div>
    </div>
  );;

};



const ProfileLogoutButton = ({ logOutClick }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };






  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <div onClick={toggleMenu} onMouseEnter={() => setIsOpen(true)} className={styles.profileLinkContainer}>
        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
      </div>

      <motion.div ref={menuRef}
        initial={{ opacity: 50, y: 0 }} // Initial position and opacity
        animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 10 : 1 }} // Final position and opacity
        transition={{ type: 'spring', stiffness: 100, damping: 20 }}
      >
        {isOpen && (<div className={styles.dropDownMenu} style={{ display: 'flex', flexDirection: 'column' }}>
          <button

            className={styles.logoutButton}
            onClick={() => navigate('/profile')}
          >
            Profile
          </button>
          <button

            className={styles.logoutButton}
            onClick={logOutClick}
          >
            Logout
          </button>
        </div>

        )}
      </motion.div>
    </div>
  );
};

export default Desktop;
