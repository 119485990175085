import React from 'react';
import styles from './radioButton.module.scss';

const RadioButton = ({ id, name, value, label, checked, disabled, onChange, children }) => (
    <div className={styles.radioWrapper}>
        <div className={styles.customRadio}>
            <input
                type="radio"
                id={id}
                name={name}
                value={value}
                checked={checked}
                disabled={disabled}
                onChange={onChange}
            />
            <label htmlFor={id} className={styles.radioLabel}>{children}</label>
        </div>
    </div>
);

export default RadioButton;
