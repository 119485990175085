import hero from '../../../../assets/insights/resumeSections/hero.png';

export const meta = {
  author: "",
  date: "2024-08-08",
  type: "Insights",
  title: "The Ultimate Guide to Resume Sections",
  subTitle: " Organize Your Way to Success",
  description: "In today's competitive job market, your resume is your ticket to getting noticed. But with so many potential sections to include, how do you know which ones are essential and which are optional? And more importantly, how do you organize them for maximum impact?",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/guide-to-resume-sections",
};