import hero from '../../../../assets/insights/scienceBehindATS/hero.png';

export const meta = {
  author: "",
  date: "2024-08-14",
  type: "Insights",
  title: "The Science Behind ATS",
  subTitle: " How AI is Revolutionizing the Job Search",
  description: "In today's job market, your resume often meets artificial intelligence before human eyes. Applicant Tracking Systems (ATS) are the digital gatekeepers of the hiring world, and understanding how they work is your secret weapon in the job hunt.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/the-science-behind-ats",
};