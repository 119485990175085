import React from 'react'
import AutosizeInput from 'react-input-autosize';
import styles from './styles.module.css'
import editIcon from '../../assets/File Loading/edit-tools.png';
import correctTickImg from '../../assets/animation/skills/correct_saved.png';

class EditableInputField extends React.Component {
    inputdivKey;
    inputRef;
    state = {
        disableInput: true,
        showTick: false,
        reRenderOnResize: false,
    }
    componentDidMount() {
        const inputElement = document.getElementById('ResumeHolderText');
        inputElement.classList.add(styles.resumeHolderInput);
    }

    componentWillReceiveProps(props) {
        const { fullName: prevName } = this.props;
        const { fullName } = props;
        const inputElement = document.getElementById('ResumeHolderText');
        inputElement.classList.add(styles.resumeHolderInput);
        if (prevName && prevName !== fullName) {
            this.setState({ showTick: true });
        } else {
            this.setState({ showTick: false });
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    onEditClicked = () => {
        this.setState({ disableInput: false }, () => {
            const inputField = document.getElementById('ResumeHolderText');
            inputField.focus();
        });
    }

    onKeyPress = event => {
        switch (event.charCode) {
            case 13: {
                const inputField = document.getElementById('ResumeHolderText');
                inputField.blur();
            }
        }
    }

    blurInput = () => {
        this.setState({ disableInput: true });
    };

    render() {
        const { disableInput, showTick } = this.state;
        const { onChangeNameText, fullName } = this.props;
        return (
            <div className={styles.containerStyles}>
                <div className={styles.contentContainer}>
                    <AutosizeInput
                        key={this.inputdivKey}
                        autoComplete="off"
                        ref={ref => this.inputRef = ref}
                        id="ResumeHolderText"
                        name="form-field-name"
                        value={fullName}
                        placeholder="Enter your name"
                        onChange={onChangeNameText}
                        onKeyPress={this.onKeyPress}
                        injectStyles={false}
                        inputStyle={{
                            display: 'flex',
                            fontSize: window.innerHeight <= 550 ? '14px' : window.innerWidth <= 550 ? '14px' : '1.82704vh',
                            fontWeight: 'bold',
                            color: '#AA6DCE',
                            letterSpacing: '0.65px',
                            lineHeight: '16px',
                        }}
                        maxLength="20"
                        onFocus={() => this.setState({ disableInput: false })}
                        onBlur={this.blurInput}
                        disabled={disableInput}
                    />
                    <div className={styles.resumeText}>&nbsp;Resume</div>
                    {disableInput ?
                        <img role="button" src={editIcon} alt="" className={styles.editIcon} onClick={this.onEditClicked} onKeyPress={this.onEditClicked} tabIndex="0" />
                        : showTick ?
                            <button className={styles.correctButton} onClick={this.blurInput} onKeyPress={this.blurInput}>
                                <img src={correctTickImg} alt="" className={styles.correctIcon} />
                            </button> : null
                    }
                </div>
                <div className={styles.noteStyles}>Is the above name correct ? If not please edit!</div>
            </div>
        );
    }
}

export default EditableInputField;