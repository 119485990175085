import API from '../network';
import { BASEPATH } from './constants';

class PannaAuthImpl {
  static client;

  static getInstance = () => {
    if (!PannaAuthImpl.client) {
      PannaAuthImpl.client = new PannaAuthImpl();
    }
    return PannaAuthImpl.client;
  };

  signIn = async email => {
    return await API.getInstance().signIn(email);
  };

  currentSession = async () => {
    return await API.getInstance().getSession();
  };

  validateToken = async token => {
    return await API.getInstance().validate(token);
  };

  signOut = async (options = { redirectToLogin: true }) => {
    localStorage.setItem('token', '');
    localStorage.setItem('payment', '');
    localStorage.clear();
    document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
    window.location.replace(BASEPATH);

    // const { redirectToLogin } = options;
    await API.getInstance().signOut();

    // if (redirectToLogin) {
    //   this.redirectToSignIn();
    // }
  };

  redirectToSignIn(redirectTo) {
    window.location.replace(BASEPATH + `/login`);
  }

  redirectToUserProfile() {
    window.location.replace(`${process.env.REACT_APP_AUTH_DOMAIN}/profile`);
  }
}

const pannaAuth = PannaAuthImpl.getInstance();
export default pannaAuth;
