import hero from '../../../../assets/insights/skillStacking/hero.png';

export const meta = {
  author: "",
  date: "2024-08-06",
  type: "Insights",
  title: "Skill Stacking: The Key to Career Success in 2024",
  subTitle: "",
  description: "In today's rapidly evolving job market, the path to career success is no longer a straight line. Gone are the days when a single skill set could guarantee lifelong employment. Welcome to the era of skill stacking – a strategic approach to career development that's revolutionizing how we think about professional growth and employability.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/skill-stacking",
};