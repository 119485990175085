import React from 'react';
import { LoginModal } from '../../component/LoginModal';
import { Navbar } from '../../component/Navbar';
// import { ResumeBuilder } from '../../component/ResumeBuilderBack';
import ResumeBuilderForm from '../../ResumeBuilderForm1';
import Helmet from 'react-helmet';
import Utility from '../../utils'

class ResumeBuilderPage extends React.Component {
  componentDidMount() {
    Utility.trackEvent('resume-builder(after payment)', 'PAGE_VISIT');
  }

  render() {
    return (
      <>
        <Helmet>
          <title>resume building</title>
          {/* <meta name={data.metaName} content={data.metaContent} />
          <meta name="theme-color" content="#008f68" /> */}
        </Helmet>
        <Navbar />
        <ResumeBuilderForm {...this.props} />
      </>
    );
  }
}

export default ResumeBuilderPage;
