import hero from '../../../../assets/insights/resumeReadingPsychology/hero.png';

export const meta = {
  author: "",
  date: "2024-07-30",
  type: "Insights",
  title: "The Psychology of Resume Reading",
  subTitle: " Inside the Mind of a Hiring Manager",
  description: "It's Monday morning, and you're a hiring manager sifting through a stack of resumes taller than your coffee mug. Your mission? Find the perfect candidate for that crucial role your company needs to fill. But here's the kicker - you've got about 7.4 seconds per resume to make an initial decision.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/resume-reading-psychology",
};