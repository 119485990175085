import { React, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { useAuth } from '../../context/AuthContext';

const UserProfile = () => {
    const { authState, loading } = useAuth();
    console.log('authState', authState);
    const { userInfo } = authState;
    const data = useSelector(state => state);
    console.log('data:', data);
    console.log('userInfo', userInfo);
    return (<>
        <div className={styles.profileContainer}>
            <div className={styles.header}>
                <div>
                    <div className={styles.text1}>My Profile</div>
                    <div className={styles.text2}>View your details</div>
                </div>
                <div className={styles.icon}><svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
                </div>
                {/* <div className={styles.logout}>
                    logout
                </div> */}
            </div>
            <div className={styles.content1}>
                <div className={styles.left}>
                    <div className={styles.icon}>
                        <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
                    </div>
                </div>

                <div className={styles.right}>
                    <InfoRow name={'Name:'} value={userInfo?.name} />
                    <InfoRow name={'E-mail:'} value={userInfo?.emailAddress} />
                    <InfoRow name={'Phone:'} value={''} />
                </div>

            </div>
            <div className={styles.footer}>
                <div className={styles.text}>
                    If you want to delete the account, contact support@panna.ai
                </div>
            </div>
        </div>

    </>)
}

function InfoRow(props) {
    const {
        name,
        value,
        disabled,
        onPhoneChange,
    } = props;
    const [inputValue, setValue] = useState(value);
    const onChange = (val) => {
        setValue(val);
    };
    return (
        <div className={styles.rowContainer}>
            <div className={styles.label}>
                {name}
            </div>
            <div className={styles.value}>
                <input className={styles.input} value={inputValue} disabled={true} />
            </div>
        </div>
    );
}

export default UserProfile;