import hero from '../../../../assets/insights/futureOfAI/hero.png';

export const meta = {
  author: "",
  date: "2024-04-09",
  type: "Insights",
  title: "The Future of AI in Hiring",
  subTitle: " How ATS is Just the Opening Act",
  description: "",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/future-of-ai-in-hiring",
};