import React from 'react';
import ProgressBar from '../../component/CustomAnimatedProgress';
import styles from './styles.module.css';

const CustomProgressBar = props => {
    const { trackColor, renderItems } = props;
    const defColor = {
        fair: trackColor,
        good: trackColor,
        excellent: trackColor,
        poor: trackColor,
    }
    return (
        <div className={styles.ContentcontainerStyle}>
            <ProgressBar
                {...props}
                defColor={defColor}
            />
            {renderItems()}
        </div>
    );
}

CustomProgressBar.defaultProps = {
    width: "400px",
    height: "10px",
    rect: true,
    fontColor: "gray",
    percentage: "70",
    rectBorderRadius: "20px",
    trackPathColor: "#E6E6E6",
    bgColor: "#333333",
    trackBorderColor: "grey",
}

export default CustomProgressBar;