import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import WinningCoverLetter from '../../../../../assets/insights/perfectCoverLetter/winningCoverLetter.png';
import CollegeStudentCoverLetter from '../../../../../assets/insights/perfectCoverLetter/collegeStudentCoverLetter.png';
import businessAnalystCoverLetter from '../../../../../assets/insights/perfectCoverLetter/businessAnalystCoverLetter.png';
import LeadProjectCoverLetter from '../../../../../assets/insights/perfectCoverLetter/leadProjectManagerCoverLetter.png';
import bulb from '../../../../../assets/insights/perfectCoverLetter/lightBulb.png';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        You've spent weeks polishing your resume, researching dream companies, and practicing 
        interview questions until you can recite them in your sleep. You're ready to conquer the 
        job market! But wait - there's one crucial piece of the puzzle missing.
      </div>

      <div className={styles.text}>
        <strong>The cover letter:</strong> a crucial component of the job application process that is 
        often overlooked or hastily composed. However, this document is far from a mere formality. In 
        fact, a well-crafted cover letter can be the differentiating factor that secures you an 
        interview and ultimately, your desired position
      </div>

      <div className={styles.text}>
        Let's dive in and unlock the mysteries of crafting a killer cover letter that will have 
        employers clamoring to meet you!
      </div>

      <div className={styles.title}>
        What Exactly Is a Cover Letter, anyway?
      </div>

      <div className={styles.text}>
        Think of your cover letter as your personal hype document - a 250-400-word pitch that introduces 
        you to potential employers and convinces them you're worth interviewing. It's your chance to:
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          <strong>
            Showcase your personality beyond the bullet points of your resume
          </strong>
        </li>
        <li className={styles.listItem}>
          <strong>
            Highlight key achievements and skills relevant to the job
          </strong>
        </li>
        <li className={styles.listItem}>
          <strong>
            Demonstrate genuine enthusiasm for the role and company
          </strong>
        </li>
        <li className={styles.listItem}>
          <strong>
            Address any potential red flags in your application
          </strong>
        </li>
      </ul>

      <div className={styles.text}>
        In essence, it's your golden opportunity to make a stellar first impression before you even 
        step foot in an interview room.
      </div>

      <div className={styles.title}>
        Why You Need a Cover Letter
      </div>

      <div className={styles.text}>
        A cover letter is an essential part of your job application for several reasons:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Personalization:</strong>
            It allows you to address the hiring manager directly and demonstrate your genuine interest 
            in the position.
          </li>

          <li className={styles.listItem}>
            <strong>Elaboration: </strong>
            While your resume provides a snapshot of your qualifications, a cover letter enables you to 
            expand on your most relevant experiences and skills.
          </li>

          <li className={styles.listItem}>
            <strong>Showcasing Communication Skills: </strong>
            Your cover letter is a sample of your written communication abilities, a crucial skill in 
            most professional settings.
          </li>

          <li className={styles.listItem}>
            <strong>Addressing Potential Concerns: </strong>
            It provides an opportunity to explain any gaps in employment or career transitions.
          </li>

          <li className={styles.listItem}>
            <strong>Cultural Fit: </strong>
            You can express your enthusiasm for the company's mission and values, indicating how well 
            you'd fit into their organizational culture.
          </li>

          <li className={styles.listItem}>
            <strong>Standing Out: </strong>
            In a competitive job market, a compelling cover letter can set you apart from other 
            candidates with similar qualifications.
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Types of Cover Letters
      </div>

      <div className={styles.text}>
        Depending on your situation and the position you're applying for, you may need to utilize 
        different types of cover letters:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Application Cover Letter: </strong>
            The most common type, sent along with your resume when applying for a specific job opening.
          </li>

          <li className={styles.listItem}>
            <strong>Prospecting Cover Letter: </strong>
            Used when inquiring about potential job opportunities at a company, even if no specific 
            openings are advertised.
          </li>

          <li className={styles.listItem}>
            <strong>Networking Cover Letter: </strong>
            Sent to contacts in your professional network to request information and assistance in your 
            job search.
          </li>

          <li className={styles.listItem}>
            <strong>Internal Position Cover Letter: </strong>
            Used when applying for a new position within your current company.
          </li>

          <li className={styles.listItem}>
            <strong>Career Change Cover Letter: </strong>
            Focuses on transferable skills when you're transitioning to a new industry or career path.
          </li>

          <li className={styles.listItem}>
            <strong>Referral Cover Letter: </strong>
            Mentions a mutual contact who referred you to the position, leveraging your professional 
            network.
          </li>

          <li className={styles.listItem}>
            <strong>Short Email Cover Letter: </strong>
            A condensed version sent in the body of an email, often with your resume attached.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Understanding these different types allows you to tailor your approach based on the specific 
        circumstances of your job application, maximizing your chances of success.
      </div>

      <div className={styles.title}>
        How to write a Winning Cover Letter
      </div>

      <div className={styles.text}>
        Just like the perfect outfit, a great cover letter has several key components that work together:
      </div>

      <div>
        <img
          src={WinningCoverLetter}
          alt='Example of winning cover letter'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        <strong>Let's break down each section:</strong>
      </div>

      <div className={styles.listWithNestedContent}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Dress to Impress: The Header </strong><br />
            Your header should include:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Full Name</li>
              <li className={styles.nestedListItem}>Professional Title</li>
              <li className={styles.nestedListItem}>Phone Number</li>
              <li className={styles.nestedListItem}>Email Address</li>
              <li className={styles.nestedListItem}>Location (City, State)</li>
              <li className={styles.nestedListItem}>Relevant Links (LinkedIn, portfolio, etc.)</li>
            </ul>
            <br />

            <span>Follow this with the employer's info:</span>
            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Hiring Manager's Name (if known)</li>
              <li className={styles.nestedListItem}>Company Name</li>
              <li className={styles.nestedListItem}>Company Address</li>
            </ul>
          </li>

          <li className={styles.listItem}>
            <strong>Make It Personal: The Greeting</strong><br />
            Avoid the generic "Dear Sir/Madam" and do some investigation to find the hiring manager's 
            name. Try:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Checking the job posting</li>
              <li className={styles.nestedListItem}>Searching LinkedIn</li>
              <li className={styles.nestedListItem}>Calling the company (politely!) to ask</li>
            </ul>
            <br />

            <span>If all else fails, "Dear [Department] Hiring Manager" or "Dear [Company Name] Team" 
              work in a pinch.</span>
          </li>

          <li className={styles.listItem}>
            <strong>Engage from the beginning: The Opening Paragraph</strong><br />
            This is where you make your grand entrance! Grab attention with:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>A relevant accomplishment</li>
              <li className={styles.nestedListItem}>An expression of genuine enthusiasm</li>
              <li className={styles.nestedListItem}>A mutual connection (if you have one)</li>
            </ul>
            <br />

            <div className={styles.highlight}>
              <strong>Example:</strong><br />
              "As a digital marketing specialist who increased client ROI by an average of 200% last 
              year, I was thrilled to see the Senior Marketing Manager position at TechCorp. Your 
              commitment to data-driven strategies aligns perfectly with my passion for measurable 
              results."
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Show Your Value: The Body Paragraphs</strong><br />
            Here's where you dive deeper, demonstrating why you're the perfect fit. Focus on:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Specific achievements that relate to the job requirements</li>
              <li className={styles.nestedListItem}>Skills that set you apart from other candidates</li>
              <li className={styles.nestedListItem}>Why you're excited about this company</li>
            </ul>
            <br />

            <span>
              <strong>Pro Tip: </strong>
              Use the job description as a guide, addressing key requirements and using similar language.
            </span>

            <div className={styles.highlight}>
              <strong>Example:</strong><br />
              "In my current role at ABC Agency, I've spearheaded social media campaigns that increased 
              engagement by 150% and led to a 75% boost in conversions. I'm particularly drawn to 
              TechCorp's innovative approach to AI-powered marketing tools, as I believe my experience 
              in marketing automation would allow me to hit the ground running and contribute to your 
              cutting-edge projects from day one."
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Wrap It Up with a Bow: The Closing</strong><br />
            Summarize your key selling points and express enthusiasm for next steps:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>Reiterate why you're a great fit</li>
              <li className={styles.nestedListItem}>Thank the reader for their time</li>
              <li className={styles.nestedListItem}>Include a clear call to action</li>
            </ul>
            <br />

            <div className={styles.highlight}>
              <strong>Example:</strong><br />
              "I'm excited about the possibility of bringing my data-driven approach and passion for 
              innovation to the TechCorp team. I'd welcome the opportunity to discuss how my skills and 
              experience align with your goals. Thank you for your consideration, and I look forward to 
              speaking with you soon about this exciting opportunity."
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>Sign Off in Style: The Signature</strong><br />
            Keep it professional with:

            <ul className={styles.nestedList}>
              <li className={styles.nestedListItem}>"Sincerely,"</li>
              <li className={styles.nestedListItem}>"Best regards,"</li>
              <li className={styles.nestedListItem}>"Thank you,"</li>
            </ul>

            <span>
              Followed by your full name.
            </span>
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Cover Letter Pro Tips: Taking It to the Next Level
      </div>

      <div className={styles.text}>
        Now that you've got the basics down, let's explore some advanced techniques to make your cover 
        letter truly shine:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>Tailor to the need: </strong>
            Customize each letter for the specific job and company. Generic letters are a fast track to 
            the rejection pile.
          </li>

          <li className={styles.listItem}>
            <strong>Show, don't tell: </strong>
            Instead of saying "I'm a great communicator," give an example: "I distilled complex data 
            into engaging infographics that increased client understanding by 85%."
          </li>

          <li className={styles.listItem}>
            <strong>Use power verbs: </strong>
            Sprinkle in action words like "spearheaded," "transformed," and "pioneered" to add punch to 
            your achievements.
          </li>

          <li className={styles.listItem}>
            <strong>Address potential concerns: </strong>
            If you're changing careers or have an employment gap, briefly explain it in a positive light
          </li>

          <li className={styles.listItem}>
            <strong>Keep it concise: </strong>
            Aim for 3-4 paragraphs on a single page. Hiring managers don't have time for your life story!
          </li>

          <li className={styles.listItem}>
            <strong>Proofread, then proofread again: </strong>
            Typos and grammatical errors are instant deal-breakers. Use tools like Grammarly, but also 
            enlist a trusted friend for a fresh set of eyes.
          </li>

          <li className={styles.listItem}>
            <strong>Match your resume's style: </strong>
            Use the same font and formatting to create a cohesive application package.
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        Common Cover Letter Pitfalls (And How to Avoid Them)
      </div>

      <div className={styles.text}>
        Even the most qualified candidates can stumble when it comes to cover letters. Here are some 
        common mistakes to steer clear of:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            <strong>The Copy-Paste Catastrophe: </strong>
            Sending the same generic letter to every employer.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Customize to the job description
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The "Me, Me, Me" Manifesto: </strong>
            Focusing solely on what you want, not what you can offer.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Emphasize how your skills solve the employer's problems.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The Novelist's Nightmare: </strong>
            Writing a multi-page epic.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Stick to one page, highlighting your most relevant qualifications.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The Resurrection of Clichés: </strong>
            Using tired phrases like "think outside the box" or "detail-oriented team player."
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Be specific and use concrete examples.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The Timid Approach: </strong>
            Being overly modest or apologetic.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Confidently (but not arrogantly) showcase your achievements.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The Information Overload: </strong>
            Rehashing your entire resume.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Choose 2-3 key accomplishments that directly relate to the job.
            </div>
          </li>

          <li className={styles.listItem}>
            <strong>The Formal Snooze-fest: </strong>
            Writing in an overly stuffy or robotic tone.
            <div className={styles.content}>
              <img
                src={bulb}
                alt='light bulb'
                className={styles.bulb}
              />
              Let your personality shine through while maintaining professionalism.
            </div>
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Check out some perfect cover letter examples for different experience levels in various 
        professions.
      </div>

      <div className={styles.text}>
        <strong>College student Cover Letter</strong>
      </div>

      <div>
        <img
          src={CollegeStudentCoverLetter}
          alt='Example of College student cover letter'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        <strong>Career Change - Software developer to Business Analyst</strong>
      </div>

      <div>
        <img
          src={businessAnalystCoverLetter}
          alt='Example of Business Analyst cover letter'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        <strong>Lead Project Manager</strong>
      </div>

      <div>
        <img
          src={LeadProjectCoverLetter}
          alt='Example of Lead Project Manager'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.title}>
        The Final Word: Your Cover Letter, Your Story
      </div>

      <div className={styles.text}>
        Remember, your cover letter is more than just a formality - it's your chance to tell your 
        professional story and make a lasting impression. By following these guidelines and infusing 
        your unique personality, you'll craft a cover letter that not only showcases your qualifications 
        but also leaves hiring managers eager to meet you.
      </div>

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>Cover Letter FAQs: Your Burning Questions Answered</strong><br />
        Still have questions? We've got answers!
      </div>

      <div className={styles.text}>
        <strong>Q: Do I really need a cover letter if it's not required?</strong><br />
        <strong>A: </strong>
        Yes! It shows initiative and gives you an edge over candidates who don't bother.
      </div>

      <div className={styles.text}>
        <strong>Q: How long should my cover letter be?</strong><br />
        <strong>A: </strong>
        Aim for 250-400 words on a single page.
      </div>

      <div className={styles.text}>
        <strong>Q: Should I mention salary requirements?</strong><br />
        <strong>A: </strong>
        Generally, no. Save salary discussions for later in the hiring process.
      </div>

      <div className={styles.text}>
        <strong>Q: Can I use humor in my cover letter?</strong><br />
        <strong>A: </strong>
        Use caution. A light touch can work in creative fields but err on the side of professionalism.
      </div>

      <div className={styles.text}>
        <strong>Q: How do I address employment gaps?</strong><br />
        <strong>A: </strong>
        Briefly explain them in a positive light, focusing on skills gained or personal growth during 
        that time.
      </div>

      <div className={styles.text}>
        <strong>Q: Is it okay to follow up after sending my application?</strong><br />
        <strong>A: </strong>
        Yes, but wait at least a week and be polite and brief in your inquiry.
      </div>

      <div className={styles.text}>
        <strong>Q: Should I tailor my cover letter for each job application?</strong><br />
        <strong>A: </strong>
        Yes, customize your letter to highlight the most relevant skills and experiences for each position
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
