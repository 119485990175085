import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';
import calender from '../../../../../assets/insights/common/calender.png';
import { monthNames } from '../../../utils';

const Desktop = (props) => {
  const {
    date,
    title,
    subTitle,
    image,
    restInsights=[],
  } = props
  const insightDate = new Date(date);

  return (
    <div className={styles.wrapper}>
      <div className={styles.flexWrapper}>
        <div className={styles.container}>
          <div className={styles.dateContainer}>
            <img className={styles.calenderImg} src={calender} alt="calender image" />
            <span className={styles.date}>
              {monthNames[insightDate.getMonth()]}&nbsp;{insightDate.getFullYear()}
            </span>
          </div>

          <h1 className={styles.title}>
            {title}{subTitle && `: ${subTitle}`}
          </h1>

          <img
            className={styles.heroImage}
            src={image}
            alt="hero image"
          />
          <>
            {props.children}
          </>
        </div>


        <div className={styles.restInsightContainer}>
          {restInsights.length > 0 && (
            <>
              <h2 className={styles.recentInsightHeader}>Recent Posts</h2>
              {
                restInsights.map((insight) => {
                  return (
                    <Link to={insight.link} className={styles.recentInsightLink}>
                      <div className={styles.recentInsightWrapper}>
                        <img src={insight.image} className={styles.recentInsightImg} />
                        <div className={styles.recentInsightTitle}>
                          <span className={styles.title}>{insight.title}</span>
                          {insight.subTitle && (<span className={styles.subTitle}>:&nbsp;{insight.subTitle}</span>)}
                        </div>
                      </div>
                    </Link>
                  )
                })
              }
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Desktop;