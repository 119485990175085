import React from 'react';
// import Linkedin from '../../../assets/social/Shape2.png'
import facebook from '../../../assets/social/facebook.png';
import twitter from '../../../assets/social/twitter.png';
// import youtube from '../../../assets/social/youtube.png';
import youtube from '../../../assets/build-resume-home/footer/youtube.png';
import Linkedin from '../../../assets/build-resume-home/footer/linkedin.png';

import classes from './Follow.module.css';
const Follow = () => {
    return (
        <div className={classes.Follow}>
            {/* <a href="https://www.facebook.com/pannaway/" target="_blank" rel="noopener noreferrer">
                <img src={facebook} alt="" />
            </a> */}
            {/* <a href="https://twitter.com/ThePannaWay" target="_blank" rel="noopener noreferrer">
                <img src={twitter} alt="" />
            </a> */}
            <a id="pannawayLinkedin" href="https://www.linkedin.com/company/pannaway/" target="_blank" rel="noopener noreferrer">
                <img src={Linkedin} alt="" />
            </a>
            <a id="pannaYoutube" href="https://www.youtube.com/channel/UCOMclJ0qdIbGonee1r-hGWQ" target="_blank" rel="noopener noreferrer">
                <img src={youtube} alt="" className={classes.youtube} />
            </a>
        </div>
    );
}

export default Follow;