import React from 'react'
import styles from './styles.module.css'
import data from './data'

const tableColumns = [
    { name: 'Category', styles: styles.col1 },
    { name: 'Action verbs found', styles: styles.col2 },
    { name: 'Suggestion', styles: styles.col3 },
    { name: '', styles: styles.col4 }];

class ActionVerbsTable extends React.Component {
    state = {}

    render() {
        const { skillData, sortJd, sortResume } = this.state;
        const { inputData } = this.props;
        return (
            <div style={{ height: window.innerWidth <= 550 && inputData.value !== 'None' && inputData.value.length > 1 ? '65vh' : (inputData.value !== 'None' && inputData.value.length <= 3) ? 'auto' : '65vh' }} className={styles.tableContainer}>
                <TableHeader sortCol={index => this.sortCol(index)} sortJd={sortJd} sortResume={sortResume} />
                <RenderRows {...this.props} data={skillData} />
            </div>
        )
    }
}

const TableHeader = () => {
    return (
        <div className={styles.tableHeader}>
            {tableColumns.map((col, index) => {
                return (
                    <div key={`${col.name}${index}`} className={[col.styles, styles.columnHeader].join(' ')}>
                        <div className={styles.colHeader}>{col.name}</div>
                    </div>
                )
            })}
        </div>
    );
}

const RenderRows = props => {
    const { inputData } = props;
    return (
        <div className={styles.actionRowWrap}>
            {inputData.value !== 'None' && inputData.value.map((actionCategory, index) => {
                return (
                    <div key={`${actionCategory.name}${index}`} className={styles.ConentContainer}>
                        <div className={[styles.col1, styles.categoryStyle].join(' ')}>
                            <div style={{ color: data.ActionColor[index].textColor }} className={styles.categoryName}>{actionCategory.name}</div>
                            <div style={{ background: `radial-gradient(rgb(255, 255, 255) 5%, ${data.ActionColor[index].countColor} 75%, transparent)` }} className={styles.categoryActionVerbCount}>{((actionCategory.value && actionCategory.value.length) || 0)}</div>
                        </div>
                        <div className={[styles.col2, styles.actionVerbFound].join(' ')}>{
                            actionCategory.value && actionCategory.value.length !== 0 ?
                                actionCategory.value.map((actionVerb, index) => {
                                    return (
                                        <div key={actionVerb} className={styles.skillTextStyle}>{actionVerb}</div>
                                    )
                                }) : <div key={`actionVerb-${index}`} className={styles.EmptyList}>None</div>
                        }</div>
                        <div className={[styles.col3, styles.recommendedVerbs].join(' ')}>{
                            actionCategory.recommended && actionCategory.recommended.map(recommendedVerb => {
                                return (
                                    <div key={recommendedVerb} className={styles.skillTextStyle}>{recommendedVerb}</div>
                                )
                            })
                        }</div>
                    </div>
                )
            })}
        </div>
    )
}

export default ActionVerbsTable;