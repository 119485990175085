import React from 'react'
import styles from './styles.module.css'
import sortArrow from '../../assets/jdToResume/sortArrow.png';

const tableColumns = [
    { name: 'Skills in job description', styles: styles.col1 },
    { name: 'Job description Count', styles: styles.col2 },
    { name: 'Resume Count', styles: styles.col3 }];

class SkillsTable extends React.Component {
    parsedSkillData = [];
    state = {
        skillData: [],
        sortJd: 'desc',
        sortResume: '',
        showMore: false,
    }

    componentDidMount() {
        const { jdSkills, resumeSkills } = this.props;
        this.calculateSkillData(jdSkills, resumeSkills);
    }

    calculateSkillData = (jdSkills, resumeSkills) => {
        const skills = Object.keys(jdSkills);
        const tempSkillData = [];
        skills.map(skill => {
            return (
                tempSkillData.push({
                    skill: skill,
                    jdSkillCount: jdSkills[skill],
                    resumeSkillCount: resumeSkills[skill],
                })
            )
        })
        const sortDescending = tempSkillData.sort((a, b) => {
            return b.jdSkillCount - a.jdSkillCount;
        });
        this.parsedSkillData = sortDescending;
        const filterSkillData = sortDescending.filter((_Skill, index) => index <= 5);
        this.setState({ skillData: filterSkillData })
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.jdSkills !== nextProps.jdSkills || this.props.resumeSkills !== nextProps.resumeSkills) {
            this.calculateSkillData(nextProps.jdSkills, nextProps.resumeSkills);
        }
    }

    sortCol = index => {
        const { sortJd, sortResume, skillData } = this.state;
        if (index == 1) {
            let sortDescending = [];
            if (sortJd === 'desc') {
                sortDescending = skillData.sort((a, b) => {
                    return a.jdSkillCount - b.jdSkillCount;
                });
                this.setState({ skillData: sortDescending, sortJd: 'asc', sortResume: '' })
            } else {
                sortDescending = skillData.sort((a, b) => {
                    return b.jdSkillCount - a.jdSkillCount;
                });
                this.setState({ skillData: sortDescending, sortJd: 'desc', sortResume: '' })
            }
        } else if (index == 2) {
            let sortDescending = [];
            if (sortResume === 'desc') {
                sortDescending = skillData.sort((a, b) => {
                    return a.resumeSkillCount - b.resumeSkillCount;
                });
                this.setState({ skillData: sortDescending, sortResume: 'asc', sortJd: '' })
            } else {
                sortDescending = skillData.sort((a, b) => {
                    return b.resumeSkillCount - a.resumeSkillCount;
                });
                this.setState({ skillData: sortDescending, sortResume: 'desc', sortJd: '' })
            }
        }
    }

    renderTableContent = () => {
        const { showMore } = this.state;
        this.setState({ showMore: !showMore }, () => {
            const { showMore } = this.state;
            let tempSkill = showMore ? this.parsedSkillData : this.parsedSkillData.filter((_Skill, index) => index <= 5);
            this.setState({ skillData: tempSkill });
        })
    }
    render() {
        const { skillData, sortJd, sortResume, showMore } = this.state;

        return (
            <div className={styles.tableContainer}>
                <TableHeader sortCol={index => this.sortCol(index)} sortJd={sortJd} sortResume={sortResume} />
                <RenderRows {...this.props} data={skillData} />
                <div role="button" className={styles.dropDown} onClick={this.renderTableContent} onKeyPress={this.renderTableContent}>
                    <button className={styles.viewMore}>{showMore ? '-View less' : '+View more'}</button>
                </div>
            </div>
        )
    }
}

const TableHeader = props => {
    const { sortCol, sortJd, sortResume } = props;
    const sortImgStyle = sortJd === 'desc' || sortResume === 'desc' ? styles.downArrow : styles.upArrow;
    return (
        <div className={styles.tableHeader}>
            {tableColumns.map((col, index) => {
                return (
                    <div role="button" key={`${col.name}${index}`} onClick={() => sortCol(index)} onKeyPress={() => sortCol(index)} className={col.styles} tabIndex="0">
                        <div style={{ cursor: index !== 0 ? 'pointer' : '' }} className={styles.colHeader}>{col.name}</div>
                        {index === 1 && sortJd !== '' ? <img src={sortArrow} alt="" className={sortImgStyle} /> : null}
                        {index === 2 && sortResume !== '' ? <img src={sortArrow} alt="" className={sortImgStyle} /> : null}
                    </div>
                )
            })}
        </div>
    );
}

const RenderRows = props => {
    const { data } = props;
    return (
        <React.Fragment>
            {data.map((skill, index) => {
                return (
                    <div key={`${data.skill}${index}`} className={styles.ConentContainer}>
                        <div className={[styles.col1, styles.commonBack1].join(' ')}>{skill.skill}</div>
                        <div className={[styles.col2, styles.commonBack2].join(' ')}>{skill.jdSkillCount}</div>
                        <div className={[styles.col3, styles.commonBack3].join(' ')}>{skill.resumeSkillCount}</div>
                    </div>
                )
            })}
        </React.Fragment>
    )
}

export default SkillsTable;