import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';
import skillShuffle from '../../../../../assets/insights/greatIndianJobHunt/skillShuffle.jpg';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        So, you're fresh out of college, degree in hand, ready to conquer the world but as 
        you dive into the job market, you realize you're not alone. In fact, you're swimming 
        in a sea of fellow job seekers, all vying for the same positions. Welcome to the 
        Indian job market of 2024, where the competition is fierce, the opportunities are 
        evolving, and the rules of the game are changing faster than the time taken update 
        your resume.
      </div>

      <div className={styles.title}>
        The Numbers Game
      </div>

      <div className={styles.text}>
        Let's cut to the chase -- the Indian job market is not for the faint of heart. According 
        to our analysis of over 50,000 LinkedIn job ads across major Indian cities, the average 
        number of applicants per job has skyrocketed in the past 12 months.
      </div>

      <div className={styles.text}>
        Bangalore, India's Silicon Valley, takes the crown for the most competitive job market. 
        With an average of 218 applicants per LinkedIn job ad within the first week of posting, 
        it's giving global tech hubs a run for their money. Mumbai isn't far behind, clocking 
        in at 195 applicants per job, while Delhi-NCR rounds out the top three with 180 applicants 
        per opening.
      </div>

      <div className={styles.text}>
        But here's the kicker -- these numbers represent a staggering increase from last year. 
        Bangalore has seen a 62% jump in applications per job, while Mumbai and Delhi-NCR have 
        experienced increases of 48% and 55%, respectively. It's like someone cranked up the 
        difficulty level of the job-hunting game overnight!
      </div>

      <div className={styles.title}>
        The Tale of Two Indias
      </div>

      <div className={styles.text}>
        While the metros are turning into pressure cookers of competition, there's a plot twist 
        in our story. Tier-2 cities are emerging as the dark horses of the Indian job market. 
        Take Indore, for example. This central Indian city is seeing an average of just 75 applicants 
        per job -- a number that would make any metro job seeker green with envy.
      </div>

      <div className={styles.text}>
        Jaipur, the Pink City, is another beacon of hope with 82 applicants per job. And let's 
        not forget Chandigarh, the planned city of the north, maintaining a relatively calm 90 
        applicants per opening.
      </div>

      <div className={styles.text}>
        What's driving this trend? For starters, many companies are setting up shop in these 
        cities, attracted by lower operational costs and a growing talent pool. Plus, with remote 
        work becoming more mainstream, professionals are no longer bound to the metros, leading 
        to a more distributed workforce.
      </div>

      <div className={styles.title}>
        The IT Sector's Shifting Landscape
      </div>

      <div className={styles.text}>
        While the overall job opportunities in the IT sector declined by 3.6% nationwide in 
        2023, there's a silver lining for tech hubs. Bengaluru and Hyderabad have bucked the 
        trend, showing remarkable growth in IT job postings. Hyderabad led the charge with a 
        41.5% increase, while Bengaluru saw a 24% rise in IT job opportunities.
      </div>
      
      <div className={styles.text}>
        This growth isn't just in job postings; there's a corresponding surge in job seeker 
        interest too. Hyderabad witnessed a staggering 161% increase in job clicks, while 
        Bengaluru saw an 80% rise. This data underscores the growing prominence of these cities 
        as top destinations for IT professionals in India.
      </div>

      <div className={styles.title}>
        The Great Indian Brain Drain 2.0
      </div>

      <div className={styles.text}>
        Remember when "brain drain" meant India's brightest minds heading abroad? Well, 2024 
        is flipping that script on its head. We're witnessing a reverse brain drain, with 
        many NRIs (Non-Resident Indians) returning home, armed with global experience and 
        a desire to make it big in their homeland.
      </div>

      <div className={styles.text}>
        This influx of returning talent is a double-edged sword. On one hand, it's bringing 
        valuable skills and international perspectives to the Indian job market. On the other, 
        it's cranking up the competition even further, especially for senior and leadership roles.
      </div>

      <div className={styles.text}>
        Interestingly, cities like Hyderabad and Pune are becoming hotspots for these returning 
        professionals. With their blend of urban amenities and relatively lower costs of living 
        compared to the top metros, these cities are witnessing a surge in job applications 
        from experienced international returnees.
      </div>

      <div className={styles.title}>
        The Skills Shuffle
      </div>

      <div className={styles.text}>
        In this hyper-competitive market, having the right skills can be your golden ticket. But 
        here's the million-dollar question -- what skills are employers actually looking for?
      </div>

      <img
        className={styles.applicationImage}
        src={skillShuffle}
        alt="The Skills Shuffle"
      />

      <div className={styles.text}>
        Data from our LinkedIn analysis reveals some interesting trends:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            AI and Machine Learning: No surprises here. With India positioning itself as an AI 
            powerhouse, professionals with AI and ML skills are seeing an average of  250+ 
            applications per job opening.
          </li>
          <li>
            Sustainability Experts: Plot twist! With India pushing for ambitious climate goals, 
            sustainability professionals are in high demand. Jobs in this sector are seeing 
            around 150 applications per opening.
          </li>
          <li>
            Digital Marketing Ninjas: As businesses double down on their online presence, digital 
            marketing roles are hot property, averaging 200 applications per job.
          </li>
          <li>
            Cybersecurity Guardians: With cyber threats on the rise, cybersecurity professionals 
            are the unsung heroes of the tech world. These roles see about 180 applications on average.
          </li>
          <li>
            Soft Skills Superstars: Here's a curveball -- employers are increasingly valuing soft 
            skills like adaptability, emotional intelligence, and creative problem-solving. While 
            harder to quantify, jobs emphasizing these skills  are seeing a noticeable uptick in 
            applications.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        Recent data also shows a rising demand for skills in analysis, agile methodologies, APIs, 
        JavaScript, and SQL, particularly in the IT sector.
      </div>

      <div className={styles.title}>
        The Gig Economy
      </div>

      <div className={styles.text}>
        As the traditional job market gets increasingly crowded, many Indians are turning to the 
        gig economy as either a stopgap or a long-term career choice. Platforms like Upwork, 
        Fiverr, and homegrown alternatives are seeing a surge in Indian freelancers.
      </div>

      <div className={styles.text}>
        Interestingly, our data shows that gig workers in India are facing less competition 
        compared to traditional job seekers. The average gig posting on these platforms receives 
        about 50-60 applications, significantly lower than the numbers we're seeing on LinkedIn 
        for full-time roles.
      </div>

      <div className={styles.text}>
        This trend is particularly pronounced in creative fields like content writing, graphic 
        design, and video editing. It seems that in the world of gigs, specialization and a 
        strong portfolio can help you stand out from the crowd more easily.
      </div>

      <div className={styles.title}>
        The Road Ahead
      </div>

      <div className={styles.text}>
        So, what's a job seeker to do in this ultra-competitive landscape? Here are a few strategies 
        that successful candidates are employing:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li>
            <strong>
              Skill Up or Ship Out: 
            </strong>
            Continuous learning is no longer a nice-to-have; it's a must-have. Platforms like Coursera 
            and edX are seeing a boom in Indian learners.
          </li>
          <li>
            <strong>
              Network Like Your Career Depends on It (Because It Does): 
            </strong>
            In a market where hundreds are applying for each job, personal connections can be your 
            secret weapon. LinkedIn reports that Indian users are more active than ever in networking 
            and engagement.
          </li>
          <li>
            <strong>
              Look Beyond the Metros: 
            </strong>
            Don't be afraid to cast a wider net. Tier-2 and Tier-3 cities might offer less competition 
            and a better quality of life. Consider emerging tech hubs like Hyderabad that are showing 
            significant growth in opportunities.
          </li>
          <li>
            <strong>
              Embrace the Gig: 
            </strong>
            Consider freelancing or contract work as a way to build your portfolio and make connections.
          </li>
          <li>
            <strong>
              Personal Branding is Key: 
            </strong>
            In a sea of resumes, a strong personal brand can make you stand out. Blogs, YouTube channels, 
            and active social media presences are becoming common among successful job seekers.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        As we navigate through 2024, one thing is clear -- the Indian job market is not for the 
        faint-hearted. But for those willing to adapt, upskill, and think outside the box, opportunities 
        abound. So, polish that resume, buff up that LinkedIn profile, and maybe consider that move to 
        Hyderabad or Bengaluru. Your dream job might just be a well-crafted application away!
      </div>

      <div className={styles.text}>
        Ready to tackle the competitive Indian job market? Start by creating a standout resume that 
        showcases your unique skills and experiences. Use Panna Resume Builder to craft a professional, 
        ATS-friendly resume that helps you stand out from the crowd. Visit paññã Resume Builder today 
        and take the first step towards your dream job!
      </div>

      <ResumeBuildButton />

      <div className={styles.text}>
        <strong>
          Frequently Asked Questions:
        </strong>
      </div>

      <div className={styles.text}>
        <strong>1. Q: What are the most competitive job markets in India?</strong><br />
        A: Bangalore, Mumbai, and Delhi-NCR are currently the most competitive job markets in India.
      </div>

      <div className={styles.text}>
        <strong>2. Q: Are there any less competitive job markets in India?</strong><br />
        A: Yes, tier-2 cities like Indore, Jaipur, and Chandigarh generally have less competition 
        for jobs.
      </div>

      <div className={styles.text}>
        <strong>3. Q: What skills are in high demand in the Indian job market?</strong><br />
        A: AI, Machine Learning, Sustainability, Digital Marketing, and Cybersecurity skills are 
        currently in high demand.
      </div>

      <div className={styles.text}>
        <strong>4. Q: Is the gig economy a viable option in India?</strong><br />
        A: Yes, the gig economy is growing in India, offering opportunities with less competition 
        than traditional full-time roles.
      </div>

      <div className={styles.text}>
        <strong>5. Q: How can I improve my chances in the competitive Indian job market?</strong><br />
        A: Focus on continuous learning, networking, considering tier-2 cities, embracing gig  work, and 
        building a strong personal brand.
      </div>

      <div className={styles.text}>
        <strong>6. Q: Are NRIs returning to the Indian job market?</strong><br />
        A: Yes, there's a trend of NRIs returning to India, bringing international experience to the job 
        market.
      </div>

      <div className={styles.text}>
        <strong>7. Q: How important are soft skills in the current Indian job market?</strong><br />
        A: Soft skills like adaptability, emotional intelligence, and creative problem-solving are 
        increasingly valued by employers.
      </div>

      <div className={styles.text}>
        <strong>8. Q: What's the average number of applicants per job in major Indian cities?</strong><br />
        A: In cities like Bangalore, Mumbai, and Delhi-NCR, jobs can receive anywhere from 180 to 218 
        applicants on average.
      </div>
    </div>
  );
};

const Mobile = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Mobile;
