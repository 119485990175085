import React, { useState } from 'react';
import Follow from './FollowLinks/Follow';
import classes from './Footer.module.scss';
import { useNavigate } from 'react-router-dom';


const Footer = ({ onClick }) => {

    const navigate = useNavigate();

    const termsPopup = () => {
        navigate("/terms-of-service");
    }

    const privacyPopup = () => {
        navigate("/privacy-policy");
    }

    return (
        <>

            <footer className={classes.Footer}>
                <div className={classes.contentWrap}>
                    <div className={classes.FooterSpanWrap}>
                        <span className={classes.FooterSpan}>© Copyright {new Date().getFullYear()}. Paññã.</span>
                        <span className={[classes.Allright, classes.allRightReserved].join(' ')}> All rights reserved.</span>
                        <span onClick={() => privacyPopup()} className={[classes.Allright, classes.privacy].join(' ')}> Privacy</span>
                        <span onClick={() => termsPopup()} className={[classes.Allright, classes.privacy].join(' ')}> Terms</span>
                    </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a id="supportMail" href="mailto:support@panna.ai" className={classes.reachOut}>support@panna.ai</a>
                    <Follow />
                </div>
            </footer>

        </>
    );
}

export default Footer;