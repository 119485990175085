import hero from '../../../../assets/insights/greatIndianJobHunt/hero.png';

export const meta = {
  author: "",
  date: "2024-09-12",
  type: "Insights",
  title: "The Great Indian Job Hunt",
  subTitle: "Navigating the 2024 Job Market",
  description: "So, you're fresh out of college, degree in hand, ready to conquer the world but as you dive into the job market, you realize you're not alone. In fact, you're swimming in a sea of fellow job seekers, all vying for the same positions.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/great-indian-job-hunt",
};