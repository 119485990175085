import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { useSelector } from 'react-redux';
import { BASEPATH } from '../../../lib/constants';
import Button from '../../Button';

const Desktop = (props) => {
    const navigate = useNavigate();
    const handleBuildResume = () => {
        navigate('/resume-build-home');
    };
    return (
        <div className={styles.footerContainer}>
            <div className={styles.btn}>
                <Button id="builder-resume-btn-link" onClick={() => {
                    handleBuildResume();
                }} text="Build your perfect resume today!" />
            </div>
        </div>
    );
}

export default Desktop;
