import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ReadingPatterns from '../../../../../assets/insights/resumeReadingPsychology/motion.png';
import HaloEffect from '../../../../../assets/insights/resumeReadingPsychology/haloEffectImage.png';
import AnchoringBias from '../../../../../assets/insights/resumeReadingPsychology/priceAnchor.png';
import MemoryRetention from '../../../../../assets/insights/resumeReadingPsychology/memoryRetention.jpg';
import HalfEmptyHalfFull from '../../../../../assets/insights/resumeReadingPsychology/framingEffect.png';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        It's Monday morning, and you're a hiring manager sifting through a stack of resumes taller than
        your coffee mug. Your mission? Find the perfect candidate for that crucial role your company
        needs to fill. But here's the kicker - you've got about 7.4 seconds per resume to make an
        initial decision.
      </div>

      <div className={styles.text}>
        Feeling the pressure yet? Welcome to the world of resume reading psychology.
      </div>

      <div className={styles.text}>
        Experienced hiring managers, with decades in the field, have seen countless resumes cross their
        desks. They can attest that it's not just about qualifications and experience. There's a whole
        psychological game at play - one that can make or break a candidate's chances before they even
        step foot in the interview room. This hidden dimension of resume evaluation often goes unnoticed
        by job seekers but plays a crucial role in the hiring process.
      </div>

      <div className={styles.text}>
        So, let's dive into the fascinating world of resume psychology and uncover what really goes on
        in a hiring manager's mind during those crucial 7.4 seconds (and beyond).
      </div>

      <div className={styles.title}>
        The 7.4 Second Rule: First Impressions Matter
      </div>

      <div className={styles.text}>
        You've probably heard that first impressions are made in seconds, but did you know that applies
        to resumes too? A study by Ladders, Inc. found that recruiters spend an average of just 7.4 seconds
        initially scanning a resume.
      </div>

      <div className={styles.text}>
        In those fleeting moments, here's what's running through a hiring manager's mind:
      </div>

      <ol className={styles.list}>
        <li className={styles.listItem}>
          Is this visually appealing or a cluttered mess?
        </li>
        <li className={styles.listItem}>
          Can I quickly spot relevant experience and skills?
        </li>
        <li className={styles.listItem}>
          Does anything stand out (in a good or bad way)?
        </li>
        <li className={styles.listItem}>
          Is there a clear career progression?
        </li>
        <li className={styles.listItem}>
          Are there any red flags (gaps, job-hopping)?
        </li>
      </ol>

      <div className={styles.text}>
        <strong>Pro Tip:</strong> As a candidate, make those 7.4 seconds count. Use clear formatting,
        bullet points, and highlight key achievements right at the top.
      </div>

      <div className={styles.title}>
        The F and Z Patterns: How We Actually Read Resumes
      </div>

      <div className={styles.text}>
        When it comes to resume scanning, hiring managers typically follow one of two patterns:
      </div>

      <div>
        <img
          src={ReadingPatterns}
          alt='The Resume Reading Patterns'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>The F Pattern:</th>
              <th>The Z Pattern:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <ul>
                  <li>
                    Eyes move across the top of the page
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Start at the top left
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <ul>
                  <li>
                    Then down the left side
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Move across to the top right
                  </li>
                </ul>
              </td>
            </tr>

            <tr>
              <td>
                <ul>
                  <li>
                    Occasionally scanning right
                  </li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Diagonally down to the bottom left and finally across to the bottom right
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className={styles.text}>
        Understanding these patterns is crucial for both hiring managers and candidates: How different patterns
        can be suited for various types of data?
      </div>

      <div className={styles.text}>
        The F-pattern is particularly effective for text-heavy, structured content like resumes. It's
        well-suited for:
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          Scanning lists of skills or qualifications
        </li>
        <li className={styles.listItem}>
          Reading job titles and company names
        </li>
        <li className={styles.listItem}>
          Quickly assessing chronological information
        </li>
      </ul>

      <div className={styles.text}>
        A 2006 study by the Nielsen Norman Group found that the F-pattern works best when information is
        presented in a clear hierarchy, with the most important details at the top and along the left
        side.
      </div>

      <div className={styles.text}>
        The Z-pattern, on the other hand, is more effective for:
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          Visually-oriented layouts
        </li>
        <li className={styles.listItem}>
          Infographic-style resumes
        </li>
        <li className={styles.listItem}>
          Designs with strong horizontal elements
        </li>
      </ul>

      <div className={styles.text}>
        Research by the CXL Institute suggests that the Z-pattern is particularly useful when there are
        four key areas of information to convey, which aligns well with resumes that highlight a candidate's
        main selling points.
        <br />
        Interestingly, a 2019 study published in the Journal of Eye Movement Research found that readers
        tend to switch between F and Z patterns depending on the layout and content density. For resumes,
        this suggests that a hybrid approach might be most effective:
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          Use an F-pattern for the main body of the resume
        </li>
        <li className={styles.listItem}>
          Incorporate Z-pattern elements for standout sections or visual components
        </li>
      </ul>

      <div className={styles.text}>
        According to a study by TheLadders, recruiters spend an average of 6 seconds reviewing an
        individual resume. In that brief time, they typically followed an F-shaped pattern, focusing on:
      </div>

      <ol className={styles.list}>
        <li className={styles.listItem}>
          Name
        </li>
        <li className={styles.listItem}>
          Current title/company
        </li>
        <li className={styles.listItem}>
          Previous title/company
        </li>
        <li className={styles.listItem}>
          Previous position start and end dates
        </li>
        <li className={styles.listItem}>
          Current position start and end dates
        </li>
        <li className={styles.listItem}>
          Education
        </li>
      </ol>

      <div className={styles.text}>
        This underscores the importance of strategically placing key information to align with these
        natural reading patterns.
      </div>

      <div className={styles.text}>
        For Hiring Managers: Be aware that you might miss important information if it's not placed
        strategically on the resume.
      </div>

      <div className={styles.text}>
        For Candidates: Structure your resume to place key information along these reading patterns. Put
        your most impressive qualifications and achievements where they're most likely to be seen.
      </div>

      <div className={styles.title}>
        The Halo Effect: When One Good Thing Overshadows Everything Else
      </div>

      <div className={styles.text}>
        Ever noticed how a candidate's impressive degree or blue-chip company experience can make you
        view their entire resume more favorably? That's the Halo Effect in action.
        <br />
        This cognitive bias can work both ways:
      </div>

      <div className={styles.text}>
        <strong>Positive Halo:</strong> "Wow, they worked at Google? They must be brilliant at everything!"
        <br />
        <strong>Negative Halo:</strong> "Three typos in the first paragraph? This person must be careless
        in their work too."
      </div>

      <div className={styles.text}>
        Hiring managers need to be aware of this bias and consciously evaluate each aspect of a resume
        independently.
      </div>

      <div>
        <img
          src={HaloEffect}
          alt='The ways to avoid Halo Effect hiring'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.title}>
        The Primacy and Recency Effects: First and Last Impressions Stick
      </div>

      <div className={styles.text}>
        When reading a resume, hiring managers are more likely to remember information at the beginning
        (Primacy Effect) and end (Recency Effect) of the document.
      </div>

      <div className={styles.text}>
        Here's how it plays out:
      </div>

      <ul className={styles.list}>
        <li className={styles.listItem}>
          <strong>Primacy Effect: </strong>
          That punchy professional summary at the top? It sets the tone for how we view the rest of
          the resume.
        </li>

        <li className={styles.listItem}>
          <strong>Recency Effect: </strong>
          The last few items, often education or volunteer work, leave a lasting impression.
        </li>

        <li className={styles.listItem}>
          <strong>Candidate Strategy: </strong>
          Put your most impressive and relevant information at the beginning and end of your resume.
          Sandwich any less exciting (but necessary) details in the middle.
        </li>
      </ul>

      <div className={styles.title}>
        The Anchoring Bias: When First Information Colors Everything Else
      </div>

      <div>
        <img
          src={AnchoringBias}
          alt="The Anchoring Bias"
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        Imagine you're reviewing two candidates:
      </div>

      <div className={styles.text}>
        <strong>Candidate A:</strong> Led a team of 5, increased sales by 20%
        <br />
        <strong>Candidate B:</strong> Increased sales by 20%, led a team of 5
      </div>

      <div className={styles.text}>
        Even though the information is identical, our brains tend to latch onto the first piece of
        information (the anchor) and judge the rest in relation to it. In this case, Candidate A might
        be perceived as more of a leader, while Candidate B as more of a sales expert.
      </div>

      <div className={styles.text}>
        Hiring Manager Hack: Be aware of this bias and consciously evaluate each piece of information
        independently.
      </div>

      <div className={styles.title}>
        The Picture Superiority Effect: Why Visuals Pack a Punch
      </div>

      <div className={styles.text}>
        Our brains process visual information 60,000 times faster than text. That's why infographic
        resumes or well-designed layouts can be so effective.
      </div>

      <div>
        <img
          src={MemoryRetention}
          alt="The Memory Retention"
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        But be careful - there's a fine line between visually appealing and overly flashy. As a hiring
        manager, I appreciate a clean, professional design that makes the information easy to digest.
      </div>

      <div className={styles.text}>
        Candidate Tip: Use simple charts or graphs to illustrate achievements, but don't go overboard
        with rainbow colors and clip art.
      </div>

      <div className={styles.title}>
        The Cognitive Load Theory: Don't Make Me Think!
      </div>

      <div className={styles.text}>
        Hiring managers process a lot of information quickly. Dense paragraphs of text increase
        cognitive load, making it harder for them to extract key information.
        <br />
        What works better?
      </div>
      <br />

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Bullet points
          </li>

          <li className={styles.listItem}>
            Short, punchy sentences
          </li>

          <li className={styles.listItem}>
            Clear headings and subheadings
          </li>

          <li className={styles.listItem}>
            Strategic use of bold or italics (but don't go overboard)
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        <strong>Remember: </strong>Make it easy for us to find the information we need. We'll thank you for it!
      </div>

      <div className={styles.title}>
        The Implicit Egotism Effect: We Like People Who Are Like Us
      </div>

      <div className={styles.text}>
        It's human nature - we tend to favor people who share similarities with us. This could be:
      </div>
      <br />

      <div className={styles.listText}>
        <ul className={styles.list}>
          <li className={styles.listItem}>
            Alma mater
          </li>

          <li className={styles.listItem}>
            Previous employers
          </li>

          <li className={styles.listItem}>
            Shared interests or volunteer work
          </li>
        </ul>
      </div>

      <div className={styles.text}>
        Hiring managers need to be aware of this bias and focus on qualifications and fit for the role,
        not personal similarities.
      </div>

      <div className={styles.text}>
        <strong>Candidate Strategy: </strong>
        Research the company and, if possible, the hiring manager. Highlight genuine connections or
        shared values, but never fabricate similarities.
      </div>

      <div className={styles.title}>
        The Cover Letter: A Psychological Bridge
      </div>

      <div className={styles.text}>
        While we've focused mainly on resumes, let's not forget the power of a well-crafted cover letter.
        From a psychological perspective, cover letters serve several important functions:
      </div>
      <br />

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            Narrative Creation: They allow candidates to tell their story, creating a more personal
            connection.
          </li>

          <li className={styles.listItem}>
            Gap Filling: Cover letters can explain résumé gaps or career changes, easing potential concerns.
          </li>

          <li className={styles.listItem}>
            Cultural Fit Indicator: The tone and content can give insights into how well a candidate
            might fit into the company culture.
          </li>

          <li className={styles.listItem}>
            Motivation Gauge: A tailored, thoughtful cover letter demonstrates genuine interest in the role.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        For hiring managers, cover letters can provide valuable context and help us see beyond the
        bullet points. For candidates, it's an opportunity to make a more personal connection and stand
        out from the crowd.
      </div>

      <div className={styles.title}>
        The Framing Effect: It's All in How You Say It
      </div>

      <div>
        <img
          src={HalfEmptyHalfFull}
          alt='Half Empty Half Full Image'
          className={styles.applicationImage}
        />
      </div>

      <div className={styles.text}>
        The way information is presented can significantly influence how it's perceived. Compare these
        two statements:
      </div>

      <div className={styles.text}>
        A) "Reduced customer complaints by 50%"
        <br />
        B) "Increased customer satisfaction, resulting in 50% fewer complaints"
      </div>

      <div className={styles.text}>
        While both convey the same information, B frames it in a more positive light.
        <br />
        Candidate Strategy: Frame your achievements positively, focusing on the value you brought rather
        than just the problems you solved.
      </div>

      <div className={styles.text}>
        Hiring Manager Awareness: Look beyond the framing to the actual substance of the achievement.
      </div>

      <div className={styles.title}>
        Putting It All Together: The Hiring Manager's Toolkit
      </div>

      <div className={styles.text}>
        So, how can hiring managers make the best of this knowledge and help candidates create more
        effective resumes? Here are some key takeaways:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            Be aware of cognitive biases and actively work to counteract them.
          </li>

          <li className={styles.listItem}>
            Use a structured evaluation process to ensure fair assessment of all candidates.
          </li>

          <li className={styles.listItem}>
            Look beyond formatting and buzzwords to the substance of a candidate's experience and achievements.
          </li>

          <li className={styles.listItem}>
            Consider using blind resume reviews to reduce unconscious bias.
          </li>

          <li className={styles.listItem}>
            Remember that the resume is just one part of the hiring process - use it as a tool to
            identify promising candidates for further evaluation.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>For candidates:</strong>
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            Make those first 7.4 seconds count with clear, visually appealing formatting.
          </li>

          <li className={styles.listItem}>
            Use the F and Z patterns to your advantage by placing key information strategically.
          </li>

          <li className={styles.listItem}>
            Reduce cognitive load with bullet points and concise language.
          </li>

          <li className={styles.listItem}>
            Frame your achievements positively and provide concrete, measurable results.
          </li>

          <li className={styles.listItem}>
            Tailor your resume and cover letter to the job and company, highlighting genuine connections
            and shared values.
          </li>
        </ol>
      </div>

      <div className={styles.text}>
        <strong>The Future of Resume Reading: AI and Beyond</strong>
      </div>

      <div className={styles.text}>
        As we look to the future, it's clear that technology will play an increasingly significant role
        in the resume reading process. AI-powered applicant tracking systems are already changing the
        game, but they come with their own set of psychological implications.
      </div>

      <div className={styles.text}>
        Key considerations:
      </div>

      <div className={styles.listText}>
        <ol className={styles.list}>
          <li className={styles.listItem}>
            AI systems may perpetuate existing biases if not carefully designed and monitored.
          </li>

          <li className={styles.listItem}>
            The human touch remains crucial in evaluating soft skills and cultural fit.
          </li>

          <li className={styles.listItem}>
            Candidates will need to optimize their resumes for both AI and human readers.
          </li>
        </ol>
      </div>

      <div className={styles.title}>
        In Conclusion: The Human Element
      </div>

      <div className={styles.text}>
        While understanding the psychology of resume reading can certainly improve the hiring process,
        it's important to remember that behind every resume is a real person with unique experiences,
        skills, and potential.
      </div>

      <div className={styles.text}>
        Hiring managers should also put some effort to look beyond the paper (or digital) resume and find
        the best person for the job - someone who will contribute to their teams, drive their companies
        forward, and grow together.
      </div>

      <div className={styles.text}>
        So, the next time you're faced with a stack of resumes, take a deep breath, be aware of your
        biases, and remember - you're not just filling a position, you're potentially changing someone's
        life. And that's a responsibility worth taking seriously.
      </div>
      <ResumeBuildButton />
      <div className={styles.text}>
        <strong>FAQs: The Psychology of Resume Reading</strong>
      </div>

      <div className={styles.text}>
        <strong>Q1: How long do hiring managers typically spend reviewing a resume?</strong><br />
        A1: On average, about 7.4 seconds for the initial scan.
      </div>

      <div className={styles.text}>
        <strong>Q2: What is the "F pattern" in resume reading?</strong><br />
        A2: A common eye movement pattern: across the top, down the left side, and occasionally right.
      </div>

      <div className={styles.text}>
        <strong>Q3: How does the "Halo Effect" impact resume evaluation?</strong><br />
        A3: One positive aspect can disproportionately influence the overall perception of the candidate.
      </div>

      <div className={styles.text}>
        <strong>Q4: What is cognitive load theory in resume context?</strong><br />
        A4: Dense, text-heavy resumes are harder to process. Use bullet points and clear formatting to
        reduce cognitive load.
      </div>

      <div className={styles.text}>
        <strong>Q5: How important is the visual design of a resume?</strong><br />
        A5: Crucial for first impressions and readability. Aim for clean and professional, not flashy.
      </div>

      <div className={styles.text}>
        <strong>Q6: What role does a cover letter play?</strong><br />
        A6: It provides context, explains gaps, demonstrates cultural fit, and shows genuine interest.
      </div>

      <div className={styles.text}>
        <strong>Q7: How can candidates use the "primacy and recency effects"?</strong><br />
        A7: Place the most impressive information at the beginning and end of the resume.
      </div>

      <div className={styles.text}>
        <strong>Q8: What is the "anchoring bias" in resume reading?</strong><br />
        A8: The first piece of information disproportionately influences how the rest is perceived.
      </div>

      <div className={styles.text}>
        <strong>Q9: How is AI changing the resume reading process?</strong><br />
        A9: AI screening tools are increasingly common, requiring resumes to be optimized for both
        algorithms and humans.
      </div>

      <div className={styles.text}>
        <strong>Q10: What are some common psychological biases in resume reading?</strong><br />
        A10: Halo effect, anchoring bias, implicit egotism, and framing effect are key biases to be
        aware of.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        {...meta}
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;
