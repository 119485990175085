/* eslint-disable no-console */
/* eslint-disable react/no-unused-state */
/* eslint-disable */

import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import JdAnimation from '../../animation/LoadingAnimation';
import FullPageView from '../../component/FullPageView';
import CustomTitle from '../../component/CustomTitle';
import SliderAnimation from '../../component/SliderAnimation';
import styles from './styles.module.scss';
import data from './data';
// import Instructions from '../../component/Instructions';
import FileUpload from '../../component/FileUpload1';
import Utility from '../../utils';
import Logger from '../../Logger';
import fileUploadstyles from '../../component/FileUpload/styles.module.css';
import JobDescriptionField from '../../component/JobDescriptionField1';
import Button from '../../component/Button';
import SaveJdPopup from '../../component/SaveJdPopup';
import config from '../../config';
import {
  cacheRecentJDs,
  cacheReportData,
  updateUserId,
  updateUserName,
  updateUserFile,
  getResumeInHTML,
  updateResumeHtmlLink,
  updateResumeUrl,
  updateParsedResumeData
} from '../../store/actions/cache';
import unDrawImg from '../../assets/jdToResume/undraw_collecting.png';
import error3Img from '../../assets/LandingPage/error3.png';
import error2Img from '../../assets/jdToResume/error2.png';
import Config from '../../config';
import Errorpopup from '../../component/ErrorPopup';
import Footer from '../../component/Footer/Footer';
import ConfirmPopup from '../../component/ConfirmPopup';
import JobDescriptionViewer from '../../component/JobDescriptionViewer';
import GooglePickerAPI from '../../component/GooglePicker';
import withRouter from '../../hooks/withRouter';
import { PaymentModal } from '../../component/PaymentModal';

import metaData from '../MetaData/data';

// TODO move all request to common request file
// TODO create new project with create-react-app and move the code there
//  TODO remove unused dependencies from package.json
// TODO configure eslint
const httpClient = axios.create();

httpClient.defaults.timeout = 85000;

const maxFileSize = 5242880;
const animationTime = 14300;

const saveJd = 'SAVE_JD_POPUP';
const uploadResumeStepID = 'Upload Resume';
const PasteJdStepId = 'Paste or Select Job Description';
const GetScoreStepId = 'Get Score';
const uploadResumeStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';
const PasteJDStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';
const GetScoreStepStyle = '0 2px 10px 0 rgba(160,141,248,0.90)';

class UploadResumeJd1 extends React.Component {
  PasteJdStepDiv;

  uploadResumeStepDiv;

  GetScoreStepDiv;

  stepText1Div;

  stepText2Div;

  stepText3Div;

  userBandwidth = 0;

  state = {
    isUploading: false,
    isUploaded: false,
    popupType: undefined,
    fullName: '',
    jobDescription: '',
    selectedJd: '',
    selectedSavedJd: '',
    isChecked: false,
    errorMessage: '',
    errorImage: error2Img,
    replaceJdName: '',
    isSample: false,
    isSampleSelected: false,
    isDone: false,
    isDriveUploadInProgress: false,
    currentStep: 1,
    stepsList: [1, 2, 3]
  };


  constructor(props) {
    super(props);
    console.log('this.props');
  }

  componentDidMount() {
    this.testUserBandwidth();
    const {
      reduxProps: { cache },
      dispatchRecentJds,
      router
    } = this.props;
    console.log('reduxProps cache', cache);
    const { recentJds } = cache;
    // this.uploadResumeStepDiv = document.getElementById(uploadResumeStepID);
    // this.PasteJdStepDiv = document.getElementById(PasteJdStepId);
    // this.GetScoreStepDiv = document.getElementById(GetScoreStepId);
    // this.stepText1Div = document.getElementById('stepText0');
    // this.stepText2Div = document.getElementById('stepText1');
    // this.stepText3Div = document.getElementById('stepText2');
    // this.highlightInstruction('step1');
    if (recentJds && typeof recentJds[0] === 'string') {
      dispatchRecentJds([]);
    }

    const paymentStatus = localStorage.getItem('payment');
    if (paymentStatus === 'success') {
      this.setState({
        paymentPopup: false
      });
    } else {
      this.setState({
        paymentPopup: true
      });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  onSavedJdSelected = (jd, title) => {
    this.setState({
      jobDescription: jd,
      selectedSavedJd: title,
      isSampleSelected: false
    });
  };

  testUserBandwidth = async () => {
    Utility.speedTest(bandwidth => {
      this.userBandwidth = bandwidth;
    });
  };

  onChange = e => {
    const file = e.target.files[0];
    if (file) {
      const resume = file.name;
      const resumeType = resume.split('.');
      const fileImg2Div = null;
      // const fileImg3Div = document.getElementById('fileImg3');
      const UploadWrapDiv = document.getElementById('uploadResumeWithJdWrap');
      const uploadFileInput = document.getElementById('uploadResumeWithJd');


      if (this.validateResume(file.size, resumeType)) {
        this.setState({
          isUploading: true,
          fileName: resume,
          resumeUploadError: ''
        });
        // if (fileImg2Div.classList.contains(fileUploadstyles.fileImg2)) {
        //   fileImg2Div.classList.replace(
        //     fileUploadstyles.fileImg2,
        //     fileUploadstyles.fileImg2Uploading
        //   );
        //   fileImg3Div.classList.replace(
        //     fileUploadstyles.fileImg3,
        //     fileUploadstyles.fileImg3Uploading
        //   );
        // } else {
        //   fileImg2Div.classList.replace(
        //     fileUploadstyles.fileImg2After,
        //     fileUploadstyles.fileImg2Uploading
        //   );
        //   fileImg3Div.classList.replace(
        //     fileUploadstyles.fileImg3After,
        //     fileUploadstyles.fileImg3Uploading
        //   );
        // }
        Utility.uploadFile(
          file,
          resumeType[resumeType.length - 1],
          response => {
            const {
              dispatchUpdateHTMLLink,
              dispatchUpdateResumeUrl,
              dispatchUpdateParsedResume
            } = this.props;
            dispatchUpdateResumeUrl({
              filename: resume,
              url: response.data.s3DownloadLink
            });

            dispatchUpdateHTMLLink(response.data.s3HtmlLink);
            setTimeout(() => {
              this.getResumeInHTML(response.data.s3HtmlLink);
            }, 15000);

            dispatchUpdateParsedResume(response.data);

            const { firstName, lastName, email, phoneNumber } = response.data;
            this.highlightInstruction('step2');
            UploadWrapDiv.classList.add(styles.onUploadInputField);

            // fileImg2Div.classList.add(fileUploadstyles.fileImg2);
            // fileImg3Div.classList.add(fileUploadstyles.fileImg3);
            const fullName =
              firstName && lastName
                ? `${firstName} ${lastName}`
                : firstName || lastName || '';
            this.setState({
              isUploaded: true,
              fullName,
              phoneNumber,
              email,
              resumeURL: response.data.s3DownloadLink
            });
            localStorage.setItem(
              'resumeDetails',
              JSON.stringify({
                fileName: resume,
                url: response.data.s3DownloadLink,
                fullName: fullName,
                phoneNumber: phoneNumber,
                email: email
              })
            );
            if (email) {
              const requestBody = {
                resumeUrl: response.data.s3DownloadLink,
                OrgName: config.organisationName,
                emailId: email
              };
              Utility.uploadResumeToSolar(requestBody);
            }
            Logger.postLogs(
              `"resume-jd-relevance - resume parse successfull", ${JSON.stringify(
                response.data
              )}, resume file-size: ${(file.size / 1000000).toFixed(
                2
              )}mb, userBandwidth: ${this.userBandwidth}`
            );
          },
          error => {
            this.highlightInstruction('step1');
            uploadFileInput.value = null;
            // fileImg2Div.classList.replace(
            //   fileUploadstyles.fileImg2Uploading,
            //   fileUploadstyles.fileImg2
            // );
            // fileImg3Div.classList.replace(
            //   fileUploadstyles.fileImg3Uploading,
            //   fileUploadstyles.fileImg3
            // );
            this.setState({
              popupType: 'error',
              isUploading: false,
              isUploaded: false,
              fileName: '',
              errorMessage:
                'Error while uploading resume, please try again in a little while.',
              errorImage: error2Img,
              isDriveUploadInProgress: false
            });
            console.info('error', error.response);
            Logger.postLogs(
              `"resume-jd-relevance - resume parse Unsuccessfull", ${JSON.stringify(
                error
              )}, resume file-size: ${(file.size / 1000000).toFixed(
                2
              )}mb, userBandwidth: ${this.userBandwidth}`
            );
          }
        );
      }
    } else {
      this.setState({
        isUploading: false,
        isUploaded: false,
        isDriveUploadInProgress: false
      });
    }
  };

  getResumeInHTML = async S3HTMLLink => {
    const { dispatchGetResumeInHTML } = this.props;
    await Utility.getResumeInHtml(
      S3HTMLLink,
      res => {
        this.resumeInHtml = res.data;
        dispatchGetResumeInHTML(this.resumeInHtml);
      },
      error => {
        this.resumeInHtml = 'Error';
        console.info('error in resume viewer', error, error.response);
      }
    );
  };

  validateResume = (fileSize, resumeType) => {
    let isResumeValid;
    const supportedVersions = ['doc', 'docx', 'txt', 'rtf', 'pdf'];
    if (
      resumeType &&
      resumeType.length !== 0 &&
      supportedVersions.includes(resumeType[resumeType.length - 1])
    ) {
      if (fileSize <= maxFileSize) {
        isResumeValid = true;
      } else {
        isResumeValid = false;
        this.setState({
          resumeUploadError: data.resumeSizeError,
          isDriveUploadInProgress: false
        });
      }
    } else {
      isResumeValid = false;
      this.setState({
        resumeUploadError: data.invalidResumeFormat,
        isDriveUploadInProgress: false
      });
    }
    return isResumeValid;
  };

  resetUploadedFile = () => {
    document.getElementById('uploadResumeWithJd').value = null;
    document
      .getElementById('uploadResumeWithJdWrap')
      .classList.replace(
        styles.onUploadInputField,
        fileUploadstyles.uploadArea
      );
    this.highlightInstruction('step1');
    this.setState({
      isUploading: false,
      isUploaded: false,
      fullName: '',
      fileName: '',
      isDriveUploadInProgress: false
    });
  };

  onChangeText = text => {
    const { value } = text.currentTarget;
    this.setState({ jobDescription: value });
    if (value) {
      this.highlightInstruction('step3');
    } else {
      this.setState({ isSampleSelected: false, selectedJd: '' });
      this.highlightInstruction('step2');
    }
  };

  onJdSelected = (jd, title) => {
    this.setState({
      jobDescription: jd,
      selectedJd: title,
      selectedSavedJd: '',
      isSampleSelected: true
    });
    this.highlightInstruction('step3');
  };

  resetJobDescription = () => {
    this.setState({
      jobDescription: '',
      selectedJd: '',
      selectedSavedJd: '',
      isSampleSelected: false
    });
    this.highlightInstruction('step2');
  };

  onCheckboxClicked = () => {
    this.setState(prevState => ({ isChecked: !prevState.isChecked }));
  };

  onErrorRetry = () => {
    this.setState({ popupType: undefined });
  };

  // TODO remove elements referene and use props instead to highlight steps
  highlightInstruction = step => {
    switch (step) {
      case 'step2': {
        this.setState({ currentStep: 2 });
        break;
      }
      case 'step3': {
        this.setState({ currentStep: 3 });
        break;
      }
      case 1:
      default: {
        this.setState({ currentStep: 1 });
      }
    }
  };

  removeSavedJd = () => {
    this.setState({ selectedSavedJd: '' });
  };

  onChangeNameText = event => {
    this.setState({ fullName: event.target.value });
  };

  hydrateJobDescription = jdName => {
    const { jobDescription } = this.state;
    const { reduxProps, dispatchRecentJds } = this.props;
    const { cache } = reduxProps;
    const { recentJds } = cache;
    let tempJds = [];
    if (recentJds && recentJds.length !== 0) {
      tempJds = cache.recentJds;
      const jdsName = [];
      tempJds.forEach(jd => jdsName.push(jd.name));
      if (!jdsName.includes(jdName)) {
        if (tempJds && tempJds.length > 2) {
          const recentThreeJDs = [];
          const jdEntry = { name: jdName, description: jobDescription };
          tempJds.push(jdEntry);
          tempJds.reverse().forEach((jobDesc, index) => {
            if (index <= 2) {
              recentThreeJDs.push(jobDesc);
            }
          });
          dispatchRecentJds(recentThreeJDs.reverse());
          this.uploadJobDescription();
        } else {
          const jdEntry = { name: jdName, description: jobDescription };
          tempJds.push(jdEntry);
          dispatchRecentJds(tempJds);
          this.uploadJobDescription();
        }
      } else {
        this.setState({ popupType: 'confirmReplace', replaceJdName: jdName });
      }
    } else {
      const jdEntry = { name: jdName, description: jobDescription };
      tempJds.push(jdEntry);
      dispatchRecentJds(tempJds);
      this.uploadJobDescription();
    }
  };

  // TODO look for different approach to disable scroll instead of disabling scroll on body element
  renderPopup = () => {
    const {
      popupType,
      isChecked,
      replaceJdName,
      jobDescription,
      isSample,
      selectedSavedJd,
      selectedJd
    } = this.state;
    const {
      reduxProps: { cache },
      dispatchRecentJds,
      history,
      router
    } = this.props;
    if (popupType) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
    switch (popupType) {
      case saveJd: {
        return isChecked ? (
          <div className={styles.popupStyle}>
            <SaveJdPopup
              title={data.saveJdPopupTitle}
              label={data.saveJdInputLabel}
              onCancel={() => this.setState({ popupType: undefined })}
              onSave={jdName => this.hydrateJobDescription(jdName)}
            />
          </div>
        ) : null;
      }
      case 'error': {
        const { errorMessage, errorImage } = this.state;
        return (
          <Errorpopup
            history={history}
            onErrorRetry={this.onErrorRetry}
            errorImage={errorImage}
            errorMessage={errorMessage}
          />
        );
      }
      case 'animation': {
        const { isDone } = this.state;
        return (
          <FullPageView history={history} style={styles.animationFullPage}>
            <JdAnimation
              isDone={isSample || isDone}
              onAnimationEnd={() => {
                if (isSample) {
                  router.navigate('/report', {
                    state: {
                      data: data.trySampleResponse,
                      isSample
                    }
                  });
                }
              }}
            />
          </FullPageView>
        );
      }
      case 'confirmReplace': {
        return (
          <div className={styles.popupStyle}>
            <ConfirmPopup
              title="Job description already exist with this name, do you want to replace ?"
              onOk={() => {
                const { recentJds } = cache;
                let tempJds = [];

                tempJds = recentJds.map(jd => {
                  if (jd.name === replaceJdName) {
                    // eslint-disable-next-line no-param-reassign
                    jd.description = jobDescription;
                  }
                  return jd;
                });
                dispatchRecentJds(tempJds);
                this.uploadJobDescription();
              }}
              onCancel={() => this.setState({ popupType: saveJd })}
            />
          </div>
        );
      }
      case 'jdViewer': {
        return (
          <div className={styles.popupStyle}>
            <JobDescriptionViewer
              jdTitle={selectedSavedJd || selectedJd}
              value={jobDescription}
              onchangeJD={this.onChangeText}
              onBack={() => this.setState({ popupType: undefined })}
              onMeasureClick={this.onMeasureClick}
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  onMeasureClick = () => {
    const { isChecked, selectedSavedJd } = this.state;
    if (isChecked && !selectedSavedJd) {
      this.setState({ popupType: saveJd });
    } else {
      this.uploadJobDescription();
    }
  };

  uploadJobDescription = () => {
    const { jobDescription } = this.state;
    this.setState({ popupType: 'animation' });
    // eslint-disable-next-line no-undef
    const file = new File([jobDescription], 'jobDescription.txt', {
      type: 'text/plain'
    });
    Utility.uploadFile(
      file,
      'txt',
      response => {
        this.setState({ jobDescriptionURL: response.data.s3DownloadLink });
        Logger.postLogs(
          `"resume-jd-relevance - job description upload successfull", ${JSON.stringify(
            response.data
          )}`
        );
        this.checkRelevance();
      },
      error => {
        this.setState({
          popupType: 'error',
          errorMessage:
            'Error while uploading job description, please try again in a little while.',
          gotoSection: 'secondSection',
          errorImage: error3Img
        });
        Logger.postLogs(
          `"resume-jd-relevance - job description upload Unsuccessfull", ${JSON.stringify(
            error
          )}`
        );
      }
    );
  };

  checkRelevance = async () => {
    const {
      resumeURL,
      jobDescriptionURL,
      fullName,
      phoneNumber,
      email,
      selectedJd,
      isSampleSelected
    } = this.state;
    const {
      dispatchReportData,
      dispatchUpdateUserId,
      dispatchUpdateUserName,
      dispatchUpdateUserFile,
      dispatchGetResumeInHTML,
      history,
      router
    } = this.props;
    const url = config.releavanceCheck;
    const configObj = {
      headers: {
        'content-type': 'application/json',
        Accept: '/'
      }
    };
    const requestBody = {
      Resume_URL: resumeURL,
      Jd_URL: jobDescriptionURL,
      Full_name: fullName,
      Email: email,
      Phone_number: phoneNumber,
      is_sample_jd: isSampleSelected,
      jd_title: selectedJd || 'None',
      application: 'panna_score_api',
      skills_deleted_status: 0
    };
    const start = new Date().getTime();
    httpClient
      .post(url, requestBody, configObj)
      .then(async response => {
        if (response.data.status_code === 0) {
          Logger.postLogs(
            `"resume-jd-relevance - relevance check unsuccessfull", ${response.data.status_msg}`
          );
          this.setState({
            popupType: 'error',
            errorMessage: response.data.status_msg,
            errorImage: unDrawImg
          });
        } else {
          const { Uid } = response.data;

          // FIXME: Add back later
          // const res = await httpClient.post(
          //   'https://resume-builder-ai.mroads.com/improved_res',
          //   {
          //     Resume_URL: resumeURL,
          //     Jd_URL: jobDescriptionURL
          //   }
          // );

          // const aiResumeData = res.data;
          const aiResumeData = null;
          let navigationTime = 0;
          let date = new Date().getTime();
          date = new Date().getTime() - start;
          console.info('response time: ', `${date} ms`);
          this.reportData = response.data;
          navigationTime = animationTime > date ? animationTime - date : 4000;
          console.info('response time: ', `${date} ms`);
          this.setState({ isDone: true });
          setTimeout(
            () =>
              // history.push({
              //   pathname: '/report',
              //   data: this.reportData,
              //   resumeInHtml: this.resumeInHtml
              // }),
              router.navigate('/report', {
                state: {
                  resumeInHtml: this.resumeInHtml,
                  data: this.reportData,
                  aiResumeData: aiResumeData
                }
              }),
            navigationTime
          );
          Logger.postLogs(
            `"resume-jd-relevance - relevance check successfull", ${JSON.stringify(
              response.data
            )}`
          );
          dispatchUpdateUserFile({
            resume: resumeURL,
            jd: jobDescriptionURL
          });
          dispatchUpdateUserId(Uid);
          dispatchUpdateUserName(fullName);
          dispatchReportData(this.reportData);
          dispatchGetResumeInHTML(this.resumeInHtml);
        }
      })
      .catch(error => {
        // eslint-disable-next-line
        console.info('error in DS service', error);
        Logger.postLogs(
          `"resume-jd-relevance - relevance check unsuccessfull", ${JSON.stringify(
            error
          )}`
        );
        this.setState({
          popupType: 'error',
          errorMessage:
            'There was an error while collecting your data. Please try again',
          errorImage: unDrawImg
        });
      });
  };

  checkSampleRelevance = () => {
    this.setState({ popupType: 'animation', isSample: true });
  };

  onDriveUploadError = error => {
    this.setState({ resumeUploadError: error, isDriveUploadInProgress: false });
  };

  driveUploadingInProgress = inProgress => {
    this.setState({
      isDriveUploadInProgress: inProgress,
      resumeUploadError: ''
    });
  };

  render() {
    const {
      isUploading,
      isUploaded,
      fileName,
      fullName,
      jobDescription,
      selectedJd,
      selectedSavedJd,
      resumeUploadError,
      isChecked,
      isDriveUploadInProgress,
      currentStep
    } = this.state;
    const {
      reduxProps: { cache },
      history,
      router
    } = this.props;

    return (
      <div className={styles.overLay}>
        <FullPageView
          style={[styles.landingPageContentContainer, currentStep !== 1 ? styles.height100 : ''].join(' ')}
          history={history}
        >
          <Helmet>
            <title>{metaData.scoreHomePage.title}</title>
            <meta name='title' content={metaData.scoreHomePage.metaTitle} />
            <meta name='description' content={metaData.scoreHomePage.metaDescription} />
          </Helmet>

          {/* {this.state.paymentPopup && <PaymentModal />} */}
          <div className={styles.LandingPageContainer}>
            {this.renderPopup()}
            <div className={styles.col1}>
              <div style={{ width: '90%' }}>
                <div className={styles.heading}>
                  Land your dream job with the world’s best AI resume-scoring tool!
                </div>
                <div className={styles.content}>
                  Elevate your job search game with our industry-leading software, meticulously engineered to deliver a resume that ranks high. Trusted by job seekers worldwide, our AI-driven platform ensures your resume stands out amidst the competition. Experience the difference today and unlock the doors to your dream career!
                </div>
                <div className={styles.points}>
                  <div className={styles.pointContent}>
                    <div className={styles.point}>1</div>
                    <div>Upload your resume</div>
                  </div>
                  <div className={styles.pointContent}>
                    <div className={styles.point}>2</div>
                    <div>Measure it against a job description</div>
                  </div>
                  <div className={styles.pointContent}>
                    <div className={styles.point}>3</div>
                    <div>Get a score to see if you match up</div>
                  </div>

                </div>
              </div>
            </div>
            <div className={styles.col2}>
              <Instructions currentStep={currentStep} />
              <FileUpload
                fileName={fileName}
                fullName={fullName}
                onChange={this.onChange}
                resetUploadedFile={this.resetUploadedFile}
                isUploading={isUploading}
                isUploaded={isUploaded}
                errorMessage={resumeUploadError}
                onChangeNameText={this.onChangeNameText}
                testSpeed={this.testUserBandwidth}
                isUploadingInProgress={isDriveUploadInProgress}
              // childNode={
              //   !isUploaded && !isUploading ? (
              //     <GooglePickerAPI
              //       onChange={this.onChange}
              //       onError={this.onDriveUploadError}
              //       inProgress={this.driveUploadingInProgress}
              //       isUploadingInProgress={isDriveUploadInProgress}
              //     />
              //   ) : null
              // }
              />
              {/* TODO instead of ternary use popup tyep to render job description field */}
              {isUploaded ? (
                <JobDescriptionField
                  jobDescription={jobDescription}
                  onChange={this.onChangeText}
                  onJdSelected={this.onJdSelected}
                  onSavedJdSelected={this.onSavedJdSelected}
                  selectedJd={selectedJd}
                  selectedSavedJd={selectedSavedJd}
                  resetJd={this.resetJobDescription}
                  isChecked={isChecked}
                  onCheckboxClicked={this.onCheckboxClicked}
                  onMeasureClick={this.onMeasureClick}
                  recentJds={cache.recentJds}
                  onRemoveSavedJd={this.removeSavedJd}
                  openJDViewer={() => this.setState({ popupType: 'jdViewer' })}
                />
              ) : (
                <div className={styles.trySampleWrap}>
                  <div className={styles.sampleWrap}>
                    <div className={styles.sampleLable}>
                      or want to know how it works ?
                    </div>
                    <Button
                      id="try-sample"
                      text="Try sample"
                      buttonStyle={styles.trySample}
                      onClick={this.checkSampleRelevance}
                      disable={
                        isUploaded || isUploading || isDriveUploadInProgress
                      }
                    />
                  </div>
                </div>
              )}
            </div>
          </div>

        </FullPageView >
        <div>
          <BlackStrip />
        </div>
        <div className={styles.footer}>
          <Footer />
        </div>
      </div>
    );
  }
}

const Instructions = ({ currentStep }) => {
  console.log('currentStep:', currentStep);
  return <div className={styles.instructionsContainer}>

    <div className={[styles.step, currentStep === 1 ? styles.bold : ''].join(' ')}>Upload Resume</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="8" viewBox="0 0 85 8" fill="none">
      <path d="M84.3536 4.10355C84.5488 3.90828 84.5488 3.5917 84.3536 3.39644L81.1716 0.214459C80.9763 0.0191968 80.6597 0.0191968 80.4645 0.214459C80.2692 0.409721 80.2692 0.726304 80.4645 0.921566L83.2929 3.74999L80.4645 6.57842C80.2692 6.77368 80.2692 7.09026 80.4645 7.28553C80.6597 7.48079 80.9763 7.48079 81.1716 7.28553L84.3536 4.10355ZM4.37114e-08 4.25L84 4.24999L84 3.24999L-4.37114e-08 3.25L4.37114e-08 4.25Z" fill="black" fill-opacity="0.2" />
    </svg>
    <div className={[styles.step, currentStep === 2 ? styles.bold : ''].join(' ')}>Job Description</div>
    <svg xmlns="http://www.w3.org/2000/svg" width="85" height="8" viewBox="0 0 85 8" fill="none">
      <path d="M84.3536 4.10355C84.5488 3.90828 84.5488 3.5917 84.3536 3.39644L81.1716 0.214459C80.9763 0.0191968 80.6597 0.0191968 80.4645 0.214459C80.2692 0.409721 80.2692 0.726304 80.4645 0.921566L83.2929 3.74999L80.4645 6.57842C80.2692 6.77368 80.2692 7.09026 80.4645 7.28553C80.6597 7.48079 80.9763 7.48079 81.1716 7.28553L84.3536 4.10355ZM4.37114e-08 4.25L84 4.24999L84 3.24999L-4.37114e-08 3.25L4.37114e-08 4.25Z" fill="black" fill-opacity="0.2" />
    </svg>
    <div className={[styles.step, currentStep === 3 ? styles.bold : ''].join(' ')}>Get your score</div>
  </div>
}

const BlackStrip = () => {
  return <div className={styles.blackStripContainer}>
    <div className={[styles.part1]}>
      <span className={styles.bigFont}>1M+</span>  resumes already scored
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="1" height="36" viewBox="0 0 1 36" fill="none">
      <line x1="0.25" y1="1.09279e-08" x2="0.249998" y2="36" stroke="white" stroke-opacity="0.5" stroke-width="0.5" />
    </svg>
    <div className={[styles.part1, styles.rightBorder].join(' ')}>
      <span className={styles.bigFont}> 90%</span>
      users improved their resume scores
    </div>
    <svg xmlns="http://www.w3.org/2000/svg" width="1" height="36" viewBox="0 0 1 36" fill="none">
      <line x1="0.25" y1="1.09279e-08" x2="0.249998" y2="36" stroke="white" stroke-opacity="0.5" stroke-width="0.5" />
    </svg>
    <div className={[styles.part1, styles.rightBorder].join(' ')}>
      <span className={styles.bigFont}>  #1 </span>
      ranked by leading HR experts
    </div>

  </div>
}


const mapStateToProps = reduxProps => ({
  reduxProps
});

const mapDispatchToProps = dispatch => ({
  dispatchRecentJds: recentJd => {
    dispatch(cacheRecentJDs(recentJd));
  },
  dispatchReportData: reportData => {
    dispatch(cacheReportData(reportData));
  },
  dispatchUpdateUserId: userId => {
    dispatch(updateUserId(userId));
  },
  dispatchUpdateUserName: userName => {
    dispatch(updateUserName(userName));
  },
  dispatchUpdateUserFile: userFiles => {
    dispatch(updateUserFile(userFiles));
  },
  dispatchGetResumeInHTML: userFiles => {
    dispatch(getResumeInHTML(userFiles));
  },
  dispatchUpdateHTMLLink: link => {
    dispatch(updateResumeHtmlLink(link));
  },
  dispatchUpdateResumeUrl: resumeUrl => {
    dispatch(updateResumeUrl(resumeUrl));
  },
  dispatchUpdateParsedResume: link => {
    dispatch(updateParsedResumeData(link));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadResumeJd1);


