import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        In today's fast-paced and digitally driven world, the role of technology in various 
        aspects of our lives is undeniable. From communication to commerce, technology has 
        reshaped how we interact with the world around us. Unsurprisingly, resume-building has 
        also been profoundly impacted by technological advancements. Gone are the days of paper 
        resumes and hand-delivered job applications; today, technology plays a transformative 
        role in crafting compelling resumes that capture attention and highlight key skills 
        and experiences. In this article, we'll delve deeper into the evolution of resume-building 
        in the digital age and explore how technology has revolutionized the process.
      </div>

      <div className={styles.title}>
        AI-Powered Resume Builders
      </div>

      <div className={styles.text}>
        In recent years, AI-powered resume builders have gained significant traction due to their 
        ability to streamline the resume creation process. These platforms employ sophisticated 
        algorithms to analyze the content provided by users and suggest improvements to enhance 
        the overall quality of the resume. For instance, AI algorithms can identify common 
        grammatical errors and offer suggestions for improvement, ensuring that the final document 
        is error-free and professionally polished.
      </div>

      <div className={styles.text}>
        AI-powered resume builders often provide users with access to a vast library of templates 
        and designs, allowing them to choose a format that best suits their individual preferences 
        and industry standards. Whether it's a sleek and modern design for a creative role or a more 
        traditional layout for a corporate position, these platforms offer unparalleled flexibility 
        in resume customization.
      </div>

      <div className={styles.text}>
        Moreover, AI algorithms can also assist users in optimizing their resumes for specific job 
        roles or industries. By analyzing job descriptions and industry trends, AI-powered platforms 
        can suggest relevant keywords and phrases to include in the resume, increasing the likelihood 
        of catching the attention of ATS systems and hiring managers. AI-powered resume builders 
        represent a significant advancement in resume-building technology, offering users a convenient 
        and efficient way to create professional resumes that effectively showcase their skills and 
        experiences.
      </div>

      <div className={styles.title}>
        Automated Resume Screening
      </div>

      <div className={styles.text}>
        As the volume of job applications continues to rise, many companies are turning to automated 
        resume-screening solutions to streamline the hiring process. These ATS systems are designed 
        to scan resumes quickly and efficiently, identifying candidates who possess the necessary 
        skills and qualifications for a given position.
      </div>

      <div className={styles.text}>
        To ensure that their resumes are successfully parsed by ATS systems, job seekers must 
        understand how these systems work and optimize their resumes accordingly. This often involves 
        strategically incorporating relevant keywords and phrases from the job description, as well as 
        formatting the document in a way that is easily readable by ATS algorithms. Fortunately, there 
        are several tools and resources available to help job seekers navigate the complexities of 
        automated resume screening. Many online platforms offer ATS optimization services, which analyze 
        resumes and provide feedback on how to improve their compatibility with ATS systems.
      </div>

      <div className={styles.text}>
        In addition to keyword optimization, job seekers can also enhance their resumes by including 
        quantifiable achievements and measurable results. By providing concrete examples of their 
        accomplishments, candidates can demonstrate their value to prospective employers and increase 
        their chances of advancing to the next stage of the hiring process.
      </div>

      <div className={styles.title}>
        Digital Portfolios and Multimedia Resumes
      </div>

      <div className={styles.text}>
        In today's digital age, traditional resumes are no longer the only option for job seekers 
        looking to showcase their skills and experiences. Digital portfolios and multimedia resumes 
        offer a dynamic and interactive way to present oneself to potential employers, allowing 
        candidates to go beyond the constraints of a traditional text-based resume. Graphic designers, 
        photographers, and other creative professionals can use digital portfolios to showcase their 
        work in a visually compelling and engaging format. These portfolios often include samples of 
        past projects, client testimonials, and other relevant materials that demonstrate the 
        candidate's expertise and creativity.
      </div>

      <div className={styles.text}>
        Similarly, video resumes provide candidates with an opportunity to introduce themselves to 
        potential employers in a more personal and engaging manner. By combining visual imagery with 
        spoken narration, candidates can effectively convey their personality, communication skills, 
        and passion for their chosen field.
      </div>

      <div className={styles.title}>
        Social Media and Online Presence
      </div>

      <div className={styles.text}>
        In addition to traditional job boards and networking events, social media platforms have 
        become valuable tools for job seekers looking to connect with potential employers and industry 
        professionals. Platforms like LinkedIn, Twitter, and Facebook allow users to create professional 
        profiles, share updates and insights, and connect with others in their field.
      </div>

      <div className={styles.text}>
        To leverage the power of social media in their job search, job seekers must carefully curate 
        their online presence to align with their professional goals and aspirations. This often 
        involves optimizing their profiles with relevant keywords and phrases, showcasing their skills 
        and experiences, and engaging with others in their industry through thoughtful commentary and 
        discussion. Moreover, many companies use social media platforms to advertise job openings and 
        recruit new talent, making it essential for job seekers to maintain an active and professional 
        presence online. By regularly updating their profiles, participating in relevant conversations, 
        and building meaningful connections with others in their field, job seekers can increase their 
        visibility and attract the attention of potential employers.
      </div>

      <div className={styles.title}>
        Data Analytics for Resume Optimization
      </div>

      <div className={styles.text}>
        Data-driven approaches are increasingly being used to optimize resumes for better results. 
        Job seekers analyze job market trends, salary insights, and resume performance metrics to 
        tailor their resumes effectively. Tools that provide feedback on readability, engagement, 
        and relevance help refine content and structure, ensuring that resumes resonate with hiring 
        managers and recruiters.
      </div>

      <div className={styles.text}>
        The COVID-19 pandemic has accelerated the shift towards remote work, placing a stronger 
        emphasis on digital competencies. Job seekers utilize technology to demonstrate their 
        proficiency in remote collaboration tools, project management software, and digital 
        communication platforms. Resumes highlight certifications, online courses, and digital badges 
        to showcase ongoing learning and adaptability in a technology-centric work landscape.
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        For the Next Generation
      </div>

      <div className={styles.text}>
        In conclusion, technology has transformed the landscape of resume building, offering 
        innovative tools and strategies to help job seekers navigate the digital frontier. By 
        embracing these advancements and adhering to fundamental principles of clarity, conciseness, 
        and relevance, individuals can craft compelling resumes that open doors to their dream careers. 
        As we continue to embrace technology in all aspects of our lives, it's essential for job 
        seekers to stay informed about the latest trends and developments in resume-building 
        technology to stay ahead in today's competitive job market.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        { ...meta }
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;