import React, { useEffect, useState } from 'react';
import styles from './styles.module.css';
import Footer from '../../component/Footer/Footer';
import PannaLogo from '../../assets/Logo/pannaBlack.png';
import DotsScale from '../../assets/LandingPage/DotsScale.png';
import triangle1 from '../../assets/LandingPage/Triangle_1.png';
import triangle2 from '../../assets/LandingPage/Triangle_2.png';
import triangle3 from '../../assets/LandingPage/Triangle_3.png';
import largeTriangle from '../../assets/LandingPage/LargerTraingle.png';
import Button from '../../component/Button';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { BASEPATH } from '../../lib/constants';
import { useDispatch } from 'react-redux';
import { clearStore } from '../../store/actions/cache';
import { Navbar } from '../Navbar';
import { useLocation } from 'react-router-dom';
// TODO use in built modularized css
const FullPageView = props => {
  const { children, style, id, history } = props;
  const contentContainerStyle = style
    ? [style, styles.secodSectionContainer]
    : [styles.secodSectionContainer];

  const { authState, pannaAuth } = useAuth();
  const location = useLocation();
  const [isReportUrl, setIsReportUrl] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const clearStoreData = () => {
    dispatch(clearStore());
    signOut();
  }
  useEffect(() => {
    if (location.pathname.includes('/report')) {
      setIsReportUrl(true);
    } else {
      setIsReportUrl(false);
    }
  }, [location]);


  const signOut = async () => {
    await pannaAuth.signOut();
  }
  return (<>   {!isReportUrl ? <Navbar /> : null}

    <div id={id} className={contentContainerStyle.join(' ')}>
      {/* <img
        src={largeTriangle}
        alt=""
        className={[styles.triangle, styles.largeTriangle].join(' ')}
      />
      <img
        src={triangle1}
        alt=""
        className={[styles.triangle, styles.triangle1].join(' ')}
      />
      <img
        src={triangle2}
        alt=""
        className={[styles.triangle, styles.triangle2].join(' ')}
      />
      <img
        src={triangle3}
        alt=""
        className={[styles.triangle, styles.triangle3].join(' ')}
      />
      <img src={DotsScale} alt="" className={styles.dotScaleImg} /> */}
      {children}
    </div>
  </>

  );
};

export default FullPageView;
