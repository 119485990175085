// Original JavaScript code by Chirp Internet: www.chirp.com.au
// Please acknowledge use of this code by including this header.
import React from 'react';
import ReactDOM from 'react-dom';
import Popover from '../Popover';

class Hilitor {
    constructor(id, tag) {
        // private variables
        var targetNode = document.getElementById(id) || document.body;
        var hiliteTag = tag || "MARK";
        var skipTags = new RegExp("^(?:" + hiliteTag + "|SCRIPT|FORM)$");
        // var colors = ["#ff6", "#a0ffff", "#9f9", "#f99", "#f6f"];
        var colors = ["#5EDC26"];
        var wordColor = [];
        var colorIdx = 0;
        this.title = 'Skill Match';
        var matchRegExp = "";
        this.openLeft = false;
        this.openRight = false;
        // characters to strip from start and end of the input string
        var endRegExp = new RegExp('^[^\\w]+|[^\\w]+$', "g");
        // characters used to break up the input string into words
        var breakRegExp = new RegExp('\\,', "g");
        this.setEndRegExp = function (regex) {
            endRegExp = regex;
            return endRegExp;
        };
        this.setBreakRegExp = function (regex) {
            breakRegExp = regex;
            return breakRegExp;
        };
        this.setMatchType = function (type) {
            switch (type) {
                case "left":
                    this.openLeft = false;
                    this.openRight = true;
                    break;
                case "right":
                    this.openLeft = true;
                    this.openRight = false;
                    break;
                case "open":
                    this.openLeft = this.openRight = true;
                    break;
                default:
                    this.openLeft = this.openRight = false;
            }
        };
        this.setRegex = function (input) {
            input = input.replace(endRegExp, "");
            input = input.replace(breakRegExp, "|");
            input = input.replace(/^\||\|$/g, "");
            if (input) {
                var re = "(" + input + ")";
                if (!this.openLeft) {
                    re = "\\b" + re;
                }
                if (!this.openRight) {
                    re = re + "\\b";
                }
                matchRegExp = new RegExp(re, "i");
                return matchRegExp;
            }
            return false;
        };
        this.getRegex = function () {
            var retval = matchRegExp.toString();
            retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
            retval = retval.replace(/\|/g, " ");
            return retval;
        };
        // recursively apply word highlighting
        this.hiliteWords = function (node) {
            if (node === undefined || !node)
                return;
            if (!matchRegExp)
                return;
            if (skipTags.test(node.nodeName))
                return;
            if (node.hasChildNodes()) {
                for (var i = 0; i < node.childNodes.length; i++)
                    this.hiliteWords(node.childNodes[i]);
            }
            if (node.nodeType == 3) { // NODE_TEXT
                node.nodeValue = `${node.nodeValue}`.replace(/[\n\r\s\t]+/g, ' ')
                const nv = node.nodeValue
                const regs = matchRegExp.exec(nv);
                if (nv && regs) {
                    if (!wordColor[regs[0].toLowerCase()]) {
                        // wordColor[regs[0].toLowerCase()] = colors[colorIdx++ % colors.length];
                        wordColor[regs[0].toLowerCase()] = colors[0];
                    }
                    var match = document.createElement(hiliteTag);
                    const uniqueKey = `${regs[0]}${Math.floor(Math.random() * 1000)}`;
                    match.id = uniqueKey;
                    match.style.backgroundColor = wordColor[regs[0].toLowerCase()];
                    match.style.color = "#000";
                    match.style.position = 'relative';
                    match.style.zIndex = '9999';
                    // Add Tooltip 
                    ReactDOM.render(<Popover uniqueKey={uniqueKey} data={regs[0]} title={this.title}/>, match);
                    match.appendChild(document.createTextNode(regs[0]));
                    var after = node.splitText(regs.index);
                    after.nodeValue = after.nodeValue.substring(regs[0].length);
                    node.parentNode.insertBefore(match, after);
                }
            }
            ;
        };
        // remove highlighting
        this.remove = function () {
            var arr = document.getElementsByTagName(hiliteTag);
            const el = arr.length && arr[0];
            while (el && el.parentNode) {
                const parent = el.parentNode;
                parent.replaceChild(el.firstChild, el);
                parent.normalize();
            }
            if(arr.length) this.remove();
        };
        // start highlighting at target node
        this.apply = async function (input, title) {
            this.title = title;
            this.remove();
            if (input === undefined || !(input = input.replace(/(^\s+|\s+$)/g, ""))) {
                return;
            }
            if (this.setRegex(input)) {
                this.hiliteWords(targetNode);
            }
            return await matchRegExp;
        };
    }
}

export default Hilitor;