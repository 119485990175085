import React from 'react';
import { Navbar } from '../../../component/Navbar';
import Footer1 from '../../../component/Footer1';
import ResumeReadingPsychology from '../../../component/Insights/Insight/resume-reading-psychology/index';
import { Helmet } from 'react-helmet';

class ResumePsychologyInsight extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>paññã Insights</title>
          <meta name="description" content="Discover key strategies for tailoring your resume effectively. Learn to analyze job descriptions, highlight relevant experiences, customize your skills section, modify your objective or summary, incorporate keywords, and understand company culture. Create a standout resume that grabs hiring managers' attention and increases your chances of securing interviews in today's competitive job market." />
          <meta name="title" content={'Crafting Tailored Resumes: Key Strategies for Every Job Application'} />
        </Helmet>
        <Navbar />
        <ResumeReadingPsychology />
        <Footer1 />
      </>
    );
  }
}

export default ResumePsychologyInsight;
