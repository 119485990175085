import React, { useEffect, useRef, useState } from 'react';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../context/AuthContext';
import { PaymentModal } from '../../PaymentModal';
import { useSelector } from 'react-redux';

import { BASEPATH } from '../../../lib/constants';
import bgImage from '../../../assets/build-resume-home/bg.png'
import img1 from '../../../assets/build-resume-home/resumes-image.png'

import rect1 from '../../../assets/build-resume-home/rectangle1.png'
import rect2 from '../../../assets/build-resume-home/rectangle2.png'
import rect3 from '../../../assets/build-resume-home/rectangle3.png'
import rect4 from '../../../assets/build-resume-home/rectangle4.png'
import video1 from '../../../assets/videos/slide1.mov';
import video2 from '../../../assets/videos/slide2.mov';
import video3 from '../../../assets/videos/slide3.mov';
import resumeBuilderVideo from '../../../assets/videos/resumeBuilder.mov';
// import Footer from '../../Footer/Footer';
import Footer1 from '../../Footer1';


import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { motion, AnimatePresence } from 'framer-motion';
import Button from '../../Button';
import FaqData from '../faqs';
import JdAnimation from '../../../animation/LoadingAnimation';
import sampleData from '../data';
import { useMediaQuery } from 'react-responsive';

import timeIcon from '../../../assets/build-resume-home/time.png'
import speedoMeterIcon from '../../../assets/build-resume-home/speedometer.png'
import favoriteIcon from '../../../assets/build-resume-home/favorites.png'

import creativeIcon from '../../../assets/build-resume-home/creative.png'
import customizationIcon from '../../../assets/build-resume-home/customization.png'
import formatIcon from '../../../assets/build-resume-home/format.png'
import templateIcon from '../../../assets/build-resume-home/template.png'
import editingIcon from '../../../assets/build-resume-home/editing.png'
import keywordIcon from '../../../assets/build-resume-home/keyword.png'
import puzzleIcon from '../../../assets/build-resume-home/puzzle.png'
import skillIcon from '../../../assets/build-resume-home/skill.png'
import Utility from '../../../utils';


const BuildAIResumeView = () => {
  const { authState, loading } = useAuth();
  console.log('authState', authState);
  const data = useSelector(state => state);
  console.log('store data', data.cache?.location);
  const navigate = useNavigate();
  const isPaid = authState?.paymentSessionId;
  const [open, setOpen] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const isIndia = BASEPATH.includes('/in') ? true : false;

  // const isIndia = BASEPATH.includes('/in') ? false : false;
  const sliderRef = useRef(null);

  const isMobile = useMediaQuery({ maxWidth: 768 });

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    speed: 35000,
    autoplaySpeed: 10,
    autoplay: true,
    rtl: true,
    pauseOnHover: true,
    cssEase: 'linear',
    arrows: false,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        }
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]

  };

  const ArrowBox1 = ({ content, name }) => {
    return (
      <div className={styles.box1}>
        <div className={styles.parentShadow}>
          <div className={styles.arrowBox}>
            <div>{content}</div>
            <div className={styles.name}>- {name}</div>
            <div className={styles.triangleContainer}>
              <div className={styles.triangle}></div>
            </div>
          </div>
          <div className={styles.triangleContainer1}>
            <div className={styles.triangle1}></div>
          </div>
        </div>
      </div>
    );
  }

  const ArrowBox2 = ({ content, name }) => {
    return (
      <div className={styles.box2}>
        <div className={styles.parentShadow}>
          <div className={styles.arrowBox}>
            <div>{content}</div>
            <div className={styles.name}>- {name}</div>
            <div className={styles.triangleContainer}>
              <div className={styles.triangle}></div>
            </div>
          </div>
          <div className={styles.triangleContainer1}>
            <div className={styles.triangle1}></div>
          </div>
        </div>
      </div>);
  }


  const ArrowBox3 = ({ content, name }) => {
    return (
      <div className={styles.box3}>
        <div className={styles.parentShadow}>
          <div className={styles.arrowBox}>
            <div>{content}</div>
            <div className={styles.name}>- {name}</div>
            <div className={styles.triangleContainer}>
              <div className={styles.triangle}></div>
            </div>
          </div>
          <div className={styles.triangleContainer1}>
            <div className={styles.triangle1}></div>
          </div>
        </div>
      </div>);
  }


  const trySample = () => {
    try {
      Utility.trackEvent('resume-build-home', 'BUTTON_CLICK', 'Try Sample');
    } catch (error) {
      console.error('Failed to track event:', error);
    }
    setIsSample(true);
  }
  const handleBuildResume = () => {
    try {
      Utility.trackEvent('resume-build-home', 'BUTTON_CLICK', 'Build Your Resume');
    } catch (error) {
      console.error('Failed to track event:', error);
    }
    if (!authState) {
      navigate('/signup');
      return;
    }

    const emailList = ['tejaswini.vemulapally@mroads.com', 'madhav.vs@mroads.com', 'santhosh.bolla@yopmail.com', 'venkatakrishna.avula@mroads.com', 'lalitha.jampana@mroads.com', 'pradeep.dantuluri@mroads.com']
    const skipPayment = emailList.includes(authState?.userInfo?.emailAddress);
    console.log('skipPayment:', skipPayment);
    if (isPaid || skipPayment) {
      navigate('/resume-builder');
    } else {
      setOpen(true);
    }
  };

  const checkResumeScore = () => {
    navigate('/check-resume-score');
  };




  useEffect(() => {
    if (!loading) {
      // if (!authState) {
      //     navigate('/login');
      // }
    }
    if (sliderRef.current) {
      sliderRef.current.slickPlay(); // Start autoplay
    }
  }, [loading, authState]);


  const QuestionAnswerToggle = () => {
    const [openIndex, setOpenIndex] = useState(null);


    const toggleAnswer = (index) => {
      setOpenIndex(openIndex === index ? null : index);
    };
    return (
      <>
        {FaqData.map((data, index) => (
          <div className={styles.questionAns} key={index}>
            <div onClick={() => toggleAnswer(index)} className={styles.question}>
              <div>{data.question}</div>
              <div>
                {openIndex === index ? <div className={styles.close}>
                  -
                </div> :
                  <div className={styles.open}>
                    +
                  </div>}
              </div>

            </div>
            <AnimatePresence>
              {openIndex === index && (
                <motion.div
                  key="answer"
                  initial={{ height: 0 }}
                  animate={{ height: 'auto' }}
                  exit={{ height: 0 }}
                  style={{ overflow: 'hidden' }}
                >
                  <div className={styles.answer}>{data.answer}</div>
                </motion.div>
              )}
            </AnimatePresence>

          </div>
        ))}
      </>
    );
  };


  return (
    <>
      {isSample ? <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '0px',
        height: '80vh'
      }} id="one">
        <JdAnimation
          title="Building Resume..."
          isDone={true}
          onAnimationEnd={() => {
            if (true) {
              navigate('/sample-builder-report', {
                state: {
                  data: {},
                  aiResumeData: sampleData,
                  prevScoreReport: {},
                  name: 'JOHN ANDREWS',
                  email: 'johnandrews123@gmail.com',
                  phoneNumber: '+1 123 456 7890',
                  s3DownloadLink: 'https://pannaoregon.s3-us-west-2.amazonaws.com/resume/test/f64b61a3-1a0f-452b-b49b-b01a7ea5500dResumebuildersamplecopyOLD.docx',
                }
              })
            }
          }}
        />
      </div> :
        <div className={styles.resumeBuildHomeContainer}>

          <div className={styles.headerSection}>
            <div className={styles.left}>
              <div className={styles.headText}>
                Get shortlisted for your next Interview!
              </div>
              <div className={styles.content}>
                Build a tailored resume with a free cover letter as per the job requirements and beat Applicant Tracking Systems (ATS) with ease.
              </div>
              <div className={styles.buttons}>
                <Button id="optimize-My-Resume-Now1" onClick={() => {
                  handleBuildResume();
                }} text="Build Your Resume" buttonStyle={styles.btn1}></Button>

                <Button id="sample-build-resume-btn" onClick={() => {
                  trySample();
                }} text="Try Sample" buttonStyle={styles.btn2}></Button>
              </div>
            </div>

            <div className={styles.right}>
              <VideoAnimations />
            </div>
          </div>



          {/* <div className={styles.header}>
            <div className={styles.pic}>
              <svg class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiAvatar-fallback css-13y7ul3" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="PersonIcon"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"></path></svg>
            </div>
            <div className={styles.text}>Hi! {authState?.userInfo?.name}</div>

            <div className={styles.name}>Supercharge Your Job Search</div>
            <div className={styles.text}> with AI-Powered Resume Optimization for Guaranteed interviews</div>
            <div className={styles.blueText}><i>Tailor your resume to beat the competition</i></div>

            <Button id="optimize-My-Resume-Now1" onClick={() => {
              handleBuildResume();
            }} text="Optimize My Resume Now" buttonStyle={styles.btn}></Button>
            <div className={styles.trySample} onClick={() => { trySample() }}>Try sample AI resume</div>
          </div> */}


          <div className={styles.midBlackStrip}>
            <div className={styles.midBlackStripChild}>
              <div className={styles.iconBlock}>
                <img src={timeIcon} alt='u' />
                <div>
                  Perfect resume and cover letter in   <span className={styles.boldColor}> {'< 5mins'}</span>
                </div>
              </div>

              <div className={styles.iconBlock}>
                <img src={speedoMeterIcon} alt='u' />
                <div>
                  Improve Resume Score by  <span className={styles.boldColor}> {'> 30%'}</span>
                </div>
              </div>

              <div className={styles.iconBlock}>
                <img src={favoriteIcon} alt='u' />
                <div>
                  Highly Rated by   <span className={styles.boldColor}> {'HR Professionals'}</span>
                </div>
              </div>
            </div>
          </div>


          <div className={styles.content1}>
            <div className={styles.left}>
              <div className={[styles.heading, styles.mgBt40].join(' ')}>Resume builder</div>
              <div className={styles.text} style={{ marginBottom: '30px' }}>
                By using advanced AI algorithms, paññã optimizes your resume for ATS screenings and gets noticed by the hiring managers. Our platform tailors resumes to each job, integrating keywords, skills, and formatting for maximum impact. Our resume builder prioritizes making your resume ATS-friendly, rather than focusing on its design or visual elements.
                <br /><br />
                Whether you're seasoned or starting out, our tool helps you create standout resumes in today's job market.
                <br />
                <br />
                <div className={styles.text}>
                  Boost Your Chances with AI-Generated Cover Letter for Free!
                </div>

              </div>
              <Button id="optimize-My-Resume-Now1" onClick={() => {
                trySample();
              }} text="Try Sample AI Resume" buttonStyle={styles.btn}></Button>
            </div>
            <ResumeVideoAnimation />
            {/* <video autoplay inline className={styles.right} src={resumeBuilderVideo} alt='i' /> */}
          </div>

          <div className={styles.featuresBlock}>
            <div className={styles.featuresHeading}>FEATURES</div>
            <div className={styles.heading}>Discover our tool</div>
            <Features />
          </div>

          {/* <div className={styles.content2}>
            <div className={styles.heading}>
              Features
            </div>
            <div className={styles.boxesContainer}>



              <Box />
            </div>

          </div> */}



          <div className={styles.faqsContainer}>
            <div className={styles.heading}>FAQs</div>

            <div className={styles.questionContainer}>
              <QuestionAnswerToggle />
            </div>
          </div>
          <div className={styles.slider}>
            <div className={styles.heading} style={{ width: '70%', margin: '0 auto', marginBottom: '50px' }}>Testimonials</div>
            <Slider {...settings} ref={sliderRef}>
              <div>
                <ArrowBox1 content="Impressive results! With just a few clicks, I had a professional resume that got me noticed by recruiters. Definitely worth it!" name="Emily Peterson" />
              </div>
              <div>

                <ArrowBox2 content="The AI-built resume was good. which landed me more interviews." name="Sarah Jones " />
              </div>

              <div>
                <ArrowBox1 content="Got more interview calls after submitting the resume built by this tool." name="Aditi Kashyap" />
              </div>

              <div>
                <ArrowBox2 content="Tool analysed the job description and highlighted my skills perfectly. I landed multiple interviews within days of using it!" name="Lisa Webb" />
              </div>
              <div>
                <ArrowBox1 content="I've tried other resume builders, but no other tool gave me a resume as per the job description. A good tool to generate focused resumes. Highly recommended!" name="Samantha Lawson" />
              </div>
              <div>
                <ArrowBox2 content="The ATS-friendly format ensures my resume gets noticed by recruiters, and I've seen a significant increase in callbacks since using it" name="Genet Davis" />
              </div>
              <div>
                <ArrowBox1 content="I'm blown away by how effective this resume builder is! Great tool." name="Shristi Kapoor" />
              </div>

            </Slider>
          </div>


          {/* <div className={styles.footer}>

                </div> */}

        </div>}
      <Footer1 />
      <PaymentModal open={open} setOpen={setOpen} />
    </>
  );
};


const Box = () => {
  return (<>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect1}></img>
      <div>
        <div className={styles.head}>Instant Job Match Analysis</div>
        <div className={styles.text}>Get a clear, percentage-based score on how well your resume aligns with your desired role.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect2}></img>
      <div>
        <div className={styles.head}>AI-Powered Rewriting</div>
        <div className={styles.text}>Our intelligent system doesn't just suggest changes; it rewrites your resume, tailoring it to the specific job description.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect3}></img>
      <div>
        <div className={styles.head}>Keyword Optimization</div>
        <div className={styles.text}>Boost your visibility in recruiter searches with the right keywords and phrases for your industry.</div>
      </div>
    </div>
    <div className={styles.boxContainer}>
      <img className={styles.img} src={rect4}></img>
      <div>
        <div className={styles.head}>ATS-Friendly Formatting</div>
        <div className={styles.text}>Focus on generating the resume in the format maximising the chances of clearing ATS-screening and not on visually attractive elements.</div>
      </div>
    </div>

  </>)
}


const VideoAnimations = () => {
  const videos = [video1, video2, video3];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
      return () => {
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentVideoIndex, videos.length]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <div className="video-container">
      <video
        ref={videoRef}
        width="100%"
        muted
        playsInline
        key={currentVideoIndex} // This key forces the video element to re-render on index change
      >
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

const ResumeVideoAnimation = () => {
  const videos = [resumeBuilderVideo, resumeBuilderVideo];
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const videoRef = useRef(null);

  useEffect(() => {
    const videoElement = videoRef.current;

    const handleEnded = () => {
      setCurrentVideoIndex((prevIndex) => (prevIndex + 1) % videos.length);
    };

    if (videoElement) {
      videoElement.addEventListener('ended', handleEnded);
      return () => {
        videoElement.removeEventListener('ended', handleEnded);
      };
    }
  }, [currentVideoIndex, videos.length]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      videoElement.play();
    }
  }, [currentVideoIndex]);

  return (
    <div className={styles.right}>
      <video
        ref={videoRef}
        width="100%"
        muted
        playsInline
        key={currentVideoIndex} // This key forces the video element to re-render on index change
      >
        <source src={videos[currentVideoIndex]} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className={styles.featureCard}>
      <img src={icon} alt={title} className={styles.featureIcon} />
      <div className={styles.featureContent}>
        <h3 className={styles.heading}>{title}</h3>
        <p className={styles.featureDescription}>{description}</p>
      </div>
    </div>
  );
};

const Features = () => {



  const features = [
    {
      icon: puzzleIcon,
      title: 'Instant Job Match Analysis',
      description: 'Get a clear, percentage-based score on how well your resume aligns with your desired role.',
    },
    {
      icon: keywordIcon,
      title: 'Keyword Optimization',
      description: 'Ensure your resume includes relevant industry-specific keywords to pass ATS checks.',
    },
    {
      icon: creativeIcon,
      title: 'AI-Powered Rewriting',
      description: 'Our intelligent system rewrites your resume, tailoring it to the specific job description and showcasing relevant skills.',
    },
    {
      icon: formatIcon,
      title: 'ATS-Friendly Formatting',
      description: 'Focus on generating the resume in the format maximising the chances of clearing ATS-screening and not on visually attractive elements.',
    },
    {
      icon: customizationIcon,
      title: 'Customized Resume Building',
      description: 'Input a job description, and our tool generates a tailored resume instantly.',
    },
    {
      icon: skillIcon,
      title: 'Instant Job Match Scoring',
      description: 'Tailor your resume instantly to any job description and get a score, ensuring you stand out to employers like never before.',
    },
    {
      icon: templateIcon,
      title: 'Professional Template',
      description: 'Template that is professional and also is readable by the Application tracking systems (ATS)',
    },
    {
      icon: editingIcon,
      title: 'Easy Editing',
      description: 'Update and customize your resume effortlessly as your career evolves.',
    },
  ];

  return (
    <div className={styles.featuresContainer}>
      {features.map((feature, index) => (
        <FeatureCard
          key={index}
          icon={feature.icon}
          title={feature.title}
          description={feature.description}
        />
      ))}
    </div>
  );
};


export default BuildAIResumeView;