/* eslint-disable no-unused-expressions */
/* eslint-disable array-callback-return */
import React from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import domtoimage from 'dom-to-image';
import CountUp from 'react-countup';
import CustomProgressBar from '../CustomProgressBar';
import styles from './styles.module.css';
import downloadIconBlue from '../../assets/jdToResume/downloadImg.png';
import downloadIconWhite from '../../assets/jdToResume/downloadImgWhite.png';
import Card from '../Card';
import staticContent from './data';
import { PDFDownloadLink } from '@react-pdf/renderer';
import CardDetails from '../CardDetails';
import MyDoc from '../../component/ReportPDF';
import { useAuth } from '../../context/AuthContext';
import { BASEPATH } from '../../lib/constants';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

import {
  cacheReportData,
  updateUserName,
  updateUserFile,
  getResumeInHTML,
  updateResumeHtmlLink
} from '../../store/actions/cache';
import Button from '../Button';
import MultiCircularProgressBar from '../MultiCircularProgressBar';
import CommentsKeys from '../../enum/CommentsKeys';
import config from '../../config';
import Logger from '../../Logger';
import Hilitor from './hilitor';
import ResumeViewer from '../ResumeViewer';
import Utility from '../../utils';
import Loader from 'react-loader-spinner';
import ReactToPrint, { PrintContextConsumer } from 'react-to-print';
import { PromptToSignupModal } from '../PromptToSignupModal';

const httpClient = axios.create();

httpClient.defaults.timeout = 20000;

class ReportViewer extends React.Component {
  percent;
  placeHolderData = [];
  cardData = [];
  actionVerbCount = 0;
  detailReportData = [];
  skillDeleteCount = 0;
  newReportData;
  cardKeys;
  myHilitor;
  tempResumeInHTML;
  SwitchTabButton = [];
  actionVerbcategory = [];
  state = {
    status: 'default',
    showImage: false,
    isTooltipActive: false,
    cardData: [],
    detailReportData: [],
    ondeletePressed: false,
    canvasImage: undefined,
    resumeViewer: '',
    selectedIndex: 0,
    isLoading: false,
    downloadIcon: downloadIconBlue,
    buttonBackground: '#FFFFFF',
    buttonTextColor: '#468CF2',
  };

  constructor(props) {
    super(props);
    this.componentRef = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.cardKeys = Math.floor(Math.random() * 100);
    const { reportData: data } = this.props;
    const { aiResumeData } = this.props;
    console.log('Ai Resume Data... in report viewer', aiResumeData);
    if (data) {
      const skillMatch = data['Skill Match'];
      this.percent =
        skillMatch !== 'None' ? skillMatch['Jd Relevance score'] : 0;
    }
    this.parseReportdata();
    this.setState({
      status:
        this.percent === 'None' || this.percent < 35
          ? 'error'
          : this.percent < 75
            ? 'default'
            : 'active'
    });
    this.caputureImage();
    this.myHilitor = new Hilitor('ResumeViewerUnique');
  }

  showTooltip() {
    this.setState({ isTooltipActive: true });
  }

  hideTooltip() {
    this.setState({ isTooltipActive: false });
  }

  caputureImage = timeout => {
    setTimeout(() => {
      const circularDiv = document.getElementById('RelevanceScore');
      if (circularDiv) {
        domtoimage
          .toJpeg(circularDiv, { bgcolor: '#ffffff' })
          .then(dataUrl => {
            // this.canvasImage = dataUrl;
            this.setState({ canvasImage: dataUrl });
          })
          .catch(error => {
            console.error(
              'oops, something went wrong while capturing the image!',
              error
            );
          });
      }
    }, timeout || 1000);
    setTimeout(() => {
      this.setState({ showImage: true });
    }, timeout || 8000);
  };
  fetchTrackColor = rate => {
    return this.percent === 'None' || rate < 35
      ? '#ff3223'
      : rate < 75
        ? '#ed7c30'
        : '#3ABF7C';
  };

  renderCardItem = cardData => {
    let message;
    let messageColor;
    switch (cardData.name) {
      case 'Skill Match': {
        const matchScore = cardData.score;
        const skillCounts = matchScore.split('/');
        const commonSkillCount = parseInt(skillCounts[0]);
        const jdSkillCount = parseInt(skillCounts[1]);
        const matchRate = ((commonSkillCount / jdSkillCount) * 100).toFixed(0);
        if (matchRate <= 35) {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        } else if (matchRate > 35 && matchRate < 75) {
          message = staticContent.goodStart;
          messageColor = '#F06A4A';
        } else {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        }
        return (
          <div key={matchRate} className={styles.skillMatchStyles}>
            {/* <div className={styles.fontFamily} /> */}
            <CustomProgressBar
              width="80%"
              percentage={`${matchRate}`}
              trackColor={this.fetchTrackColor(matchRate)}
              renderItems={() => (
                <React.Fragment>
                  <span>&nbsp;</span>
                  {/* <CountUp delay={0.5} end={matchScore} duration={2} formattingFn={value => this.formatValue(value)}/>
                                <span className={styles.animatedNumber}>%</span> */}
                  <span>
                    {commonSkillCount}/{jdSkillCount}
                  </span>
                </React.Fragment>
              )}
            />
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Resume Percentile': {
        const matchRate = cardData.value;
        if (matchRate <= 35) {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        } else if (matchRate < 75) {
          message = staticContent.goodStart;
          messageColor = '#F06A4A';
        } else {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        }
        return (
          <div key={matchRate} className={styles.skillMatchStyles}>
            {/* <div className={styles.fontFamily} /> */}
            <CustomProgressBar
              width="80%"
              percentage={`${matchRate}`}
              trackColor={this.fetchTrackColor(matchRate)}
              renderItems={() => (
                <React.Fragment>
                  <span>&nbsp;</span>
                  <CountUp
                    delay={0.5}
                    end={matchRate}
                    duration={2}
                    formattingFn={value => this.formatValue(value)}
                  />
                  <span>%</span>
                </React.Fragment>
              )}
            />
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Readability': {
        const fontFamily = cardData.value;
        if (fontFamily.toLowerCase() === 'easy read') {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        } else {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        }
        return (
          <div className={styles.ReadabilityWrap}>
            <div
              className={[
                styles.animatedNumberWrapper,
                styles.ReadabilityCard
              ].join(' ')}
            >
              {fontFamily}
            </div>
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Summary/Objective': {
        const objective = cardData.value;
        if (objective.toLowerCase() === 'yes') {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        } else {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        }
        return (
          <div className={styles.ReadabilityWrap}>
            <div className={styles.summaryText}>{objective}</div>
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Action Verbs': {
        return (
          <div className={styles.animatedNumberWrapper}>
            <CountUp
              end={this.actionVerbCount}
              duration={2}
              formattingFn={value => this.formatValue(value)}
            />
          </div>
        );
      }
      case 'Estimated Time to Read': {
        const timeToRead = cardData.value.split(' ');
        if (timeToRead[0] <= 3) {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        } else if (timeToRead[0] <= 5) {
          message = staticContent.goodStart;
          messageColor = '#F06A4A';
        } else {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        }
        return (
          <div
            className={[styles.SummaryOrObjective, styles.ReadabilityWrap].join(
              ' '
            )}
          >
            <div className={styles.summaryText}>{cardData.value}</div>
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Last Modified': {
        const lastModifiedDate = cardData.value;
        if (lastModifiedDate.toLowerCase() === 'recently modified') {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        } else if (lastModifiedDate.toLowerCase() === 'less than 1 month old') {
          message = staticContent.goodStart;
          messageColor = '#F06A4A';
        } else {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        }
        return (
          <div
            style={{ backgroundColor: cardData.cardColor }}
            className={[styles.SummaryOrObjective, styles.ReadabilityWrap].join(
              ' '
            )}
          >
            <div className={styles.summaryText}>{cardData.value}</div>
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Experience/Consistency': {
        const Consistency =
          cardData.value[0].toLowerCase() === 'true' ? 'Yes' : 'No';
        const experience = cardData.value[1];
        return (
          <div
            style={{ backgroundColor: cardData.cardColor }}
            className={[styles.ExperieneCard, styles.ReadabilityWrap].join(' ')}
          >
            {/* <img src={parseInt(timeToRead[0]) > 6 ? sadEmo : happyEmo } alt="" className={styles.rigthTick}/> */}
            <div className={styles.summaryText}>{experience}</div>
            <div className={styles.experienceText}>{Consistency}</div>
          </div>
        );
      }
      case 'Experience Gap': {
        const experienceGap = cardData.value;
        const hasNoGap = experienceGap === '0 months';
        if (hasNoGap) {
          message = staticContent.excellentJob;
          messageColor = '#22A600';
        }
        return (
          <div
            style={{ backgroundColor: cardData.cardColor }}
            className={[styles.ExperieneCard, styles.ReadabilityWrap].join(' ')}
          >
            <div className={styles.summaryText}>{experienceGap}</div>
            {hasNoGap && (
              <div
                style={{ color: messageColor }}
                className={styles.messageStyle}
              >{`(${message})`}</div>
            )}
          </div>
        );
      }
      case 'Qualification': {
        let val = cardData.value;
        const hasQualification = cardData.value ? true : false;
        message = staticContent.excellentJob;
        messageColor = '#22A600';
        if (!hasQualification) {
          message = staticContent.needWork;
          messageColor = '#FF0000';
          val = 'No Qualification';
        }
        return (
          <div
            style={{ backgroundColor: cardData.cardColor }}
            className={[styles.SummaryOrObjective, styles.ReadabilityWrap].join(
              ' '
            )}
          >
            <div className={styles.experienceText}>{val}</div>
            <div
              style={{ color: messageColor }}
              className={styles.messageStyle}
            >{`(${message})`}</div>
          </div>
        );
      }
      case 'Quantitative Matrixes': {
        const val = +cardData.value;
        if (val === 0) {
          message = staticContent.needWork;
          messageColor = '#FF0000';
        }
        return (
          <div
            style={{ backgroundColor: cardData.cardColor }}
            className={[styles.SummaryOrObjective, styles.ReadabilityWrap].join(
              ' '
            )}
          >
            <div className={styles.experienceText}>{cardData.value}</div>
            {val === 0 && (
              <div
                style={{ color: messageColor }}
                className={styles.messageStyle}
              >{`(${message})`}</div>
            )}
          </div>
        );
      }
      case 'Hyperlinks': {
        return null
      }

      default: {
        return (
          <div>
            <div>{cardData.value}</div>
          </div>
        );
      }
    }
  };

  parseActionVerbs = key => {
    const { reportData: data } = this.props;
    const actionVerbs = data[key];
    const ActionKeys = data ? Object.keys(data[key]) : undefined;
    let parsedActionVerbs = [];
    this.actionVerbCount = 0;
    ActionKeys &&
      ActionKeys.map(actionKey => {
        const actionVerbsData =
          actionKey === 'The remaining list of action verbs'
            ? actionVerbs[actionKey]
            : actionVerbs[actionKey].Found;
        this.actionVerbCount += actionVerbsData.length;
        parsedActionVerbs.push({
          name:
            actionKey === 'The remaining list of action verbs'
              ? 'Others'
              : actionKey,
          value: actionVerbsData,
          recommended: actionVerbs[actionKey]['Recommended Words'],
          description: ''
        });
      });
    return {
      name: key,
      value: parsedActionVerbs,
      description: staticContent.description[key],
      cardColor: staticContent.cardColor[key],
      comments:
        (data.Comments && data.Comments[CommentsKeys['Action Verbs']]) || ''
    };
  };

  parseReportdata = () => {
    const {
      reportData,
      reduxProps: {
        cache: { reportData: report }
      },
      router
    } = this.props;
    const { isSample } = router.location.state || {};
    const data = isSample
      ? reportData
      : this.newReportData || report || reportData;
    const skillMatch = data['Skill Match'];
    this.percent = skillMatch !== 'None' ? skillMatch['Jd Relevance score'] : 0;
    let tempParsedData = [];
    let tempActionVerbs = [];
    let tempSkillMatch = [];
    const cardKeys = data
      ? [
        ...Object.keys(data).filter(
          key =>
            key !== 'Jd Skill frequency' &&
            key !== 'Resume Skill frequency' &&
            key !== 'Uid' &&
            key !== 'status_code' &&
            key !== 'Comments' &&
            key !== 'JD Type Class'
        )
      ]
      : undefined;
    cardKeys &&
      cardKeys.map(key => {
        switch (key) {
          case 'Skill Match': {
            data[key] === 'None' ||
              (key === 'Skill Match' &&
                data[key] &&
                data[key].matched.length === 0 &&
                data[key].unmatched.length === 0)
              ? null
              : key === 'Skill Match'
                ? tempSkillMatch.push({
                  name: key,
                  value: [
                    {
                      name: 'Matched Skills:',
                      computedSkills: data[key].matched
                    },
                    {
                      name: 'Unmatched Skills:',
                      computedSkills: data[key].unmatched
                    }
                  ],
                  score: data[key]['Skill match score'],
                  jdSKillFrequency: data['Jd Skill frequency'],
                  resumeSkillFrequency: data['Resume Skill frequency'],
                  description: staticContent.description[key],
                  cardColor: staticContent.cardColor[key],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys['Skill Match']]) ||
                    ''
                })
                : null;
            break;
          }
          case 'Action Verbs': {
            key === 'Action Verbs' && data[key] === 'None'
              ? null
              : key === 'Action Verbs'
                ? tempActionVerbs.push(this.parseActionVerbs(key))
                : null;
            break;
          }
          case 'Percentile score': {
            key === 'Percentile score' && data[key] === 'None'
              ? null
              : key === 'Percentile score'
                ? tempParsedData.push({
                  name: 'Resume Percentile',
                  value: data[key],
                  description: staticContent.description['Resume Percentile'],
                  cardColor: staticContent.cardColor['Resume Percentile'],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys['Percentile score']]) ||
                    ''
                })
                : null;
          }
          case 'Experience_Consistency': {
            if (key === 'Experience_Consistency' && data[key] !== 'None') {
              if (
                data[key]?.Candidate_consistency &&
                data[key]?.Candidate_consistency !== 'None' &&
                data[key]?.Candidate_experience &&
                data[key]?.Candidate_experience !== 'None'
              ) {
                tempParsedData.push({
                  name: 'Experience/Consistency',
                  value: [
                    data[key].Candidate_consistency,
                    data[key].Candidate_experience
                  ],
                  description:
                    staticContent.description['Experience_Consistency'],
                  cardColor: staticContent.cardColor[key],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys.Experience_Consistency]) ||
                    ''
                });
              }
              if (
                data[key]?.Candidate_GAP &&
                data[key]?.Candidate_GAP !== 'None'
              ) {
                tempParsedData.push({
                  name: 'Experience Gap',
                  value: data[key].Candidate_GAP,
                  description:
                    staticContent.description['Experience_Consistency'],
                  cardColor: staticContent.cardColor[key],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys.Experience_Consistency]) ||
                    ''
                });
              }
            }
            break;
          }
          case 'quantitative_matrixes': {
            key === 'quantitative_matrixes' && data[key] === 'None'
              ? null
              : key === 'quantitative_matrixes'
                ? tempParsedData.push({
                  name: 'Quantitative Matrixes',
                  value: data[key],
                  description:
                    staticContent.description['Quantitative_matrixes'],
                  cardColor: staticContent.cardColor[key],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys.Quantitative_Matrixes]) ||
                    ''
                })
                : null;
            break;
          }
          case 'qualification': {
            key === 'qualification' && data[key] === 'None'
              ? null
              : key === 'qualification'
                ? tempParsedData.push({
                  name: 'Qualification',
                  value: typeof data[key] === 'string' ? data[key] : '',
                  description: staticContent.description['Qualification'],
                  cardColor: staticContent.cardColor[key],
                  comments:
                    (data.Comments &&
                      data.Comments[CommentsKeys.Qualification]) ||
                    ''
                })
                : null;
            break;
          }
          default: {
            data[key] && (data[key] === 'None' || data[key] === 'Not Found')
              ? null
              : tempParsedData.push({
                name: key,
                value: data[key],
                description: staticContent.description[key],
                cardColor: staticContent.cardColor[key],
                comments:
                  (data.Comments && data.Comments[CommentsKeys[key]]) || ''
              });
          }
        }
      });
    this.setState(
      {
        cardData: [...tempSkillMatch, ...tempActionVerbs, ...tempParsedData],
        detailReportData: [
          ...tempSkillMatch,
          ...tempActionVerbs,
          ...tempParsedData
        ]
      },
      () => console.log(this.state.cardData)
    );
  };

  deleteSkills = (skillSection, skillToBeDeleted) => {
    const { cardData } = this.state;
    let parsedSkills = cardData.filter(card => card.name === 'Skill Match');
    let skillsAfterDelete = [];
    let tempCardData;
    if (skillSection === 'matched') {
      parsedSkills[0].value[0].computedSkills.forEach(skill => {
        if (skill !== skillToBeDeleted) {
          skillsAfterDelete.push(skill);
        }
      });
      tempCardData = cardData.map(card => {
        if (card.name === 'Skill Match') {
          card.value[0].computedSkills = skillsAfterDelete;
        }
        return card;
      });
    } else {
      parsedSkills[0].value[1].computedSkills.forEach(skill => {
        if (skill !== skillToBeDeleted) {
          skillsAfterDelete.push(skill);
        }
      });
      tempCardData = cardData.map(card => {
        if (card.name === 'Skill Match') {
          card.value[1].computedSkills = skillsAfterDelete;
        }
        return card;
      });
    }
    this.skillDeleteCount = this.skillDeleteCount + 1;
    this.setState({ cardData: tempCardData });
  };

  onDeleteCancel = () => {
    this.newReportData = undefined;
    this.setState({ ondeletePressed: false });
    this.parseReportdata();
    this.skillDeleteCount = 0;
  };

  onDeleteClick = () => {
    this.setState({ ondeletePressed: true });
  };

  onDoneDelete = () => {
    const { cardData } = this.state;
    const {
      reduxProps: { cache },
      dispatchReportData,
      setPopup
    } = this.props;
    const { userFile, userId, reportData } = cache;
    const skillMatchCard = cardData.filter(card => card.name === 'Skill Match');
    const configObj = {
      headers: {
        'content-type': 'application/json',
        Accept: '/'
      }
    };
    const requestBody = {
      Jd_URL: (userFile && userFile.jd) || '',
      Resume_URL: (userFile && userFile.resume) || '',
      skills_deleted_status: 1,
      'Resume Skill frequency':
        skillMatchCard && skillMatchCard.length !== 0
          ? skillMatchCard[0].resumeSkillFrequency
          : null,
      'Jd Skill frequency':
        skillMatchCard && skillMatchCard.length !== 0
          ? skillMatchCard[0].jdSKillFrequency
          : null,
      matched: skillMatchCard[0].value[0].computedSkills,
      unmatched: skillMatchCard[0].value[1].computedSkills,
      Uid: userId,
      application: 'panna_score_api',
      'JD Type Class': reportData['JD Type Class']
    };
    this.cardKeys = Math.floor(Math.random() * 100);
    setPopup('loading');
    httpClient
      .post(config.deleteSkills, requestBody, configObj)
      .then(response => {
        console.info('response for delete skills', response.data);
        setPopup(undefined);
        this.setState({ ondeletePressed: false, showImage: false });
        this.skillDeleteCount = 0;
        this.newReportData = {
          ...reportData,
          ...response.data,
          Comments: { ...reportData.Comments, ...response.data.Comments }
        };
        this.percent = this.newReportData['Skill Match']['Jd Relevance score'];
        dispatchReportData(this.newReportData);
        window.scrollTo(0, 0);
        this.caputureImage(0);
        this.parseReportdata();
        Logger.postLogs(
          `"resume-jd-relevance - Delete and Update Skills successfull", ${JSON.stringify(
            response.data
          )}`
        );
      })
      .catch(error => {
        console.info('error in deleting skills', error);
        setPopup('error');
        Logger.postLogs(
          `"resume-jd-relevance - Delete and Update Skills Unsuccessfull", ${JSON.stringify(
            error
          )}`
        );
      });
  };

  formatValue = data => {
    return data.toFixed(0);
  };

  renderCardInDetail = () => {
    const { selectedIndex, detailReportData, ondeletePressed } = this.state;
    const { router } = this.props;
    const { isSample } = router?.location?.state || {};
    let wordsToHighlight = [];
    let reportData = [];
    switch (this.SwitchTabButton[selectedIndex].name) {
      case 'Skill Match': {
        reportData = detailReportData.filter(
          data => data.name === 'Skill Match'
        );
        wordsToHighlight =
          reportData &&
          reportData.length !== 0 &&
          reportData[0].value[0].computedSkills;
        const tempHighlightWords = wordsToHighlight;
        const sortHighlightWordsDescending = tempHighlightWords.sort((a, b) => {
          return b.length - a.length;
        });
        sortHighlightWordsDescending &&
          sortHighlightWordsDescending.length !== 0
          ? this.myHilitor.apply(
            sortHighlightWordsDescending.join(','),
            'Skill Match'
          )
          : this.myHilitor.remove();
        break;
      }
      case 'Action Verbs': {
        reportData = detailReportData.filter(
          data => data.name === 'Action Verbs'
        );
        this.actionVerbcategory = reportData[0].value;
        reportData[0].value.forEach(actionVerbCateogory => {
          if (
            actionVerbCateogory.value &&
            actionVerbCateogory.value.length !== 0
          ) {
            wordsToHighlight = [
              ...wordsToHighlight,
              ...actionVerbCateogory.value
            ];
          }
        });
        const tempHighlightWords = wordsToHighlight;
        const sortHighlightWordsDescending = tempHighlightWords.sort((a, b) => {
          return b.length - a.length;
        });
        sortHighlightWordsDescending &&
          sortHighlightWordsDescending.length !== 0
          ? this.myHilitor.apply(
            sortHighlightWordsDescending.join(','),
            'Action Verbs'
          )
          : this.myHilitor.remove();
        break;
      }
      case 'Suggestions': {
        reportData = detailReportData.filter(
          data => data.name !== 'Skill Match' && data.name !== 'Action Verbs'
        );
        this.myHilitor.remove();
        break;
      }
      default: {
        reportData = detailReportData.filter(
          data => data.name === 'Skill Match' || data.name === 'Action Verbs'
        );
      }
    }
    return (
      <div className={styles.detailReportContainer}>
        {reportData.map((card, index) => {
          return (
            <CardDetails
              key={`CardDropDown${index}`}
              rootDivStyle={styles.cardContainer}
              data={card}
              trianglDivStyle={card.trianglDivStyle}
              delay={1000 * index}
              actionVerbCount={this.actionVerbCount}
              onDeleteSkills={this.deleteSkills}
              onDeleteCancel={this.onDeleteCancel}
              onDoneDelete={this.onDoneDelete}
              deletedSkillCount={this.skillDeleteCount}
              onDoneDeleteSuccess={ondeletePressed}
              ondeletePressed={this.onDeleteClick}
              isSample={isSample}
            />
          );
        })}
      </div>
    );
  };

  getResumeInHTML = async S3HTMLLink => {
    this.setState({ isLoading: true });
    const { dispatchGetResumeInHTML } = this.props;
    await Utility.getResumeInHtml(
      S3HTMLLink,
      res => {
        dispatchGetResumeInHTML(res.data);
        this.setState({ isLoading: false, selectedIndex: 0 });
      },
      error => {
        this.setState({ isLoading: false });
        dispatchGetResumeInHTML('Error');
        console.info('error in resume viewer', error, error.response);
      }
    );
  };

  onDownloadReportHover = () => {
    this.setState({
      downloadIcon: downloadIconWhite,
      buttonBackground: '#007AFF',
      buttonTextColor: '#FFFFFF'
    });
  };

  onDownloadReportBlur = () => {
    this.setState({
      downloadIcon: downloadIconBlue,
      buttonBackground: '#FFFFFF',
      buttonTextColor: '#468CF2'
    });
  };

  render() {
    const {
      showImage,
      cardData,
      detailReportData,
      isLoading,
      canvasImage,
      selectedIndex,
      downloadIcon,
      buttonBackground,
      buttonTextColor
    } = this.state;
    const {
      dispatchReportData,
      dispatchUpdateUserName,
      dispatchGetResumeInHTML,
      dispatchUpdateHTMLLink,
      router,
      reduxProps,
      reportData,
      dispatchUpdateUserFile,
      aiResumeData
    } = this.props;
    const {
      cache: { fullName, reportData: report, resumeInHTML, HTMLLink }
    } = reduxProps;
    const { isSample } = router?.location?.state || {};
    const data = isSample ? reportData : report;
    const htmlResume = isSample ? staticContent.resumeHTML : resumeInHTML;
    const percentScore = this.percent === 'None' ? 0 : this.percent;
    const relevanceComments =
      data &&
      data.Comments &&
      data.Comments[CommentsKeys['Jd Relevance score']];
    this.SwitchTabButton = [];
    detailReportData.forEach(data => {
      data.name === 'Skill Match'
        ? this.SwitchTabButton.push({
          name: 'Skill Match'
        })
        : data.name === 'Action Verbs'
          ? this.SwitchTabButton.push({
            name: 'Action Verbs'
          })
          : null;
    });
    this.SwitchTabButton.push({
      name: 'Suggestions'
    });
    const pdfDownload = (
      <React.Fragment>
        {showImage ? (
          <PDFDownloadLink
            document={
              <MyDoc
                title={fullName}
                totalScore={percentScore}
                cardData={detailReportData}
                imageSrc={canvasImage}
                relevanceComments={relevanceComments}
                actionVerbCount={this.actionVerbCount}
              />
            }
            fileName={`${fullName ? fullName : 'Pannascore'
              }_${new Date().getFullYear()}/${new Date().getMonth() +
              1}/${new Date().getDate()}-${new Date().getHours()}_${new Date().getMinutes()}_${new Date().getSeconds()}.pdf`}
            className={styles.downloadLink}
          >
            {({ blob, url, loading, error }) =>
              loading ? (
                <div style={{ padding: '10px', borderRadius: '3.5px' }}>
                  Loading document...
                </div>
              ) : (
                <div
                  role="button"
                  className={styles.ButtonIconWrap}
                  tabIndex="0"
                  style={{
                    background: buttonBackground,
                    color: buttonTextColor,
                    borderRadius: '3.5px'
                  }}
                  onMouseEnter={this.onDownloadReportHover}
                  onMouseLeave={this.onDownloadReportBlur}
                >
                  <div style={{ alignSelf: 'center' }}>Download Report</div>
                  <img
                    src={downloadIcon}
                    alt=""
                    className={styles.buttonIcon}
                  />
                </div>
              )
            }
          </PDFDownloadLink>
        ) : null}
      </React.Fragment>
    );

    const newCardData = cardData.filter(card => card.name !== 'Hyperlinks');

    return (
      <React.Fragment>
        <div className={[styles.ReportLabel].join(' ')}>
          {isSample ? 'Sample ' : fullName ? `${fullName}'s ` : 'Your '}
          <span className={styles.spanScore}>Report</span>
        </div>
        <div id="resumePrint" className={styles.computingpopup}>
          <div
            className={styles.CanvasDoughnutChart}
            onMouseEnter={this.showTooltip.bind(this)}
            onMouseLeave={this.hideTooltip.bind(this)}
          >
            <div className={styles.reportHeading}>
              {staticContent.percentileTitle}
              <span className={styles.spanScore}>Score</span>
            </div>
            {/* <div className={styles.percentile}>{staticContent.percentileTitle}</div> */}
            <MultiCircularProgressBar percentScore={percentScore} />
            <div className={styles.relevanceNote}>{relevanceComments}</div>
          </div>
          <div className={styles.resumeCardWrapper}>
            {newCardData &&
              newCardData.map((card, index) => {
                const position =
                  window.innerWidth < 1150 && index > 2
                    ? 'top'
                    : window.innerWidth > 1150 && index >= 4
                      ? 'top'
                      : 'bottom';
                return (
                  <div
                    key={`Card${index}`}
                    className={styles.cardContentContainer}
                  >
                    <Card
                      id={`Card${this.cardKeys}${index}`}
                      data={card}
                      renderItem={() => this.renderCardItem(card)}
                      ContainerStyle={card.ContainerStyle}
                      timeOut={1000 * ++index}
                      rootDivStyle={styles.cardContainer}
                      trianglDivStyle={card.trianglDivStyle}
                      delay={1000 * index}
                      position={position}
                    />
                  </div>
                );
              })}
          </div>
          {isSample ? (
            <div className={styles.sampleWrap}>
              <div className={styles.sampleText}>
                Now it is your turn to upload resume and get your score!
              </div>
              <Button
                id="uploadYourResume"
                text="Upload your resume"
                onClick={() => {
                  const { router } = this.props;
                  router.navigate('/');
                }}
              />
            </div>
          ) : null}

          <OpacityWrapper>
            <div>
              <div className={styles.detailedReportContainer}>
                <div className={styles.detailedReportLabel}>
                  Detailed <span className={styles.spanScore}>Report</span>
                </div>
                <div className={styles.pdfDownloadLinkWrap}>
                  {!isSample ? pdfDownload : null}
                </div>
              </div>
              <div className={styles.switchViewContainer}>
                <div className={styles.detailedReportWrap}>
                  <div className={styles.tabButtonsWrap}>
                    {this.SwitchTabButton &&
                      this.SwitchTabButton.map((tab, index) => {
                        return (
                          <button
                            key={`${tab.name}-${index}`}
                            type="button"
                            style={{
                              backgroundColor:
                                selectedIndex === index ? '#130E49' : '#ffffff',
                              color: selectedIndex === index ? '#ffffff' : '#000000'
                            }}
                            onClick={() => this.setState({ selectedIndex: index })}
                            className={styles.tabButton}
                          >
                            {tab.name}
                          </button>
                        );
                      })}
                  </div>
                  {detailReportData.length !== 0 ? this.renderCardInDetail() : null}
                </div>
                <div className={styles.resumeWrap}>
                  <div id="ResumeViewerUnique" className={styles.ResumeViewer}>
                    <ResumeViewer
                      id={Math.floor(Math.random() * 100)}
                      resumeInHTML={htmlResume === 'Error' ? '' : htmlResume}
                    />
                    {htmlResume === 'Error' ? (
                      <div className={styles.ErrorStyleWrap}>
                        <div className={styles.ErrorTitle}>Oops!</div>
                        <div className={styles.ErrorMessage}>
                          Something went wrong, Please try again...
                        </div>
                        <Button
                          text="Try again"
                          disable={isLoading}
                          onClick={() => this.getResumeInHTML(HTMLLink)}
                        />
                        {isLoading ? (
                          <Loader
                            height={window.innerWidth <= 320 ? '40' : '50'}
                            type="ThreeDots"
                            color="#457BEF"
                          />
                        ) : null}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </OpacityWrapper>
          {/* {aiResumeData && (
            <ComponentToPrint
              ref={el => (this.componentRef = el)}
              aiResumeData={aiResumeData}
              fullName={fullName}
            />
          )}
          <ReactToPrint
            trigger={() => {
              return (
                <button className={styles.CheckNavButton}>
                  Download Resume
                </button>
              );
            }}
            content={() => this.componentRef}
          /> */}
          {!isSample ? (
            <button
              id="CheckForOthers"
              className={styles.CheckNavButton}
              onClick={() => {
                const { router } = this.props;
                dispatchReportData(null);
                dispatchUpdateUserName(null);
                dispatchUpdateUserFile(null);
                dispatchUpdateHTMLLink(null);
                dispatchGetResumeInHTML(null);
                router.navigate('/');
              }}
            >
              Check for other resume
            </button>
          ) : null}
        </div>
      </React.Fragment >
    );
  }
}

const OpacityWrapper = ({ children }) => {
  const { authState, loading } = useAuth();
  const location = useLocation();
  const isSample = location?.state?.isSample;
  const navigate = useNavigate();
  console.log('isSample:', isSample);
  if (!authState && document.getElementById('CheckForOthers')) {
    document.getElementById('CheckForOthers').style.display = 'none';
  }
  const signUpToNavigate = () => {
    try {
      Utility.trackEvent('score-report', 'BUTTON_CLICK', 'Sign Up(from masked content)');
    } catch (e) {
      console.error('error in tracking in event', e);
    }

    if (authState) {
      navigate('/resume-build-home');
      // window.location.href = BASEPATH + '/resume-build-home';
    } else {
      navigate('/signup');
      //window.location.href = BASEPATH + '/signup';
    }
  }
  return (<>
    <div className={!authState && !isSample ? styles.opacityWrapper : ''}>
      {!authState && !isSample ? <div className={styles.content}>
      </div> : null}
      {children}
    </div>
    {!authState && !isSample ? <div className={styles.blurSignUpContainer}>
      <div className={styles.blurSignUpText}>
        Sign up to view your full detailed report
      </div>
      <div className={styles.blurContent}>
        Unlock additional features and land your dream job!
      </div>
      <Button
        id="SignupBtnFromBlurContent"
        text="Sign up"
        buttonStyle={styles.blurButtonStyle}
        onClick={signUpToNavigate}
      />
    </div> : null}
  </>
  );
};



export const ComponentToPrint = React.forwardRef((props, ref) => {
  const { aiResumeData, fullName } = props;

  return (
    <div className={styles.aiGeneratedContainer}>
      <div className={styles.detailedReportLabel}>
        AI Generated <span className={styles.spanScore}>Resume</span>
      </div>
      <div className={styles.aiGeneratedWrap}>
        <div ref={ref} className={styles.resumeContainer}>
          <p className={styles.resumeName}>{fullName || `John Doe`} </p>

          <div className={styles.resumeRow}>
            <p className={styles.resumeTitle}>Summary</p>
            <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Summary
              }}
            />
          </div>
          <div className={styles.resumeRow}>
            <p className={styles.resumeTitle}>Education</p>
            <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Education
              }}
            />
          </div>
          <div className={styles.resumeRow}>
            <p className={styles.resumeTitle}>Experience</p>
            <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Experience
              }}
            />
          </div>
          <div className={styles.resumeRow}>
            <p className={styles.resumeTitle}>Skills</p>
            <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Skills
              }}
            />
          </div>
          <div className={styles.resumeRow}>
            <p className={styles.resumeTitle}>Certification</p>
            <p
              className={styles.resumeDescrition}
              dangerouslySetInnerHTML={{
                __html: aiResumeData?.Certification
              }}
            />
          </div>
          {aiResumeData.Achievement && (
            <div className={styles.resumeRow}>
              <p className={styles.resumeTitle}>Achievement</p>
              <p
                className={styles.resumeDescrition}
                dangerouslySetInnerHTML={{
                  __html: aiResumeData?.Achievement
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
});

const Mailto = ({ email, subject, body, ...props }) => {
  return (
    <a
      href={`mailto:${email}?subject=${encodeURIComponent(subject) ||
        ''}&body=${encodeURIComponent(body) || ''}`}
    >
      {props.children}
    </a>
  );
};

const mapStateToProps = reduxProps => ({
  reduxProps
});
const mapDispatchToProps = dispatch => ({
  dispatchReportData: reportData => {
    dispatch(cacheReportData(reportData));
  },
  dispatchUpdateUserName: userName => {
    dispatch(updateUserName(userName));
  },
  dispatchUpdateUserFile: userFile => {
    dispatch(updateUserFile(userFile));
  },
  // dispatchUpdateFeedback: reportData => {
  //     dispatch(updateFeedBack(reportData));
  // },
  dispatchGetResumeInHTML: userFiles => {
    dispatch(getResumeInHTML(userFiles));
  },
  dispatchUpdateHTMLLink: link => {
    dispatch(updateResumeHtmlLink(link));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(ReportViewer);
