import hero from '../../../../assets/insights/evolutionOfJobHunt/hero.png';

export const meta = {
  author: "",
  date: "2024-07-02",
  type: "Insights",
  title: "The Evolution of Job Hunting",
  subTitle: " Top 6 Job Aggregators in India and U.S.A",
  description: "Remember the days of circling job ads in newspapers? Or tirelessly scouring through endless search results on primitive search engines? The way we hunt for jobs has evolved dramatically, transitioning from traditional methods to sophisticated, AI-driven platforms. Today, job aggregators are the cornerstone of the modern job search, offering a centralized hub for a vast array of opportunities.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/evolution-of-job-hunting",
};