import React from 'react';
import { LoginModal } from '../../component/LoginModal';
import { Navbar } from '../../component/Navbar';
import { Account } from '../../component/Account';
import Insights from '../../component/Insights';
import Helmet from 'react-helmet';
import data from '../MetaData/data';
import { BASEPATH } from '../../lib/constants';
import Utility from '../../utils'

class InsightsPage extends React.Component {
  componentDidMount() {
    Utility.trackEvent('insights', 'PAGE_VISIT');
  }
  render() {
    return (
      <>
        <Helmet>
          <title>{data.insightsPage.title}</title>
          <link rel="alternate" href="https://www.panna.ai/score/us/insights" hreflang="en-us" />
          <link rel="alternate" href="https://www.panna.ai/score/in/insights" hreflang="en-in" />
          <link rel="alternate" href="https://www.panna.ai/score/us/insights" hreflang="x-default" />
          <link rel="canonical" href={'https://www.panna.ai' + BASEPATH + '/insights'} />
          <meta name="title" content={data.insightsPage.metaTitle} />
          <meta name="description" content={data.insightsPage.metaDescription} />
        </Helmet>
        <Navbar />
        <Insights />
      </>
    );
  }
}

export default InsightsPage;
