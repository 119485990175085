import React from 'react';
import styles from './styles.module.css';
import SkillsTable from '../SkillsTable';
import TabularTable from '../TabularTable';
import ActionVerbsTable from '../ActionVerbsTable';

class CardDetails extends React.Component {

    getCardValueColor = data => {
        let messageColor;
        switch (data.name) {
            case 'Estimated Time to Read': {
                const timeToRead = data.value.split(' ');
                if (timeToRead[0] <= 3) {
                    messageColor = '#22A600'
                } else if (timeToRead[0] <= 5) {
                    messageColor = '#F06A4A'
                } else {
                    messageColor = '#FF0000';
                }
                return messageColor;
            }
            case 'Experience/Consistency': {
                const Consistency = data.value[0];
                return Consistency.toLowerCase() === 'true' ? '#22A600' : '#FF0000';
            }
            case 'Last Modified': {
                const lastModifiedDate = data.value;
                if (lastModifiedDate.toLowerCase() === 'recently modified' || lastModifiedDate.toLowerCase() === '1 month old') {
                    messageColor = '#22A600'
                } else if (lastModifiedDate.toLowerCase() === '2 month old' || lastModifiedDate.toLowerCase() === '3 month old') {
                    messageColor = '#F06A4A'
                } else {
                    messageColor = '#FF0000';
                }
                return messageColor;
            }
            case 'Relevance Score': {
                return data.value <= 35 ? '#f04a4a' : data.value < 65 ? '#f87108' : '#15a637';
            }
            case 'Readability': {
                return data.value === 'Easy read' ? '#15a637' : '#f04a4a';
            }
            case 'Summary/Objective': {
                return data.value === 'Yes' ? '#15a637' : '#f04a4a';
            }
            case 'Resume Percentile': {
                return data.value <= 35 ? '#f04a4a' : data.value < 75 ? '#f87108' : '#15a637';
            }
        }
    }

    render() {
        const { containerStyle } = defaultProps;
        const { data, rootDivStyle, onDeleteSkills, onDeleteCancel, onDoneDelete, deletedSkillCount, onDoneDeleteSuccess, ondeletePressed, isSample } = this.props;
        const subDivStyle = [rootDivStyle, styles.contentContainer];
        const cardName = data.name === 'Skill Match' ? 'Skill Match' : data.name;
        const computedName = cardName.split(' ');
        const dataValue = data.name === 'Resume Percentile' || data.name === 'Relevance Score' ? `${data.value}%` : data.value;
        const SkillCountTitle = 'Skill Count Comparison'.split(' ')
        return (
            <React.Fragment>
                {data.name !== 'Skill Match' && data.name !== 'Action Verbs' && data.comments && data.comments !== 'None' || data.name === 'Skill Match' || data.name === 'Action Verbs' ?
                    <React.Fragment>
                        <div className={styles.cardNameWrap}>
                            <div className={styles.detailedCardTitle}>{computedName[0]} {computedName.map((name, index) => {
                                return index !== 0 ? <span key={name} className={index === computedName.length - 1 ? styles.spanScore : null}>{`${name} `}</span> : null
                            })}</div>
                            {data.name === 'Experience/Consistency' ?
                                <div style={{ color: this.getCardValueColor(data) }} className={styles.cardValueStyle}>&nbsp;{`(${dataValue[0].toLowerCase() === 'true' ? 'Yes' : 'No'}, ${dataValue[1] === 'None' ? 'No' : dataValue[1]})`}</div>
                                : data.name !== 'Skill Match' && data.name !== 'Action Verbs' ?
                                    <div style={{ color: this.getCardValueColor(data) }} className={styles.cardValueStyle}>&nbsp;{`(${dataValue})`}</div>
                                    : null
                            }
                        </div>
                        <div style={{ marginBottom: data.name !== 'Skill Match' && data.name !== 'Action Verbs' ? window.innerWidth <= 500 ? '20px' : '20px' : '30px' }} className={styles.commentsStyle}>{data.comments}</div>
                    </React.Fragment> : null
                }
                {data.name === 'Skill Match' || data.name === 'Action Verbs' ? <div style={containerStyle} className={subDivStyle.join(' ')}>
                    <div className={data.name === 'Skill Match' ? styles.matchSkillCardValueWrapper : styles.cardValueWrapper}>
                        {data.name === 'Skill Match' ? (
                            <div className={styles.skillsMatchedWrap}>
                                <TabularTable skillCategoryData={data} jdSkills={data.jdSKillFrequency} resumeSkills={data.resumeSkillFrequency} onDeleteSkills={onDeleteSkills} onDeleteCancel={onDeleteCancel} onDoneDelete={onDoneDelete} deletedSkillCount={deletedSkillCount} onDoneDeleteSuccess={onDoneDeleteSuccess} ondeletePressed={ondeletePressed} isSample={isSample} />
                                <div className={[styles.detailedCardTitle, styles.SkillCountTitle].join(' ')}>{SkillCountTitle[0]} {SkillCountTitle.map((name, index) => {
                                    return index !== 0 ? <span key={name} className={index === SkillCountTitle.length - 1 ? styles.spanScore : null}>{`${name} `}</span> : null
                                })}</div>
                                <SkillsTable jdSkills={data.jdSKillFrequency} resumeSkills={data.resumeSkillFrequency} />
                            </div>
                        ) : data.name === 'Action Verbs' ?
                            <div className={styles.actionVerbsWrap}>
                                <ActionVerbsTable inputData={data} />
                            </div> :
                            Array.isArray(data.value) ?
                                data.value.map((val, i) => <div key={`${val}-${i}`} className={styles.cardValue}>{val}</div>) :
                                <div className={styles.cardValue}>{data.value}</div>
                        }
                    </div>
                </div> : null}
            </React.Fragment>
        )
    }
}

const defaultProps = {
    containerStyle: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
    }
}

export default CardDetails;