import hero from '../../../../assets/insights/atsResumeBuilder/hero.png';

export const meta = {
  author: "",
  date: "2024-05-01",
  type: "Insights",
  title: "Best ATS Resume Builders",
  subTitle: " Optimize Your Resume for 2024",
  description: "",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/best-ats-resume-builder",
};