/* eslint-disable */
export default {
  pageTitle: 'Panna Report',
  metaName: 'description',
  metaContent: "",
  measureAgain: 'Click here',
  reportData: {
    "Action Verbs": {
      "Achievement": {
        "Found": [
          "Secured",
          "Completed"
        ],
        "Recommended Words": [
          "Reached",
          "Attained",
          "Exceeded",
          "Succeeded",
          "Surpassed",
          "Awarded",
          "Showcased",
          "Earned"
        ]
      },
      "Analytical": {
        "Found": [],
        "Recommended Words": [
          "Forecasted",
          "Compared",
          "Estimated",
          "Concluded",
          "Analysed",
          "Investigated",
          "Assessed",
          "Examined",
          "Analyzed",
          "Evaluated"
        ]
      },
      "Communication": {
        "Found": [],
        "Recommended Words": [
          "Persuaded",
          "Communicated",
          "Addressed",
          "Informed",
          "Wrote",
          "Convinced",
          "Promoted",
          "Presented",
          "Drafted",
          "Explained"
        ]
      },
      "Creativity": {
        "Found": [],
        "Recommended Words": [
          "Authored",
          "Designed",
          "Brainstormed",
          "Fabricated",
          "Shaped",
          "Engineered",
          "Illustrated",
          "Visualized",
          "Envisioned",
          "Constructed"
        ]
      },
      "Flexibility": {
        "Found": [
          "Converted"
        ],
        "Recommended Words": [
          "Amended",
          "Adjusted",
          "Balanced",
          "Adapted",
          "Grew",
          "Incorporated",
          "Accommodated",
          "Tailored",
          "Altered"
        ]
      },
      "Initiative": {
        "Found": [
          "Established",
          "Created"
        ],
        "Recommended Words": [
          "Implemented",
          "Expanded",
          "Improved",
          "Boosted",
          "Demonstrated",
          "Accelerated",
          "Volunteered",
          "Initiated"
        ]
      },
      "Leadership": {
        "Found": [
          "Managed"
        ],
        "Recommended Words": [
          "Supervised",
          "Guided",
          "Approved",
          "Monitored",
          "Owned",
          "Coordinated",
          "Instructed",
          "Motivated",
          "Controlled"
        ]
      },
      "Organization": {
        "Found": [
          "Maintained",
          "Assembled",
          "Classified"
        ],
        "Recommended Words": [
          "Centralized",
          "Consolidated",
          "Planned",
          "Collected",
          "Arranged",
          "Organized",
          "Compiled"
        ]
      },
      "Problem Solving": {
        "Found": [],
        "Recommended Words": [
          "Improvised",
          "Customized",
          "Streamlined",
          "Explored",
          "Debugged",
          "Automated",
          "Quantified",
          "Refined",
          "Solved",
          "Strengthened"
        ]
      },
      "Teamwork": {
        "Found": [],
        "Recommended Words": [
          "Contributed",
          "Assisted",
          "Participated",
          "Collaborated",
          "Consulted",
          "Networked",
          "Cooperated",
          "Partnered",
          "Helped",
          "Mediated"
        ]
      },
      "The remaining list of action verbs": [
        "required",
        "racked",
        "included",
        "confirmed",
        "migrated",
        "added",
        "outlined"
      ]
    },
    "Comments": {
      "action_verbs_comment": "Starting your resume bullet points with action verbs creates a stronger and more appealing tone to hiring managers. We suggest including action verbs throughout your resume to demonstrate initiative while keeping your descriptions short and compelling.",
      "estimated_time_to_read_comment": "Needs work! It takes 6 min to read your resume which is much longer than the recommended time range. The time it takes to read your resume is calculated from the number of words present in your resume. On average, it is recommended to have between 500 to 700 words, which takes approximately 2 to 2.5 mins to read. However, if you have over 10 years of experience or are applying for a federal position a higher word count is acceptable.",
      "experience_comment": "We find that you have total work experience of 15 years 8 months without any gaps in between",
      "last_modified_comment": "None",
      "relevance_score_comment": "Excellent! Your score is 94% which falls into the ideal range. This means that your resume is well-targeted and on track. You can improve your score by including more keywords and skills that have been recommended in the skill match report table.",
      "resume_complexity_comment": "Excellent! Your resume is easy to read and fits the recommended readability index. The index is calculated from the average length of your sentences and the number of syllables in each word.",
      "resume_percentile_comment": "Excellent! Your Resume Percentile is 90% which means that you are amongst the top 10% of market applicants for this job. ",
      "skill_match_comment": "Excellent! Looks like your resume has most of the skills that are required for the job. You are on track and doing a great job.",
      "summary_comment": "Excellent! Your resume includes a summary/objective section which is recommended as it helps employers quickly determine if you have the skills and background they require."
    },
    "Estimated Time to Read": "6 min",
    "Experience_Consistency": {
      "Candidate_consistency": "True",
      "Candidate_experience": "15 years 8 months"
    },
    "JD Type Class": "T",
    "Jd Skill frequency": {
      "Acl": 1,
      "Active Directory": 5,
      "Altiris": 1,
      "Bmc Remedy": 1,
      "C": 1,
      "Compliance": 1,
      "Computer Software": 1,
      "Data Center": 2,
      "Design": 1,
      "Dfs": 1,
      "Dhcp": 2,
      "Dns": 4,
      "Domain Controllers": 3,
      "End User Computing": 3,
      "Go": 1,
      "Gpo": 4,
      "Imaging": 1,
      "Infrastructure": 4,
      "Integration": 1,
      "Ip": 1,
      "Itsm": 1,
      "Mdt": 1,
      "Mvp": 1,
      "Netbackup": 1,
      "Network Design": 1,
      "Network Monitoring": 1,
      "Powershell": 1,
      "Professional Development": 1,
      "Provision": 1,
      "Prtg": 1,
      "R": 8,
      "San": 2,
      "Securities": 5,
      "Solarwinds": 1,
      "Sql": 1,
      "Switches": 2,
      "Tcp": 1,
      "Tcp/Ip": 3,
      "Technical Documentation": 1,
      "Training": 3,
      "Vlan": 1,
      "Vms": 2,
      "Vmware Esxi": 3,
      "Windows": 13
    },
    "Last Modified": "Not Found",
    "Percentile score": 90,
    "Readability": "Easy read",
    "Resume Skill frequency": {
      "Acl": 2,
      "Active Directory": 13,
      "Altiris": 1,
      "Bmc Remedy": 1,
      "C": 1,
      "Compliance": 1,
      "Computer Software": 1,
      "Data Center": 3,
      "Design": 1,
      "Dfs": 3,
      "Dhcp": 6,
      "Dns": 9,
      "Domain Controllers": 6,
      "End User Computing": 3,
      "Go": 1,
      "Gpo": 4,
      "Imaging": 1,
      "Infrastructure": 6,
      "Integration": 1,
      "Ip": 2,
      "Itsm": 1,
      "Mdt": 2,
      "Mvp": 0,
      "Netbackup": 3,
      "Network Design": 1,
      "Network Monitoring": 1,
      "Powershell": 2,
      "Professional Development": 1,
      "Provision": 2,
      "Prtg": 2,
      "R": 21,
      "San": 0,
      "Securities": 16,
      "Solarwinds": 2,
      "Sql": 3,
      "Switches": 4,
      "Tcp": 2,
      "Tcp/Ip": 6,
      "Technical Documentation": 1,
      "Training": 4,
      "Vlan": 1,
      "Vms": 5,
      "Vmware Esxi": 5,
      "Windows": 38
    },
    "Skill Match": {
      "Jd Relevance score": 94,
      "Skill match score": "42/44",
      "matched": [
        "Dns",
        "Powershell",
        "Acl",
        "Vmware Esxi",
        "Altiris",
        "Network Design",
        "Dhcp",
        "Vms",
        "Securities",
        "Infrastructure",
        "Tcp/Ip",
        "Ip",
        "Computer Software",
        "Domain Controllers",
        "Dfs",
        "Solarwinds",
        "Integration",
        "Switches",
        "Vlan",
        "Compliance",
        "Sql",
        "Windows",
        "Mdt",
        "Bmc Remedy",
        "Gpo",
        "Imaging",
        "Technical Documentation",
        "Professional Development",
        "Data Center",
        "Provision",
        "Go",
        "Network Monitoring",
        "R",
        "Training",
        "C",
        "End User Computing",
        "Tcp",
        "Itsm",
        "Design",
        "Netbackup",
        "Prtg",
        "Active Directory"
      ],
      "unmatched": [
        "Mvp",
        "San"
      ]
    },
    "Summary/Objective": "Yes",
    "Uid": "5f6320a2bb9cf0c357df88ba",
    "status_code": 1
  }
}