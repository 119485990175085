export default {
    loginPage: {
        title: 'paññã login',
        metaTitle: '',
        metaDescription: ''
    },
    historyPage: {
        title: 'paññã Builder History',
        metaTitle: '',
        metaDescription: ''
    },
    profilePage: {
        title: 'paññã Builder Profile',
        metaTitle: '',
        metaDescription: ''
    },
    insightsPage: {
        title: 'paññã Insights',
        metaTitle: 'Career Insights and Tips - Optimize Your Job Applications',
        metaDescription: 'Stay ahead in your job search with our expert insights and tips. Learn how to optimize your resume, improve ATS ranking, and increase your chances of getting hired. Explore the latest trends and advice for job seekers.'
    },
    resumeBuilderHome: {
        title: 'AI Resume Builder - Create Professional Resumes Effortlessly | panna resume builder',
        metaTitle: 'Build a High-Scoring ATS Resume - AI Resume Builder Tool',
        metaDescription: 'Create an optimized resume that ranks high in ATS screenings with our Resume Builder Tool. Upload your existing resume and the job description, and let our tool generate a new resume to increase your chances of getting shortlisted. Start building your perfect resume now!'
    },
    builderReport: {
        title: 'paññã Builder Report',
        metaTitle: '',
        metaDescription: ''
    },
    scoreHomePage: {
        title: 'paññã Score - FREE Resume Scoring Tool, Match Your Resume to Job Descriptions',
        metaTitle: 'Free Resume Score Tool - Match Your Resume to Job Descriptions',
        metaDescription: 'Boost your job application success with our free AI Resume Score Checker. Upload your resume and job description to receive an ATS relevance score. Stand out to employers with our AI-powered resume checker and optimize your resume now!'
    },
    scoreReport: {
        title: 'paññã Score Report',
        metaTitle: 'paññã Score',
        metaDescription: 'Get Shortlisted for your next Interview'
    },
}