import React from 'react';
import Loader from 'react-loader-spinner';
import EditableInput from '../EditableInputField';
import globalConstants from '../../constants/globalConstants';
import styles from './styles.module.scss';
import FileImg1 from '../../assets/File Loading/resumePageUpload.png';
import FileImg2 from '../../assets/File Loading/2Uploadhover.png';
import FileImg3 from '../../assets/File Loading/file1.png';
// import uploadIcon from '../../assets/icons/uploadIcon.png';
import uploadIcon from '../../assets/score/uploadIcon.png';

import docImg from '../../assets/File Loading/documentImg.png';
import quitImg from '../../assets/jdToResume/quit.png';
import errorImg from '../../assets/File Loading/error.png';
import Button from '../Button';

const containerStyle = {
  borderRadius: '3px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  // background: '#FFFFFF',
  border: '1px dashed #B2B2B2',
  boxShadow: '0 2px 3px 0 rgba(239,239,239,0.92)',
  borderRadius: '15px',
  transition: 'all 0.6s linear'
};
const inputStyle = {
  height: '100%',
  width: '100%',
  opacity: '0',
  zIndex: '99',
  cursor: 'pointer'
};

const fileImgStyle = {
  width: '31px',
  height: '37px',
  marginBottom: '20px'
};

class FileUpload extends React.Component {
  state = {
    isHovered: false
  };
  onUploadEnter = () => {
    const { isUploading, isUploaded } = this.props;
    this.setState({ isHovered: true });
    if (!isUploaded) {
      if (!isUploading) {
        // document
        //   .getElementById('fileImg2')
        //   .classList.replace(styles.fileImg2, styles.fileImg2After);
        // document
        //   .getElementById('fileImg3')
        //   .classList.replace(styles.fileImg3, styles.fileImg3After);
      } else {
        // document
        //   .getElementById('fileImg2')
        //   .classList.replace(styles.fileImg2After, styles.fileImg2);
        // document
        //   .getElementById('fileImg3')
        //   .classList.replace(styles.fileImg3After, styles.fileImg3);
      }
    }
  };
  onUploadBlur = () => {
    const { isUploaded, isUploading } = this.props;
    this.setState({ isHovered: false });
    if (!isUploaded) {
      if (isUploading) {
        // document.getElementById('fileImg2').classList.add(styles.fileImg2);
        // document.getElementById('fileImg3').classList.add(styles.fileImg3);
      } else {
        // document
        //   .getElementById('fileImg2')
        //   .classList.replace(styles.fileImg2After, styles.fileImg2);
        // document
        //   .getElementById('fileImg3')
        //   .classList.replace(styles.fileImg3After, styles.fileImg3);
      }
    }
  };

  render() {
    const { isHovered } = this.state;
    const {
      onChange,
      inputBox,
      uploadResume,
      isUploaded,
      isUploading,
      fileName,
      resetUploadedFile,
      fullName,
      errorMessage,
      onChangeNameText,
      testSpeed,
      childNode,
      isUploadingInProgress
    } = this.props;
    const uploadAreaStyle = [inputBox, styles.uploadArea];
    return (
      <div className={styles.FileUploadContainer}>
        {fullName || isUploaded ? (
          <EditableInput
            fullName={fullName}
            onChangeNameText={onChangeNameText}
          />
        ) : (
          null
        )}
        <div className={styles.fileInputContainer}>
          <div className={styles.placeHolderForFile}>
            {isUploaded ? (
              <div className={styles.afterUploadSection}>
                <img src={docImg} alt="" className={styles.docImg} />
                <div className={styles.fileName}>Document {fileName}</div>
                <div
                  role="button"
                  className={styles.quitButton}
                  onClick={resetUploadedFile}
                  onKeyPress={resetUploadedFile}
                  tabIndex="0"
                >
                  <img src={quitImg} alt="" className={styles.quiteStyle} />
                </div>
              </div>
            ) : (
              <React.Fragment>
                <div>
                  <img
                    src={uploadIcon}
                    alt=""
                    className={[styles.fileImg].join(' ')}
                  />

                </div>
                {isUploading ? (
                  <div>
                    <Loader
                      height={window.innerWidth <= 320 ? '40' : '50'}
                      type="ThreeDots"
                      color="#457BEF"
                    />
                    {isUploaded ? (
                      <div className={styles.completedText}>Completed</div>
                    ) : (
                      <div className={styles.uploadingText}>Uploading...</div>
                    )}
                  </div>
                ) : (
                  <React.Fragment>
                    <div className={styles.placeholderText}>
                      <Button id="file-upload-btn1" onClick={() => {

                      }} text="Upload your resume" buttonStyle={styles.btn1}></Button>
                      <div className={styles.dropLabel}>
                        or drop your file here{' '}
                      </div>{' '}

                    </div>
                    {childNode}
                    <div className={styles.supportFormat}>
                      Supports : pdf, doc, docx, txt, rtf
                    </div>
                    <div className={styles.supportFormat}>Size : upto 5mb</div>
                    {errorMessage ? (
                      <div className={styles.errorContainer}>
                        <img
                          src={errorImg}
                          alt=""
                          className={styles.errorImg}
                        />
                        <div className={styles.errorText}>{errorMessage}</div>
                      </div>
                    ) : null}
                  </React.Fragment>
                )}
              </React.Fragment>
            )}
          </div>
          <div className={styles.uploadAreaStylewrap}>
            <div
              id="uploadResumeWithJdWrap"
              style={containerStyle}
              className={uploadAreaStyle.join(' ')}
              onMouseEnter={this.onUploadEnter}
              onMouseLeave={this.onUploadBlur}
            >
              {' '}
              <input
                type="file"
                id="uploadResumeWithJd"
                accept={globalConstants.resume_Accepteded_File_Format}
                style={inputStyle}
                className={uploadResume}
                onChange={onChange}
                disabled={isUploading || isUploaded || isUploadingInProgress}
                onClick={testSpeed}
              />{' '}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FileUpload;
