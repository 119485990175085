import React from 'react'
import styles from './styles.module.css'

// TODO pass style and text as props to this component
const CustomTitle = () => {
    return (
        <div className={styles.CutomTitleWrap}>
            <div className={styles.resumeText}>Resume</div>
            <div className={styles.scoreText}>Score</div>
        </div>
    );
}

export default CustomTitle;