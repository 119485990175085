/* eslint-disable */

import axios from 'axios';
import Config from '../config';
import Logger from '../Logger';
import { v4 as uuidv4 } from 'uuid';
import API from '../network';
import { BASEPATH } from '../lib/constants';



const Lib = require('speedtest.js');

const httpClient = axios.create();

httpClient.defaults.timeout = 40000;

export default class Utility {
  static uploadFile = (file, extension, success, err) => {
    const url = Config.hostName + Config.parseResume;
    const token = localStorage.getItem('token');
    const fd = new FormData();
    fd.append('file', file);
    fd.append('s3FolderName', 'test');
    fd.append('userId', Config.userId);
    fd.append('fileExtension', extension);
    fd.append('organizationId', Config.organisationId);
    fd.append('isLocationRequired', 'true');
    fd.append('isHtmlLinkRequired', 'true');
    fd.append('sourceApplication', 'resumeBuilder');

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        Authorization: `Bearer ${token}`
      }
    };
    const start = new Date().getTime();
    httpClient
      .post(url, fd, config)
      .then(response => {
        let date = new Date().getTime();
        date = new Date().getTime() - start;
        // eslint-disable-next-line no-console
        console.info('response time :', `${date} ms`);
        success(response);
      })
      .catch(error => {
        err(error);
      });
  };

  static uploadResumeToSolar = async requestData => {
    const url = Config.uploadResumeToSolar;
    const config = {
      'content-type': 'application/json'
    };
    try {
      return await httpClient
        .post(url, requestData, config)
        .then(response => {
          Logger.postLogs(
            `"resume-jd-relevance - resume parse to solar successfull", ${JSON.stringify(
              response.data
            )}`
          );
        })
        .catch(error => {
          Logger.postLogs(
            `"resume-jd-relevance - resume parse to solar Unsuccessfull", ${JSON.stringify(
              error
            )}`
          );
        });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.info('error in uploading reume to solar');
      return error;
    }
  };

  static getResumeInHtml = (htmlUrl, success, error) => {
    axios
      .get(`${htmlUrl}`, {
        responseType: 'text',
        headers: {
          Accept: 'text/plain;charset=UTF-8'
        }
      })
      .then(response => {
        success(response);
      })
      .catch(err => {
        error(err);
        console.info('error in geting html resume', err);
      });
  };

  static speedTest = getSpeed => {
    const URL =
      'https://pannaliferay.s3-us-west-2.amazonaws.com/resume/test/67316b61-2962-4007-b8e2-b7de4c28d0e2resumesamples.pdf';
    const PACKET_SIZE = 301808; // dd if=/dev/random of=item bs=50000000 count=1

    const SpeedTest = new Lib(PACKET_SIZE, URL);
    SpeedTest.runDownload(result => {
      getSpeed(`${result.toFixed(2)}mbps`);
    });
  };

  static getClientSessionId = () => {
    let sessionId = sessionStorage.getItem('sessionId');
    if (!sessionId) {
      sessionId = uuidv4();
      sessionStorage.setItem('sessionId', sessionId);
    }
    return sessionId;
  };

  static getClientIP = () => {
    let clientIp = sessionStorage.getItem('clientIP');
    return clientIp;
  };

  static trackEvent = (pageName, eventType, buttonName) => {
    const trackData = {
      ipAddress: sessionStorage.getItem('clientIP') ? sessionStorage.getItem('clientIP') : '',
      sessionId: Utility.getClientSessionId(),
      emailAddress: sessionStorage.getItem('emailAddress') ? sessionStorage.getItem('emailAddress') : '',
      userId: sessionStorage.getItem('userID') ? sessionStorage.getItem('userID') : 0,
      eventType: eventType,
      pageName: pageName,
      buttonName: buttonName ? buttonName : '',
      countryPath: BASEPATH.includes('/us') ? 'us' : 'in',
    }
    //   console.log('trackData:', trackData);
    API.getInstance().saveEvent(trackData);
  };

}
