import React from 'react'
import styles from './styles.module.css'
import data from './data';

const RenderTitle = props => {
  return (
    <div className={styles.home}>
      <div className={styles.content}>{data.title}</div>
      <div className={styles.textWrap}>
        <div className={styles.contentType}>{data.jdContent1}</div>
        <div className={styles.contentType}>{data.jdContent2}</div>
      </div>
    </div>
  );
}

export default RenderTitle;