import React from 'react';
import styles from './styles.module.scss';
import closeIcon from '../assets/icons/image.png';
import Button from '../component/Button';
import { useNavigate } from 'react-router-dom';

type Props = {
  messageObject: Array<Object>,
  onClose?: () => void,
  buttons?: Button[],
  heading?: String,
  showAsPopup?: Boolean
};

function Privacy(props: Props) {
  const {
    messageObject,
    heading,
    onClose,
    buttonText = 'Ok',
    showAsPopup = false
  } = props;

  const navigate = useNavigate();

  const handleClick = () => {
    if (onClose) onClose();
    else navigate('/');
  };

  return (
    <div className={showAsPopup ? styles.popupContainer : styles.container}>
      <div
        className={
          showAsPopup
            ? [styles.popupSubContainer].join(' ')
            : styles.subContainer
        }
      >
        <img
          alt="close"
          src={closeIcon}
          className={showAsPopup ? styles.popupClose : styles.close}
          onClick={handleClick}
        />
        <div className={styles.heading}> {heading}</div>

        <div className={styles.bodyContainer}>
          <div>
            {messageObject.map((message, idx) => {
              const { title, content } = message;
              return (
                <div key={`privacy-content-${idx}`}>
                  <div className={styles.title}>{title}</div>
                  <div className={styles.content}>{content}</div>
                </div>
              );
            })}
          </div>
          <div className={styles.buttonContainer}>
            <Button
              text={buttonText}
              id={buttonText}
              onClick={handleClick}
              key={'popup-button-okay'}
            ></Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Privacy;
