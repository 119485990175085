import React from 'react';
import InsightContainer from '../../InsightContainer';
import { meta } from '../data';
import styles from './styles.module.scss';
import { getAllInsights } from '../../../utils';
import ResumeBuildButton from '../../../ResumeBuildButton';

const Content = () => {
  return (
    <div className={styles.container}>
      <div className={styles.text}>
        Crafting a resume is not just about listing your experiences; it's about strategically 
        presenting your qualifications to match the requirements of each job you apply for. 
        A tailored resume can significantly increase your chances of landing interviews and 
        ultimately securing your dream job. In this comprehensive guide, we'll delve into the 
        art of crafting the perfect resume tailored to every job application.
      </div>

      <div className={styles.title}>
        The Importance of Tailoring
      </div>

      <div className={styles.text}>
        Your resume is your first opportunity to make an impression on a potential employer. 
        Tailoring it to each job application shows that you've taken the time to understand the 
        role and how your skills and experiences align with it. A tailored resume not only 
        highlights your qualifications but also demonstrates your enthusiasm and commitment 
        to the position. Employers are more likely to consider candidates who demonstrate a 
        genuine interest in their organization and the specific role they're applying for.
      </div>

      <div className={styles.title}>
        Analyzing Job Descriptions
      </div>

      <div className={styles.text}>
        Before you start crafting your resume, carefully review the job description for the position 
        you're applying for. Pay attention to the required qualifications, skills, and experiences 
        listed. These details will provide valuable insights into what the employer is looking for 
        and help you customize your resume accordingly. Additionally, look for any keywords or 
        phrases used repeatedly in the job description and incorporate them into your resume. 
        Many companies use applicant tracking systems (ATS) to scan resumes for specific keywords, 
        so including relevant terms can help ensure that your resume gets noticed.
      </div>

      <div className={styles.title}>
        Crafting a Customized Professional Summary
      </div>

      <div className={styles.text}>
        Your professional summary is the first section of your resume that recruiters will read. Use 
        this space to highlight your most relevant skills, experiences, and achievements. Tailor your 
        summary to align with the job requirements, using keywords and phrases from the job 
        description to demonstrate your fit for the role. Additionally, consider including a brief 
        overview of your career objectives and how they align with the company's mission and values. 
        A well-crafted professional summary can grab the recruiter's attention and entice them to 
        continue reading your resume.
      </div>

      <div className={styles.title}>
        Showcasing Relevant Work Experience
      </div>

      <div className={styles.text}>
        When listing your work experience, focus on highlighting accomplishments and responsibilities 
        that are directly relevant to the job you're applying for. Tailor your bullet points to 
        emphasize the skills and achievements that best demonstrate your qualifications for the 
        position. Quantify your accomplishments whenever possible to provide concrete evidence of 
        your abilities. For example, instead of simply stating that you "increased sales," specify 
        the percentage or dollar amount by which you increased sales and provide specific examples 
        of strategies or initiatives you implemented to achieve that growth. Additionally, consider 
        including any relevant awards, recognitions, or promotions you received during your tenure 
        at previous positions to further showcase your achievements.
      </div>

      <div className={styles.title}>
        Highlighting Key Skills
      </div>

      <div className={styles.text}>
        Create a dedicated skills section on your resume and include those that are most relevant 
        to the job at hand. Whether they're technical skills, soft skills, or industry-specific 
        competencies, make sure they align with the requirements outlined in the job description. 
        Use the same language and terminology to ensure your resume resonates with hiring managers. 
        Additionally, consider providing examples or brief descriptions of how you've demonstrated 
        each skill in previous roles to provide context and demonstrate your proficiency.
      </div>

      <div className={styles.title}>
        Tailoring Your Education and Certifications
      </div>

      <div className={styles.text}>
        While your educational background and certifications may remain consistent across 
        applications, customize this section to highlight relevant coursework, projects, or 
        certifications. Focus on credentials and experiences that directly support your 
        qualifications for the position you're applying for. For example, if you're applying for 
        a marketing position, emphasize any relevant coursework or projects related to marketing 
        strategy, consumer behavior, or digital marketing. Additionally, highlight any 
        certifications or professional development courses you've completed that are relevant 
        to the role, such as Google Analytics certification or HubSpot inbound marketing 
        certification.
      </div>

      <div className={styles.title}>
        Customizing Formatting and Design
      </div>

      <div className={styles.text}>
        In addition to customizing the content of your resume, pay attention to the formatting 
        and design. Choose a clean and professional layout that is easy to read and visually 
        appealing. Tailor the design to match the industry or company culture, using appropriate 
        fonts, spacing, and formatting elements. Consider using bullet points or bold headings to 
        break up large blocks of text and make it easier for recruiters to scan your resume 
        quickly. Additionally, ensure that your contact information is prominently displayed at 
        the top of the page and easily accessible. Finally, proofread your resume carefully to 
        check for any typos or grammatical errors, as these can detract from your professionalism 
        and attention to detail.
      </div>

      <ResumeBuildButton />

      <div className={styles.title}>
        Conclusion
      </div>

      <div className={styles.text}>
        Crafting a tailored resume for every job application is essential for maximizing your chances 
        of success in the job market. By customizing your resume to match the specific requirements 
        of each role, you demonstrate your suitability and commitment to the position. Follow the 
        tips outlined in this guide to create a resume that effectively showcases your qualifications 
        and sets you apart from the competition. With a well-crafted resume tailored to each job 
        application, you'll be well on your way to landing your dream job.
      </div>

      <div className={styles.text}>
        paññã can build you a customized resume in 3 simple steps, Its advanced AI algorithms can 
        maximize the chances of your resume passing the ATS screenings by including the right 
        skills and keywords from your resume as per the job description of the job you intend to 
        apply.
      </div>
    </div>
  );
};

const Desktop = (props) => {
  return (
    <div>
      <InsightContainer
        { ...meta }
        restInsights={getAllInsights().filter((insight) => insight.link !== meta.link)}
      >
        <Content />
      </InsightContainer>
    </div>
  );
}

export default Desktop;
