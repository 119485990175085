import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Link, useNavigate } from 'react-router-dom';
import Footer from '../../Footer/Footer';
import { PaymentModal } from '../../PaymentModal';
import { useAuth } from '../../../context/AuthContext';
import { useSelector } from 'react-redux';
import { BASEPATH } from '../../../lib/constants';
import Button from '../../Button';
import { useMediaQuery } from 'react-responsive';
import Utility from '../../../utils';

const Desktop = (props) => {

  const { authState, loading } = useAuth();
  console.log('authState', authState);
  const data = useSelector(state => state);
  console.log('store data', data.cache?.location);
  const navigate = useNavigate();
  const isPaid = authState?.paymentSessionId;
  const [open, setOpen] = useState(false);
  const isIndia = BASEPATH.includes('/in') ? true : false;

  const handleBuildResume = () => {
    Utility.trackEvent('resume-build-home', 'BUTTON_CLICK', 'Optimize My Resume Now');
    if (!authState) {
      navigate('/signup');
      return;
    }

    const emailList = ['tejaswini.vemulapally@mroads.com', 'madhav.vs@mroads.com', 'santhosh.bolla@yopmail.com', 'venkatakrishna.avula@mroads.com', 'lalitha.jampana@mroads.com', 'pradeep.dantuluri@mroads.com']
    const skipPayment = emailList.includes(authState?.userInfo?.emailAddress);
    console.log('skipPayment:', skipPayment);
    if (isPaid || skipPayment) {
      navigate('/resume-builder');
    } else {
      setOpen(true);
    }
  };
  return (

    <div className={styles.footerContainer}>
      <div className={styles.content3}>
        <div className={styles.left}>
          <div>
            <div className={styles.text}>Get started transforming your resume today!</div>

            {isIndia ? <div className={styles.heading}>   <span className={styles.priceNew}>₹499</span> <span className={styles.onlyText}>Only</span></div> :

              <div className={styles.heading}><span className={styles.priceNew}>$4.99</span> <span className={styles.onlyText}>Only</span></div>}
            <div className={styles.btn}>
              <Button id="optimize-My-Resume-Now2" onClick={() => {
                handleBuildResume();
              }} text="Optimize My Resume Now" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <PaymentModal open={open} setOpen={setOpen} />
    </div>
  );
}

export default Desktop;
