import hero from '../../../../assets/insights/crackingLinkedinCode/hero.png';

export const meta = {
  author: "",
  date: "2024-08-13",
  type: "Insights",
  title: "Cracking the LinkedIn Code",
  subTitle: " Navigating the Hidden Job Market",
  description: "When most people join LinkedIn, they think it's just another social media platform - but they couldn't be more wrong. Fast forward to 2024, and LinkedIn has become a powerhouse for professional networking, job hunting, and career advancement.",
  image: `${hero}`,
  authorAvatar: "",
  link: "/insights/cracking-the-linkedin-code",
};