import React from 'react'
import { Document, Page, Image, View, StyleSheet, Text, Font } from '@react-pdf/renderer'
import pannaLogo from '../../assets/jdToResume/pannaBlack.png';
import staticContent from './data';
import Montserrate from './font/Montserrat-Regular.ttf';
import MontserrateSemiBold from './font/Montserrat-SemiBold.ttf';
import MontserrateMedium from './font/Montserrat-Medium.ttf';
import MontserrateBold from './font/Montserrat-Bold.ttf';
import MontserrateExtraBold from './font/Montserrat-ExtraBold.ttf';
Font.register({ family: 'Montserrat', src: Montserrate});
Font.register({ family: 'MontserratSemiBold', src: MontserrateSemiBold, fontWeight: '600'});
Font.register({ family: 'MontserratMedium', src: MontserrateMedium, fontWeight: '500'});
Font.register({ family: 'MontserratBold', src: MontserrateBold, fontWeight: '700'});
Font.register({ family: 'MontserratExtraBold', src: MontserrateExtraBold, fontWeight: '800'});

const tableColumns = [
    {name: 'Skills in job description'},
    {name: 'Job description Count'},
    {name: 'Resume Count'}];

class ReportPDF extends React.Component {
    renderData;
    parsedSkillData;

    state = {
        skillData: []
    }

    componentDidMount() {
        const {cardData} = this.props;
        const skillMatchData = cardData.filter(card => card.name === 'Skill Match')
        const jdSkills = skillMatchData && skillMatchData.length !== 0 ? skillMatchData[0].jdSKillFrequency : null;
        const resumeSkills = skillMatchData && skillMatchData.length !== 0 ? skillMatchData[0].resumeSkillFrequency : null;
        const skills = jdSkills && Object.keys(jdSkills);
        const tempSkillData= [];
        skills && skills.map(skill => {
            return (
                tempSkillData.push({
                    skill: skill,
                    jdSkillCount: jdSkills[skill],
                    resumeSkillCount: resumeSkills[skill],
                })
            )
        })
        const sortDescending = tempSkillData.sort((a, b) => {
            return b.jdSkillCount - a.jdSkillCount;
        });
        this.parsedSkillData = sortDescending;
        this.setState({skillData: this.parsedSkillData})
    }

    getCardValueColor = data => {
        let messageColor;
        switch(data.name) {
            case 'Estimated Time to Read': {
                const timeToRead = data.value.split(' ');
                if (timeToRead[0] <= 3) {
                    messageColor = '#22A600'
                } else if(timeToRead[0] <= 5) {
                    messageColor = '#F06A4A'
                } else {
                    messageColor = '#FF0000';
                }
                return messageColor;
            }
            case 'Experience/Consistency': {
                const Consistency = data.value[0];
                return Consistency.toLowerCase() === 'true' ? '#22A600' : '#FF0000';
            }
            case 'Last Modified': {
                const lastModifiedDate = data.value;
                if (lastModifiedDate.toLowerCase() === 'recently modified' || lastModifiedDate.toLowerCase() === '1 month old') {
                    messageColor = '#22A600'
                } else if(lastModifiedDate.toLowerCase() === '2 month old' || lastModifiedDate.toLowerCase() === '3 month old') {
                    messageColor = '#F06A4A'
                } else {
                    messageColor = '#FF0000';
                }
                return messageColor;
            }
            case 'Relevance Score': {
                return data.value <= 35 ? '#f04a4a' : data.value <65 ? '#f87108' : '#15a637';
            }
            case 'Readability': {
                return data.value === 'Easy read' ? '#15a637' : '#f04a4a';
            }
            case 'Summary/Objective': {
                return data.value === 'Yes' ? '#15a637' : '#f04a4a';
            }
            case 'Resume Percentile': {
                return data.value <= 35 ? '#f04a4a' : data.value <75 ? '#f87108' : '#15a637';
            }
        }
    }

    renderCardItem = (cardData) => {
        const {actionVerbCount} = this.props;
        let message;
        let messageColor;
        switch(cardData.name) {
            case 'Skill Match': {
                const matchScore = cardData.score;
                const skillCounts = matchScore.split('/');
                const commonSkillCount = parseInt(skillCounts[0])
                const jdSkillCount = parseInt(skillCounts[1]);
                const matchRate = ((commonSkillCount / jdSkillCount) * 100).toFixed(0);
                if (matchRate <= 35) {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                } else if (matchRate <75) {
                    message = staticContent.goodStart;
                    messageColor = '#F06A4A'
                } else {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                }
                return (
                    <View style={styles.skillMatchStyles}>
                        <Text style={styles.cardValueStyle}>{`${matchScore}`}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                )
            }
            case 'Resume Percentile': {
                const matchRate = cardData.value;
                if (matchRate <= 35) {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                } else if (matchRate <75) {
                    message = staticContent.goodStart;
                    messageColor = '#F06A4A'
                } else {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                }
                return (
                    <View key={matchRate} style={styles.skillMatchStyles}>
                        <Text style={styles.cardValueStyle}>{`${matchRate}%`}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                )
            }
            case 'Readability': {
                const fontFamily = cardData.value;
                if (fontFamily.toLowerCase() === 'easy read') {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                } else {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                }
                return (
                    <View style={styles.ReadabilityWrap}>
                        <Text style={styles.cardValueStyle}>{fontFamily}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                );
            }
            case 'Summary/Objective': {
                const objective = cardData.value;
                if (objective.toLowerCase() === 'yes') {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                } else {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                }
                return (
                    <View className={styles.ReadabilityWrap}>
                        <Text style={styles.cardValueStyle}>{objective}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                );
            }
            case 'Action Verbs': {
                return (
                    <View className={styles.animatedNumberWrapper}>
                        <Text style={styles.cardValueStyle}>{actionVerbCount}</Text>
                        {/* <CountUp end={this.actionVerbCount} duration={2} formattingFn={value => this.formatValue(value)}/> */}
                    </View>
                )
            } 
            case 'Estimated Time to Read': {
                const timeToRead = cardData.value.split(' ');
                if (timeToRead[0] <= 3) {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                } else if(timeToRead[0] <= 5) {
                    message = staticContent.goodStart;
                    messageColor = '#F06A4A'
                } else {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                }
                return (
                    <View style={[styles.SummaryOrObjective, styles.ReadabilityWrap].join(' ')}>
                        <Text style={styles.cardValueStyle}>{cardData.value}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                );
            }
            case 'Last Modified': {
                const lastModifiedDate = cardData.value;
                if (lastModifiedDate.toLowerCase() === 'recently modified' || lastModifiedDate.toLowerCase() === '1 month old') {
                    message = staticContent.excellentJob;
                    messageColor = '#22A600'
                } else if(lastModifiedDate.toLowerCase() === '2 month old' || lastModifiedDate.toLowerCase() === '3 month old') {
                    message = staticContent.goodStart;
                    messageColor = '#F06A4A'
                } else {
                    message = staticContent.needWork;
                    messageColor = '#FF0000';
                }
                return (
                    <View style={{...styles.SummaryOrObjective, ...styles.ReadabilityWrap, backgroundColor: cardData.cardColor}}>
                        <Text style={styles.cardValueStyle}>{cardData.value}</Text>
                        <Text style={{...styles.messageStyle, color: messageColor}}>{`(${message})`}</Text>
                    </View>
                );
            }
            case 'Experience/Consistency': {
                const Consistency = cardData.value[0].toLowerCase() === 'true' ? 'Yes' : 'No';
                const experience = cardData.value[1];
                return (
                    <View style={{backgroundColor: cardData.cardColor}} className={[styles.ExperieneCard, styles.ReadabilityWrap].join(' ')} wrap>
                        {/* <img src={parseInt(timeToRead[0]) > 6 ? sadEmo : happyEmo } alt="" className={styles.rigthTick}/> */}
                        <Text style={styles.cardValueStyle}>{experience}</Text>
                        <Text style={{...styles.cardValueStyle, fontSize: 14, }}>{Consistency}</Text>
                    </View>
                );
            }
            default: {
                return (
                    <View>
                        <Text style={styles.cardValueStyle}>{cardData.value}</Text>
                    </View>
                )
            }
        }
    }

    render() {
        const {skillData} = this.state;
        const {cardData, imageSrc, actionVerbCount, title, relevanceComments} = this.props;
        return (
            <Document footerText="Montserrat">
                <Page size="A3" style={{position: 'relative', backgroundColor: '#fafafa', padding: 10, paddingTop: 40, paddingBottom: 40} } wrap>
                    <Image source={pannaLogo} style={{width: 80, height: 25, position: 'absolute', top: 0, right: 0, marginTop: 10, marginRight: 10}} fixed/>
                    {/* <View style={styles.logoWrap} fixed>
                        <Image source={pannaLogo} style={{width: 400, height: 120, opacity: 0.15, transform: 'rotate(-45deg)'}}/>
                    </View> */}
                    <Text style={styles.totalScore}>{title ? `${title}'s ` : 'Your Resume '}<Text style={{color: '#AA6DCE'}}>Report</Text></Text>
                    <View style={{...styles.progressImgWrap, width: '70%', alignSelf: 'center'}}>
                        <Text style={{...styles.heading, fontFamily: 'MontserratBold', fontSize: 22, width: '100%', textAlign: 'center', marginTop: 10}}>
                            Relevance <Text style={{fontFamily: 'MontserratBold', color: '#AA6DCE'}}>Score</Text>
                        </Text>
                        {imageSrc ? <Image source={imageSrc} style={styles.progressImg}/> : null}
                        <View style={styles.Faq}>
                            <Text style={styles.FaqText}>{relevanceComments}</Text>
                        </View>
                    </View>
                    <View style={styles.cardsWrapper} wrap={false}>
                        {cardData.map(card => {
                            return (
                                card.name !== 'Relevance Score' ? 
                                <View key={`${card.name}`} style={styles.CardContentWrapper} wrap={false}>
                                    <Text style={styles.title}>{card.name}</Text>
                                    <View style={{...styles.renderItemwrap, backgroundColor: card.cardColor}} wrap={false}>
                                        {this.renderCardItem(card)}
                                    </View>
                                </View> : null
                            )
                        })}
                    </View>
                    <Text style={styles.DetailedReportLable}>{'Detailed '}<Text style={{color: '#AA6DCE'}}>Report</Text></Text>
                    {cardData.map((card, index) => {
                        const computedName = card.name.split(' ');
                        return (
                            <View key={`${card.name}_${index}`} style={styles.containerStyle} wrap={card.name === 'Skill Match' || card.name === 'Action Verbs'}>
                                <View style={{display: 'flex', flexDirection: 'column'}}>
                                    <View style={styles.headingWrap}>
                                        <Text style={styles.heading}>{computedName[0]} {computedName.map((name, index) => {
                                                return index !== 0 ? <Text key={name} style={{color: index === computedName.length - 1 ? '#AA6DCE' : 'null'}}>{`${name} `}</Text> : null
                                        })}: </Text>
                                        {card.name === 'Skill Match' ? 
                                        <Text style={styles.matchSkillScore}>
                                            {`${card.score}`}
                                        </Text>: 
                                        card.name === 'Action Verbs' ?
                                            <Text style={styles.cardValue}>{actionVerbCount}</Text>
                                            : 
                                            <View style={{display: 'flex', flexDirection: 'row'}}>
                                                {Array.isArray(card.value) ?
                                                card.name === 'Experience/Consistency' && card && card.value.length !== 0 ? <Text key={`${card.value[0]}-${card.value[1]}`} style={{...styles.cardValue, color: this.getCardValueColor(card)}}>{`${card.value[0].toLowerCase() === 'true' ? 'Yes' : 'No'}, ${card.value[1] === 'None' ? 'No' : card.value[1]}`}</Text>  :
                                                card.value.map((val, i) => <Text key={`${val}-${i}`} style={styles.cardValue}>{val}&nbsp;</Text>) : 
                                                    <Text style={{...styles.cardValue, color: this.getCardValueColor(card)}} wrap={false}>{card.name === 'Resume Percentile' || card.name === 'Relevance Score' ? `${card.value}%` : card.value}</Text>}
                                            </View>
                                        }
                                    </View>
                                    <Text style={{...styles.FaqText, textAlign: 'justify', marginBottom: card.name === 'Skill Match' || card.name === 'Action Verbs' ? 10 : 0}}>{card.comments}</Text>
                                    <View style={styles.matchSkillCardValueWrapper}>
                                        {card.name === 'Skill Match' ? card.value &&  card.value.map((matchskill, index) => {
                                            const style = index === 0 ? styles.matchedSkillLabel : styles.unMatchedSkills;
                                            const skills = matchskill.computedSkills;
                                                    return (
                                                        <View key={`${matchskill.name}_${index}`} style={styles.skillsMatchedWrap} wrap={false}>
                                                            <View style={style}><Text>{matchskill.name}<Text>{` (${skills && skills.length})`}</Text></Text></View>
                                                            <View style={styles.computedSkills} wrap={false}>
                                                                {skills && skills.map((skill, i) => {
                                                                return <Text key={`${skill}_${i}`} style={styles.skillTextStyle}>{skill}</Text>
                                                                })}
                                                            </View>
                                                        </View>
                                                    )
                                                }) : card.name === 'Action Verbs' ? 
                                                <View style={styles.actionVerbsWrap}>
                                                    {/* <Text style={styles.cardValue}>{`$$${card.value.name}`}</Text> */}
                                                    {card.value !== 'None' && card.value.map((category, i) => {
                                                    const categoryName = category.name === 'The remaining list of action verbs' ? 'Others' : category.name;
                                                    return (
                                                        <View key={`categoryVerb_${i}`} style={styles.actionCategoryVerbsWrap} wrap={false}>
                                                            <Text style={styles.actionVerbCategory}>{`${categoryName}: `}<Text>{`(${category.value && category.value.length})`}</Text></Text>
                                                            <View style={styles.actionVerbListWrap}>
                                                                <View style={styles.actionVerbList}>
                                                                    {category.value && category.value.length !== 0 ?
                                                                        category.value && category.value.map((verb, i) => {
                                                                            return (
                                                                                <Text key={`${verb}_${i}`} style={styles.skillTextStyle}>{verb}</Text>
                                                                            )
                                                                    }) : <Text style={{...styles.skillTextStyle, opacity: '0.5'}}>None</Text>
                                                                    }
                                                                </View>
                                                                {
                                                                    category.name !== 'Others' 
                                                                    ? (
                                                                        <React.Fragment>
                                                                            <Text style={styles.suggesstionsLabel}>Suggestions</Text>
                                                                            <View style={styles.actionVerbList}>
                                                                                {category.recommended && category.recommended.length !== 0 ?
                                                                                    category.recommended && category.recommended.map((verb, i) => {
                                                                                        return (
                                                                                            <Text key={`${verb}_${i}`} style={{...styles.skillTextStyle, backgroundColor: '#EEF8EC'}}>{verb}</Text>
                                                                                        )
                                                                                }) : <Text style={{...styles.skillTextStyle, opacity: '0.5'}}>None</Text>
                                                                                }
                                                                            </View>
                                                                        </React.Fragment>
                                                                    ) : null
                                                                }
                                                            </View>
                                                        </View>
                                                    )})}
                                                </View> : null
                                        }
                                    </View>
                                </View>
                                {card.name === 'Skill Match' ? (
                                    <View style={styles.tableWrapper}>
                                        <Text style={styles.SkillCountComparison}>Skill Count Comparison</Text>
                                        <View style={styles.tableContainer}>
                                            <View style={styles.tableHeader}>
                                                {tableColumns.map((col, index) => {
                                                    return (
                                                        <View key={`${col.name}${index}`} style={{...styles.colHeader, textAlign: index === 0 ? 'left' : 'center'}}>
                                                            <Text style={styles.colHeader}>{col.name}</Text>
                                                        </View>
                                                    )
                                                })}
                                            </View>
                                            {skillData && skillData.map((skill, index) => {
                                                return (
                                                    <View key={`${skillData.skill}${index}`} style={styles.ContentContainer}>
                                                        <Text style={{...styles.colHeader, ...styles.rowStyle, textAlign: 'left'}}>{skill.skill}</Text>
                                                        <Text style={{...styles.colHeader, ...styles.rowStyle, textAlign: 'center'}}>{skill.jdSkillCount}</Text>
                                                        <Text style={{...styles.colHeader, ...styles.rowStyle, textAlign: 'center'}}>{skill.resumeSkillCount}</Text>
                                                    </View>
                                                )
                                            })}
                                        </View>
                                    </View>
                                    ): null
                                }
                                {/* {!card['Skill Match'] ? <Text style={styles.cardDescription}>{card.description}</Text> : null} */}
                        </View>
                        );
                    })}
                    <View style={styles.Footer} fixed>
                        <Text style={styles.footerText}>© Copyright {new Date().getFullYear()}. Paññã. All rights reserved.</Text>
                        <Text style={styles.footerText}>reachout@panna.com</Text>
                        <Text style={styles.footerText}>www.panna.ai/score</Text>
                    </View>
                </Page>
            </Document>
        );
    }
}

const styles = StyleSheet.create({
    containerStyle : {
        background: '#ffffff',
        padding: 15,
        width: '100%',
        display: "flex",
        flexDirection: 'column',
        justifyContent: 'space-between',
        backgroundColor: '#ffffff',
    },
    progressImgWrap: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 20,
        marginBottom: 10,
        backgroundColor: '#fffffff',
        // border: 1,
        // borderColor: '#AFAFAF',
        borderRadius: 5,
    },
    legend: {
        width: '35%',
        height : '100%',
        position: 'absolute',
        top: 0,
        right: 0,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
    },
    progressImg: {
        width: 120,
        height: 120,
        marginBottom: 20,
    },
    legendDescWrap: {
        display: 'flex',
        flexDirection: 'row',
        margin: 10,
    },
    colorBlock: {
        width: 15,
        height: 15,
        backgroundColor: '#3ABF7C',
        marginRight: 20,
    },
    lengendText: {
        fontSize: 14,
        color: '#AFAFAF',
        letterSpacing: 0.8,
    },
    logoWrap: {
        position: 'absolute',
        width: '100%',
        height: '100%', 
        zIndex: 1000,
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    headingWrap: {
        display: 'flex',
        flexDirection: 'row',
    },
    heading: {
        fontSize: 16,
        fontFamily: 'MontserratBold',
        letterSpacing: 0.8,
        marginBottom: 10,
        color: '#000000',
    }, 
    SkillCountComparison: {
        fontSize: 14,
        fontFamily: 'MontserratSemiBold',
        letterSpacing: 0.8,
        marginTop: 10,
        color: '#000000',
    }, 
    matchSkillCardValueWrapper: {
        display: 'flex',
        flexDirection: 'column',
    },
    totalScore: {
        fontFamily: 'MontserratBold',
        fontSize: 24,
        color: '#000000',
        marginBottom: 10,
        textAlign: 'center',
    },
    Faq: {
        padding: 5,
    },
    FaqText: {
        fontFamily: 'MontserratMedium',
        fontSize: 12,
        color: '#130E49',
        textAlign: 'center',
        letterSpacing: 0.8,
    },
    skillsMatchedWrap: {
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 10,
    },
    actionCategoryVerbsWrap: {
        display: 'flex',
        flexDirection: 'column',
    },
    actionVerbCategory: {
        fontSize: 14,
        fontFamily: 'MontserratSemiBold',
        letterSpacing: 0.8,
        marginBottom: 10,
        color: '#2673F1',
    },
    matchSkillScore: {
        fontSize: 16,
        fontFamily: 'MontserratBold',
        letterSpacing: 0.8,
        marginBottom: 15,
        textDecoration: 'none',
        color: '#FF8484',
    },
    matchedSkillLabel: {
        fontSize: 14,
        fontFamily: 'MontserratSemiBold',
        color: '#22A600',
        letterSpacing: 0,
        marginBottom: 10,
    },
    unMatchedSkills: {
        fontSize: 14,
        fontFamily: 'MontserratSemiBold',
        color: '#F06A4A',
        letterSpacing: 0,
        marginBottom: 10,
        marginTop: 10,
    },
    computedSkills: {
        width: '91%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
    },
    tableWrapper: {
        display: 'flex',
        flexDirection: 'column',
        paddingHorizontal: 10,
    },
    tableContainer: {
        display: 'flex',
        flexDirection: 'column',
        border: 1,
        borderStyle: 'solid',
        borderColor: '#DADADA',
        marginTop: 10,
    },
    tableHeader: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: '#DBFAFA',
    },
    colHeader: {
        flex: 1,
        fontSize: 12,
        fontFamily: 'MontserratSemiBold',
        paddingHorizontal: 5,
        paddingVertical: 3,
    },
    rowStyle: {
        fontSize: 12,
        fontFamily: 'MontserratMedium',
        color: '#000000',
        paddingHorizontal: 10,
        paddingVertical: 5,
    },
    ContentContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
    },
    actionVerbListWrap: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'flex-start',
      backgroundColor: '#ffffff',
      marginBottom: 8,
    },
    actionVerbList: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        backgroundColor: '#ffffff',
        borderRadius: 5,
        padding: 5,
    },
    suggesstionsLabel: {
        fontSize: 14,
        fontFamily: 'MontserratSemiBold',
        color: '#000000',
        paddingLeft: 10,
        textDecoration: 'underline',
        textDecorationColor: '#000000'
    },
    skillTextStyle: {
        fontSize: 12,
        fontFamily: 'MontserratMedium',
        backgroundColor: '#F4F4F4',
        borderRadius: 3,
        paddingVertical: 3,
        paddingHorizontal: 8,
        color: '#000000',
        letterSpacing: 0.8,
        margin: 5,
        marginRight: 10,
    },
    actionVerbsWrap: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        padding: 10,
        backgroundColor: ' #F8F8F8',
        borderRadius: 5,
    },
    DetailedReportLable: {
        fontSize: 14,
        fontFamily: 'MontserratBold',
        textAlign: 'left',
        marginVertical: 10,
    }, 
    cardValue: {
        fontSize: 16,
        fontFamily: 'MontserratSemiBold',
        color: '#FF8484',
        letterSpacing: 0,
    },
    cardDescription: {
        fontSize: 12,
        color: '#AFAFAF',
        letterSpacing: 0,
    },
    cardsWrapper: {
        width: '100%',
        height: 'auto',
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        flexWrap: 'wrap',
        padding: 10,
        backgroundColor: '#ffffff',
        alignSelf: 'center'
    },
    CardContentWrapper: {
        width: '32%',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        borderRadius: 4,
        padding: 20,
    },
    title: {
        fontFamily: 'MontserratSemiBold',
        fontSize: 14,
        marginBottom: 5,
    },
    renderItemwrap: {
        height: 100,
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 10,
    },
    cardValueStyle: {
        fontFamily: 'MontserratBold',
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 10,
        alignSelf: 'center',
    },
    messageStyle: {
        fontFamily: 'MontserratSemiBold',
        fontSize: 10,
        alignSelf: 'center',
    },
    Footer: {
        width: '100%',
        height: 30,
        position: 'absolute',
        bottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'center',
    },
    footerText: {
        padding: 5,
        fontSize: 12,
        color: '#AFAFAF',
        letterSpacing: 0,
    },
})

export default ReportPDF;