/* eslint-disable */
export default {
    loadingText: [
        'Gathering information',
        // 'Parsing your resume and job description',
        'Identifying core sections of your resume',
        // 'Identifying work experience and education',
        'Extracting soft and hard skills',
        // 'Comparing with keywords extracted from job description',
        // 'Calculating resume length ',
        'Identifying action verbs ',
        'Analyzing resume complexity and length',
        // 'Benchmarking your resume',
        'Calculating relevant metrics',
        'Preparing your Report',
    ]
}