import step1 from '../../assets/videos/step1.mov';
import step2 from '../../assets/videos/step2.mov';
import step3 from '../../assets/videos/step3.mov';
import step4 from '../../assets/videos/step4.mov';

export default [{
    point: 'Upload',
    pointDesc: 'Provide the job description and your resume by uploading them into our platform.',
    video: step1,
    step: 1,
},
{
    point: 'paññã AI analysis',
    pointDesc: 'paññã resume scorer scans your resume, extracting and analyzing key information such as skills, experience, and keywords.',
    video: step2,
    step: 2,

},
{
    point: 'Resume Relevance Score',
    pointDesc: 'We calculate various metrics, including skill match, action verb usage, ATS resume score, and overall compatibility with the job description.',
    video: step3,
    step: 3,
},
{
    point: 'Detailed Report',
    pointDesc: 'Receive a comprehensive report highlighting strengths, areas for improvement, and specific suggestions to enhance your resume\'s effectiveness.',
    video: step4,
    step: 4,
},

]