import { BASEPATH } from "../lib/constants";
import Config from '../config'
const sampleModule = {
  sampleEndpoint: 'restapi_endpoint'
};



console.log('process.env.REACT_APP_PAYMENT_BACK_URL', process.env.REACT_APP_PAYMENT_BACK_URL);

const session = '/session';

const auth = {
  google: '/oAuth/google',
  linkedin: '/oAuth/linkedin',
  signUp: '/account/signUp',
  login: '/account/login',
  validate: '/account/validate',
  session: '/session',
  createPaymentSession: '/payments/create-checkout-session',
  checkPaymentSession: '/payments/checkPaymentStatus',
  callbackUrl: process.env.REACT_APP_PAYMENT_BACK_URL + BASEPATH + '/payment',
  // callbackUrl: process.env.REACT_APP_PAYMENT_BACK_URL + BASEPATH + '/resume-build-home',
  // callbackUrl: 'https://app.panna.ai/score/new/payment',

  // callbackUrl: 'http://localhost:3000/score/us/payment',
  validatePayment: '/payments/checkPaymentStatus',
  logout: '/account/logout',
  updateResumeBuiltStatus: '/payments/updateResumeBuiltStatus',
  saveUserHistory: '/account-history/saveUserHistory',
  saveAIResumeEditedResponse: '/account-history/saveEditedAIResume',
  saveAIResumeEditedUrl: '/account-history/saveAIResumeEditedUrl',
  fetchUserHistory: '/account-history/fetchUserHistory',
  saveUserFeedBack: '/feed-back/saveFeedback',
  createOrder: '/payments/rp/createOrder',
  paymentSuccess: '/payments/rp/paymentSucess',
  paymentFailure: '/payments/rp/paymentFailure',
  saveScore: '/score-history/saveScore',
  fetchScore: '/score-history/fetchScore',
  uploadFilesToS3: Config.hostName + 'commonservices/api/uploadFileToS3',
  saveEvent: '/api/events/track',
  getCoverLetter: Config.getCoverLetter,
  saveCoverLetter: '/cover-letter/saveCoverLetter',
  unsubscribe: '/account/unsubscribe',
};

export { sampleModule, auth, session };
