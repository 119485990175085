import React from 'react';
import { Navbar } from '../../../component/Navbar';
import Footer1 from '../../../component/Footer1';
import FutureOfAI from '../../../component/Insights/Insight/future-of-ai-in-hiring/index';
import { Helmet } from 'react-helmet';
class FutureOfAIInsight extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>paññã Insights</title>
          <meta name="description" content="" />
          <meta name="title" content="" />
        </Helmet>
        <Navbar />
        <FutureOfAI />
        <Footer1 />
      </>
    );
  }
}

export default FutureOfAIInsight;
