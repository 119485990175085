import React from 'react';
import { Navbar } from '../../../component/Navbar';
import Footer1 from '../../../component/Footer1';
import OptimizeResume from '../../../component/Insights/Insight/tips-to-optimize-resume/index';
import { Helmet } from 'react-helmet';
class OptimizeResumeInsight extends React.Component {
  render() {
    return (
      <>
        <Helmet>
          <title>paññã Insights</title>
          <meta name="description" content="Increase your chances of getting hired with our comprehensive tips to optimize your resume for ATS. Discover how to effectively use keywords, structure your resume, and make it ATS-friendly to stand out to employers. Learn more on Paññã Insights!" />
          <meta name="title" content={'Top Tips to Optimize Your Resume for ATS - Paññã Insights'} />
        </Helmet>

        <Navbar />
        <OptimizeResume />
        <Footer1 />
      </>
    );
  }
}

export default OptimizeResumeInsight;
